import React, { useState } from 'react';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import { generatePublishingSummary } from '../store/actions';
import { getAssetsLoading, getAssetsSummary } from '../store/selectors';

const styles = (theme) => ({
  tableHead: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  emphasize: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  backdrop: {
    zIndex: 999,
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
  },
  dateTimePicker: {
    marginTop: 8,
    marginBottom: 16,
    marginRight: 8,
    width: 250,
  },
});

const ImageSummaryDialog = ({
  classes,
  isLoading,
  summary,
  generatePublishingSummary,
}) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState({ isSuccess: false, content: null });
  const [selectedDate, setSelectedDate] = useState(new Date());

  const onOpenClick = () => {
    const now = new Date();

    generatePublishingSummary(now.getTime()).then(() => {
      setSelectedDate(now);
      setOpen(true);
    });
  };

  const onCloseClick = () => {
    setOpen(false);
  };

  const onEffectiveDatetimeChange = (selectedDate) => {
    setSelectedDate(selectedDate);
    generatePublishingSummary(selectedDate.getTime());
  };

  const onNowClick = () => {
    const now = new Date();
    setSelectedDate(now);
    generatePublishingSummary(now.getTime());
  };

  const resetMessage = () => {
    setMessage({ isSuccess: false, message: null });
  };

  return (
    <>
      <Tooltip title="Show summary">
        <span>
          <Button
            aria-label="Summary"
            color="primary"
            variant="contained"
            onClick={onOpenClick}
            disabled={isLoading}
          >
            Summary
          </Button>
        </span>
      </Tooltip>
      <Dialog
        open={open}
        maxWidth="xl"
        fullWidth={true}
        onClose={onCloseClick}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Publishing Summary</DialogTitle>
        <DialogContent>
          <Backdrop className={classes.backdrop} open={isLoading}>
            <CircularProgress />
          </Backdrop>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <MobileDateTimePicker
              slotProps={{
                textField: { variant: 'standard', fullWidth: true },
              }}
              ampm={false}
              value={selectedDate}
              onChange={onEffectiveDatetimeChange}
              format="EEE, MMM dd, yyyy HH:mm"
              showTodayButton
              label="Effective Datetime"
              className={classes.dateTimePicker}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={onNowClick}
              disabled={isLoading}
            >
              Now
            </Button>
          </Grid>
          <TableContainer component={Paper}>
            <Table aria-label="Image Summary Table">
              <TableHead>
                <TableRow>
                  <TableCell classes={{ head: classes.tableHead }}>
                    Name
                  </TableCell>
                  <TableCell
                    align="right"
                    classes={{ head: classes.tableHead }}
                  >
                    Total
                  </TableCell>
                  <TableCell
                    align="right"
                    classes={{ head: classes.tableHead }}
                  >
                    Published - All
                  </TableCell>
                  <TableCell
                    align="right"
                    classes={{ head: classes.tableHead }}
                  >
                    Published - Active
                  </TableCell>
                  <TableCell
                    align="right"
                    classes={{ head: classes.tableHead }}
                  >
                    Published - Expired
                  </TableCell>
                  <TableCell
                    align="right"
                    classes={{ head: classes.tableHead }}
                  >
                    Published - Scheduled
                  </TableCell>
                  <TableCell
                    align="right"
                    classes={{ head: classes.tableHead }}
                  >
                    Top Stories - All
                  </TableCell>
                  <TableCell
                    align="right"
                    classes={{ head: classes.tableHead }}
                  >
                    Top Stories - Active
                  </TableCell>
                  <TableCell
                    align="right"
                    classes={{ head: classes.tableHead }}
                  >
                    Top Stories - Scheduled
                  </TableCell>
                  <TableCell
                    align="right"
                    classes={{ head: classes.tableHead }}
                  >
                    Not Published - Up-To-Date
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {summary.map((category) => (
                  <TableRow key={category.name}>
                    <TableCell
                      component="th"
                      scope="row"
                      classes={{ body: classes.emphasize }}
                    >
                      {category.name}
                    </TableCell>
                    <TableCell align="right">{category.total}</TableCell>
                    <TableCell align="right">{category.publishedAll}</TableCell>
                    <TableCell
                      align="right"
                      classes={{ body: classes.emphasize }}
                    >
                      {category.publishedActive}
                    </TableCell>
                    <TableCell align="right">
                      {category.publishedExpired}
                    </TableCell>
                    <TableCell align="right">
                      {category.publishedInactive}
                    </TableCell>
                    <TableCell align="right">
                      {category.topStoriesAll}
                    </TableCell>
                    <TableCell
                      align="right"
                      classes={{ body: classes.emphasize }}
                    >
                      {category.topStoriesActive}
                    </TableCell>
                    <TableCell align="right">
                      {category.topStoriesInactive}
                    </TableCell>
                    <Tooltip
                      title={
                        <>
                          <Typography style={{ fontSize: 'inherit' }}>
                            <b>Not published - All</b>:{' '}
                            {category.notPublishedAll}
                          </Typography>
                          <Typography style={{ fontSize: 'inherit' }}>
                            <b>Not published - Out-Of-Date</b>:{' '}
                            {category.notPublishedOutOfDate}
                          </Typography>
                          <Typography style={{ fontSize: 'inherit' }}>
                            <b>Not published - Up-To-Date</b>:{' '}
                            {category.notPublishedUpToDate}
                          </Typography>
                        </>
                      }
                    >
                      <TableCell align="right">
                        {category.notPublishedUpToDate}
                      </TableCell>
                    </Tooltip>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCloseClick} variant="contained" disabled={false}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={!!message.content}
        autoHideDuration={7000}
        onClose={resetMessage}
      >
        <Alert
          severity={message.isSuccess ? 'success' : 'error'}
          action={
            <Button size="small" color="secondary" onClick={resetMessage}>
              Dismiss
            </Button>
          }
        >
          {message.content}
        </Alert>
      </Snackbar>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    summary: getAssetsSummary(state),
    isLoading: getAssetsLoading(state),
  };
};

export default compose(
  connect(mapStateToProps, {
    generatePublishingSummary,
  }),
  withStyles(styles)
)(ImageSummaryDialog);
