import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Font,
  Link,
  Page,
  StyleSheet,
  Text,
  View,
  Image,
} from '@react-pdf/renderer';
import { format } from 'date-fns';
import _ from 'lodash';
import { formatDate } from '../utils';

const landingPageUrl = 'https://worldinbeauty.app.link';

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: '/Roboto-Medium.ttf',
      fontWeight: 500,
    },
    {
      src: '/Roboto-Regular.ttf',
      fontWeight: 400,
    },
  ],
});

Font.registerHyphenationCallback((word) => [word]);

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    alignItems: 'center',
    padding: '10 60',
  },
  logo: {
    width: 140,
    margin: '20 0 10 0',
    position: 'absolute',
    top: 20,
  },
  coverImage: {
    width: 480,
    margin: '30 0 10 0',
  },
  title: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: 20,
    color: '#000096',
    marginBottom: 2,
    textTransform: 'uppercase',
  },
  reportDate: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: 12,
    color: '#000096',
    marginBottom: 10,
  },
  fromSection: {
    width: '100%',
    flexDirection: 'column',
    alignItems: 'flex-start',
    margin: '2 0',
  },
  toSection: {
    width: '100%',
    flexDirection: 'column',
    alignItems: 'flex-end',
    margin: '2 0',
  },
  sectionTitle: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: 14,
    color: '#000096',
  },
  companyName: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: 14,
  },
  contact: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 12,
  },
  line: {
    width: '100%',
    marginTop: 2,
    marginBottom: 2,
    borderBottom: '2 solid #000096',
  },
  preview: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 100,
    transform: 'rotate(-45deg)',
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'rgba(183, 28, 28, 0.5)',
  },
});

const PdfCoverPage = ({
  report: { assets, client, fromTime, toTime, createdAt, id, type },
  author,
  isPreview,
}) => {
  const { totalImages, totalVideos } = useMemo(() => {
    let images = 0;
    let videos = 0;
    assets.forEach((asset) => {
      _.forEach(asset.sections, (section) => {
        if (section.hasVideo) {
          videos++;
        } else {
          images++;
        }
      });
    });

    return { totalImages: images, totalVideos: videos };
  }, [assets]);

  return (
    <Page size="A4" style={styles.page}>
      <Link src={landingPageUrl}>
        <Image src="/screenshots/report_cover.jpg" style={styles.coverImage} />
      </Link>
      <Image src="/wib-blue-tm-logo-1000x500.png" style={styles.logo} />
      <Text style={styles.title}>
        Editorial Report{type === 'cover-story' && ' - Cover Story'}
      </Text>
      <Text style={styles.reportDate}>
        {format(new Date(createdAt), 'MMM-dd-yyyy')}
      </Text>
      <Text style={{ ...styles.sectionTitle, alignSelf: 'flex-start' }}>
        From
      </Text>
      <hr style={styles.line} />
      <View style={styles.fromSection}>
        <Text style={styles.companyName}>{author.companyName}</Text>
        <Text style={styles.contact}>
          {author.address.city ? `${author.address.city} - ` : ''}
          {author.address.country}
        </Text>
        <Text style={styles.contact}>{author.email}</Text>
        <Text style={styles.contact}>{author.phone}</Text>
      </View>
      {!_.isEmpty(client) && (
        <>
          <Text style={{ ...styles.sectionTitle, alignSelf: 'flex-end' }}>
            To
          </Text>
          <hr style={styles.line} />
          <View style={styles.toSection}>
            <Text style={styles.companyName}>{client.companyName}</Text>
            <Text style={styles.contact}>
              {client.address.city ? `${client.address.city} - ` : ''}
              {client.address.country}
            </Text>
            <Text style={styles.contact}>{client.email}</Text>
            <Text style={styles.contact}>{client.phone}</Text>
          </View>
          <Text style={{ ...styles.sectionTitle, alignSelf: 'flex-start' }}>
            Info
          </Text>
          <hr style={styles.line} />
          <View style={styles.fromSection}>
            {id && <Text style={styles.contact}>{`Report ID: ${id}`}</Text>}
            <Text style={styles.contact}>{`Period: ${formatDate(
              fromTime
            )} - ${formatDate(toTime)}`}</Text>
            <Text
              style={styles.contact}
            >{`Total articles: ${assets.length}`}</Text>
            {totalImages > 0 && (
              <Text
                style={styles.contact}
              >{`Total images: ${totalImages}`}</Text>
            )}
            {totalVideos > 0 && (
              <Text
                style={styles.contact}
              >{`Total videos: ${totalVideos}`}</Text>
            )}
          </View>
        </>
      )}
      {isPreview && (
        <View style={styles.preview}>
          <Text>Preview</Text>
        </View>
      )}
    </Page>
  );
};

PdfCoverPage.propTypes = {
  author: PropTypes.any.isRequired,
  isPreview: PropTypes.bool,
  report: PropTypes.shape({
    assets: PropTypes.array.isRequired,
    client: PropTypes.any.isRequired,
    createdAt: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    fromTime: PropTypes.number,
    id: PropTypes.string,
    toTime: PropTypes.number,
  }),
};

export default PdfCoverPage;
