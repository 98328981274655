import React, { useEffect, useState } from 'react';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Link from '@mui/material/Link';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';
import { Eye, EyeOff } from 'mdi-material-ui';
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import { useForm } from 'react-hook-form';
import {
  signIn,
  facebookSignIn,
  googleSignIn,
  unloadAuthPage,
} from '../../store/actions';
import { selectAuthProcessing, getError } from '../../store/selectors';
import { emailPattern } from '../../utils';
import Copyright from '../../components/Copyright';
import ControllerTextField from '../../components/react-hook-form-wrappers/controller-text-field';

// DO NOT Delete! May need in the future
// import GoogleLogin from 'react-google-login';
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
// import { Facebook, Google } from 'mdi-material-ui';

const styles = (theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(2),
    width: theme.spacing(20),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(4, 0, 2),
  },
});

const SignIn = ({ errorMessage, unloadAuthPage, signIn, classes }) => {
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    return () => {
      unloadAuthPage();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    control,
    formState: { isSubmitted, isSubmitting, isValid },
    handleSubmit,
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onSubmit = (formValues) => {
    return signIn(formValues);
  };

  // DO NOT Delete! May need in the future
  // onFacebookResponse = (response) => {
  //   const payload = {
  //     accessToken: response.accessToken,
  //   };
  //   this.props.facebookSignIn(payload).then(() => {
  //     // FIXME:
  //     if (this.props.errorMessage) {
  //       console.log(this.props.errorMessage);
  //     }
  //   });
  // };

  // onGoogleResponse = (response) => {
  //   const payload = {
  //     accessToken: response.accessToken,
  //   };
  //   this.props.googleSignIn(payload).then(() => {
  //     // FIXME:
  //     if (this.props.errorMessage) {
  //       console.log(this.props.errorMessage);
  //     }
  //   });
  // };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img
          className={classes.logo}
          alt="WiB blue trademark logo"
          src="/wib-blue-tm-logo-1000x500.png"
        />
        <Typography component="h1" variant="h5" color="primary">
          Sign in
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <ControllerTextField
                name="email"
                label="Email Address"
                control={control}
                rules={{
                  required: 'Required',
                  pattern: {
                    value: emailPattern,
                    message: 'Invalid email address',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <ControllerTextField
                name="password"
                label="Password"
                control={control}
                rules={{
                  required: 'Required',
                  minLength: {
                    value: 8,
                    message: 'Must be at least 8 characters',
                  },
                }}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          setShowPassword((currentState) => !currentState)
                        }
                        size="large"
                      >
                        {showPassword ? <EyeOff /> : <Eye />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Button
            className={classes.submit}
            color="primary"
            disabled={isSubmitting || (isSubmitted && !isValid)}
            fullWidth
            type="submit"
            variant="contained"
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link
                underline="none"
                href="/request-password-reset"
                variant="body2"
              >
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link underline="none" href="/register" variant="body2">
                {"Don't have an account? Register"}
              </Link>
            </Grid>
          </Grid>
        </form>

        {/* DO NOT Delete! May need in the future */}
        {/* <Box m={3}>
            <Typography variant="body2" color="textSecondary">
              Or Sign In With
            </Typography>
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <FacebookLogin
                appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                fields="name,email,picture"
                scope="public_profile,email"
                version="7.0"
                callback={this.onFacebookResponse}
                render={(renderProps) => (
                  <Button
                    color="primary"
                    disabled={renderProps.isProcessing}
                    fullWidth
                    onClick={renderProps.onClick}
                    startIcon={<Facebook />}
                    variant="outlined"
                  >
                    Facebook
                  </Button>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <GoogleLogin
                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                buttonText="Google Login"
                onSuccess={this.onGoogleResponse}
                onFailure={this.onGoogleResponse}
                render={(renderProps) => (
                  <Button
                    color="secondary"
                    disabled={renderProps.disabled}
                    fullWidth
                    onClick={renderProps.onClick}
                    startIcon={<Google />}
                    variant="outlined"
                  >
                    Google
                  </Button>
                )}
              />
            </Grid>
          </Grid> */}
      </div>
      <Copyright />
      <Snackbar open={!!errorMessage}>
        {errorMessage !== 'Email is not verified' ? (
          <Alert severity="error">{errorMessage}</Alert>
        ) : (
          <Alert
            severity="error"
            action={
              <Link
                underline="none"
                href="/request-verification-email"
                variant="body2"
              >
                Click here
              </Link>
            }
          >
            Email is not verified. Have not received verification email?
          </Alert>
        )}
      </Snackbar>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    isProcessing: selectAuthProcessing(state),
    errorMessage: getError(state),
  };
};

export default compose(
  connect(mapStateToProps, {
    signIn,
    facebookSignIn,
    googleSignIn,
    unloadAuthPage,
  }),
  withStyles(styles)
)(SignIn);
