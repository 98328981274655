import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { Controller } from 'react-hook-form';

const ControllerCheckbox = ({
  control,
  name,
  label,
  disabled,
  ...otherProps
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, ...otherField } }) => {
        return (
          <FormControlLabel
            control={
              <Checkbox
                checked={value}
                {...otherField}
                disabled={disabled}
                {...otherProps}
              />
            }
            label={label}
          />
        );
      }}
    />
  );
};

export default ControllerCheckbox;
