import React, { useEffect, useMemo, useRef, useState } from 'react';

import {
  Alert,
  Backdrop,
  Button,
  Card,
  CircularProgress,
  Collapse,
  CssBaseline,
  Grid,
  Link,
  MenuItem,
  Snackbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { addDays, endOfDay, differenceInDays } from 'date-fns';
import {
  useFieldArray,
  useForm,
  useFormState,
  useWatch,
} from 'react-hook-form';
import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';
import { Prompt, useParams } from 'react-router-dom';
import _ from 'lodash';
import omitDeep from 'omit-deep-lodash';

import localforage from 'localforage';

import {
  getAssetsErrorMessage,
  getAssetsLoading,
  getAssetsMessage,
  selectCategories,
  getClients,
  getCurrentUser,
  getEditingAsset,
  selectAssetsPreferences,
  selectClientsLoading,
  selectCategoriesLoading,
  selectAuthProcessing,
} from '../../../store/selectors';
import {
  loadCategories,
  loadAsset,
  loadClients,
  addAsset,
  duplicateAsset,
  updateAsset,
  removeImage,
  removeS3Objects,
  clearEditingAsset,
  clearAssetErrorMessage,
  clearAssetMessage,
  unloadClients,
} from '../../../store/actions';
import { formatDateTime } from '../../../utils';
import {
  FONT_STYLE,
  IMAGE_MODE,
  MAX_NUMBER_OF_SECTIONS,
  TEXT_STYLE,
} from '../constants';

import ControllerCheckbox from '../../../components/react-hook-form-wrappers/controller-checkbox';
import ControllerTextField from '../../../components/react-hook-form-wrappers/controller-text-field';
import PreviewDialog from '../../../components/PreviewDialog';
import HistoryChart from '../../../components/HistoryChart';
import FabSavePreview from '../../../components/fab-save-preview';
import SectionFields from './section-fields';
import StyledTextField from './styled-text-field';
import ReplicateTextField from './replicate-text-field';
import CheckboxAndDateTimePicker from './checkbox-and-date-time-range-picker';
import YesNoDialog from '../../../components/yes-no-dialog';
import useCoverStory from '../../../components/custom-hooks/use-cover-story';
import usePrevious from '../../../components/custom-hooks/use-previous';
import useFormPersist from '../../../components/custom-hooks/use-form-persist';

const styles = (theme) => ({
  container: {},
  title: {
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  backdrop: {
    zIndex: 999,
    backgroundColor: 'rgba(255, 255, 255, 0.6)',
  },
  countdown: {
    display: 'flex',
    alignItems: 'center',
  },
  copyright: {
    marginBottom: theme.spacing(4),
  },
});

let renderCount = 0;

const renderOptions = (options) => {
  return options.map((option) => {
    return (
      <MenuItem key={option.id} value={option.id}>
        {option.name}
      </MenuItem>
    );
  });
};

const renderCategoryOptions = (categories) => {
  return _.map(categories, (category) => {
    if (category.isTopStories) {
      return null;
    }
    return (
      <MenuItem key={category.id} value={category.id}>
        {category.name}
      </MenuItem>
    );
  });
};

const renderClientOptions = (clientOptions) => {
  return clientOptions.map((client) => {
    return (
      <MenuItem key={client.id} value={client.id}>
        {client.companyName}
      </MenuItem>
    );
  });
};

const ShortTitleOrPromotionalTextFields = ({
  control,
  watchField,
  disabled,
}) => {
  const categoryId = useWatch({ control, name: watchField });
  const promoteInTopStories = useWatch({
    control,
    name: 'promoteInTopStories',
  });

  const coverStoryCategory = useCoverStory();
  const isCoverStory = coverStoryCategory?.id === categoryId;

  if (!isCoverStory) {
    return (
      <Grid item xs={12}>
        <ControllerTextField
          control={control}
          label="Short Title (Home Screeen)"
          name="shortTitle"
          placeholder="Enter Short Title"
          countWords
          showCopyButton
          maxWordCount={10}
          disabled={disabled}
        />
      </Grid>
    );
  }

  return (
    <>
      <Grid item xs={12}>
        <ControllerCheckbox
          control={control}
          label="Show Promotion In Top Stories"
          name="promoteInTopStories"
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12}>
        <StyledTextField
          control={control}
          label="Promotional Text"
          name="promotionalText"
          placeholder="Enter Promotional Text"
          watchStyleField="promotionalTextStyle"
          multiline
          countWords
          showCopyButton
          maxWordCount={15}
          disabled={!promoteInTopStories || disabled}
          required={promoteInTopStories}
        />
      </Grid>
      <Grid item xs={12}>
        <ControllerTextField
          select
          control={control}
          label="Promotional Text Style"
          name="promotionalTextStyle"
          placeholder="Choose a style for promotional text"
          disabled={!promoteInTopStories || disabled}
          required={promoteInTopStories}
        >
          {renderOptions(TEXT_STYLE)}
        </ControllerTextField>
      </Grid>
    </>
  );
};

const AuthorField = ({ control, watchField, disabled, ...otherProps }) => {
  const showAuthor = useWatch({ control, name: watchField });
  return (
    <ControllerTextField
      control={control}
      disabled={!showAuthor || disabled}
      required={showAuthor}
      {...otherProps}
    />
  );
};

const CheckboxWatchIsPublished = ({ control, disabled, ...otherProps }) => {
  const isPublished = useWatch({ control, name: 'isPublished' });
  return (
    <ControllerCheckbox
      control={control}
      disabled={isPublished || disabled}
      {...otherProps}
    />
  );
};

const PublishTimeCheckbox = ({ control, getValues, setValue, disabled }) => {
  const isCompleted = useWatch({ control, name: 'isCompleted' });
  const isOutOfDate = useWatch({ control, name: 'isOutOfDate' });
  const publishDate = useWatch({ control, name: 'publishDate' });
  const { defaultValues: initialValues } = useFormState({ control });
  return (
    <CheckboxAndDateTimePicker
      control={control}
      getValues={getValues}
      setValue={setValue}
      checkboxLabel={
        publishDate?.getTime() > Date.now() ? 'Will Be Published' : 'Published'
      }
      checkboxName="isPublished"
      checkboxDisabled={!isCompleted || isOutOfDate || disabled}
      startDateLabel="Published At"
      startDateName="publishDate"
      startDateDisabled={
        initialValues.isPublished &&
        initialValues.publishDate?.getTime() < Date.now() &&
        initialValues.cutOffDate?.getTime() > Date.now()
      }
      endDateLabel="Cut-off At"
      endDateName="cutOffDate"
      endDateMin={publishDate}
    />
  );
};

const TopStoryTimeCheckbox = ({ control, getValues, setValue, disabled }) => {
  const isPublished = useWatch({ control, name: 'isPublished' });
  const publishDate = useWatch({ control, name: 'publishDate' });
  const cutOffDate = useWatch({ control, name: 'cutOffDate' });
  const categoryId = useWatch({ control, name: 'categoryId' });
  const topStoryFromDate = useWatch({ control, name: 'topStoryFromDate' });
  const isTopStory = useWatch({ control, name: 'isTopStory' });
  const { defaultValues: initialValues } = useFormState({ control });

  const coverStoryCategory = useCoverStory();
  const isCoverStory = coverStoryCategory?.id === categoryId;

  useEffect(() => {
    if (isTopStory) {
      const startDate = getValues('topStoryFromDate');
      const endDate = getValues('topStoryToDate');
      const diffInDays = differenceInDays(
        endOfDay(publishDate),
        endOfDay(startDate)
      );
      setValue('topStoryFromDate', publishDate);
      setValue('topStoryToDate', addDays(endDate, diffInDays));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTopStory, setValue]);

  useEffect(() => {
    if (cutOffDate?.getTime() <= Date.now()) {
      setValue('isTopStory', false);
    }
  }, [cutOffDate, setValue]);

  useEffect(() => {
    if (!isPublished) {
      setValue('isTopStory', false);
    }
  }, [isPublished, setValue]);

  useEffect(() => {
    if (isCoverStory) {
      setValue('isTopStory', false);
    }
  }, [isCoverStory, setValue]);

  if (!isPublished || isCoverStory) {
    return null;
  }

  return (
    <CheckboxAndDateTimePicker
      control={control}
      getValues={getValues}
      setValue={setValue}
      checkboxLabel={
        topStoryFromDate?.getTime() > Date.now()
          ? 'Will Be Top Story'
          : 'Top Story'
      }
      checkboxName="isTopStory"
      checkboxDisabled={cutOffDate?.getTime() < Date.now() || disabled}
      startDateLabel="From"
      startDateName="topStoryFromDate"
      startDateDisabled={
        initialValues.isTopStory &&
        initialValues.topStoryFromDate?.getTime() < Date.now() &&
        initialValues.topStoryToDate?.getTime() > Date.now()
      }
      startDateMin={publishDate}
      startDateMax={cutOffDate}
      endDateLabel="To"
      endDateName="topStoryToDate"
      endDateMin={topStoryFromDate}
      endDateMax={cutOffDate}
    />
  );
};

const DEFAULT_SECTION_VALUES = {
  originalFileName: '',
  imageMode: '',
  hasVideo: false,
  youTubeUrl: '',
  videoIds: '',
  videoCredit: '',
  caption: '',
  imageCredit: '',
  imageAltText: '',
  titleOne: '',
  titleOneStyle: FONT_STYLE.BOLD,
  descriptionOne: '',
  titleTwo: '',
  titleTwoStyle: FONT_STYLE.BOLD_ITALIC,
  descriptionTwo: '',
  citation: '',
  sourceUrl: '',
  imageCopyright: '',
  expanded: true,
};

const AssetDetail = ({
  classes,
  initialValues,
  loadAsset,
  clearEditingAsset,
  unloadClients,
  loadCategories,
  loadClients,
  isProcessing,
  categories,
  clients,
  editingAsset,
  preferences,
  removeImage,
  removeS3Objects,
  push,
  clearAssetErrorMessage,
  assetErrorMessage,
  addAsset,
  updateAsset,
  duplicateAsset,
  currentUser,
}) => {
  const { assetId } = useParams();
  const isEditing = useMemo(() => !!assetId && assetId !== 'create', [assetId]);
  const isAdminOrEditor = useMemo(() => {
    let isAdmin = false;
    let isEditor = false;

    if (currentUser) {
      isAdmin = currentUser.role === 'admin' || currentUser.role === 'root';
      isEditor =
        currentUser.permissions.assetInsert &&
        currentUser.permissions.assetModify &&
        currentUser.permissions.assetRead;
    }

    return isAdmin || isEditor;
  }, [currentUser]);
  const previousInitialValues = usePrevious(initialValues);
  const coverStoryCategory = useCoverStory();

  const [newSectionIndex, setNewSectionIndex] = useState(null);
  const [successfullySavedAssetId, setSuccessfullySavedAssetId] =
    useState(null);

  const [showRestoreDialog, setShowRestoreDialog] = useState(false);
  const [hideAllSections, setHideAllSections] = useState(false);
  const [collapseAllSections, setCollapseAllSections] = useState(false);

  const [errorMessage, setErrorMessage] = useState(null);

  const [isSaving, setIsSaving] = useState(false);

  // NOTE: Robyn is getting multiple submissions when clicking
  // This is a temporary fix
  const isSubmittingRef = useRef(false);

  const clientOptions = useMemo(() => {
    return [{ id: 'none', companyName: '<< None >>' }, ...(clients || [])];
  }, [clients]);

  // Remove items from localforage that are older than 10 days
  useEffect(() => {
    const now = Date.now();
    const tenDays = 10 * 24 * 60 * 60 * 1000; // 10 days
    localforage
      .iterate((value, key) => {
        const { __timestamp } = value;

        if (now - __timestamp >= tenDays) {
          localforage.removeItem(key);
        }
      })
      .then(() => {
        // ignore
      })
      .catch((err) => {
        console.log('[localforage.iterate] Error:', err);
      });
  }, []);

  useEffect(() => {
    loadCategories({ pageSize: 100 });
    loadClients({ pageSize: 300 });
    if (isEditing) {
      loadAsset(assetId).then(() => {
        // make sure hide loader after creating new article
        setIsSaving(false);
      });
    }

    renderCount = 0;

    return () => {
      clearEditingAsset();
      unloadClients();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetId]);

  renderCount += 1;

  const [showOriginalImages, setShowOriginalImages] = useState(false);
  const [showPreview, setShowPreview] = useState(false);

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    reset,
    resetField,
    setError,
    clearErrors,
    formState: { isDirty, isSubmitting },
  } = useForm({
    defaultValues: {
      assetId: '',
      slug: '',
      categoryId: '',
      sections: [
        {
          ...DEFAULT_SECTION_VALUES,
        },
      ],
      tags: '',
      clientId: '',
      shortTitle: '',
      promoteInTopStories: false,
      promotionalText: '',
      promotionalTextStyle: FONT_STYLE.NORMAL,
      showAuthor: false,
      author: `By ${currentUser.firstName} ${currentUser.lastName}, WiB`,
      sourceUrl: '',
      likesCount: 0,
      sourceUrlCount: 0,
      createdAt: '',
      updatedAt: '',
      isCompleted: false,
      isOutOfDate: false,
      isPublished: false,
      publishDate: new Date(),
      cutOffDate: endOfDay(addDays(new Date(), preferences?.cutOffTimeDs)),
      isTopStory: false,
      topStoryFromDate: new Date(),
      topStoryToDate: endOfDay(
        addDays(new Date(), preferences?.topStoriesTimeDs)
      ),
      seoTitle: '',
      seoDescription: '',
      seoH1Title: '',
    },
  });

  const { clearPersist, stopPersistInterval } = useFormPersist({
    name: assetId ? `asset-details-form-${assetId}` : 'asset-details-form',
    control,
    getValues,
    reset,
    onDataRestored: () => {
      setShowRestoreDialog(true);
    },
    timeout: 6 * 24 * 60 * 60 * 1000, // 6 days
    exclude: [
      'createdAt',
      'updatedAt',
      'isCompleted',
      'publishDate',
      'cutOffDate',
      'isPublished',
      'isOutOfDate',
      'isTopStory',
      'topStoryFromDate',
      'topStoryToDate',
      'likesCount',
      'sourceUrlCount',
    ],
    disabled: !isAdminOrEditor,
  });

  useEffect(() => {
    if (
      (initialValues &&
        initialValues.assetId !== previousInitialValues?.assetId) ||
      successfullySavedAssetId
    ) {
      setNewSectionIndex(null);
      setSuccessfullySavedAssetId(null);
      reset(initialValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues, reset]);

  useEffect(() => {
    if (successfullySavedAssetId) {
      clearPersist();
      if (isEditing) {
        loadAsset(successfullySavedAssetId).then(() => {
          setIsSaving(false);
        });
      } else {
        push(`/dashboard/assets/${successfullySavedAssetId}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successfullySavedAssetId]);

  const {
    fields: sections,
    append: appendSection,
    remove: removeSection,
  } = useFieldArray({
    control,
    name: 'sections',
  });

  const handleDeleteSectionConfirmed = (index) => {
    // remove existing uploaded images
    const uploadedImages = getValues(`sections[${index}].uploadedImages`);

    if (!_.isEmpty(uploadedImages)) {
      removeS3Objects({
        objectsToRemove: [...uploadedImages],
        type: 'asset',
      });
    }

    if (editingAsset?.sections && editingAsset.sections[index]) {
      removeImage(editingAsset.id, index);
    }
    removeSection(index);
  };

  const handleAssetDuplicated = () => {
    if (isSaving) {
      return;
    }

    setIsSaving(true);
    duplicateAsset(assetId)
      .then((asset) => {
        if (asset) {
          push(`/dashboard/assets/${asset.id}`);
        }
      })
      .catch(() => {
        setIsSaving(false);
      });
  };

  const validateUploadedImages = (allSections) => {
    return _.every(allSections, (section, index) => {
      if (!section.originalFileName) {
        setErrorMessage(`Please upload an image for section ${index + 1}`);
        return false;
      }

      if (section.localOriginalUrl && section.uploadedImages.length !== 18) {
        setErrorMessage(`Please crop all images for section ${index + 1}`);
        return false;
      }

      return true;
    });
  };

  const onSubmit = async (data) => {
    // to debug duplicate submissions for Robyn {{{
    console.log('onSubmit', new Date().toISOString());
    if (isSubmittingRef.current) {
      console.log('Submit duplicate, ignore...');
      return;
    }
    // }}}

    isSubmittingRef.current = true;

    setErrorMessage(null);
    clearAssetErrorMessage();

    // validate uploaded images
    if (!validateUploadedImages(data.sections)) {
      isSubmittingRef.current = false;
      return;
    }

    setIsSaving(true);

    const formValues = omitDeep(data, [
      'awsS3UploadKit',
      'croppedImageUrl',
      'expanded',
      'initialRequestOptions',
      'localOriginalUrl',
      'temp',
    ]);

    const isCoveryStory = formValues.categoryId === coverStoryCategory?.id;

    if (isCoveryStory) {
      formValues.shortTitle = undefined;
      formValues.isTopStory = false;
      formValues.topStoryFrom = 0;
      formValues.topStoryTo = 0;
    }

    formValues.imgs = [];
    _.forEach(formValues.sections, (section, index) => {
      let img = {};
      _.forEach(section.uploadedImages, (e) => {
        img[e.mode] = img[e.mode] ? img[e.mode] : {};
        img[e.mode][e.key] = { name: e.name, type: e.type };
      });
      formValues.imgs[index] = img;
    });

    if (!isCoveryStory) {
      if (formValues.isTopStory) {
        formValues.topStoryTo = formValues.topStoryToDate.getTime();
        if (formValues.topStoryFromDate) {
          formValues.topStoryFrom = formValues.topStoryFromDate.getTime();
        } else {
          formValues.topStoryFrom = Date.now();
        }
      } else {
        if (initialValues?.isTopStory) {
          formValues.topStoryTo = 0;
        } else {
          formValues.topStoryTo = undefined;
        }
      }
    }

    if (formValues.isPublished) {
      if (formValues.cutOffDate) {
        formValues.cutOffTime = formValues.cutOffDate.getTime();
      }
      if (formValues.publishDate) {
        formValues.publishTime = formValues.publishDate.getTime();
      } else {
        formValues.publishTime = Date.now();
      }
    } else {
      formValues.cutOffTime = 0;
    }

    if (!formValues.clientId || formValues.clientId === 'none') {
      formValues.clientId = undefined;
    }

    // transform video
    _.forEach(formValues.sections, (section, index) => {
      formValues.sections[index].video = {
        ids: _.replace(section.videoIds, /\s/g, '')
          .split(',')
          .filter((id) => !!id),
        credit: section.videoCredit,
      };
    });

    try {
      let response;
      if (isEditing) {
        response = await updateAsset(formValues);
      } else {
        response = await addAsset(formValues);
      }

      if (!assetErrorMessage && !errorMessage) {
        setSuccessfullySavedAssetId(response?.asset?.id);
      }
    } catch (error) {
      setIsSaving(false);

      // navigate to assets list if error occur (most likely due to Robyn's duplication mouse issue)
      push('/dashboard/assets?prompt=no');
    }

    isSubmittingRef.current = false;
  };

  const onCancel = () => {
    reset(initialValues);
    push('/dashboard/assets');
  };

  const canPreview = !isDirty && isEditing;

  return (
    <>
      <CssBaseline />
      <Grid className={classes.container}>
        <Grid container className={classes.title}>
          <Typography component="h1" variant="h6">
            {isEditing ? 'Edit' : 'Create New'} Article
            {editingAsset.copyOf && (
              <>
                {' '}
                - Duplicate of{' '}
                <Link
                  underline="none"
                  href={`/dashboard/assets/${editingAsset.copyOf}`}
                  variant="h6"
                  target="_blank"
                >
                  {editingAsset.copyOf}
                </Link>
              </>
            )}
          </Typography>
        </Grid>
        <Grid container direction="row" justifyContent="center">
          <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            <Grid container item xs={12} direction="column">
              <Grid
                container
                justifyContent="space-around"
                direction={{ xs: 'row', md: 'row-reverse' }}
                style={{ paddingBottom: 24 }}
              >
                {showOriginalImages && <Grid item xs={12} md={4} />}
                <Grid
                  component={Card}
                  container
                  item
                  xs={12}
                  md={7}
                  gap={3}
                  style={{ padding: 24 }}
                >
                  <Grid item xs={12}>
                    Render count: {renderCount}
                  </Grid>
                  {isEditing && (
                    <Grid item xs={12}>
                      <ControllerTextField
                        control={control}
                        label="Article ID"
                        name="assetId"
                        showCopyButton
                        required={false}
                        disabled
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <ControllerTextField
                      select
                      control={control}
                      label={`Category`}
                      name="categoryId"
                      placeholder="Choose a category"
                      disabled={!isAdminOrEditor}
                    >
                      {renderCategoryOptions(categories)}
                    </ControllerTextField>
                  </Grid>
                  <ShortTitleOrPromotionalTextFields
                    control={control}
                    watchField="categoryId"
                    disabled={!isAdminOrEditor}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="space-around"
                direction={{ xs: 'row', md: 'row-reverse' }}
                style={{ paddingBottom: 24 }}
              >
                {showOriginalImages && <Grid item xs={12} md={4} />}
                <Grid container item xs={12} md={7}>
                  <Grid container item xs={4} justifyContent="center">
                    <Button
                      onClick={() => {
                        setHideAllSections((prevState) => !prevState);
                      }}
                    >
                      {hideAllSections
                        ? 'Show all sections'
                        : 'Hide all sections'}
                    </Button>
                  </Grid>
                  <Grid container item xs={4} justifyContent="center">
                    <Button
                      disabled={hideAllSections}
                      onClick={() => {
                        setCollapseAllSections((prevState) => {
                          _.forEach(sections, (section, index) => {
                            setValue(`sections[${index}].expanded`, prevState);
                          });

                          return !prevState;
                        });
                      }}
                    >
                      {collapseAllSections
                        ? 'Uncollapse all sections'
                        : 'Collapse all sections'}
                    </Button>
                  </Grid>
                  <Grid container item xs={4} justifyContent="center">
                    <Button
                      disabled={hideAllSections}
                      onClick={() => {
                        setShowOriginalImages((prevState) => !prevState);
                      }}
                    >
                      {showOriginalImages
                        ? 'Hide original images'
                        : 'Show original images'}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Collapse in={!hideAllSections}>
                {sections.map((section, index) => (
                  <SectionFields
                    key={section.id}
                    control={control}
                    isEditing={isEditing}
                    clearErrors={clearErrors}
                    getValues={getValues}
                    setValue={setValue}
                    resetField={resetField}
                    setError={setError}
                    index={index}
                    showOriginalImage={showOriginalImages}
                    onDeleteSectionConfirmed={handleDeleteSectionConfirmed}
                    openUploaderOnInitialRender={index === newSectionIndex}
                    disabled={!isAdminOrEditor}
                  />
                ))}
                <Grid
                  container
                  justifyContent="space-around"
                  direction={{ xs: 'row', md: 'row-reverse' }}
                  style={{ paddingBottom: 24 }}
                >
                  {showOriginalImages && <Grid item xs={12} md={4} />}
                  <Grid container item xs={12} md={7}>
                    <Grid
                      container
                      item
                      xs={12}
                      justifyContent="center"
                      style={{ paddingBottom: 24 }}
                    >
                      <Tooltip
                        title={
                          sections.length >= MAX_NUMBER_OF_SECTIONS
                            ? 'Maximum number of sections reached'
                            : !canPreview
                            ? 'Please save the article first'
                            : null
                        }
                      >
                        <span>
                          <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              setNewSectionIndex(sections.length);
                              const lastSectionIndex = sections.length - 1;
                              const imageCredit =
                                getValues(
                                  `sections[${lastSectionIndex}].imageCredit`
                                ) || '';
                              const imageCopyright =
                                getValues(
                                  `sections[${lastSectionIndex}].imageCopyright`
                                ) || '';

                              appendSection({
                                ...DEFAULT_SECTION_VALUES,
                                imageMode: IMAGE_MODE.PORTRAIT,
                                imageCredit,
                                imageCopyright,
                              });
                            }}
                            disabled={
                              !canPreview ||
                              sections.length >= MAX_NUMBER_OF_SECTIONS ||
                              !isAdminOrEditor
                            }
                          >
                            Add new section
                          </Button>
                        </span>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
              </Collapse>
              <Grid
                container
                justifyContent="space-around"
                direction={{ xs: 'row', md: 'row-reverse' }}
                style={{ paddingBottom: 24 }}
              >
                {showOriginalImages && <Grid item xs={12} md={4} />}
                <Grid
                  component={Card}
                  container
                  item
                  xs={12}
                  md={7}
                  gap={3}
                  style={{ padding: 24 }}
                >
                  <Grid item xs={12}>
                    <ControllerTextField
                      control={control}
                      label="Tags"
                      name="tags"
                      showCopyButton
                      disabled={!isAdminOrEditor}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ControllerTextField
                      select
                      control={control}
                      label="Client"
                      name="clientId"
                      placeholder="Choose a client"
                      required={false}
                      disabled={!isAdminOrEditor}
                    >
                      {renderClientOptions(clientOptions)}
                    </ControllerTextField>
                  </Grid>
                  <Grid item xs={12}>
                    <ControllerCheckbox
                      control={control}
                      label="Show Author"
                      name="showAuthor"
                      disabled={!isAdminOrEditor}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <AuthorField
                      control={control}
                      label="Author"
                      name="author"
                      placeholder="Enter author"
                      watchField="showAuthor"
                      countWords
                      showCopyButton
                      disabled={!isAdminOrEditor}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ControllerTextField
                      control={control}
                      label="Source URL"
                      name="sourceUrl"
                      placeholder="Enter the original source URL"
                      required={false}
                      disabled={!isAdminOrEditor}
                    />
                  </Grid>
                  {isEditing && (
                    <Grid container item xs={12} spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <ControllerTextField
                          control={control}
                          label="Likes"
                          name="likesCount"
                          disabled
                          required={false}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ControllerTextField
                          control={control}
                          label="Source URL Clicks"
                          name="sourceUrlCount"
                          disabled
                          required={false}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ControllerTextField
                          control={control}
                          label="Created At"
                          name="createdAt"
                          disabled
                          required={false}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ControllerTextField
                          control={control}
                          label="Updated At"
                          name="updatedAt"
                          disabled
                          required={false}
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="space-around"
                direction={{ xs: 'row', md: 'row-reverse' }}
                style={{ paddingBottom: 24 }}
              >
                {showOriginalImages && <Grid item xs={12} md={4} />}
                <Grid
                  component={Card}
                  container
                  item
                  xs={12}
                  md={7}
                  gap={3}
                  style={{ padding: 24 }}
                >
                  <Grid item xs={12}>
                    SEO
                  </Grid>
                  <Grid item xs={12}>
                    <ControllerTextField
                      control={control}
                      label="Slug"
                      name="slug"
                      multiline
                      showCopyButton
                      required={false}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ReplicateTextField
                      control={control}
                      setValue={setValue}
                      sameAsField="sections[0].titleOne"
                      label="SEO Title"
                      name="seoTitle"
                      placeholder="Enter SEO Title"
                      multiline
                      showCopyButton
                      required={false}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ReplicateTextField
                      control={control}
                      setValue={setValue}
                      sameAsField="sections[0].descriptionOne"
                      label="SEO Description"
                      name="seoDescription"
                      placeholder="Enter SEO Description"
                      multiline
                      showCopyButton
                      required={false}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ReplicateTextField
                      control={control}
                      setValue={setValue}
                      sameAsField="sections[0].titleOne"
                      label="SEO H1 Title"
                      name="seoH1Title"
                      placeholder="Enter SEO H1 Title"
                      multiline
                      showCopyButton
                      required={false}
                      disabled
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="space-around"
                direction={{ xs: 'row', md: 'row-reverse' }}
                style={{ paddingBottom: 24 }}
              >
                {showOriginalImages && <Grid item xs={12} md={4} />}
                <Grid
                  component={Card}
                  container
                  item
                  xs={12}
                  md={7}
                  gap={3}
                  style={{ padding: 24 }}
                >
                  <Grid item xs={12}>
                    <CheckboxWatchIsPublished
                      control={control}
                      label="Completed"
                      name="isCompleted"
                      disabled={!isAdminOrEditor}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CheckboxWatchIsPublished
                      control={control}
                      label="Out-Of-Date"
                      name="isOutOfDate"
                      disabled={!isAdminOrEditor}
                    />
                  </Grid>
                  <PublishTimeCheckbox
                    control={control}
                    getValues={getValues}
                    setValue={setValue}
                    disabled={!isAdminOrEditor}
                  />
                  <TopStoryTimeCheckbox
                    control={control}
                    getValues={getValues}
                    setValue={setValue}
                    disabled={!isAdminOrEditor}
                  />
                  {isEditing && (
                    <>
                      <Grid item xs={12}>
                        <HistoryChart
                          publishHistory={editingAsset?.publishHistory}
                          topStoryHistory={editingAsset?.topStoryHistory}
                        />
                      </Grid>
                      {!editingAsset?.copyOf && (
                        <Grid item xs={12}>
                          <Button
                            color="primary"
                            type="button"
                            variant="contained"
                            disabled={
                              isSubmitting || isDirty || !isAdminOrEditor
                            }
                            onClick={handleAssetDuplicated}
                          >
                            Duplicate this article
                          </Button>
                        </Grid>
                      )}
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              item
              justifyContent="space-around"
              direction={{ xs: 'row', md: 'row-reverse' }}
            >
              {showOriginalImages && <Grid item xs={12} md={4} />}
              <Grid container item xs={12} md={7}>
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="center"
                  columnGap={3}
                >
                  <Button
                    color="primary"
                    disabled={
                      isSubmitting || !isDirty || !isAdminOrEditor || isSaving
                    }
                    type="submit"
                    variant="contained"
                  >
                    Save
                  </Button>
                  <PreviewDialog
                    open={showPreview}
                    asset={editingAsset}
                    tooltip={
                      canPreview
                        ? 'Preview and change the image orders'
                        : 'Please save the article first'
                    }
                    disabled={!canPreview}
                    readOnly={!isAdminOrEditor}
                    noExport={!isAdminOrEditor}
                    onChangeImageOrdersSuccess={() => {
                      setSuccessfullySavedAssetId(editingAsset.id);
                    }}
                    onClose={() => {
                      setShowPreview(false);
                    }}
                  />
                  <Button type="button" variant="contained" onClick={onCancel}>
                    {isDirty ? 'Cancel' : 'Go Back To Article List'}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            {(canPreview || isDirty) && !isSubmitting && !isSaving && (
              <FabSavePreview
                type={canPreview ? 'button' : 'submit'}
                showPreviewButton={canPreview}
                onPreview={() => {
                  setShowPreview(true);
                }}
                disabled={isSubmitting || isSaving}
              />
            )}
          </form>
        </Grid>
      </Grid>
      {showRestoreDialog && (
        <YesNoDialog
          open={showRestoreDialog}
          title="Restore?"
          message="There are unsaved changes from last edit. Do you want to restore the changes?"
          primaryButtonText="Yes"
          onPrimaryButtonClick={() => {
            setShowRestoreDialog(false);
          }}
          secondaryButtonText="No"
          onSecondaryButtonClick={() => {
            setShowRestoreDialog(false);
            // this will allow the dialog to close first
            setTimeout(() => {
              clearPersist();
              reset(initialValues);
            }, 100);
          }}
        />
      )}
      <Prompt
        when={isDirty && !successfullySavedAssetId}
        message={(location, action) => {
          if (action === 'PUSH' && location.search.includes('prompt=no')) {
            return true;
          }

          const userResponse = window.confirm(
            'There are unsaved changes. Do you wish to discard them?'
          );

          if (userResponse) {
            stopPersistInterval();
            clearPersist();
          }

          return userResponse;
        }}
      />
      <Prompt
        when={!isDirty || !!successfullySavedAssetId}
        message={() => {
          stopPersistInterval();
          clearPersist();
          return true;
        }}
      />
      <Snackbar
        autoHideDuration={5000}
        onClose={() => {
          setErrorMessage(null);
          clearAssetErrorMessage();
        }}
        open={!!assetErrorMessage || !!errorMessage}
      >
        <Alert
          severity="error"
          action={
            <Button
              size="small"
              color="secondary"
              onClick={() => {
                setErrorMessage(null);
                clearAssetErrorMessage();
              }}
            >
              Dismiss
            </Button>
          }
        >
          {assetErrorMessage || errorMessage}
        </Alert>
      </Snackbar>
      <Backdrop className={classes.backdrop} open={isProcessing || isSaving}>
        <CircularProgress />
      </Backdrop>
    </>
  );
};

const mapStateToProps = (state) => {
  const now = Date.now();
  const assetsLoading = getAssetsLoading(state);
  const authProcessing = selectAuthProcessing(state);
  const clientsLoading = selectClientsLoading(state);
  const categoriesLoading = selectCategoriesLoading(state);
  const isProcessing =
    authProcessing || assetsLoading || clientsLoading || categoriesLoading;

  const editingAsset = getEditingAsset(state);
  const categories = selectCategories(state);
  const currentUser = getCurrentUser(state);
  const clients = getClients(state);
  const preferences = selectAssetsPreferences(state);
  const assetErrorMessage = getAssetsErrorMessage(state);
  const message = getAssetsMessage(state);

  let initialValues = null;
  if (
    !_.isEmpty(editingAsset) &&
    !_.isEmpty(currentUser) &&
    !_.isEmpty(categories) && // make sure categories are loaded
    !_.isEmpty(preferences) // make sure preferences are loaded
  ) {
    initialValues = {
      assetId: editingAsset.id,
      categoryId: editingAsset.category?.id,
      clientId: editingAsset.client?.id ? editingAsset.client.id : 'none',
      shortTitle: editingAsset.shortTitle ? editingAsset.shortTitle : '',
      promoteInTopStories: !!editingAsset.promoteInTopStories,
      promotionalText: editingAsset.promotionalText
        ? editingAsset.promotionalText
        : '',
      promotionalTextStyle: editingAsset.promotionalTextStyle
        ? editingAsset.promotionalTextStyle
        : '',
      tags: editingAsset.tags?.join(', '),
      sourceUrl: editingAsset.sourceUrl ? editingAsset.sourceUrl : '',
      sourceUrlCount: editingAsset.sourceUrlCount
        ? editingAsset.sourceUrlCount
        : 0,
      likesCount: editingAsset.likesCount ? editingAsset.likesCount : 0,
      isPublished: editingAsset.publishTime > 0,
      isCompleted: !!editingAsset.isCompleted,
      isOutOfDate: !!editingAsset.isOutOfDate,
      publishDate:
        editingAsset.publishTime > 0
          ? new Date(editingAsset.publishTime)
          : new Date(),
      cutOffDate:
        editingAsset.cutOffTime > 0
          ? new Date(editingAsset.cutOffTime)
          : endOfDay(addDays(new Date(), preferences.cutOffTimeDs)),
      isTopStory: editingAsset.topStoryTo > now,
      topStoryFromDate:
        editingAsset.topStoryTo > now && editingAsset.topStoryFrom > 0
          ? new Date(editingAsset.topStoryFrom)
          : new Date(),
      topStoryToDate:
        editingAsset.topStoryTo > now
          ? new Date(editingAsset.topStoryTo)
          : endOfDay(addDays(new Date(), preferences.topStoriesTimeDs)),
      createdAt: formatDateTime(editingAsset.createdAt),
      updatedAt: formatDateTime(editingAsset.updatedAt),
      author: editingAsset.author
        ? editingAsset.author
        : `By ${currentUser.firstName} ${currentUser.lastName}, WiB`,
      showAuthor: !!editingAsset.showAuthor,
      seoTitle: editingAsset.seoTitle ? editingAsset.seoTitle : '',
      seoDescription: editingAsset.seoDescription,
      seoH1Title: editingAsset.seoH1Title ? editingAsset.seoH1Title : '',
      slug: editingAsset.slug ? editingAsset.slug : '',
    };

    if (
      editingAsset.sections &&
      editingAsset.images &&
      editingAsset.awsS3UploadKit
    ) {
      initialValues.sections = editingAsset.sections.map(
        ({ video, _id, ...others }, index) => {
          const videoIds = video?.ids ? video.ids.join(', ') : '';
          const videoCredit = video?.credit ? video.credit : '';
          const youTubeUrl = videoIds ? `https://youtu.be/${videoIds}` : '';
          const originalFileName = editingAsset.images[index].original.name;
          const initialRequestOptions = editingAsset.requestOptions[index];
          const awsS3UploadKit = editingAsset.awsS3UploadKit[index];

          return _.merge({}, DEFAULT_SECTION_VALUES, others, {
            originalFileName,
            videoIds,
            videoCredit,
            youTubeUrl,
            initialRequestOptions,
            awsS3UploadKit,
            expanded: true,
          });
        }
      );
    }
  }

  // Make sure the client is loaded
  // NOTE: we need to do this after the initial values are set
  // because in the very beginning of the database, there is no client
  // and the asset does not have a client assigned to it
  if (initialValues?.clientId && initialValues.clientId !== 'none') {
    // Make sure the clients are loaded
    if (_.isEmpty(clients)) {
      initialValues = null;
    }
  }

  let result = {
    assetErrorMessage,
    message,
    isProcessing,
    categories,
    clients,
    preferences,
    initialValues,
    editingAsset,
    currentUser,
  };

  return result;
};

export default compose(
  connect(mapStateToProps, {
    loadCategories,
    loadAsset,
    loadClients,
    push,
    addAsset,
    duplicateAsset,
    updateAsset,
    removeImage,
    removeS3Objects,
    clearEditingAsset,
    clearAssetErrorMessage,
    clearAssetMessage,
    unloadClients,
  }),
  withStyles(styles)
)(AssetDetail);
