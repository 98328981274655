import { createSelector } from '@reduxjs/toolkit';
import { denormalize } from 'normalizr';
import * as schemas from '../schemas';

export const getCoverImagesState = (state) => state.coverImages;

export const getCoverImagesAllIds = (state) => state.coverImages.allIds;

export const getCoverImagesEntities = (state) => state.coverImages.entities;

export const getCoverImagesErrorMessage = (state) => state.coverImages.error;

export const getCoverImagesMessage = (state) => state.coverImages.message;

export const getCoverImagesCount = (state) => state.coverImages.totalCount;

export const selectCoverImagesPreferences = (state) =>
  state.coverImages.preferences;

export const getCoverImages = createSelector(
  getCoverImagesEntities,
  getCoverImagesAllIds,
  (entities, allIds) =>
    denormalize(allIds, schemas.coverImageListSchema, entities)
);

export const getEditingCoverImage = (state) => state.coverImages.editing;
