import authReducer from './auth.reducer';
import { reducer as formReducer } from 'redux-form';
import categoriesReducer from './categories.reducer';
import assetsReducer from './assets.reducer';
import base64Reducer from './base64.reducer';
import usersReducer from './users.reducer';
import adminReducer from './admin.reducer';
import adsReducer from './ads.reducer';
import clientsReducer from './clients.reducer';
import invoicesReducer from './invoices.reducer';
import reportsReducer from './reports.reducer';
import adPricesReducer from './adPrices.reducer';
import awsS3UploadKitReducer from './aws-s3-upload-kit.reducer';
import notificationMessagesReducer from './notificationMessages.reducer';
import coverImagesReducer from './cover-images.reducer';

const createRootReducer = (routerReducer) => ({
  router: routerReducer,
  form: formReducer,
  auth: authReducer,
  admin: adminReducer,
  categories: categoriesReducer,
  assets: assetsReducer,
  users: usersReducer,
  base64: base64Reducer,
  ads: adsReducer,
  clients: clientsReducer,
  invoices: invoicesReducer,
  reports: reportsReducer,
  adPrices: adPricesReducer,
  awsS3UploadKit: awsS3UploadKitReducer,
  notificationMessages: notificationMessagesReducer,
  coverImages: coverImagesReducer,
});

export default createRootReducer;
