import React from 'react';
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import { Controller } from 'react-hook-form';

const ControllerDateTimePicker = ({
  control,
  name,
  label,
  disabled,
  disablePast,
  maxDateTime,
  minDateTime,
  onChange: onChangeProp,
  ...otherProps
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, ...otherFields } }) => {
        return (
          <MobileDateTimePicker
            {...otherFields}
            onChange={(value) => {
              if (onChangeProp) {
                onChangeProp(value);
              }
              onChange(value);
            }}
            slotProps={{
              textField: { fullWidth: true },
            }}
            ampm={false}
            format="EEE, MMM dd, yyyy HH:mm"
            label={label}
            maxDateTime={maxDateTime}
            minDateTime={minDateTime}
            disablePast={disablePast}
            disabled={disabled}
            {...otherProps}
          />
        );
      }}
    />
  );
};

export default ControllerDateTimePicker;
