export const COMPLETION_STATUS = [
  { id: 'completed', name: 'Completed' },
  { id: 'incompleted', name: 'Incompleted' },
];

export const ADMIN_ASSET_STATUSES = [
  { id: 'duplicated-asset', name: 'Duplicated Asset' },
  { id: 'has-video', name: 'Has-Video Asset' },
  { id: 'legacy-asset', name: 'Legacy Asset' },
  { id: 'out-of-date', name: 'Out-Of-Date Asset' },
  { id: 'testing-asset', name: 'Testing Asset' },
  { id: 'up-to-date', name: 'Up-To-Date Asset' },
];

export const ASSET_STATUSES = [
  { id: 'duplicated-asset', name: 'Duplicated Asset' },
  { id: 'has-video', name: 'Has-Video Asset' },
  { id: 'legacy-asset', name: 'Legacy Asset' },
];

export const IMAGE_MODE = {
  LANDSCAPE: 'landscape',
  PORTRAIT: 'portrait',
};

export const IMAGE_MODE_OPTIONS = [
  { id: IMAGE_MODE.LANDSCAPE, name: 'Landscape' },
  { id: IMAGE_MODE.PORTRAIT, name: 'Portrait' },
];

export const FONT_STYLE = {
  BOLD: 'bold',
  BOLD_ITALIC: 'boldItalic',
  ITALIC: 'italic',
  NORMAL: 'normal',
};

export const TEXT_STYLE = [
  { id: FONT_STYLE.BOLD, name: 'Bold' },
  { id: FONT_STYLE.BOLD_ITALIC, name: 'Bold and Italic' },
  { id: FONT_STYLE.ITALIC, name: 'Italic' },
  { id: FONT_STYLE.NORMAL, name: 'Normal' },
];

export const TEXT_FIELD_STYLE_DEF = {
  [FONT_STYLE.BOLD]: {
    fontWeight: 'bold',
  },
  [FONT_STYLE.BOLD_ITALIC]: {
    fontWeight: 'bold',
    fontStyle: 'italic',
  },
  [FONT_STYLE.ITALIC]: {
    fontStyle: 'italic',
  },
  [FONT_STYLE.NORMAL]: {},
};

export const ADMIN_PUBLISH_STATUS = [
  { id: 'not-published', name: 'Not Published' },
  { id: 'published-all', name: 'Published - All' },
  { id: 'published-active', name: 'Published - Active' },
  { id: 'published-expired', name: 'Published - Expired' },
  { id: 'published-inactive', name: 'Published - Scheduled' },
];

export const PUBLISH_STATUS = [
  { id: 'published-active', name: 'Published - Mobile & Web' },
  { id: 'published-expired', name: 'Published - Web only' },
];

export const MAX_NUMBER_OF_SECTIONS = 30;

// REMOVEME
export const MAX_NUMBER_OF_IMAGES = 30;
export const MAX_NUMBER_OF_IMAGES_COVER_STORY = 30;
