import * as actionTypes from '../actions/types';
import { getPreferences } from '../../utils';

const INITIAL_STATE = {
  entities: {},
  allIds: [],
  loading: false,
  totalCount: 0,
  preferences: getPreferences('clients'),
  editing: {},
  error: null,
  message: null,
};

const clientsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.LOAD_CLIENT:
    case actionTypes.LOAD_CLIENTS: {
      return { ...state, loading: true, error: null };
    }

    case actionTypes.LOAD_CLIENTS_SUCCESS: {
      const clients = action.payload.clients;
      const result = clients.reduce(
        (result, client) => {
          return {
            entities: {
              ...result.entities,
              [client.id]: client,
            },
            allIds: [...result.allIds, client.id],
          };
        },
        { entities: {}, allIds: [] }
      );

      return {
        ...state,
        loading: false,
        entities: result.entities,
        allIds: result.allIds,
        totalCount: action.payload.clientsCount,
        preferences: {
          ...getPreferences('clients'), // reset filters
          ...action.payload.preferences,
        },
      };
    }

    case actionTypes.UNLOAD_CLIENTS: {
      return {
        ...INITIAL_STATE,
      };
    }

    case actionTypes.LOAD_CLIENT_SUCCESS: {
      return {
        ...state,
        editing: action.payload,
      };
    }

    case actionTypes.CLEAR_EDITING_CLIENT: {
      return {
        ...state,
        editing: {},
      };
    }

    case actionTypes.LOAD_CLIENT_FAIL:
    case actionTypes.LOAD_CLIENTS_FAIL: {
      return { ...state, loading: false };
    }

    case actionTypes.ADD_CLIENT:
    case actionTypes.UPDATE_CLIENT:
    case actionTypes.REMOVE_CLIENT: {
      return { ...state, error: null, message: null };
    }

    case actionTypes.ADD_CLIENT_FAIL:
    case actionTypes.UPDATE_CLIENT_FAIL:
    case actionTypes.REMOVE_CLIENT_FAIL: {
      return { ...state, error: action.payload };
    }

    case actionTypes.UPDATE_CLIENT_SUCCESS: {
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.payload.id]: action.payload,
        },
        message: 'A client has been updated',
      };
    }

    case actionTypes.ADD_CLIENT_SUCCESS: {
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.payload.id]: action.payload,
        },
        message: 'A client has been updated',
      };
    }

    case actionTypes.REMOVE_CLIENT_SUCCESS: {
      const deletedId = action.payload.deletedId;
      const { [deletedId]: removed, ...clients } = state.entities;
      return {
        ...state,
        allIds: state.allIds.filter((id) => id !== deletedId),
        entities: {
          ...clients,
        },
        message: 'A client has been removed',
      };
    }

    case actionTypes.CLEAR_CLIENT_MESSAGE: {
      return { ...state, message: null };
    }

    case actionTypes.CLEAR_CLIENT_ERROR_MESSAGE: {
      return { ...state, error: null };
    }

    case actionTypes.UPDATE_PREFERENCES: {
      const { key, subKey, value } = action.payload;
      if (key === 'clients') {
        return {
          ...state,
          preferences: { ...state.preferences, [subKey]: value },
        };
      }
      return state;
    }

    case actionTypes.RESET_PREFERENCES: {
      return { ...state, preferences: { ...action.payload.clients } };
    }

    default:
      return state;
  }
};

export default clientsReducer;
