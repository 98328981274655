import { normalize } from 'normalizr';
import * as actionTypes from '../actions/types';
import * as schemas from '../schemas';
import { getPreferences } from '../../utils';

const INITIAL_STATE = {
  entities: { notificationMessages: {} },
  allIds: [],
  loading: false,
  preferences: getPreferences('notificationMessages'),
  error: null,
  message: null,
  editing: {},
};

const notificationMessagesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.REMOVE_NOTIFICATION_MESSAGE:
    case actionTypes.ADD_NOTIFICATION_MESSAGE:
    case actionTypes.UPDATE_NOTIFICATION_MESSAGE:
    case actionTypes.LOAD_NOTIFICATION_MESSAGE:
    case actionTypes.LOAD_NOTIFICATION_MESSAGES: {
      return { ...state, loading: true, error: null };
    }

    case actionTypes.LOAD_NOTIFICATION_MESSAGES_SUCCESS: {
      const notificationMessages = action.payload.notificationMessages;
      const data = normalize(
        notificationMessages,
        schemas.notificationMessageListSchema
      );
      return {
        ...state,
        loading: false,
        allIds: data.result,
        entities: data.entities,
        preferences: {
          ...getPreferences('notificationMessages'), // reset filters
          ...action.payload.preferences,
        },
        totalCount: action.payload.notificationMessagesCount,
      };
    }

    case actionTypes.ADD_NOTIFICATION_MESSAGE_SUCCESS: {
      return {
        ...state,
        allIds: [...state.allIds, action.payload.id],
        entities: {
          ...state.entities,
          notificationMessages: {
            ...state.entities.notificationMessages,
            [action.payload.id]: action.payload,
          },
        },
        loading: false,
        message: 'A notification-message has been added',
      };
    }

    case actionTypes.UPDATE_NOTIFICATION_MESSAGE_SUCCESS: {
      return {
        ...state,
        entities: {
          ...state.entities,
          notificationMessages: {
            ...state.entities.notificationMessages,
            [action.payload.id]: action.payload,
          },
        },
        loading: false,
        message: 'A notification-message has been updated',
      };
    }

    case actionTypes.LOAD_NOTIFICATION_MESSAGE_SUCCESS: {
      return {
        ...state,
        editing: action.payload,
      };
    }

    case actionTypes.REMOVE_NOTIFICATION_MESSAGE_SUCCESS: {
      const deletedId = action.payload.deletedId;
      const { [deletedId]: removed, ...notificationMessages } =
        state.entities.notificationMessages;
      return {
        ...state,
        allIds: state.allIds.filter((id) => id !== deletedId),
        entities: {
          ...state.entities,
          notificationMessages,
        },
        message: 'A notification-message has been removed',
      };
    }

    case actionTypes.ADD_NOTIFICATION_MESSAGE_FAIL:
    case actionTypes.UPDATE_NOTIFICATION_MESSAGE_FAIL:
    case actionTypes.REMOVE_NOTIFICATION_MESSAGE_FAIL:
    case actionTypes.LOAD_NOTIFICATION_MESSAGE_FAIL:
    case actionTypes.LOAD_NOTIFICATION_MESSAGES_FAIL: {
      return { ...state, loading: false, error: action.payload };
    }

    case actionTypes.CLEAR_EDITING_NOTIFICATION_MESSAGE: {
      return {
        ...state,
        editing: {},
      };
    }

    case actionTypes.CLEAR_NOTIFICATION_MESSAGE_MESSAGE: {
      return { ...state, message: null };
    }

    case actionTypes.CLEAR_NOTIFICATION_MESSAGE_ERROR: {
      return { ...state, error: null };
    }

    case actionTypes.UPDATE_PREFERENCES: {
      const { key, subKey, value } = action.payload;
      if (key === 'notificationMessages') {
        return {
          ...state,
          preferences: { ...state.preferences, [subKey]: value },
        };
      }
      return state;
    }

    case actionTypes.RESET_PREFERENCES: {
      return {
        ...state,
        preferences: { ...action.payload.notificationMessages },
      };
    }

    default:
      return state;
  }
};

export default notificationMessagesReducer;
