import * as actionTypes from './types';

export const loadInvoices =
  (payload) =>
  (dispatch, getState, { mernApi }) => {
    dispatch({ type: actionTypes.LOAD_INVOICES });
    const {
      filters,
      page = 0,
      pageSize = 10,
      orderBy,
      orderDirection,
      search,
    } = payload;
    const skip = page * pageSize;
    const sort = orderBy
      ? `${orderDirection === 'desc' ? '-' : ''}${
          orderBy.field === 'no' ? 'createdAt' : orderBy.field
        }`
      : '-createdAt';
    const params = { sort, limit: pageSize, skip };
    const preferences = {
      page,
      pageSize,
    };
    if (orderBy?.field) {
      preferences.orderBy = orderBy.field;
    }
    if (orderDirection) {
      preferences.orderDirection = orderDirection;
    }
    filters.forEach((filter) => {
      if (filter.column.field === 'client.companyName') {
        params.clientId = filter.value;
        preferences.clientId = filter.value;
      }
      if (filter.column.field === 'isCompleted') {
        params.isCompleted = filter.value === 'completed';
        preferences.completionStatus = filter.value;
      }
    });
    let searchText = search.trim();
    if (searchText) {
      params.searchText = searchText;
      preferences.searchText = searchText;
    }
    return mernApi.get('/api/invoices', { params }).then(
      (response) => {
        dispatch({
          type: actionTypes.LOAD_INVOICES_SUCCESS,
          payload: {
            ...response.data,
            preferences,
          },
        });
      },
      (err) => {
        dispatch({
          type: actionTypes.LOAD_INVOICES_FAIL,
          payload: err.response.data.error.message,
        });
      }
    );
  };

export const loadInvoice =
  (reportId) =>
  (dispatch, getState, { mernApi }) => {
    dispatch({ type: actionTypes.LOAD_INVOICE });
    return mernApi.get(`/api/invoices/${reportId}`, { params: {} }).then(
      (response) => {
        dispatch({
          type: actionTypes.LOAD_INVOICE_SUCCESS,
          payload: response.data.invoice,
        });
      },
      (err) => {
        dispatch({
          type: actionTypes.LOAD_INVOICE_FAIL,
          payload: err.response.data.error.message,
        });
      }
    );
  };

export const removeInvoice =
  (reportId) =>
  (dispatch, getState, { mernApi }) => {
    dispatch({ type: actionTypes.REMOVE_INVOICE });
    return mernApi.delete(`/api/invoices/${reportId}`).then(
      (response) => {
        dispatch({
          type: actionTypes.REMOVE_INVOICE_SUCCESS,
          payload: { ...response.data, deletedId: reportId },
        });
      },
      (err) => {
        dispatch({
          type: actionTypes.REMOVE_INVOICE_FAIL,
          payload: err.response.data.error.message,
        });
      }
    );
  };

export const generatePreviewInvoice =
  (formValues) =>
  (dispatch, getState, { mernApi }) => {
    dispatch({ type: actionTypes.GENERATE_PREVIEW_INVOICE });
    return mernApi.post('/api/invoices/generate', formValues).then(
      (response) => {
        dispatch({
          type: actionTypes.GENERATE_PREVIEW_INVOICE_SUCCESS,
          payload: response.data.invoice,
        });
        return Promise.resolve(response.data);
      },
      (err) => {
        dispatch({
          type: actionTypes.GENERATE_PREVIEW_INVOICE_FAIL,
          payload: err.response.data.error.message,
        });
      }
    );
  };

export const addInvoice =
  (formValues) =>
  (dispatch, getState, { mernApi }) => {
    dispatch({ type: actionTypes.ADD_INVOICE });
    formValues.save = true;
    return mernApi.post('/api/invoices/generate', formValues).then(
      (response) => {
        dispatch({
          type: actionTypes.ADD_INVOICE_SUCCESS,
          payload: response.data.invoice,
        });
        return Promise.resolve(response.data);
      },
      (err) => {
        dispatch({
          type: actionTypes.ADD_INVOICE_FAIL,
          payload: err.response.data.error.message,
        });
      }
    );
  };

export const updateInvoice =
  (formValues) =>
  (dispatch, getState, { mernApi }) => {
    dispatch({ type: actionTypes.UPDATE_INVOICE });
    return mernApi.put(`/api/invoices/${formValues.id}`, formValues).then(
      (response) => {
        dispatch({
          type: actionTypes.UPDATE_INVOICE_SUCCESS,
          payload: response.data.invoice,
        });
        return Promise.resolve();
      },
      (err) => {
        dispatch({
          type: actionTypes.UPDATE_INVOICE_FAIL,
          payload: err.response.data.error.message,
        });
      }
    );
  };

export const clearEditingInvoice = () => {
  return {
    type: actionTypes.CLEAR_EDITING_INVOICE,
  };
};

export const clearInvoiceErrorMessage = () => {
  return {
    type: actionTypes.CLEAR_INVOICE_ERROR_MESSAGE,
  };
};

export const clearInvoiceMessage = () => {
  return {
    type: actionTypes.CLEAR_INVOICE_MESSAGE,
  };
};
