import React, { useEffect } from 'react';

import { withStyles } from '@mui/styles';
import { useWatch } from 'react-hook-form';

import ControllerTextField from '../../../components/react-hook-form-wrappers/controller-text-field';
import { TEXT_FIELD_STYLE_DEF } from '../constants';

const ReplicateTextField = ({
  control,
  setValue,
  sameAsField,
  ...otherProps
}) => {
  const value = useWatch({ control, name: sameAsField });

  useEffect(() => {
    setValue(otherProps.name, value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return <ControllerTextField control={control} {...otherProps} />;
};

export default withStyles(TEXT_FIELD_STYLE_DEF)(ReplicateTextField);
