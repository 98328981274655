import React from 'react';
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import { Field, reduxForm, SubmissionError, isDirty } from 'redux-form';
import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';
import { PatternFormat } from 'react-number-format';
import { Prompt } from 'react-router-dom';
import {
  getClientsErrorMessage,
  getEditingClient,
} from '../../store/selectors';
import {
  addClient,
  loadClient,
  updateClient,
  clearEditingClient,
} from '../../store/actions';
import { formatDateTime } from '../../utils';

const TOTAL_REPRESENTORS = 3;
const TOTAL_EMAILS = 3;
const TOTAL_PHONES = 5;

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  avatars: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
  },
  avatar: {
    width: theme.spacing(18),
    height: theme.spacing(18),
    margin: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(3, 4, 3, 0),
  },
  backdrop: {
    zIndex: 999,
    backgroundColor: 'rgba(255, 255, 255, 0.6)',
  },
});

class ClientDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: null,
    };
  }

  componentDidMount() {
    this.clientId = this.props.match.params.clientId;
    if (this.clientId !== 'create') {
      this.isEditing = true;
      this.props.loadClient(this.clientId);
    }
  }

  componentWillUnmount() {
    this.props.clearEditingClient();
  }

  onSubmit = ({ ...formValues }) => {
    return Promise.resolve()
      .then(() => {
        const representors = [];
        const emails = [];
        const phones = [];
        for (let i = 0; i < TOTAL_REPRESENTORS; i++) {
          const title = formValues[`title${i}`] ? formValues[`title${i}`] : '';
          const firstName = formValues[`firstName${i}`];
          const lastName = formValues[`lastName${i}`];
          if (firstName || lastName) {
            representors.push({ title, firstName, lastName });
          }
        }
        for (let i = 0; i < TOTAL_EMAILS; i++) {
          const email = formValues[`email${i}`];
          if (email) {
            emails.push(email);
          }
        }
        for (let i = 0; i < TOTAL_PHONES; i++) {
          const phone = formValues[`phone${i}`];
          if (phone) {
            phones.push(phone);
          }
        }
        formValues.representors = [...representors];
        formValues.emails = [...emails];
        formValues.phones = [...phones];
        formValues.address = {
          street: formValues.street,
          city: formValues.city,
          province: formValues.province,
          postalCode: formValues.postalCode,
          country: formValues.country,
        };
        if (this.isEditing) {
          return this.props.updateClient(formValues);
        }
        return this.props.addClient(formValues);
      })
      .then(() => {
        if (this.props.errorMessage) {
          throw new SubmissionError({ _error: this.props.errorMessage });
        } else {
          this.props.push('/dashboard/clients?prompt=no');
        }
      });
  };

  onCancel = () => {
    this.props.push('/dashboard/clients?prompt=no');
  };

  renderTextField = ({ input, label, meta: { touched, error }, ...custom }) => (
    <TextField
      label={label}
      error={touched && !!error}
      helperText={touched && error}
      variant="outlined"
      margin="none"
      required
      fullWidth
      {...input}
      {...custom}
    />
  );

  renderPhoneNumberField = ({
    input,
    label,
    meta: { touched, error },
    ...custom
  }) => {
    return (
      <PatternFormat
        allowNegative={false}
        customInput={TextField}
        label={label}
        error={touched && !!error}
        helperText={touched && error}
        variant="outlined"
        margin="none"
        fullWidth
        type="tel"
        prefix="+"
        {...input}
        {...custom}
      />
    );
  };

  renderOptions = (options) => {
    return options.map((option) => {
      return (
        <MenuItem key={option.id} value={option.id}>
          {option.name}
        </MenuItem>
      );
    });
  };

  renderRepresentors = () => {
    let result = [];
    for (let i = 0; i < TOTAL_REPRESENTORS; i++)
      result.push(
        <React.Fragment key={`representor${i}`}>
          <Grid item xs={4}>
            <Field
              id={`title${i}`}
              label={`Title ${i + 1}`}
              name={`title${i}`}
              required={false}
              component={this.renderTextField}
            />
          </Grid>
          <Grid item xs={4}>
            <Field
              id={`firstName${i}`}
              label={`First Name ${i + 1}`}
              name={`firstName${i}`}
              required={false}
              component={this.renderTextField}
            />
          </Grid>
          <Grid item xs={4}>
            <Field
              id={`lastName${i}`}
              label={`Last Name ${i + 1}`}
              name={`lastName${i}`}
              required={false}
              component={this.renderTextField}
            />
          </Grid>
        </React.Fragment>
      );
    return result;
  };

  renderEmails = () => {
    let result = [];
    for (let i = 0; i < TOTAL_EMAILS; i++)
      result.push(
        <Grid item xs={6} key={`phone${i}`}>
          <Field
            id={`email${i}`}
            label={`Email ${i + 1}`}
            name={`email${i}`}
            component={this.renderTextField}
            required={false}
          />
        </Grid>
      );
    return result;
  };

  renderPhones = () => {
    let result = [];
    for (let i = 0; i < TOTAL_PHONES; i++)
      result.push(
        <Grid item xs={6} key={`phone${i}`}>
          <Field
            id={`phone${i}`}
            label={`Phone ${i + 1}`}
            name={`phone${i}`}
            component={this.renderTextField}
            required={false}
          />
        </Grid>
      );
    return result;
  };

  render() {
    const {
      handleSubmit,
      classes,
      pristine,
      submitting,
      valid,
      error,
      initialValues,
      isDirty,
    } = this.props;

    const provider = initialValues.provider ? initialValues.provider : {};
    const { local, facebook, google } = provider;

    return (
      <>
        <CssBaseline />
        <Paper elevation={1} className={classes.paper}>
          <Typography component="h1" variant="h6" className={classes.title}>
            {this.isEditing ? 'Edit' : 'Create New'} Client
          </Typography>
          <Grid container spacing={4} direction="row" justifyContent="center">
            <Grid item xs={12} sm={10} xl={8}>
              <form
                className={classes.form}
                onSubmit={handleSubmit(this.onSubmit)}
              >
                <Grid container spacing={3}>
                  {this.isEditing && (
                    <Grid item xs={12}>
                      <Field
                        id="id"
                        label="Client ID"
                        name="id"
                        disabled
                        component={this.renderTextField}
                        required={false}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Field
                      id="companyName"
                      label="Company Name"
                      name="companyName"
                      component={this.renderTextField}
                    />
                  </Grid>
                  <Grid item xs={12} />
                  {this.renderEmails()}
                  <Grid item xs={12} />
                  {this.renderRepresentors()}
                  <Grid item xs={12} />
                  {this.renderPhones()}
                  <Grid item xs={12} />
                  <Grid item xs={12}>
                    <Field
                      id="street"
                      label="Street"
                      name="street"
                      component={this.renderTextField}
                      required={false}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      id="city"
                      label="City"
                      name="city"
                      component={this.renderTextField}
                      required={false}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      id="province"
                      label="Province/State"
                      name="province"
                      component={this.renderTextField}
                      required={false}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      id="postalCode"
                      label="Postal Code"
                      name="postalCode"
                      component={this.renderTextField}
                      required={false}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      id="country"
                      label="Country"
                      name="country"
                      component={this.renderTextField}
                    />
                  </Grid>
                  {this.isEditing && (
                    <>
                      <Grid item xs={12} sm={6}>
                        <Field
                          id="createdAt"
                          label="Created At"
                          name="createdAt"
                          disabled
                          component={this.renderTextField}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          id="updatedAt"
                          label="Updated At"
                          name="updatedAt"
                          disabled
                          component={this.renderTextField}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
                <Button
                  className={classes.button}
                  color="primary"
                  disabled={pristine || submitting || !valid}
                  type="submit"
                  variant="contained"
                >
                  {this.isEditing ? 'Save' : 'Submit'}
                </Button>
                <Button
                  className={classes.button}
                  type="button"
                  variant="contained"
                  onClick={this.onCancel}
                >
                  Cancel
                </Button>
              </form>
            </Grid>
            <Grid item xs={12} sm={1} xl={3}>
              <div className={classes.avatars}>
                {!!local && (
                  <Tooltip title="Email Account">
                    <Avatar
                      src={initialValues.profilePictureUrl}
                      className={classes.avatar}
                    />
                  </Tooltip>
                )}
                {!!facebook && facebook.picture && (
                  <Tooltip title="Facebook Account">
                    <Avatar src={facebook.picture} className={classes.avatar} />
                  </Tooltip>
                )}
                {!!google && google.picture && (
                  <Tooltip title="Google Account">
                    <Avatar src={google.picture} className={classes.avatar} />
                  </Tooltip>
                )}
              </div>
            </Grid>
          </Grid>
        </Paper>
        <Prompt
          when={isDirty}
          message={(location, action) => {
            return action === 'PUSH' && location.search.includes('prompt=no')
              ? true
              : 'There are unsaved changes. Do you wish to discard them?';
          }}
        />
        <Snackbar
          autoHideDuration={3000}
          onClose={() => this.setState({ errorMessage: null })}
          open={!!error || !!this.state.errorMessage}
        >
          <Alert severity="error">{error || this.state.errorMessage}</Alert>
        </Snackbar>
        <Backdrop className={classes.backdrop} open={submitting}>
          <CircularProgress />
        </Backdrop>
      </>
    );
  }
}

const validate = (values) => {
  const errors = {};
  return errors;
};

const mapStateToProps = (state) => {
  const editingClient = getEditingClient(state);
  const representors = {};
  if (editingClient.representors) {
    editingClient.representors.forEach((representor, i) => {
      representors[`title${i}`] = representor.title;
      representors[`firstName${i}`] = representor.firstName;
      representors[`lastName${i}`] = representor.lastName;
    });
  }
  const emails = {};
  if (editingClient.emails) {
    editingClient.emails.forEach((email, i) => {
      emails[`email${i}`] = email;
    });
  }
  const phones = {};
  if (editingClient.phones) {
    editingClient.phones.forEach((phone, i) => {
      phones[`phone${i}`] = phone;
    });
  }
  return {
    errorMessage: getClientsErrorMessage(state),
    initialValues: {
      id: editingClient.id,
      companyName: editingClient.companyName,
      ...representors,
      ...emails,
      ...phones,
      email: editingClient.email,
      phone: editingClient.phone,
      street: editingClient.address?.street,
      city: editingClient.address?.city,
      province: editingClient.address?.province,
      postalCode: editingClient.address?.postalCode,
      country: editingClient.address?.country,
      createdAt: formatDateTime(editingClient.createdAt),
      updatedAt: formatDateTime(editingClient.updatedAt),
    },
    isDirty: isDirty('createClient')(state),
    provider: editingClient.provider,
  };
};

export default compose(
  connect(mapStateToProps, {
    addClient,
    loadClient,
    push,
    updateClient,
    clearEditingClient,
  }),
  reduxForm({ form: 'createClient', validate, enableReinitialize: true }),
  withStyles(styles)
)(ClientDetail);
