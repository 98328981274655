import React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Tooltip from '@mui/material/Tooltip';
import Alert from '@mui/material/Alert';
import { withStyles } from '@mui/styles';
import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';
import { PlusBox } from 'mdi-material-ui';
import {
  getClients,
  getClientsCount,
  getClientsErrorMessage,
  getClientsMessage,
} from '../../store/selectors';
import {
  loadClients,
  updateClient,
  removeClient,
  clearClientErrorMessage,
  clearClientMessage,
} from '../../store/actions';
import MaterialTable from '../../components/MaterialTable';
import { formatDateTime } from '../../utils';

const styles = (theme) => ({
  avatarContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: theme.spacing(1),
  },
});

class ClientList extends React.Component {
  initialPage = -1;

  componentDidMount() {
    this.initialPage = this.props.initial.page;
  }

  onRowDelete = ({ id }) => {
    return new Promise((resolve, reject) => {
      this.props.removeClient({ id }).then(() => {
        if (!this.props.errorMessage) {
          resolve();
        }
        reject();
      });
    });
  };

  renderAvatar = (row) => {
    const { facebook, google, local } = row.provider;
    const { classes } = this.props;
    return (
      <div className={classes.avatarContainer}>
        {!!local && (
          <Tooltip title="Email Account">
            <Avatar src={row.profilePictureUrl} className={classes.avatar} />
          </Tooltip>
        )}
        {!!facebook && facebook.picture && (
          <Tooltip title="Facebook Account">
            <Avatar src={facebook.picture} className={classes.avatar} />
          </Tooltip>
        )}
        {!!google && google.picture && (
          <Tooltip title="Google Account">
            <Avatar src={google.picture} className={classes.avatar} />
          </Tooltip>
        )}
      </div>
    );
  };

  renderCreatedAt = (row) => {
    return formatDateTime(row.createdAt);
  };

  renderEmails = (row) => {
    let emails = [];
    row.emails.forEach((email, i) => {
      emails.push(
        <React.Fragment key={`email${i}`}>
          {email} <br />
        </React.Fragment>
      );
    });
    return emails;
  };

  renderRepresentors = (row) => {
    let representors = [];
    row.representors.forEach((representor, i) => {
      representors.push(
        <React.Fragment key={`representor${i}`}>
          {`${representor.firstName} ${representor.lastName}`} <br />
        </React.Fragment>
      );
    });
    return representors;
  };

  renderPhones = (row) => {
    let phones = [];
    row.phones.forEach((phone, i) => {
      phones.push(
        <React.Fragment key={`phone${i}`}>
          {phone} <br />
        </React.Fragment>
      );
    });
    return phones;
  };

  renderUpdatedAt = (row) => {
    return formatDateTime(row.updatedAt);
  };

  render() {
    const {
      errorMessage,
      message,
      initial,
      loading,
      clearClientErrorMessage,
      clearClientMessage,
      loadClients,
    } = this.props;
    const columns = [
      {
        title: 'Company',
        field: 'companyName',
        filtering: false,
        defaultSort:
          initial.orderBy === 'companyName'
            ? initial.orderDirection
            : undefined,
      },
      {
        title: 'Emails',
        field: 'emails',
        filtering: false,
        sorting: false,
        render: this.renderEmails,
      },
      {
        title: 'Representors',
        filtering: false,
        sorting: false,
        render: this.renderRepresentors,
      },
      {
        title: 'Phones',
        field: 'phones',
        filtering: false,
        sorting: false,
        render: this.renderPhones,
      },
      {
        title: 'Created At',
        field: 'createdAt',
        type: 'datetime',
        render: this.renderCreatedAt,
        filtering: false,
        defaultSort:
          initial.orderBy === 'createdAt' ? initial.orderDirection : undefined,
      },
      {
        title: 'Updated At',
        field: 'updatedAt',
        type: 'datetime',
        render: this.renderUpdatedAt,
        filtering: false,
        defaultSort:
          initial.orderBy === 'updatedAt' ? initial.orderDirection : undefined,
      },
    ];
    return (
      <>
        <MaterialTable
          title="Clients"
          columns={columns}
          data={(query) =>
            new Promise((resolve) => {
              if (this.initialPage > 0) {
                query.page = this.initialPage;
                this.initialPage = -1;
              }
              // orderby and orderDirection are missing from the initial query
              const orderBy = columns[query.orderByCollection[0].orderBy];
              const orderDirection = query.orderByCollection[0].orderDirection;
              loadClients({ ...query, orderBy, orderDirection })
                .then(() => {
                  if (this.props.clients.length === 0 && query.page > 0) {
                    query.page = query.page - 1;
                    return loadClients(query);
                  }
                  return null;
                })
                .then(() => {
                  resolve({
                    data: this.props.clients,
                    page: query.page,
                    totalCount: this.props.totalCount,
                  });
                });
            })
          }
          onRowClick={(event, row) => this.props.push(`clients/${row.id}`)}
          editable={{
            onRowDelete: this.onRowDelete,
          }}
          actions={[
            {
              icon: () => <PlusBox color="secondary" />,
              tooltip: 'Add New Client',
              isFreeAction: true,
              onClick: () => this.props.push('clients/create'),
            },
          ]}
          options={{
            initialPage: this.initialPage > 0 ? this.initialPage : 0,
            pageSize: this.props.initial.pageSize,
            searchText: this.props.initial.searchText,
            emptyRowsWhenPaging: false,
          }}
          isLoading={loading}
        />
        <Snackbar
          open={!!errorMessage}
          autoHideDuration={7000}
          onClose={clearClientErrorMessage}
        >
          <Alert
            severity="error"
            action={
              <Button
                size="small"
                color="secondary"
                onClick={clearClientErrorMessage}
              >
                Dismiss
              </Button>
            }
          >
            {errorMessage}
          </Alert>
        </Snackbar>
        <Snackbar
          open={!!message}
          autoHideDuration={7000}
          onClose={clearClientMessage}
        >
          <Alert
            severity="success"
            action={
              <Button
                size="small"
                color="secondary"
                onClick={clearClientMessage}
              >
                Dismiss
              </Button>
            }
          >
            {message}
          </Alert>
        </Snackbar>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    errorMessage: getClientsErrorMessage(state),
    message: getClientsMessage(state),
    clients: getClients(state),
    totalCount: getClientsCount(state),
    loading: state.clients.loading,
    initial: state.clients.preferences,
  };
};

export default compose(
  connect(mapStateToProps, {
    loadClients,
    updateClient,
    removeClient,
    push,
    clearClientErrorMessage,
    clearClientMessage,
  }),
  withStyles(styles)
)(ClientList);
