import React from 'react';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Snackbar from '@mui/material/Snackbar';
import { withStyles } from '@mui/styles';
import { PlusBox } from 'mdi-material-ui';
import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';
import {
  getReports,
  getReportsCount,
  getReportsMessage,
  getClients,
} from '../../store/selectors';
import {
  loadReports,
  loadClients,
  removeReport,
  clearReportMessage,
  unloadClients,
} from '../../store/actions';
import MaterialTable from '../../components/MaterialTable';
import SelectFilterField from '../../components/SelectFilterField';
import { MTableToolbar } from '@material-table/core';
import { formatDateTime, MAX_PAGE_SIZE } from '../../utils';

const styles = (theme) => ({
  toolbarInfo: {
    padding: `0px ${theme.spacing(2)}`,
  },
  toolbarChip: {
    marginRight: theme.spacing(1),
  },
});

class ReportList extends React.Component {
  initialPage = -1;
  tableRef = React.createRef();

  componentDidMount() {
    this.initialPage = this.props.initial.page;
    this.props.loadClients({ pageSize: MAX_PAGE_SIZE });
  }

  componentWillUnmount() {
    this.props.unloadClients();
  }

  getData = (columns) => (query) =>
    new Promise((resolve) => {
      if (this.initialPage > 0) {
        query.page = this.initialPage;
        this.initialPage = -1;
      }
      // orderby and orderDirection are missing from the initial query
      const orderBy = columns[query.orderByCollection[0].orderBy];
      const orderDirection = query.orderByCollection[0].orderDirection;
      this.props
        .loadReports({ ...query, orderBy, orderDirection })
        .then(() => {
          if ((this.props.reports.length === 0) & (query.page > 0)) {
            query.page = query.page - 1;
            return this.props.loadReports(query);
          }
          return null;
        })
        .then(() => {
          resolve({
            data: this.props.reports,
            page: query.page,
            totalCount: this.props.totalCount,
          });
        });
    });

  renderToolbar = (props) => {
    const { classes, totalCount = 0 } = this.props;

    return (
      <div>
        <MTableToolbar {...props} />
        <div className={classes.toolbarInfo}>
          <Chip
            label={`Total: ${totalCount}`}
            color="secondary"
            className={classes.toolbarChip}
          />
        </div>
      </div>
    );
  };

  renderDateTime = (date) => {
    return formatDateTime(date);
  };

  renderClientFilter = (props) => {
    const { clients, initial } = this.props;
    return (
      <SelectFilterField
        items={clients}
        initialValue={initial.clientId}
        onFilterChanged={(value) => {
          props.onFilterChanged(props.columnDef.tableData.id, value);
        }}
        labelKey="companyName"
      />
    );
  };

  render() {
    const { removeReport, push, message, clearReportMessage, initial } =
      this.props;
    const columns = [
      {
        title: 'ID',
        field: 'id',
        type: 'string',
        editable: 'never',
        filtering: false,
        sorting: false,
      },
      {
        title: 'Date From',
        field: 'fromTime',
        type: 'datetime',
        editable: 'never',
        filtering: false,
        render: (row) => this.renderDateTime(row.fromTime),
        defaultSort:
          initial.orderBy === 'fromTime' ? initial.orderDirection : undefined,
      },
      {
        title: 'Date To',
        field: 'toTime',
        type: 'datetime',
        editable: 'never',
        filtering: false,
        render: (row) => this.renderDateTime(row.toTime),
        defaultSort:
          initial.orderBy === 'toTime' ? initial.orderDirection : undefined,
      },
      {
        title: 'Client',
        field: 'client.companyName',
        filterComponent: this.renderClientFilter,
        sorting: false,
        editable: 'never',
        defaultFilter: initial.clientId,
      },
      {
        title: 'Type',
        field: 'type',
        filtering: false,
        sorting: false,
        editable: 'never',
        lookup: {
          all: 'All Categories',
          'cover-story': 'Cover Story',
          normal: 'Normal Categories',
        },
      },
      {
        title: 'Created At',
        field: 'createdAt',
        type: 'datetime',
        editable: 'never',
        filtering: false,
        render: (row) => this.renderDateTime(row.createdAt),
        defaultSort:
          initial.orderBy === 'createdAt' ? initial.orderDirection : undefined,
      },
      {
        title: 'Updated At',
        field: 'updatedAt',
        type: 'datetime',
        editable: 'never',
        filtering: false,
        render: (row) => this.renderDateTime(row.updatedAt),
        defaultSort:
          initial.orderBy === 'updatedAt' ? initial.orderDirection : undefined,
      },
    ];
    return (
      <>
        <MaterialTable
          title="Reports"
          tableRef={this.tableRef}
          components={{
            Toolbar: this.renderToolbar,
          }}
          columns={columns}
          data={this.getData(columns)}
          onRowClick={(event, row) => {
            if (event.target.type !== 'checkbox') {
              this.props.push(`reports/${row.id}`);
            }
          }}
          editable={{
            onRowDelete: ({ id }) => removeReport(id),
          }}
          actions={[
            {
              icon: () => <PlusBox color="secondary" />,
              tooltip: 'Add New Report',
              isFreeAction: true,
              onClick: () => push('reports/create'),
            },
          ]}
          options={{
            filtering: true,
            initialPage: this.initialPage > 0 ? this.initialPage : 0,
            pageSize: this.props.initial.pageSize,
            searchText: this.props.initial.searchText,
            padding: 'dense',
            emptyRowsWhenPaging: false,
          }}
        />

        <Snackbar
          open={!!message}
          autoHideDuration={7000}
          onClose={clearReportMessage}
        >
          <Alert
            severity="success"
            action={
              <Button
                size="small"
                color="secondary"
                onClick={clearReportMessage}
              >
                Dismiss
              </Button>
            }
          >
            {message}
          </Alert>
        </Snackbar>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    reports: getReports(state),
    clients: getClients(state),
    initial: state.reports.preferences,
    totalCount: getReportsCount(state),
    message: getReportsMessage(state),
  };
};

export default compose(
  connect(mapStateToProps, {
    loadReports,
    loadClients,
    removeReport,
    push,
    clearReportMessage,
    unloadClients,
  }),
  withStyles(styles)
)(ReportList);
