import React, { useEffect } from 'react';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import { isMobile } from 'react-device-detect';
import { verifyEmail, unloadAuthPage } from '../../store/actions';
import {
  getError,
  getMagicToken,
  getProcessed,
  selectAuthProcessing,
} from '../../store/selectors';
import Copyright from '../../components/Copyright';

const styles = (theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(2),
    width: theme.spacing(20),
  },
  submit: {
    margin: theme.spacing(8, 0, 2),
  },
});

const VerifyEmail = ({
  classes,
  errorMessage,
  isProcessed,
  isProcessing,
  magicToken,
  match,
  verifyEmail,
  unloadAuthPage,
}) => {
  useEffect(() => {
    const verifyToken = match.params.token;
    verifyEmail(verifyToken);
    return () => {
      unloadAuthPage();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img
          className={classes.logo}
          alt="WiB blue trademark logo"
          src="/wib-blue-tm-logo-1000x500.png"
        />
        <Typography component="h1" variant="h5" color="primary">
          Verify Your Email
        </Typography>
        <Grid container spacing={3}></Grid>
        <Button
          className={classes.submit}
          color="primary"
          disabled={isProcessing || !isProcessed}
          fullWidth
          variant="contained"
          href={
            isMobile ? `worldinbeauty://image/magic/${magicToken}` : '/signin'
          }
        >
          {isProcessing
            ? 'Verifying...'
            : isMobile
            ? 'Open WiB App'
            : 'Go To Sign In'}
        </Button>
      </div>
      <Copyright />
      <Snackbar open={!!errorMessage}>
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
      <Snackbar open={isProcessed && !errorMessage}>
        <Alert
          severity="success"
          action={
            <Link
              href={
                isMobile
                  ? `worldinbeauty://image/magic/${magicToken}`
                  : '/signin'
              }
              variant="body2"
            >
              {isMobile ? 'Open WiB' : 'Sign In'}
            </Link>
          }
        >
          Email has been verified successfully
        </Alert>
      </Snackbar>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    errorMessage: getError(state),
    isProcessed: getProcessed(state),
    isProcessing: selectAuthProcessing(state),
    magicToken: getMagicToken(state),
  };
};

export default compose(
  connect(mapStateToProps, { verifyEmail, unloadAuthPage }),
  withStyles(styles)
)(VerifyEmail);
