import React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import { withStyles } from '@mui/styles';
import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';
import {
  AccountCircleOutline,
  AccountHardHat,
  Check,
  ShieldAccount,
} from 'mdi-material-ui';
import Countdown from 'react-countdown';
import _ from 'lodash';
import {
  getUsers,
  getUsersCount,
  getUsersErrorMessage,
  getUsersMessage,
} from '../../store/selectors';
import {
  loadUsers,
  updateUser,
  removeUser,
  clearUserErrorMessage,
  clearUserMessage,
} from '../../store/actions';
import MaterialTable from '../../components/MaterialTable';
import { formatDateTime, formatCountdown } from '../../utils';
import {
  EDITOR,
  PLATFORM,
  ROLES,
  STATUS,
  SUBSCRIPTION_STATUS,
} from './constants';
import SelectFilterField from '../../components/SelectFilterField';

const styles = (theme) => ({
  avatarContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: theme.spacing(1),
  },
});

class UserList extends React.Component {
  initialPage = -1;

  componentDidMount() {
    this.initialPage = this.props.initial.page;
  }

  renderRole = (row) => {
    if (row.role === 'user') {
      return (
        <Tooltip title="User">
          <AccountCircleOutline />
        </Tooltip>
      );
    }
    if (row.role === 'admin') {
      return (
        <Tooltip title="Admin">
          <ShieldAccount />
        </Tooltip>
      );
    }
    if (row.role === 'root') {
      return (
        <Tooltip title="Root">
          <AccountHardHat />
        </Tooltip>
      );
    }
  };

  renderRoleFilter = (props) => {
    return (
      <SelectFilterField
        items={ROLES}
        initialValue={this.props.initial.role}
        onFilterChanged={(value) =>
          props.onFilterChanged(props.columnDef.tableData.id, value)
        }
        labelKey="name"
      />
    );
  };

  renderEditor = (row) => {
    return row.isEditor ? <Check color="primary" /> : null;
  };

  renderEditorFilter = (props) => {
    return (
      <SelectFilterField
        items={EDITOR}
        initialValue={this.props.initial.isEditor}
        onFilterChanged={(value) => {
          props.onFilterChanged(props.columnDef.tableData.id, value);
        }}
        labelKey="name"
      />
    );
  };

  renderPlatformFilter = (props) => {
    return (
      <SelectFilterField
        items={PLATFORM}
        initialValue={this.props.initial.platform}
        onFilterChanged={(value) =>
          props.onFilterChanged(props.columnDef.tableData.id, value)
        }
        labelKey="name"
      />
    );
  };

  renderStatusFilter = (props) => {
    return (
      <SelectFilterField
        items={STATUS}
        initialValue={this.props.initial.status}
        onFilterChanged={(value) =>
          props.onFilterChanged(props.columnDef.tableData.id, value)
        }
        labelKey="name"
      />
    );
  };

  renderSubscriptionFilter = (props) => {
    return (
      <SelectFilterField
        items={SUBSCRIPTION_STATUS}
        initialValue={this.props.initial.subscriptionStatus}
        onFilterChanged={(value) => {
          props.onFilterChanged(props.columnDef.tableData.id, value);
        }}
        labelKey="name"
      />
    );
  };

  renderAvatar = (row) => {
    const { apple, facebook, google, local } = row.provider;
    const { classes } = this.props;
    return (
      <div className={classes.avatarContainer}>
        {!!apple && (
          <Tooltip title="Apple Account">
            <Avatar src={apple.picture} className={classes.avatar} />
          </Tooltip>
        )}
        {!!facebook && (
          <Tooltip title="Facebook Account">
            <Avatar src={facebook.picture} className={classes.avatar} />
          </Tooltip>
        )}
        {!!google && (
          <Tooltip title="Google Account">
            <Avatar src={google.picture} className={classes.avatar} />
          </Tooltip>
        )}
        {!!local && (
          <Tooltip title="Email Account">
            <Avatar src={row.profilePictureUrl} className={classes.avatar} />
          </Tooltip>
        )}
      </div>
    );
  };

  renderCountdown = (countdownObj) => {
    return (
      <Typography style={{ fontSize: 'inherit' }}>
        <b>Countdown</b>: {formatCountdown(countdownObj)}
      </Typography>
    );
  };

  renderSubscriptionStatus = (row) => {
    let expiresAt = row.trialEndsAt;
    if (!!row.subscription && row.subscription.endTime > row.trialEndsAt) {
      expiresAt = row.subscription.endTime;
    }
    return (
      <Tooltip
        title={
          <>
            <Countdown date={expiresAt} renderer={this.renderCountdown} />
            <Typography style={{ fontSize: 'inherit' }}>
              <b>Trial Ends At</b>: {formatDateTime(row.trialEndsAt)}
            </Typography>
            {!_.isEmpty(row.subscription) ? (
              <>
                <Typography style={{ fontSize: 'inherit' }}>
                  <b>Subscription Starts At</b>:{' '}
                  {formatDateTime(row.subscription.startTime)}
                </Typography>
                <Typography style={{ fontSize: 'inherit' }}>
                  <b>Subscription Ends At</b>:{' '}
                  {formatDateTime(row.subscription.endTime)}
                </Typography>
              </>
            ) : null}
          </>
        }
      >
        <Typography>
          {!_.isEmpty(row.subscription)
            ? row.subscription.isCanceled
              ? `Canceled (${
                  row.subscription.platform === 'ios' ? 'iOS' : 'Android'
                })`
              : `Active Paid (${
                  row.subscription.platform === 'ios' ? 'iOS' : 'Android'
                })`
            : row.trialEndsAt <= Date.now()
            ? 'Not Subscribed'
            : 'Free Trial'}
        </Typography>
      </Tooltip>
    );
  };

  renderCreatedAt = (row) => {
    return formatDateTime(row.createdAt);
  };

  renderUpdatedAt = (row) => {
    return formatDateTime(row.updatedAt);
  };

  render() {
    const {
      errorMessage,
      message,
      loading,
      clearUserErrorMessage,
      clearUserMessage,
      loadUsers,
      initial,
    } = this.props;

    const columns = [
      {
        title: 'Role',
        field: 'role',
        render: this.renderRole,
        filterComponent: this.renderRoleFilter,
        defaultFilter: initial.role,
        width: 32,
        defaultSort:
          initial.orderBy === 'role' ? initial.orderDirection : undefined,
      },
      { title: 'Avatar', render: this.renderAvatar, sorting: false },
      {
        title: 'Editor',
        field: 'isEditor',
        render: this.renderEditor,
        filterComponent: this.renderEditorFilter,
        defaultFilter: initial.isEditor,
        type: 'boolean',
        sorting: false,
      },
      {
        title: 'Email',
        field: 'email',
        filtering: false,
        defaultSort:
          initial.orderBy === 'email' ? initial.orderDirection : undefined,
      },
      {
        title: 'First Name',
        field: 'firstName',
        filtering: false,
        defaultSort:
          initial.orderBy === 'firstName' ? initial.orderDirection : undefined,
      },
      {
        title: 'Last Name',
        field: 'lastName',
        filtering: false,
        defaultSort:
          initial.orderBy === 'lastName' ? initial.orderDirection : undefined,
      },
      {
        title: 'Platform',
        field: 'platform',
        filterComponent: this.renderPlatformFilter,
        defaultFilter: initial.platform,
        lookup: {
          android: 'Android',
          ios: 'iOS',
        },
        defaultSort:
          initial.orderBy === 'platform' ? initial.orderDirection : undefined,
      },
      {
        title: 'Status',
        field: 'status',
        filterComponent: this.renderStatusFilter,
        defaultFilter: initial.status,
        lookup: {
          active: 'Active',
          deleted: 'Deleted',
          disabled: 'Disabled',
          'unverified-email': 'Unverified Email',
        },
        defaultSort:
          initial.orderBy === 'status' ? initial.orderDirection : undefined,
      },
      {
        title: 'Subscription Status',
        field: 'subscription.isCanceled',
        filterComponent: this.renderSubscriptionFilter,
        defaultFilter: initial.subscriptionStatus,
        render: this.renderSubscriptionStatus,
        sorting: false,
      },
      {
        title: 'Created At',
        field: 'createdAt',
        type: 'datetime',
        render: this.renderCreatedAt,
        filtering: false,
        defaultSort:
          initial.orderBy === 'createdAt' ? initial.orderDirection : undefined,
      },
      {
        title: 'Updated At',
        field: 'updatedAt',
        type: 'datetime',
        render: this.renderUpdatedAt,
        filtering: false,
        defaultSort:
          initial.orderBy === 'updatedAt' ? initial.orderDirection : undefined,
      },
    ];

    return (
      <>
        <MaterialTable
          title="Users"
          columns={columns}
          data={(query) =>
            new Promise((resolve) => {
              if (this.initialPage > 0) {
                query.page = this.initialPage;
                this.initialPage = -1;
              }
              // orderby and orderDirection are missing from the initial query
              const orderBy = columns[query.orderByCollection[0].orderBy];
              const orderDirection = query.orderByCollection[0].orderDirection;
              loadUsers({ ...query, orderBy, orderDirection })
                .then(() => {
                  if (this.props.users.length === 0 && query.page > 0) {
                    query.page = query.page - 1;
                    return loadUsers(query);
                  }
                  return null;
                })
                .then(() => {
                  resolve({
                    data: this.props.users,
                    page: query.page,
                    totalCount: this.props.totalCount,
                  });
                });
            })
          }
          onRowClick={(event, row) => this.props.push(`users/${row.id}`)}
          options={{
            filtering: true,
            initialPage: this.initialPage > 0 ? this.initialPage : 0,
            pageSize: this.props.initial.pageSize,
            searchText: this.props.initial.searchText,
            emptyRowsWhenPaging: false,
            padding: 'dense',
          }}
          isLoading={loading}
        />
        <Snackbar
          open={!!errorMessage}
          autoHideDuration={7000}
          onClose={clearUserErrorMessage}
        >
          <Alert
            severity="error"
            action={
              <Button
                size="small"
                color="secondary"
                onClick={clearUserErrorMessage}
              >
                Dismiss
              </Button>
            }
          >
            {errorMessage}
          </Alert>
        </Snackbar>
        <Snackbar
          open={!!message}
          autoHideDuration={7000}
          onClose={clearUserMessage}
        >
          <Alert
            severity="success"
            action={
              <Button size="small" color="secondary" onClick={clearUserMessage}>
                Dismiss
              </Button>
            }
          >
            {message}
          </Alert>
        </Snackbar>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    errorMessage: getUsersErrorMessage(state),
    message: getUsersMessage(state),
    users: getUsers(state),
    totalCount: getUsersCount(state),
    loading: state.users.loading,
    initial: state.users.preferences,
  };
};

export default compose(
  connect(mapStateToProps, {
    loadUsers,
    updateUser,
    removeUser,
    push,
    clearUserErrorMessage,
    clearUserMessage,
  }),
  withStyles(styles)
)(UserList);
