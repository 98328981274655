import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { withStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import { push } from 'redux-first-history';
import PdfDocument from './PdfDocument';

const fromUser = {
  companyName: 'Reputation Media Group Inc.',
  phone: '+1 778 998 2727',
  address: {
    street: '1005-590 Nicola Street',
    city: 'Vancouver',
    postalCode: 'V6G 3J8',
    province: 'British Columbia',
    country: 'Canada',
  },
  email: 'contact@worldinbeauty.com',
};

const styles = (theme) => ({
  backdrop: {
    zIndex: 999,
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
  },
  dialogContent: {
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
  },
  pdfViewer: {
    width: '100%',
    height: '70vh',
  },
  note: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
});

const ExportPreviewDialog = ({
  asset,
  classes,
  mobileAppConfig,
  open,
  processing,
  push,
  onClose,
}) => {
  const title = 'Preview';
  const subject = 'All Categories';

  const report = useMemo(() => {
    return {
      client: {},
      createdAt: Date.now(),
      // publishTime should be undefined for preview
      assets: [asset].map((asset) => ({ ...asset, publishTime: undefined })),
    };
  }, [asset]);

  return (
    <>
      <div>
        <Dialog
          open={open}
          maxWidth={'lg'}
          fullWidth
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Export Preview</DialogTitle>
          <DialogContent className={classes.dialogContent}>
            {report && report.assets && (
              <>
                <Grid item>
                  <PDFViewer className={classes.pdfViewer}>
                    <PdfDocument
                      report={report}
                      author={fromUser}
                      title={title}
                      subject={subject}
                      isPreview
                      mobileAppConfig={mobileAppConfig}
                    />
                  </PDFViewer>
                </Grid>
                <Grid item>
                  {report && report.assets && (
                    <PDFDownloadLink
                      document={
                        <PdfDocument
                          report={report}
                          author={fromUser}
                          title={title}
                          subject={subject}
                          isPreview
                          mobileAppConfig={mobileAppConfig}
                        />
                      }
                      fileName={`Digital_${title}.pdf`}
                    >
                      {({ blob, url, loading, error }) =>
                        loading
                          ? 'Generating the digital preview...'
                          : 'Download the Digital Preview!'
                      }
                    </PDFDownloadLink>
                  )}
                </Grid>
                <Grid item>
                  {report && report.assets && (
                    <PDFDownloadLink
                      document={
                        <PdfDocument
                          report={report}
                          author={fromUser}
                          title={title}
                          subject={subject}
                          isPreview
                          mobileAppConfig={mobileAppConfig}
                          printable={true}
                        />
                      }
                      fileName={`Printable_${title}.pdf`}
                    >
                      {({ blob, url, loading, error }) =>
                        loading
                          ? 'Generating the printable preview...'
                          : 'Download the Printable Preview!'
                      }
                    </PDFDownloadLink>
                  )}
                </Grid>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                onClose();
              }}
              variant="contained"
              disabled={processing}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

ExportPreviewDialog.propTypes = {
  asset: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

ExportPreviewDialog.defaultProps = {
  open: false,
  onClose: () => {},
};

const mapStateToProps = (state) => {
  return {
    mobileAppConfig: state.admin.appVersion.config,
  };
};

export default compose(
  connect(mapStateToProps, { push }),
  withStyles(styles)
)(ExportPreviewDialog);
