import React from 'react';
import PropTypes from 'prop-types';
import {
  Font,
  Link,
  Page,
  StyleSheet,
  Text,
  View,
  Image,
} from '@react-pdf/renderer';
import {
  formatLongPostedDate,
  formatPostedDate,
  generateActionBarUrl,
  generateCreditStr,
} from '../utils';
import PdfInfoTable from './PdfInfoTable';

const landingPageUrl = 'https://worldinbeauty.app.link';

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: '/Roboto-Medium.ttf',
      fontWeight: 500,
    },
    {
      src: '/Roboto-MediumItalic.ttf',
      fontWeight: 500,
      fontStyle: 'italic',
    },
    {
      src: '/Roboto-Regular.ttf',
      fontWeight: 400,
    },
    {
      src: '/Roboto-Italic.ttf',
      fontWeight: 400,
      fontStyle: 'italic',
    },
  ],
});

Font.registerHyphenationCallback((word) => [word]);

const styles = StyleSheet.create({
  page: {},
  pageA4: {
    paddingBottom: 56,
  },
  viewContainer: {
    width: '100%',
    height: '100%',
    paddingBottom: 64,
  },
  header: {
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '16 60 8 60',
  },
  line: {
    width: '100%',
    marginTop: 4,
    marginBottom: 4,
    borderBottom: '1 solid #000096',
  },
  logo: {
    width: 80,
  },
  videoContainer: {
    position: 'relative',
  },
  youtubeLink: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  homeYoutubeLink: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  image: {
    width: 220,
    marginHorizontal: 188,
  },
  text: {
    width: 220,
    marginHorizontal: 188,
  },
  info: {
    width: '100%',
    padding: '0 60 0 60',
    flexDirection: 'row',
    justifyContent: 'center',
    flex: 1,
  },
  infoWithScreenShot: {
    width: '100%',
    padding: '0 60 0 60',
    flexDirection: 'row',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    flex: 1,
  },
  leftColumn: {
    width: 400,
  },
  leftColumnWithScreenShot: {
    width: '50%',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  rightColumn: {
    width: '50%',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  footer: {
    position: 'absolute',
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    paddingHorizontal: 60,
  },
  infoTable: {
    marginBottom: 12,
  },
  bannerScreenShot: {
    width: 220,
    flexDirection: 'column',
    border: '0.5 solid #E4E4E4',
    marginBottom: 2,
  },
  homeScreenShot: {
    width: 220,
    flexDirection: 'column',
    border: '0.5 solid #E4E4E4',
    marginBottom: 16,
  },
  homeImage: {
    width: '100%',
  },
  banner: {
    flexDirection: 'row',
    border: '0.5 solid #E4E4E4',
    margin: 2,
  },
  bannerImage: {
    width: 64,
  },
  bannerRight: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    paddingVertical: 6,
    paddingLeft: 4,
    paddingRight: 2,
  },
  bannerText: {
    width: '100%',
    fontSize: 7,
    padding: 0,
  },
  bannerButton: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 7,
    border: '1 solid #000096',
    borderRadius: 2,
    paddingVertical: 2,
    paddingHorizontal: 4,
    marginRight: 4,
  },
  bannerNote: {
    width: 220,
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 7,
    textAlign: 'right',
  },
  readMoreContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  readMoreButton: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 7,
    border: '1 solid #000096',
    borderRadius: 2,
    paddingVertical: 2,
    paddingHorizontal: 4,
    marginRight: 10,
  },
  heading: {
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: 12,
    marginBottom: 8,
  },
  actionBar: {
    borderBottom: '0.5 solid #E4E4E4',
    borderTop: '0.5 solid #E4E4E4',
    paddingBottom: 1,
    paddingTop: 1,
  },
  actionBarHome: {
    width: '100%',
    marginTop: 2,
    borderBottom: '0.5 solid #E4E4E4',
    paddingBottom: 1,
  },
  likesCount: {
    color: '#000096',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 8,
    position: 'relative',
    left: 18,
    top: -17,
  },
  shortTitle: {
    margin: '6 8 0 8',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 10,
  },
  caption: {
    alignSelf: 'flex-start',
    marginTop: 5,
    paddingHorizontal: 8,
    color: 'gray',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 8,
  },
  citation: {
    alignSelf: 'flex-start',
    marginBottom: 9,
    paddingHorizontal: 8,
    color: 'gray',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontStyle: 'italic',
    fontSize: 8,
    textAlign: 'left',
  },
  captionWithLikeButton: {
    alignSelf: 'flex-start',
    marginTop: 0,
    paddingHorizontal: 8,
    color: 'gray',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 8,
  },
  credit: {
    alignSelf: 'flex-start',
    marginBottom: 8,
    paddingHorizontal: 8,
    color: 'gray',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 8,
  },
  creditWithoutCaption: {
    alignSelf: 'flex-start',
    marginTop: 5,
    marginBottom: 8,
    paddingHorizontal: 8,
    color: 'gray',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 8,
  },
  creditWithoutCaptionWithLikeButton: {
    alignSelf: 'flex-start',
    marginTop: 0,
    marginBottom: 8,
    paddingHorizontal: 8,
    color: 'gray',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 8,
  },
  title: {
    marginBottom: 8,
    paddingHorizontal: 8,
    fontSize: 10,
  },
  normal: {
    fontFamily: 'Roboto',
    fontWeight: 400,
  },
  italic: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontStyle: 'italic',
  },
  bold: {
    fontFamily: 'Roboto',
    fontWeight: 500,
  },
  boldItalic: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontStyle: 'italic',
  },
  author: {
    alignSelf: 'flex-start',
    marginBottom: 8,
    paddingHorizontal: 8,
    color: 'gray',
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontStyle: 'italic',
    fontSize: 9,
  },
  description: {
    marginBottom: 9,
    paddingHorizontal: 8,
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 10,
  },
  homePublishTime: {
    margin: '2 8 0 8',
    color: 'gray',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 7,
  },
  publishTime: {
    textAlign: 'right',
    marginBottom: 8,
    paddingHorizontal: 8,
    color: 'gray',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 8,
  },
  discoverButton: {
    textAlign: 'center',
    marginTop: 4,
    marginBottom: 8,
    paddingHorizontal: 8,
    color: '#000096',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 8,
    textTransform: 'uppercase',
    textDecoration: 'none',
  },
  sectionDiscoverButton: {
    textAlign: 'center',
    marginTop: 0,
    marginBottom: 16,
    paddingHorizontal: 8,
    color: '#000096',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 8,
    textTransform: 'capitalize',
    textDecoration: 'none',
  },
  pageNumbers: {
    color: 'gray',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 8,
    marginTop: 2,
  },
  preview: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 100,
    transform: 'rotate(-45deg)',
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'rgba(183, 28, 28, 0.5)',
  },
});

const PdfAssetDetail = ({
  asset,
  isPreview,
  reportTime,
  mobileAppConfig,
  index,
  printable,
}) => {
  const actionBarUrl = generateActionBarUrl({ mobileAppConfig, asset });
  const isCoverStory = asset.category.name === 'Cover Story';

  const renderPageContent = () => {
    return (
      <>
        <View style={styles.header} fixed>
          <Link src={landingPageUrl}>
            <Image src="/wib-blue-tm-logo-1000x500.png" style={styles.logo} />
          </Link>
          <hr style={styles.line} />
        </View>
        <View
          style={
            (isCoverStory && asset.promoteInTopStories) || !isCoverStory
              ? styles.infoWithScreenShot
              : styles.info
          }
        >
          <View
            style={
              (isCoverStory && asset.promoteInTopStories) || !isCoverStory
                ? styles.leftColumnWithScreenShot
                : styles.leftColumn
            }
          >
            <PdfInfoTable
              asset={asset}
              style={styles.infoTable}
              reportTime={reportTime}
              index={index}
              tableWidth={
                (isCoverStory && asset.promoteInTopStories) || !isCoverStory
                  ? 220
                  : 400
              }
            />
          </View>
          {isCoverStory ? (
            asset.promoteInTopStories && (
              <View style={styles.rightColumn}>
                <View style={styles.bannerScreenShot}>
                  <Image src="/screenshots/home-screen_status-bar_top-stories.png" />
                  <View style={styles.banner}>
                    <Image
                      src={{
                        uri: asset.requestOptions[0].square.url,
                        method: 'GET',
                        headers: { 'Cache-Control': 'no-cache' },
                      }}
                      alt="home-screen"
                      style={styles.bannerImage}
                    />
                    <View style={styles.bannerRight}>
                      <Text
                        style={{
                          ...styles.bannerText,
                          ...styles[asset.promotionalTextStyle],
                        }}
                      >
                        {asset.promotionalText}
                      </Text>
                      <Text style={styles.bannerButton}>Read Cover Story</Text>
                    </View>
                  </View>
                </View>
                <Text style={styles.bannerNote}>
                  * This banner is always promoted at the top of Top Stories
                </Text>
              </View>
            )
          ) : (
            <View style={styles.rightColumn}>
              <View style={styles.homeScreenShot}>
                <Image src="/screenshots/home-screen_status-bar_top-stories.png" />
                {asset.sections[0].hasVideo ? (
                  <View style={styles.videoContainer}>
                    <Image
                      src={{
                        uri: asset.requestOptions[0].landscape.url,
                        method: 'GET',
                        headers: { 'Cache-Control': 'no-cache' },
                      }}
                      alt="home-screen"
                      style={styles.homeImage}
                    />
                    <Link
                      src={`https://youtu.be/${asset.sections[0].video.ids[0]}`}
                      style={styles.homeYoutubeLink}
                    >
                      <Image
                        src="/youtube-logo-landscape.png"
                        style={styles.homeImage}
                      />
                    </Link>
                  </View>
                ) : (
                  <Image
                    src={{
                      uri: asset.requestOptions[0].landscape.url,
                      method: 'GET',
                      headers: { 'Cache-Control': 'no-cache' },
                    }}
                    alt="home-screen"
                    style={styles.homeImage}
                  />
                )}
                <Text style={styles.shortTitle}>{asset.shortTitle}</Text>
                <Text style={styles.homePublishTime}>
                  {!!asset.publishTime
                    ? `Published ${formatPostedDate(asset.publishTime)}`
                    : 'Published date - N/A'}
                </Text>
                {asset.sections[0].hasVideo && (
                  <View style={styles.readMoreContainer}>
                    <Text style={styles.readMoreButton}>Read more</Text>
                  </View>
                )}
                <Image src={actionBarUrl} style={styles.actionBarHome} />
                {mobileAppConfig.likeButton && (
                  <Text style={styles.likesCount}>{asset.likesCount}</Text>
                )}
              </View>
            </View>
          )}
        </View>
        {isCoverStory && (
          <Text style={{ ...styles.text, ...styles.heading }}>Cover Story</Text>
        )}
        {asset.sections.map((item, index) => {
          const requestOptions = asset.requestOptions[index];
          const displayedCredit = generateCreditStr(item);
          return (
            <React.Fragment key={index}>
              {index === 0 &&
                (isCoverStory ? (
                  <Image
                    src="/screenshots/home-screen_status-bar_cover-story.png"
                    style={styles.image}
                  />
                ) : (
                  <Image
                    src="/screenshots/detail-screen_status-bar.png"
                    style={styles.image}
                  />
                ))}
              {item.hasVideo ? (
                <View style={styles.videoContainer}>
                  <Image
                    src={{
                      uri: requestOptions.landscape.url,
                      method: 'GET',
                      headers: { 'Cache-Control': 'no-cache' },
                    }}
                    style={styles.image}
                  />
                  <Link
                    src={`https://youtu.be/${item.video.ids[0]}`}
                    style={styles.youtubeLink}
                  >
                    <Image
                      src="/youtube-logo-landscape.png"
                      style={styles.image}
                    />
                  </Link>
                </View>
              ) : (
                <Image
                  src={{
                    uri: requestOptions[item.imageMode].url,
                    method: 'GET',
                    headers: { 'Cache-Control': 'no-cache' },
                  }}
                  style={styles.image}
                />
              )}
              {index === 0 && (
                <Image
                  src={actionBarUrl}
                  style={{ ...styles.image, ...styles.actionBar }}
                />
              )}
              {index === 0 && mobileAppConfig.likeButton && (
                <Text style={{ ...styles.text, ...styles.likesCount }}>
                  {asset.likesCount}
                </Text>
              )}
              {!!item.caption && (
                <Text
                  style={
                    index === 0 && mobileAppConfig.likeButton
                      ? { ...styles.text, ...styles.captionWithLikeButton }
                      : { ...styles.text, ...styles.caption }
                  }
                >
                  {item.caption}
                </Text>
              )}
              {!!displayedCredit && (
                <Text
                  style={{
                    ...styles.text,
                    ...(!!item.caption
                      ? styles.credit
                      : index === 0 && mobileAppConfig.likeButton
                      ? styles.creditWithoutCaptionWithLikeButton
                      : styles.creditWithoutCaption),
                  }}
                >
                  {displayedCredit}
                </Text>
              )}
              {!!item.titleOne && (
                <Text
                  style={{
                    ...styles.text,
                    ...styles.title,
                    ...styles[item.titleOneStyle],
                  }}
                >
                  {item.titleOne}
                </Text>
              )}
              {index === 0 && asset.showAuthor && (
                <Text style={{ ...styles.text, ...styles.author }}>
                  {asset.author}
                </Text>
              )}
              {!!item.descriptionOne && (
                <Text
                  style={{ ...styles.text, ...styles.description }}
                  wrap={true}
                >
                  {item.descriptionOne}
                </Text>
              )}
              {!!item.titleTwo && (
                <Text
                  style={{
                    ...styles.text,
                    ...styles.title,
                    ...styles[item.titleTwoStyle],
                  }}
                >
                  {item.titleTwo}
                </Text>
              )}
              {!!item.descriptionTwo && (
                <Text
                  style={{ ...styles.text, ...styles.description }}
                  wrap={true}
                >
                  {item.descriptionTwo}
                </Text>
              )}
              {!!item.citation && (
                <Text style={{ ...styles.text, ...styles.citation }}>
                  {item.citation}
                </Text>
              )}
              {!!item.sourceUrl && (
                <Link
                  style={{ ...styles.text, ...styles.sectionDiscoverButton }}
                  src={item.sourceUrl}
                >
                  Discover more
                </Link>
              )}
            </React.Fragment>
          );
        })}
        <Text style={{ ...styles.text, ...styles.publishTime }}>
          {!!asset.publishTime
            ? `Published ${formatLongPostedDate(asset.publishTime)}`
            : 'Published date - N/A'}
        </Text>
        {!!asset.sourceUrl && (
          <Link
            style={{ ...styles.text, ...styles.discoverButton }}
            src={asset.sourceUrl}
          >
            Discover more
          </Link>
        )}
        <Image
          src="/screenshots/home-screen_footer_cover-story.png"
          style={styles.image}
        />
      </>
    );
  };

  return printable ? (
    // Multiple pages
    <Page size={'A4'} key={asset.id} style={styles.pageA4}>
      {renderPageContent()}
      <View style={styles.footer} fixed>
        <hr style={styles.line} />
        <Text
          style={styles.pageNumbers}
          render={({ pageNumber, totalPages }) =>
            `Page ${pageNumber} of ${totalPages}`
          }
        />
      </View>
      {isPreview && (
        <View style={styles.preview} fixed>
          <Text>Preview</Text>
        </View>
      )}
    </Page>
  ) : (
    // One page
    <Page size={{ width: 595.28 }} key={asset.id} style={styles.page}>
      <View style={styles.viewContainer} break={false}>
        {renderPageContent()}
        <View style={styles.footer} fixed>
          <hr style={styles.line} />
          <Text
            style={styles.pageNumbers}
            render={({ pageNumber, totalPages }) =>
              `Article ${pageNumber - 1} of ${totalPages - 1}`
            }
          />
        </View>
        {isPreview && (
          <View style={styles.preview} fixed>
            <Text>Preview</Text>
          </View>
        )}
      </View>
    </Page>
  );
};

PdfAssetDetail.propTypes = {
  asset: PropTypes.any.isRequired,
  isPreview: PropTypes.bool,
  reportTime: PropTypes.shape({
    from: PropTypes.number,
    to: PropTypes.number,
  }).isRequired,
  mobileAppConfig: PropTypes.any.isRequired,
  printable: PropTypes.bool,
};

export default PdfAssetDetail;
