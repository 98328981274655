import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Button, Grid, Menu, MenuItem } from '@mui/material';
import { useWatch } from 'react-hook-form';
import {
  addDays,
  endOfDay,
  differenceInDays,
  startOfDay,
  addSeconds,
  format,
} from 'date-fns';
import Countdown, { calcTimeDelta } from 'react-countdown';
import _ from 'lodash';

import ControllerCheckbox from '../../../components/react-hook-form-wrappers/controller-checkbox';
import ControllerDateTimePicker from '../../../components/react-hook-form-wrappers/controller-date-time-picker';
import { formatCountdown } from '../../../utils';

const CheckboxAndDateTimePicker = ({
  control,
  getValues,
  setValue,
  checkboxName,
  checkboxLabel,
  checkboxDisabled,
  endDateLabel,
  endDateName,
  endDateDisabled,
  endDateDisablePast,
  endDateMax,
  endDateMin,
  startDateLabel,
  startDateName,
  startDateDisabled,
  startDateDisablePast,
  startDateMax,
  startDateMin,
}) => {
  const showDateTimePicker = useWatch({ control, name: checkboxName });
  const endDate = useWatch({ control, name: endDateName });
  const countdownRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (endDate && countdownRef.current?.isStopped()) {
      countdownRef.current.start();
    }
  }, [endDate]);

  const handleStartDateChange = useCallback(
    (value) => {
      const startTime = getValues(startDateName) || new Date();
      const endTime = getValues(endDateName) || new Date();
      const diffInDays = differenceInDays(endOfDay(value), endOfDay(startTime));

      // Update endDate
      setValue(endDateName, endOfDay(addDays(endTime, diffInDays)));
    },
    [getValues, setValue, startDateName, endDateName]
  );

  const renderCountdown = useCallback(
    (countdownObj) => {
      const startTime = getValues(startDateName) || new Date();
      const endTime = getValues(endDateName) || new Date();

      if (startTime.getTime() > Date.now()) {
        return (
          <span>
            {formatCountdown(
              calcTimeDelta(endTime, { now: () => startTime.getTime() })
            )}
          </span>
        );
      }

      return <span>{formatCountdown(countdownObj)}</span>;
    },
    [getValues, startDateName, endDateName]
  );

  const startDateOptions = useMemo(() => {
    const options = [];
    const today = new Date();
    const tomorrow = startOfDay(addDays(today, 1));
    const tomorrowStartDates = [];

    for (let minute = 0; minute < 4; minute++) {
      tomorrowStartDates.push(addSeconds(tomorrow, 25200 + minute * 60));
    }

    for (let i = 0; i < 100; i++) {
      _.forEach(tomorrowStartDates, (tomorrowStartDate) => {
        const value = addDays(tomorrowStartDate, i);
        options.push({
          value,
          label: format(value, 'EEE, MMM dd, yyyy HH:mm'),
        });
      });
    }

    return options;
  }, []);

  const openQuickSelectMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeQuickSelectMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Grid
        item
        xs={12}
        md={showDateTimePicker ? 3 : 12}
        xl={showDateTimePicker ? 2 : 12}
      >
        <ControllerCheckbox
          control={control}
          label={checkboxLabel}
          name={checkboxName}
          disabled={checkboxDisabled}
        />
      </Grid>

      {showDateTimePicker && (
        <>
          <Grid container item xs={12} md={7} xl={9} alignItems="center">
            <Countdown
              date={endDate}
              renderer={renderCountdown}
              ref={countdownRef}
            />
          </Grid>

          <Grid container item xs={12} spacing={3} alignItems="center">
            {startDateName && (
              <>
                <Grid item xs={12} md={5} xl={3}>
                  <ControllerDateTimePicker
                    control={control}
                    label={startDateLabel}
                    name={startDateName}
                    maxDateTime={startDateMax}
                    minDateTime={startDateMin}
                    disabled={startDateDisabled || checkboxDisabled}
                    disablePast={startDateDisablePast}
                    onChange={handleStartDateChange}
                  />
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  md={7}
                  xl={9}
                  alignItems="center"
                  gap={2}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={openQuickSelectMenu}
                    disabled={startDateDisabled || checkboxDisabled}
                  >
                    Quick Select
                  </Button>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={closeQuickSelectMenu}
                    keepMounted
                  >
                    {_.map(startDateOptions, ({ label, value }) => {
                      return (
                        <MenuItem
                          key={label}
                          value={label}
                          onClick={() => {
                            handleStartDateChange(value);
                            setValue(startDateName, value, {
                              shouldDirty: true,
                              shouldTouch: true,
                            });
                            closeQuickSelectMenu();
                          }}
                        >
                          {label}
                        </MenuItem>
                      );
                    })}
                  </Menu>
                </Grid>
              </>
            )}

            <Grid item xs={12} md={5} xl={3}>
              <ControllerDateTimePicker
                control={control}
                label={endDateLabel}
                name={endDateName}
                maxDateTime={endDateMax}
                minDateTime={endDateMin}
                disabled={endDateDisabled || checkboxDisabled}
                disablePast={endDateDisablePast}
              />
            </Grid>
            <Grid
              container
              item
              xs={12}
              md={7}
              xl={9}
              alignItems="center"
              gap={2}
            >
              {[1, 2, 4, 8, 12, 16, 32].map((week) => {
                return (
                  <Button
                    key={week}
                    variant="outlined"
                    color="primary"
                    disabled={endDateDisabled || checkboxDisabled}
                    onClick={() => {
                      const startTime = getValues(startDateName) || new Date();
                      const endTime = endOfDay(addDays(startTime, week * 7));
                      setValue(endDateName, endTime, {
                        shouldDirty: true,
                        shouldTouch: true,
                      });
                    }}
                  >
                    {week}w
                  </Button>
                );
              })}
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default CheckboxAndDateTimePicker;
