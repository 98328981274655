import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { FilterVariant } from 'mdi-material-ui';

const SelectFilterField = (props) => {
  const {
    initialValue,
    items,
    onFilterChanged,
    labelKey,
    allOption,
    noneOption,
  } = props;
  return items.length > 0 ? (
    <Select
      displayEmpty
      value={initialValue}
      onChange={({ target }) => {
        onFilterChanged(target.value);
      }}
      IconComponent={() => <FilterVariant />}
      variant="standard"
    >
      {allOption && (
        <MenuItem key="all" value="">
          <em>All</em>
        </MenuItem>
      )}
      {noneOption && (
        <MenuItem key="none" value="<< None >>">
          <em>{'<< None >>'}</em>
        </MenuItem>
      )}
      {items.map((item) => {
        return (
          <MenuItem key={item.id} value={item.id}>
            {item[labelKey]}
          </MenuItem>
        );
      })}
    </Select>
  ) : null;
};

SelectFilterField.propTypes = {
  allOption: PropTypes.bool,
  items: PropTypes.array.isRequired,
  initialValue: PropTypes.string.isRequired,
  labelKey: PropTypes.string.isRequired,
  noneOption: PropTypes.bool,
  onFilterChanged: PropTypes.func.isRequired,
};

SelectFilterField.defaultProps = {
  items: [],
  allOption: true,
};

export default SelectFilterField;
