export const formatCountdown = (countdownObj, hideZeroFigure) => {
  const { days, hours, minutes, seconds, completed } = countdownObj;
  if (completed) {
    return 'Expired';
  }

  const daysStr = days > 1 ? `${days} days ` : days === 1 ? `1 day ` : '';
  const hoursStr =
    hours > 1
      ? `${hours} hours `
      : hours === 1
      ? `1 hour `
      : daysStr
      ? hideZeroFigure
        ? ''
        : `0 hour `
      : '';
  const minutesStr =
    minutes > 1
      ? `${minutes} minutes `
      : minutes === 1
      ? `1 minute `
      : hoursStr
      ? hideZeroFigure
        ? ''
        : `0 minute `
      : '';
  const secondsStr =
    seconds > 1 ? `${seconds} seconds ` : seconds === 1 ? `1 second ` : '';
  return `${daysStr}${hoursStr}${minutesStr}${secondsStr}`;
};
