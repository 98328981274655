import React, { useEffect, useMemo, useRef, useState } from 'react';

import {
  Alert,
  Backdrop,
  Button,
  Card,
  CircularProgress,
  CssBaseline,
  Grid,
  MenuItem,
  Snackbar,
  Typography,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { compose } from '@reduxjs/toolkit';
import { addDays, addSeconds, startOfDay } from 'date-fns';
import localforage from 'localforage';
import _ from 'lodash';
import omitDeep from 'omit-deep-lodash';
import queryString from 'query-string';
import { useForm, useWatch } from 'react-hook-form';
import { connect } from 'react-redux';
import { Prompt, useLocation, useParams } from 'react-router-dom';
import { push } from 'redux-first-history';

import Previewer from '../../components/Previewer';
import useFormPersist from '../../components/custom-hooks/use-form-persist';
import usePrevious from '../../components/custom-hooks/use-previous';
import ControllerCheckbox from '../../components/react-hook-form-wrappers/controller-checkbox';
import ControllerDateTimePicker from '../../components/react-hook-form-wrappers/controller-date-time-picker';
import ControllerTextField from '../../components/react-hook-form-wrappers/controller-text-field';
import YesNoDialog from '../../components/yes-no-dialog';
import {
  addCoverImage,
  clearCoverImageError,
  clearEditingAsset,
  clearEditingCoverImage,
  loadAsset,
  loadCoverImage,
  updateCoverImage,
} from '../../store/actions';
import {
  getCoverImagesErrorMessage,
  getEditingAsset,
  getEditingCoverImage,
} from '../../store/selectors';
import { formatDateTime } from '../../utils';
import { COVER_IMAGE_TYPE, COVER_IMAGE_TYPE_OPTIONS } from './constants';
import CoverImageUploader from './cover-image-uploader';

const DEFAULT_COVER_IMAGE_VALUES = {
  originalFileName: '',
};

const styles = (theme) => ({
  container: {},
  title: {
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  backdrop: {
    zIndex: 999,
    backgroundColor: 'rgba(255, 255, 255, 0.6)',
  },
  countdown: {
    display: 'flex',
    alignItems: 'center',
  },
  copyright: {
    marginBottom: theme.spacing(4),
  },
});

let renderCount = 0;

const ControllerPublishDateTimePicker = ({
  control,
  setValue,
  disabled,
  ...otherProps
}) => {
  const publishDate = useWatch({ control, name: 'publishDate' });
  const isPublished = useWatch({ control, name: 'isPublished' });

  if (!isPublished) {
    return null;
  }

  return (
    <>
      <Grid item xs={12} md={5} xl={3}>
        <ControllerDateTimePicker
          control={control}
          label={
            publishDate && publishDate.getTime() > Date.now()
              ? 'Will Be Pushed At'
              : 'Pushing Time'
          }
          disabled={disabled}
          {...otherProps}
        />
      </Grid>
      <Grid container item xs={12} md={5} xl={3} alignItems="center">
        <Button
          variant="contained"
          color="primary"
          disabled={disabled}
          onClick={() => {
            setValue('publishDate', new Date(), {
              shouldDirty: true,
              shouldTouch: true,
            });
          }}
        >
          Now
        </Button>
      </Grid>
    </>
  );
};

const renderTypeOptions = (titles) => {
  return _.map(titles, (title) => {
    return (
      <MenuItem key={title.id} value={title.id}>
        {title.name}
      </MenuItem>
    );
  });
};

const CoverImageDetail = ({
  classes,
  initialValues,
  loadAsset,
  loadCoverImage,
  clearEditingAsset,
  isProcessing,
  editingCoverImage,
  clearEditingCoverImage,
  clearCoverImageError,
  addCoverImage,
  updateCoverImage,
  coverImageErrorMessage,
  push,
  assetToLink,
}) => {
  const { coverImageId } = useParams();
  const isEditing = useMemo(
    () => !!coverImageId && coverImageId !== 'create',
    [coverImageId]
  );
  const { search: locationSearch } = useLocation();
  const assetId = useMemo(() => {
    return queryString.parse(locationSearch).assetId;
  }, [locationSearch]);

  const [showRestoreDialog, setShowRestoreDialog] = useState(false);

  const [isSaving, setIsSaving] = useState(false);
  const [assetUrl, setAssetUrl] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const previousInitialValues = usePrevious(initialValues);

  // NOTE: Robyn is getting multiple submissions when clicking
  // This is a temporary fix
  const isSubmittingRef = useRef(false);

  useEffect(() => {
    if (editingCoverImage?.asset) {
      setAssetUrl(editingCoverImage.asset.requestOptions[0].landscape.url);
    }
  }, [editingCoverImage]);

  // Remove items from localforage that are older than 10 days
  useEffect(() => {
    const now = Date.now();
    const tenDays = 10 * 24 * 60 * 60 * 1000; // 10 days
    localforage
      .iterate((value, key) => {
        const { __timestamp } = value;

        if (now - __timestamp >= tenDays) {
          localforage.removeItem(key);
        }
      })
      .then(() => {
        // ignore
      })
      .catch((err) => {
        console.log('[localforage.iterate] Error:', err);
      });
  }, []);

  useEffect(() => {
    if (isEditing) {
      loadCoverImage(coverImageId).then(() => {
        // make sure hide loader after creating new article
        setIsSaving(false);
      });
    } else {
      if (assetId) {
        loadAsset(assetId);
      }
    }

    renderCount = 0;

    return () => {
      clearEditingCoverImage();
      if (assetId) {
        clearEditingAsset();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coverImageId]);

  renderCount += 1;

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState: { isDirty, isSubmitting },
  } = useForm({
    defaultValues: {
      id: '',
      type: 'top',
      publishDate: new Date(),
      assetTitle: '',
      assetCategory: '',
      createdAt: '',
      updatedAt: '',
      assetId: '',
      isPublished: false,
      coverImage: {
        ...DEFAULT_COVER_IMAGE_VALUES,
      },
    },
  });

  const { clearPersist, stopPersistInterval } = useFormPersist({
    name: coverImageId
      ? `cover-image-details-form-${coverImageId}`
      : 'cover-image-details-form',
    control,
    getValues,
    reset,
    onDataRestored: () => {
      setShowRestoreDialog(true);
    },
    timeout: 6 * 24 * 60 * 60 * 1000, // 6 days
    exclude: ['createdAt', 'updatedAt'],
  });

  const type = useWatch({ control, name: 'type' });
  const cropSettings = useMemo(() => {
    switch (type) {
      case COVER_IMAGE_TYPE.HIGHLIGHT_ONE:
      case COVER_IMAGE_TYPE.HIGHLIGHT_TWO: {
        return {
          heightThreshold: 1920,
          widthThreshold: 1490,
          aspectRatio: 1490 / 1920,
          screenRatio: 1490 / 2160,
        };
      }

      case COVER_IMAGE_TYPE.TOP:
      default: {
        return {
          heightThreshold: 1000,
          widthThreshold: 2160,
          aspectRatio: 2160 / 1000,
          screenRatio: 1,
        };
      }
    }
  }, [type]);

  useEffect(() => {
    if (initialValues && initialValues.id !== previousInitialValues?.id) {
      reset(initialValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues, reset]);

  useEffect(() => {
    if (_.isEmpty(assetToLink)) {
      return;
    }

    const valuesToSet = {
      assetId: assetToLink.id,
      assetTitle:
        assetToLink.category?.name === 'Cover Story'
          ? assetToLink.promotionalText
          : assetToLink.shortTitle,
      assetCategory: assetToLink.category?.name || '',
    };

    if (assetToLink.publishTime >= Date.now()) {
      valuesToSet.publishDate = new Date(assetToLink.publishTime);
    }

    if (
      assetToLink.category?.name === 'Cover Story' ||
      assetToLink.topStoryFrom <= 0 ||
      assetToLink.topStoryTo <= Date.now()
    ) {
      valuesToSet.categoryId = assetToLink.category?.id;
    }

    setAssetUrl(assetToLink.requestOptions[0].landscape.url);

    reset(valuesToSet, { keepDefaultValues: true });
  }, [assetToLink, reset]);

  const validateUploadedCoverImage = (coverImage) => {
    if (!coverImage.originalFileName) {
      setErrorMessage('Please upload an image');
      return;
    }

    if (coverImage.localOriginalUrl && coverImage.uploadedImages.length !== 6) {
      setErrorMessage('Please crop the image');
      return false;
    }

    return true;
  };

  const onSubmit = async (data) => {
    if (isSubmittingRef.current) {
      return;
    }

    isSubmittingRef.current = true;

    if (!validateUploadedCoverImage(data.coverImage)) {
      isSubmittingRef.current = false;
      return;
    }

    setIsSaving(true);

    const formValues = omitDeep(data, [
      'awsS3UploadKit',
      'croppedImageUrl',
      'expanded',
      'initialRequestOptions',
      'localOriginalUrl',
      'temp',
    ]);

    formValues.img = { ...editingCoverImage?.img };

    _.forEach(formValues.coverImage.uploadedImages, (e) => {
      if (e.mode === 'original') {
        formValues.img[e.mode] = { name: e.name, type: e.type };
      } else {
        formValues.img[e.key] = { name: e.name, type: e.type };
      }
    });

    formValues.publishTime = 0;

    if (formValues.isPublished && formValues.publishDate) {
      formValues.publishTime = formValues.publishDate.getTime();
    }

    try {
      if (isEditing) {
        await updateCoverImage(formValues);
      } else {
        await addCoverImage(formValues);
      }
      if (!coverImageErrorMessage) {
        setIsSaving(false);
        reset();
        isSubmittingRef.current = false;
        push('/dashboard/cover-images?prompt=no');
      }
    } catch (error) {
      setIsSaving(false);
      isSubmittingRef.current = false;
    }
  };

  const onCancel = () => {
    reset(initialValues);
    if (assetId) {
      push('/dashboard/assets?prompt=no');
    } else {
      push('/dashboard/cover-images?prompt=no');
    }
  };

  const isActivePublished =
    !!editingCoverImage &&
    editingCoverImage.publishTime > 0 &&
    editingCoverImage.publishTime <= Date.now();

  return (
    <>
      <CssBaseline />
      <Grid className={classes.container}>
        <Grid container className={classes.title}>
          <Typography component="h1" variant="h6">
            {isEditing ? 'Edit' : 'Create New'} Cover Image
          </Typography>
        </Grid>
        <Grid container direction="row" justifyContent="center">
          <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            <Grid container item xs={12} direction="column">
              <Grid
                container
                justifyContent="space-around"
                direction={{ xs: 'row', md: 'row-reverse' }}
                style={{ paddingBottom: 24 }}
              >
                <Grid container item xs={12} md={4} />
                <Grid
                  component={Card}
                  container
                  item
                  xs={12}
                  md={7}
                  gap={3}
                  style={{ padding: 24 }}
                >
                  <Grid item xs={12}>
                    Render count: {renderCount}
                  </Grid>
                  {isEditing && (
                    <Grid item xs={12}>
                      <ControllerTextField
                        control={control}
                        label="Cover Image  ID"
                        name="id"
                        showCopyButton
                        required={false}
                        disabled
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <ControllerTextField
                      select
                      control={control}
                      label="Type"
                      name="type"
                      placeholder="Choose a type"
                      disabled={isActivePublished || isEditing}
                    >
                      {renderTypeOptions(COVER_IMAGE_TYPE_OPTIONS)}
                    </ControllerTextField>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="space-around"
                direction={{ xs: 'row', md: 'row-reverse' }}
                style={{ paddingBottom: 24 }}
              >
                <CoverImageUploader
                  control={control}
                  isEditing={isEditing}
                  getValues={getValues}
                  setValue={setValue}
                  showOriginalImage
                  heightThreshold={cropSettings?.heightThreshold}
                  widthThreshold={cropSettings?.widthThreshold}
                  aspectRatio={cropSettings?.aspectRatio}
                  screenRatio={cropSettings?.screenRatio}
                  // openUploaderOnInitialRender={false}
                  // disabled={!isAdminOrEditor}
                />
                {!!assetUrl && (
                  <Grid
                    container
                    item
                    xs={12}
                    md={4}
                    direction="row"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    style={{ marginBottom: 24 }}
                  >
                    <Grid
                      container
                      item
                      xs={12}
                      md={8}
                      justifyContent="center"
                      style={{ marginTop: 24 }}
                    >
                      <Previewer imageUrl={assetUrl} tooltip="Article Image" />
                    </Grid>
                  </Grid>
                )}
                <Grid
                  component={Card}
                  container
                  item
                  xs={12}
                  md={7}
                  gap={3}
                  style={{ padding: 24 }}
                >
                  <Grid item xs={12}>
                    Linked Article
                  </Grid>
                  <Grid item xs={12}>
                    <ControllerTextField
                      control={control}
                      label="Article  ID"
                      name="assetId"
                      showCopyButton
                      required={false}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ControllerTextField
                      control={control}
                      label="Article Title"
                      name="assetTitle"
                      showCopyButton
                      required={false}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ControllerTextField
                      control={control}
                      label="Category"
                      name="assetCategory"
                      required={false}
                      disabled
                    />
                  </Grid>
                  {isEditing && (
                    <Grid container item xs={12} spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <ControllerTextField
                          control={control}
                          label="Created At"
                          name="createdAt"
                          disabled
                          required={false}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ControllerTextField
                          control={control}
                          label="Updated At"
                          name="updatedAt"
                          disabled
                          required={false}
                        />
                      </Grid>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <ControllerCheckbox
                      control={control}
                      label="Published"
                      name="isPublished"
                    />
                  </Grid>
                  <ControllerPublishDateTimePicker
                    control={control}
                    setValue={setValue}
                    name="publishDate"
                    maxDate={new Date('2100-01-01')}
                    disabled={isActivePublished}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              item
              justifyContent="space-around"
              direction={{ xs: 'row', md: 'row-reverse' }}
            >
              {!!assetUrl && <Grid item xs={12} md={4} />}
              <Grid container item xs={12} md={7}>
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="center"
                  columnGap={3}
                >
                  <Button
                    color="primary"
                    disabled={isSubmitting || !isDirty || isSaving}
                    type="submit"
                    variant="contained"
                  >
                    {isEditing ? 'Save' : 'Submit'}
                  </Button>
                  <Button type="button" variant="contained" onClick={onCancel}>
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
      {showRestoreDialog && (
        <YesNoDialog
          open={showRestoreDialog}
          title="Restore?"
          message="There are unsaved changes from last edit. Do you want to restore the changes?"
          primaryButtonText="Yes"
          onPrimaryButtonClick={() => {
            setShowRestoreDialog(false);
          }}
          secondaryButtonText="No"
          onSecondaryButtonClick={() => {
            setShowRestoreDialog(false);
            // this will allow the dialog to close first
            setTimeout(() => {
              clearPersist();
              reset(initialValues);
            }, 100);
          }}
        />
      )}
      <Prompt
        when={isDirty}
        message={(location, action) => {
          return action === 'PUSH' && location.search.includes('prompt=no')
            ? true
            : 'There are unsaved changes. Do you wish to discard them?';
        }}
      />
      <Prompt
        when={!isDirty}
        message={() => {
          stopPersistInterval();
          clearPersist();
          return true;
        }}
      />
      <Snackbar
        autoHideDuration={5000}
        onClose={() => {
          if (errorMessage) {
            setErrorMessage(null);
          }
          if (coverImageErrorMessage) {
            clearCoverImageError();
          }
        }}
        open={!!errorMessage || !!coverImageErrorMessage}
      >
        <Alert
          severity="error"
          action={
            <Button
              size="small"
              color="secondary"
              onClick={() => {
                if (errorMessage) {
                  setErrorMessage(null);
                }
                if (coverImageErrorMessage) {
                  clearCoverImageError();
                }
              }}
            >
              Dismiss
            </Button>
          }
        >
          {errorMessage || coverImageErrorMessage}
        </Alert>
      </Snackbar>
      <Backdrop className={classes.backdrop} open={isProcessing || isSaving}>
        <CircularProgress />
      </Backdrop>
    </>
  );
};

const mapStateToProps = (state) => {
  const editingCoverImage = getEditingCoverImage(state);
  const asset = getEditingAsset(state);
  const coverImageErrorMessage = getCoverImagesErrorMessage(state);

  let initialValues = null;
  if (_.isEmpty(editingCoverImage)) {
    initialValues = {
      id: '',
      type: 'top',
      publishDate: addSeconds(startOfDay(addDays(Date.now(), 1)), 25440), // Tomorrow @ 7:04 AM
      createdAt: '',
      updatedAt: '',
      assetId: '',
      assetTitle: '',
      assetCategory: '',
      isPublished: false,
      coverImage: {
        ...DEFAULT_COVER_IMAGE_VALUES,
      },
    };
  } else {
    initialValues = {
      id: editingCoverImage.id,
      type: editingCoverImage.type,
      publishDate:
        editingCoverImage.publishTime > 0
          ? new Date(editingCoverImage.publishTime)
          : new Date(),
      createdAt: formatDateTime(editingCoverImage.createdAt),
      updatedAt: formatDateTime(editingCoverImage.updatedAt),
      isPublished: editingCoverImage.publishTime > 0,
      assetId: editingCoverImage.asset?.id || '',
      assetTitle:
        editingCoverImage.asset?.category?.name === 'Cover Story'
          ? editingCoverImage.asset?.promotionalText
          : editingCoverImage.asset?.shortTitle || '',
      assetCategory: editingCoverImage.asset?.category?.name || '',
      coverImage: {
        ...DEFAULT_COVER_IMAGE_VALUES,
        ...editingCoverImage.coverImage,
        originalFileName: editingCoverImage.img.original.name,
        initialRequestOptions: editingCoverImage.requestOptions,
        awsS3UploadKit: editingCoverImage.awsS3UploadKit,
      },
    };
  }

  return {
    editingCoverImage,
    coverImageErrorMessage,
    assetToLink: asset,
    initialValues,
  };
};

export default compose(
  connect(mapStateToProps, {
    loadAsset,
    loadCoverImage,
    addCoverImage,
    updateCoverImage,
    clearEditingAsset,
    clearEditingCoverImage,
    clearCoverImageError,
    push,
  }),
  withStyles(styles)
)(CoverImageDetail);
