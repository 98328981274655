import React, { useEffect } from 'react';
import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { requestPasswordReset, unloadAuthPage } from '../../store/actions';
import RequestTokenForm from '../../components/RequestTokenForm';

const RequestPasswordReset = ({ requestPasswordReset, unloadAuthPage }) => {
  useEffect(() => {
    return () => {
      unloadAuthPage();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RequestTokenForm
      tokenPurpose="reset-password"
      title="Send Password Reset Email"
      onSubmit={requestPasswordReset}
    />
  );
};

export default compose(connect(null, { requestPasswordReset, unloadAuthPage }))(
  RequestPasswordReset
);
