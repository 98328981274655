import { createSelector } from '@reduxjs/toolkit';

export const selectCategoriesState = (state) => state.categories;

export const selectCategoriesAllIds = (state) => state.categories.allIds;

export const selectCategoriesEntities = (state) => state.categories.entities;

export const selectCategoriesErrorMessage = (state) => state.categories.error;

export const selectCategoriesMessage = (state) => state.categories.message;

export const selectCategoriesCount = (state) => state.categories.totalCount;

export const selectCategoriesLoading = (state) => state.categories.loading;

export const selectCategories = createSelector(
  selectCategoriesEntities,
  selectCategoriesAllIds,
  (entities, allIds) => allIds.map((id) => entities[id])
);

export const selectCategoryLookup = createSelector(
  selectCategoriesEntities,
  (entities) => {
    const result = {};
    Object.keys(entities).forEach((id) => {
      result[id] = entities[id].name;
    });
    return result;
  }
);
