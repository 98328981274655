import React from 'react';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import { Field, reduxForm, reset } from 'redux-form';
import {
  cleanUpStorage,
  clearAdminMessages,
  countOrphanFiles,
} from '../../store/actions';
import { required } from '../../utils';

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    paddingBottom: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(3, 4, 3, 0),
  },
  warning: {
    color: theme.palette.secondary.main,
    textAlign: 'center',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  backdrop: {
    zIndex: 999,
    backgroundColor: 'rgba(255, 255, 255, 0.6)',
  },
});

class CleanUpStorage extends React.Component {
  componentDidMount() {
    this.props.countOrphanFiles();
  }

  onSubmit = (formValues) => {
    this.props.cleanUpStorage(formValues).then(() => {
      this.props.dispatch(reset('cleanUpStorage'));
    });
  };

  renderTextField = ({ input, label, meta: { touched, error }, ...custom }) => (
    <TextField
      label={label}
      error={touched && !!error}
      helperText={touched && error}
      variant="outlined"
      margin="none"
      required
      fullWidth
      {...input}
      {...custom}
    />
  );

  render() {
    const {
      handleSubmit,
      classes,
      pristine,
      processing,
      orphanFiles,
      valid,
      clearAdminMessages,
      errorMessage,
      message,
    } = this.props;

    const orphanMessage =
      orphanFiles > 0
        ? orphanFiles === 1
          ? 'There is 1 orphan file.'
          : `There are ${orphanFiles} orphan files.`
        : 'There is no orphan file.';

    return (
      <>
        <CssBaseline />
        <Paper elevation={1} className={classes.paper}>
          <Typography component="h1" variant="h6" className={classes.title}>
            Clean Up Storage
          </Typography>
          <Grid container spacing={4} direction="row" justifyContent="center">
            <Grid item xs={12} sm={7}>
              <form
                className={classes.form}
                onSubmit={handleSubmit(this.onSubmit)}
              >
                <Grid item xs={12}>
                  <Typography className={classes.warning}>
                    {orphanMessage}
                  </Typography>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item sm={9} xs={12}>
                    <Field
                      id="password"
                      label="Password"
                      name="password"
                      type="password"
                      placeholder="Please enter your current password"
                      component={this.renderTextField}
                      disabled={orphanFiles <= 0}
                    />
                  </Grid>
                  <Grid item sm={3} xs={12} align="end">
                    <Button
                      color="secondary"
                      variant="contained"
                      disabled={
                        pristine || processing || !valid || orphanFiles <= 0
                      }
                      type="submit"
                    >
                      Clean Up Storage
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Paper>
        <Snackbar
          autoHideDuration={5000}
          open={!!message}
          onClose={() => clearAdminMessages()}
        >
          <Alert severity="success">{message}</Alert>
        </Snackbar>
        <Snackbar
          autoHideDuration={5000}
          open={!!errorMessage}
          onClose={() => clearAdminMessages()}
        >
          <Alert severity="error">{errorMessage}</Alert>
        </Snackbar>
        <Backdrop className={classes.backdrop} open={processing}>
          <CircularProgress />
        </Backdrop>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.admin.errorMessage,
    message: state.admin.message,
    processing: state.admin.processing,
    orphanFiles: state.admin.orphanFiles,
  };
};

const validate = (values) => {
  const errors = {
    password: required(values.password),
  };
  return errors;
};

export default compose(
  connect(mapStateToProps, {
    cleanUpStorage,
    clearAdminMessages,
    countOrphanFiles,
  }),
  reduxForm({ form: 'cleanUpStorage', validate }),
  withStyles(styles)
)(CleanUpStorage);
