import React from 'react';
import MTable from '@material-table/core';
import { forwardRef } from 'react';

import {
  Check,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  Close,
  Delete,
  ExportVariant,
  FilterVariant,
  Magnify,
  Minus,
  PageFirst,
  PageLast,
  Pencil,
  PlusBox,
  ViewColumn,
} from 'mdi-material-ui';

const buttonStyle = { margin: 16 };

const tableIcons = {
  Add: forwardRef((props, ref) => (
    <PlusBox {...props} color="secondary" ref={ref} />
  )),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Close {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <Delete {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Pencil {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <ExportVariant {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterVariant {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => (
    <PageFirst {...props} ref={ref} style={buttonStyle} />
  )),
  LastPage: forwardRef((props, ref) => (
    <PageLast {...props} ref={ref} style={buttonStyle} />
  )),
  NextPage: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} style={buttonStyle} />
  )),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} style={buttonStyle} />
  )),
  ResetSearch: forwardRef((props, ref) => <Close {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Magnify {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ChevronDown {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Minus {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const MaterialTable = ({ localization, options, ...rest }) => (
  <MTable
    icons={tableIcons}
    options={{
      debounceInterval: 500,
      actionsColumnIndex: -1,
      pageSizeOptions: [5, 10, 20, 50, 100],
      thirdSortClick: false,
      searchFieldStyle: { minWidth: 300 },
      maxColumnSort: 1,
      ...options,
    }}
    localization={{
      body: {
        editRow: { saveTooltip: 'Done', cancelTooltip: 'Cancel' },
      },
      ...localization,
    }}
    {...rest}
  />
);

export default MaterialTable;
