import React from 'react';
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { withStyles } from '@mui/styles';
import {
  addMonths,
  endOfDay,
  endOfMonth,
  startOfDay,
  startOfMonth,
} from 'date-fns';
import {
  change,
  Field,
  reduxForm,
  SubmissionError,
  formValueSelector,
  isDirty,
} from 'redux-form';
import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';
import { PatternFormat } from 'react-number-format';
import { Prompt } from 'react-router-dom';
import {
  getInvoicesErrorMessage,
  getClients,
  getDerivedInvoice,
} from '../../store/selectors';
import {
  addInvoice,
  loadInvoice,
  loadClients,
  generatePreviewInvoice,
  clearEditingInvoice,
  unloadClients,
} from '../../store/actions';
import {
  dateToInvoiceNo,
  formatCurrency,
  formatDate,
  formatDateTime,
  formatPercent,
  required,
  MAX_PAGE_SIZE,
} from '../../utils';

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  invoicePaper: {
    padding: theme.spacing(2),
  },
  invoice: {
    width: '100%',
  },
  button: {
    margin: theme.spacing(3, 4, 3, 0),
  },
  backdrop: {
    zIndex: 999,
    backgroundColor: 'rgba(255, 255, 255, 0.6)',
  },
  countdown: {
    display: 'flex',
    alignItems: 'center',
  },
  preview: {
    marginTop: theme.spacing(2),
  },
  ad: {
    width: 120,
    height: 40,
  },
});

class InvoiceDetail extends React.Component {
  state = {
    noteWords: 0,
    errorMessage: null,
  };

  componentDidMount() {
    this.invoiceId = this.props.match.params.invoiceId;
    this.props.loadClients({ pageSize: MAX_PAGE_SIZE });
    if (this.invoiceId !== 'create') {
      this.isViewOnly = true;
      this.props.loadInvoice(this.invoiceId);
    }
  }

  componentWillUnmount() {
    this.props.clearEditingInvoice();
    this.props.unloadClients();
  }

  createPayload = (payload) => {
    return {
      ...payload,
      from: startOfDay(payload.from).getTime(),
      to: endOfDay(payload.to).getTime(),
    };
  };

  onSubmit = ({ ...formValues }) => {
    formValues = this.createPayload(formValues);
    return Promise.resolve()
      .then(() => {
        return this.props.addInvoice(formValues);
      })
      .then(() => {
        if (this.props.errorMessage) {
          throw new SubmissionError({ _error: this.props.errorMessage });
        } else {
          this.props.push('/dashboard/invoices?prompt=no');
        }
      });
  };

  onPreview = () => {
    const {
      formValues: { ...payload },
    } = this.props;
    if (!payload.clientId || !payload.from || !payload.to) {
      return;
    }
    this.props.generatePreviewInvoice(this.createPayload(payload)).then(() => {
      if (this.props.errorMessage) {
        this.setState({ errorMessage: this.props.errorMessage });
      }
    });
  };

  onCancel = () => {
    this.props.push('/dashboard/invoices?prompt=no');
  };

  countWords = (str) => {
    return str.split(/ +|\n+/).length;
  };

  onNoteChange = (e) => {
    const note = e.target.value.trim();
    this.setState({
      noteWords: note === '' ? 0 : this.countWords(note),
    });
  };

  setFromToDate = (date) => {
    const from = startOfMonth(date);
    const to = endOfMonth(date);
    this.props.dispatch(change('createInvoice', 'from', from));
    this.props.dispatch(change('createInvoice', 'to', to));
  };

  onLastMonthPress = () => {
    const thisDayLastMonth = addMonths(new Date(), -1);
    this.setFromToDate(thisDayLastMonth);
  };

  onThisMonthPress = () => {
    const today = new Date();
    this.setFromToDate(today);
  };

  onNextMonthPress = () => {
    const thisDayNextMonth = addMonths(new Date(), 1);
    this.setFromToDate(thisDayNextMonth);
  };

  renderTextField = ({ input, label, meta: { touched, error }, ...custom }) => (
    <TextField
      label={label}
      error={touched && !!error}
      helperText={touched && error}
      variant="outlined"
      margin="none"
      required
      fullWidth
      {...input}
      {...custom}
    />
  );

  renderDatePicker = ({ input, label, maxDate, maxDateMessage, disabled }) => {
    return (
      <MobileDatePicker
        slotProps={{ textField: { variant: 'standard', fullWidth: true } }}
        value={input.value}
        onChange={(value) => input.onChange(value)}
        format="EEE, MMM dd, yyyy"
        showTodayButton
        label={label}
        maxDate={maxDate}
        maxDateMessage={maxDateMessage}
        disabled={disabled}
      />
    );
  };

  renderClientOptions = (clients) => {
    return clients.map((client) => {
      return (
        <MenuItem key={client.id} value={client.id}>
          {client.companyName}
        </MenuItem>
      );
    });
  };

  renderRepresentorOptions = (representors) => {
    return representors.map((representor, index) => {
      return (
        <MenuItem key={index} value={index}>
          {`${representor.firstName} ${representor.lastName}`}
        </MenuItem>
      );
    });
  };

  renderDiscountField = ({
    input,
    label,
    meta: { touched, error },
    ...custom
  }) => {
    return (
      <PatternFormat
        allowNegative={false}
        customInput={TextField}
        label={label}
        error={touched && !!error}
        helperText={touched && error}
        variant="outlined"
        margin="none"
        fullWidth
        format="###"
        mask=" "
        {...input}
        {...custom}
      />
    );
  };

  renderTableRows = (ad) => {
    return ad.publishHistory.map((row, index) => {
      return (
        <TableRow
          key={`${ad.id}_${index}`}
          onClick={() => this.props.push(`/dashboard/ads/${ad.id}`)}
          hover
        >
          {index === 0 && (
            <TableCell rowSpan={ad.publishHistory.length}>
              <Avatar
                src={ad.imageUrl}
                variant="rounded"
                className={this.props.classes.ad}
              />
            </TableCell>
          )}
          <TableCell>{formatDateTime(row.from)}</TableCell>
          <TableCell>{formatDateTime(row.to)}</TableCell>
          <TableCell>{ad.category.name}</TableCell>
          <TableCell>{row.position}</TableCell>
          <TableCell align="right">{formatCurrency(row.priceRate)}</TableCell>
          <TableCell align="right">{`${
            row.duration
          }(≈${row.durationInHours.toFixed(2)} hours)`}</TableCell>
          <TableCell align="right">{formatCurrency(row.price)}</TableCell>
        </TableRow>
      );
    });
  };

  renderTable = (invoice) => {
    return (
      <TableContainer
        component={Paper}
        style={{ marginTop: 16, marginBottom: 16 }}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Ad</TableCell>
              <TableCell>Start</TableCell>
              <TableCell>End</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Position</TableCell>
              <TableCell align="right">Price Rate ($/hr)</TableCell>
              <TableCell align="right">Duration</TableCell>
              <TableCell align="right">Price ($)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoice.ads.map((ad) => {
              return this.renderTableRows(ad);
            })}
            <TableRow>
              <TableCell rowSpan={3} colSpan={5} />
              <TableCell>Subtotal</TableCell>
              <TableCell colSpan={2} align="right">
                {formatCurrency(invoice.subtotalPrice)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Discount</TableCell>
              <TableCell colSpan={2} align="right">
                {formatPercent(invoice.discount)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Total</b>
              </TableCell>
              <TableCell colSpan={2} align="right">
                <b>{formatCurrency(invoice.totalPrice)}</b>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  render() {
    const {
      derivedInvoice,
      handleSubmit,
      classes,
      clients,
      selectedClient,
      pristine,
      processing,
      submitting,
      valid,
      error,
      isDirty,
    } = this.props;

    const { noteWords } = this.state;

    let representors = [];
    if (selectedClient) {
      representors = selectedClient.representors;
    }

    let clientAddress = '';
    if (
      derivedInvoice &&
      derivedInvoice.client &&
      derivedInvoice.client.address
    ) {
      const { address } = derivedInvoice.client;
      clientAddress = address.country;
      if (address.postalCode) {
        clientAddress = `${address.postalCode}, ` + clientAddress;
      }
      if (address.province) {
        clientAddress = `${address.province}, ` + clientAddress;
      }
      if (address.city) {
        clientAddress = `${address.city}, ` + clientAddress;
      }
      if (address.street) {
        clientAddress = `${address.street}, ` + clientAddress;
      }
    }

    return (
      <>
        <CssBaseline />
        {!this.isViewOnly && (
          <Paper elevation={1} className={classes.paper}>
            <Typography variant="h6" className={classes.title}>
              {this.isViewOnly ? 'Invoice Details' : 'Create New Invoice'}
            </Typography>
            <Grid container spacing={4} direction="row" justifyContent="center">
              <Grid item xs={12} sm={7}>
                <form
                  className={classes.form}
                  onSubmit={handleSubmit(this.onSubmit)}
                >
                  <Grid container spacing={3}>
                    {clients.length > 0 && (
                      <Grid item xs={12}>
                        <Field
                          id="clientId"
                          label="Client"
                          name="clientId"
                          placeholder="Choose a client"
                          component={this.renderTextField}
                          select
                          disabled={this.isViewOnly}
                        >
                          {this.renderClientOptions(clients)}
                        </Field>
                      </Grid>
                    )}
                    {representors.length > 0 && (
                      <Grid item xs={12}>
                        <Field
                          id="representorIndex"
                          label="Representor"
                          name="representorIndex"
                          placeholder="Choose a representor"
                          component={this.renderTextField}
                          select
                          disabled={this.isViewOnly}
                        >
                          {this.renderRepresentorOptions(representors)}
                        </Field>
                      </Grid>
                    )}
                    <Grid item xs={12} sm={6}>
                      <Field
                        id="from"
                        name="from"
                        label="Date From"
                        component={this.renderDatePicker}
                        disabled={this.isViewOnly}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        id="to"
                        name="to"
                        label="Date To"
                        component={this.renderDatePicker}
                        disabled={this.isViewOnly}
                      />
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      alignItems="center"
                      justifyContent="space-evenly"
                    >
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={this.onLastMonthPress}
                      >
                        Last Month
                      </Button>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={this.onThisMonthPress}
                      >
                        This Month
                      </Button>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={this.onNextMonthPress}
                      >
                        Next Month
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        id="discount"
                        name="discount"
                        label="Discount (%)"
                        placeholder="Enter discount"
                        component={this.renderDiscountField}
                        disabled={this.isViewOnly}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        id="note"
                        label="Note"
                        name="note"
                        placeholder="Enter Note"
                        multiline
                        helperText={noteWords > 0 && `${noteWords} word(s)`}
                        required={false}
                        onChange={this.onNoteChange}
                        component={this.renderTextField}
                        disabled={this.isViewOnly}
                      />
                    </Grid>
                  </Grid>
                  <Button
                    className={classes.button}
                    type="button"
                    variant="contained"
                    disabled={pristine || processing || !valid}
                    onClick={this.onPreview}
                  >
                    Preview
                  </Button>
                  <Button
                    className={classes.button}
                    color="primary"
                    disabled={pristine || submitting || !valid}
                    type="submit"
                    variant="contained"
                  >
                    Save
                  </Button>
                  <Button
                    className={classes.button}
                    type="button"
                    variant="contained"
                    onClick={this.onCancel}
                  >
                    Cancel
                  </Button>
                </form>
              </Grid>
              <Grid item xs={12} sm={4}></Grid>
            </Grid>
          </Paper>
        )}
        {!!derivedInvoice.client && (
          <Paper
            elevation={1}
            className={`${classes.paper} ${classes.preview}`}
          >
            <Typography variant="h6" className={classes.title}>
              {this.isViewOnly
                ? `Invoice #${dateToInvoiceNo(derivedInvoice.createdAt)}`
                : 'Preview'}
            </Typography>
            <Grid container spacing={4} direction="row" justifyContent="center">
              <Grid item xs={12} sm={11}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography align="right">
                      Period: {formatDate(derivedInvoice.from)} -{' '}
                      {formatDate(derivedInvoice.to)}
                    </Typography>
                    {!!derivedInvoice.createdAt && (
                      <Typography align="right">
                        Created Date: {formatDate(derivedInvoice.createdAt)}
                      </Typography>
                    )}
                    <Typography variant="h6">Client Info</Typography>
                    <Typography>
                      Company: {derivedInvoice.client.companyName}
                    </Typography>
                    {!!derivedInvoice.client.representor && (
                      <Typography>
                        Representor:{' '}
                        {`${derivedInvoice.client.representor.firstName} ${derivedInvoice.client.representor.lastName}`}
                      </Typography>
                    )}
                    <Typography>
                      Email: {derivedInvoice.client.email}
                    </Typography>
                    {!!derivedInvoice.client.phone && (
                      <Typography>
                        Phone: {derivedInvoice.client.phone}
                      </Typography>
                    )}
                    <Typography gutterBottom>
                      Address: {clientAddress}
                    </Typography>
                    {!!derivedInvoice.note && (
                      <>
                        <Divider />
                        <Typography variant="h6">Others</Typography>
                        <Typography>Note: {derivedInvoice.note}</Typography>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                    <Typography variant="h6">Detail</Typography>
                    {this.renderTable(derivedInvoice)}
                  </Grid>
                </Grid>
                {this.isViewOnly && (
                  <Button
                    className={classes.button}
                    type="button"
                    variant="contained"
                    onClick={this.onCancel}
                  >
                    Go Back
                  </Button>
                )}
              </Grid>
            </Grid>
          </Paper>
        )}
        <Prompt
          when={isDirty}
          message={(location, action) => {
            return action === 'PUSH' && location.search.includes('prompt=no')
              ? true
              : 'There are unsaved changes. Do you wish to discard them?';
          }}
        />
        <Snackbar
          autoHideDuration={3000}
          onClose={() => this.setState({ errorMessage: null })}
          open={!!error || !!this.state.errorMessage}
        >
          <Alert severity="error">{error || this.state.errorMessage}</Alert>
        </Snackbar>
        <Backdrop className={classes.backdrop} open={submitting}>
          <CircularProgress />
        </Backdrop>
      </>
    );
  }
}

const validate = (values) => {
  const errors = {};
  errors.clientId = required(values.clientId);
  errors.discount =
    values.discount < 0 || values.discount > 100
      ? 'Discount must be between 0 and 100'
      : undefined;
  return errors;
};

const selector = formValueSelector('createInvoice');

const mapStateToProps = (state) => {
  const formValues = selector(
    state,
    'from',
    'to',
    'clientId',
    'representorIndex',
    'note',
    'discount'
  );
  return {
    formValues,
    errorMessage: getInvoicesErrorMessage(state),
    clients: getClients(state),
    selectedClient: state.clients.entities[formValues.clientId],
    derivedInvoice: getDerivedInvoice(state),
    initialValues: {
      representorIndex: 0,
      from: startOfMonth(Date.now()),
      to: endOfMonth(Date.now()),
    },
    processing: state.invoices.loading,
    isDirty: isDirty('createInvoice')(state),
  };
};

export default compose(
  connect(mapStateToProps, {
    addInvoice,
    loadInvoice,
    loadClients,
    push,
    generatePreviewInvoice,
    clearEditingInvoice,
    unloadClients,
  }),
  reduxForm({ form: 'createInvoice', validate, enableReinitialize: true }),
  withStyles(styles)
)(InvoiceDetail);
