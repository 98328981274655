import PropTypes from 'prop-types';
import { Document } from '@react-pdf/renderer';
import PdfCoverPage from './PdfCoverPage';
import PdfAssetDetail from './PdfAssetDetail';

const PdfDocument = ({
  title,
  subject,
  report,
  author,
  isPreview,
  onRender,
  mobileAppConfig,
  printable,
}) => {
  const reportTime = { from: report.fromTime, to: report.toTime };
  return (
    <Document
      title={title}
      subject={subject}
      pdfVersion="1.7"
      author={author.companyName}
      onRender={onRender}
    >
      <PdfCoverPage report={report} author={author} isPreview={isPreview} />
      {report.assets.map((asset, index) => {
        // return asset.isLegacy ? (
        //   <PdfLegacyAssetDetail
        //     asset={asset}
        //     key={asset.id}
        //     isPreview={isPreview}
        //     reportTime={reportTime}
        //     mobileAppConfig={mobileAppConfig}
        //     index={index}
        //     printable={printable}
        //   />
        // ) : (
        //   <PdfAssetDetail
        //     asset={asset}
        //     key={asset.id}
        //     isPreview={isPreview}
        //     reportTime={reportTime}
        //     mobileAppConfig={mobileAppConfig}
        //     index={index}
        //     printable={printable}
        //   />
        // );

        return (
          <PdfAssetDetail
            asset={asset}
            key={asset.id}
            isPreview={isPreview}
            reportTime={reportTime}
            mobileAppConfig={mobileAppConfig}
            index={index}
            printable={printable}
          />
        );
      })}
    </Document>
  );
};

PdfDocument.propTypes = {
  author: PropTypes.any.isRequired,
  isPreview: PropTypes.bool,
  onRender: PropTypes.func,
  report: PropTypes.shape({
    assets: PropTypes.array.isRequired,
    client: PropTypes.any.isRequired,
    createdAt: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    fromTime: PropTypes.number,
    id: PropTypes.string,
    toTime: PropTypes.number,
  }),
  title: PropTypes.string.isRequired,
  subject: PropTypes.string,
  mobileAppConfig: PropTypes.any.isRequired,
  printable: PropTypes.bool,
};

export default PdfDocument;
