export const generateActionBarUrl = ({ mobileAppConfig, asset } = {}) => {
  let actionBarUrl = '/screenshots/action-bar_favourite_share.png';
  if (asset && asset.requestOptions && mobileAppConfig) {
    actionBarUrl = `/screenshots/action-bar${
      mobileAppConfig.likeButton ? '_like' : ''
    }${
      mobileAppConfig.fullScreenButton && asset.requestOptions.length > 1
        ? '_multiple-full-screens'
        : mobileAppConfig.fullScreenButton && asset.requestOptions.length <= 1
        ? '_single-full-screen'
        : ''
    }_favourite_share.png`;
  }
  return actionBarUrl;
};
