import currency from 'currency.js';

export const centsToDollars = (cents) => {
  return currency(cents, { fromCents: true }).value;
};

export const dollarsToCents = (dollars) => {
  return currency(dollars).intValue;
};

export const formatCurrency = (cents) => {
  return currency(cents, { fromCents: true }).format();
};
