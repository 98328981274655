import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { calcTimeDelta } from 'react-countdown';
import Chart from 'react-apexcharts';
import _ from 'lodash';

import { formatDateTime, formatCountdown } from '../utils';

const chartOptions = {
  title: {
    text: 'Publishing timeline',
    style: {
      fontFamily: 'Roboto',
      fontSize: 18,
    },
  },
  colors: ['#000096'],
  plotOptions: {
    bar: {
      horizontal: true,
    },
  },
  xaxis: {
    type: 'datetime',
    labels: {
      format: 'MMM/dd/yyyy',
    },
  },
  tooltip: {
    custom: function (data) {
      return `<div style="padding: 16px">
      <b>Start</b>: ${formatDateTime(data.y1)}<br />
      <b>End</b>: ${formatDateTime(data.y2)}<br />
      <b>Duration</b>: ${formatCountdown(
        calcTimeDelta(data.y2, { now: () => data.y1 })
      )}
      </div>`;
    },
  },
};

const HistoryChart = ({ publishHistory, showPosition, topStoryHistory }) => {
  const [showDetail, setShowDetail] = useState(false);

  const toggleShowDetail = () => {
    setShowDetail((currentState) => !currentState);
  };

  const renderTableRows = (data, type) => {
    return data.map((row) => (
      <TableRow key={row._id}>
        <TableCell>{type}</TableCell>
        <TableCell>{formatDateTime(row.from)}</TableCell>
        <TableCell>{formatDateTime(row.to)}</TableCell>
        {showPosition && <TableCell>{row.position}</TableCell>}
        <TableCell align="right">
          {formatCountdown(calcTimeDelta(row.to, { now: () => row.from }))}
        </TableCell>
      </TableRow>
    ));
  };

  const renderTable = () => {
    return (
      <TableContainer
        component={Paper}
        style={{ marginTop: 16, marginBottom: 16 }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Start</TableCell>
              <TableCell>End</TableCell>
              {showPosition && <TableCell>Position</TableCell>}
              <TableCell align="right">Duration</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {renderTableRows(topStoryHistory, 'Top Story')}
            {renderTableRows(publishHistory, 'Publish')}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const chartSeries = useMemo(() => {
    let series = null;
    let data = [];
    _.forEach(topStoryHistory, (e) => {
      data.push({ x: 'Top Story', y: [e.from, e.to] });
    });
    _.forEach(publishHistory, (e) => {
      data.push({ x: 'Publish', y: [e.from, e.to] });
    });

    if (!_.isEmpty(data)) {
      series = [{ data }];
    }

    return series;
  }, [publishHistory, topStoryHistory]);

  if (!chartSeries) {
    return null;
  }

  return (
    <>
      <Chart
        options={chartOptions}
        series={chartSeries}
        type="rangeBar"
        height={200}
      />
      <FormControlLabel
        control={
          <Switch
            checked={showDetail}
            onChange={toggleShowDetail}
            name="detail"
          />
        }
        label="Show Details"
      />
      {showDetail && renderTable()}
    </>
  );
};

HistoryChart.propTypes = {
  topStoryHistory: PropTypes.array,
  publishHistory: PropTypes.array,
  showPosition: PropTypes.bool,
};

HistoryChart.defaultProps = {
  topStoryHistory: [],
  publishHistory: [],
  showPosition: false,
};

export default HistoryChart;
