import React from 'react';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import { withStyles } from '@mui/styles';
import { DashboardModal } from '@uppy/react';
import { addDays, endOfDay, endOfMinute } from 'date-fns';
import Countdown from 'react-countdown';
import Uppy from '@uppy/core';
import AwsS3 from '@uppy/aws-s3';
import {
  Field,
  reduxForm,
  SubmissionError,
  change,
  formValueSelector,
  isDirty,
} from 'redux-form';
import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';
import { Prompt } from 'react-router-dom';
import {
  getAdsErrorMessage,
  getEditingAd,
  selectCategories,
  getClients,
  getAdsAvailablePositions,
  selectAwsS3UploadKitAd,
} from '../../store/selectors';
import {
  loadAd,
  loadCategories,
  loadClients,
  getAdAwsS3UploadKit,
  getAdOccupiedPositions,
  addAd,
  updateAd,
  removeS3Objects,
  clearEditingAd,
  unloadClients,
  unloadCategories,
} from '../../store/actions';
import { formatCountdown, formatDateTime, required } from '../../utils';
import CropperDialog from '../../components/CropperDialog';
import HistoryChart from '../../components/HistoryChart';
import Previewer from '../../components/Previewer';

require('@uppy/core/dist/style.css');
require('@uppy/dashboard/dist/style.css');

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  button: {
    margin: theme.spacing(3, 4, 3, 0),
  },
  backdrop: {
    zIndex: 999,
    backgroundColor: 'rgba(255, 255, 255, 0.6)',
  },
  countdown: {
    display: 'flex',
    alignItems: 'center',
  },
});

class AdDetail extends React.Component {
  constructor(props) {
    super(props);
    this.uppy = new Uppy({
      restrictions: {
        maxNumberOfFiles: 1,
        minNumberOfFiles: 1,
        allowedFileTypes: ['image/png', 'image/jpeg', 'image/jpg'],
      },
    })
      .use(AwsS3, {
        getUploadParameters(file) {
          return props
            .getAdAwsS3UploadKit({
              fileName: `${file.name}.${file.extension}`,
            })
            .then((res) => {
              return {
                method: 'PUT',
                url: res.original.putOptions.url,
              };
            });
        },
      })
      .on('upload', (data) => {
        this.setState({ newUpload: true });
      })
      .on('complete', (result) => {
        if (result.successful.length === 1) {
          const reader = new FileReader();
          reader.onloadend = () =>
            this.setState({ originalLocalUrl: reader.result });
          reader.readAsDataURL(result.successful[0].data);
          props.dispatch(
            change(
              'createAd',
              'originalFileName',
              this.props.awsS3UploadKit.original.fileName
            )
          );
          this.setState({
            uploadedAds: [
              {
                key: 'original',
                name: this.props.awsS3UploadKit.original.fileName,
                deleteOptions: this.props.awsS3UploadKit.original.deleteOptions,
                type: result.successful[0].type,
              },
            ],
          });
        } else if (result.failed.length > 0) {
          this.setState({ newUpload: false });
        }
      })
      .on('file-removed', (file, reason) => {
        if (reason === 'removed-by-user') {
          props.dispatch(change('createAd', 'originalFileName', null));
          this.props
            .removeS3Objects({
              objectsToRemove: [...this.state.uploadedAds],
              type: 'ad',
            })
            .then(() => {
              this.setState({
                uploadedAds: [],
                originalLocalUrl: null,
              });
            });
        }
      });

    this.state = {
      uploaderOpen: false,
      descriptionWords: 0,
      originalLocalUrl: null,
      newUpload: false,
      uploadedAds: [],
      errorMessage: null,
      preview: null,
      isUploadedAdValid: false,
      isImageUpdated: false,
    };
  }

  componentDidMount() {
    this.adId = this.props.match.params.adId;
    this.props.loadCategories({ pageSize: 100 });
    this.props.loadClients({ pageSize: 300 });
    if (this.adId !== 'create') {
      this.isEditing = true;
      this.props.getAdOccupiedPositions({ adId: this.adId });
      this.props.getAdAwsS3UploadKit({ adId: this.adId });
      this.props.loadAd(this.adId);
    }
  }

  componentWillUnmount() {
    this.uppy.close();
    this.props.clearEditingAd();
    this.props.unloadClients();
    this.props.unloadCategories();
  }

  validateUploadedAds = (uploadedAds, newUpload) => {
    if (newUpload && uploadedAds.length < 6) {
      return false;
    }
    return true;
  };

  onSubmit = ({ originalFileName, ...formValues }) => {
    const { uploadedAds, newUpload, preview } = this.state;
    if (!this.validateUploadedAds(uploadedAds, newUpload)) {
      return this.setState({
        errorMessage: 'Please crop the ad',
      });
    }
    if (uploadedAds.length > 0) {
      let img = {};
      uploadedAds.forEach((e) => {
        img[e.key] = { name: e.name, type: e.type };
      });
      formValues.img = img;
    }
    if (preview) {
      formValues.preview = preview;
    }
    if (formValues.isPublished) {
      if (formValues.cutOffDate) {
        formValues.cutOffTime = endOfMinute(formValues.cutOffDate).getTime();
      }
    } else {
      formValues.cutOffTime = 0;
    }
    return Promise.resolve()
      .then(() => {
        if (this.isEditing) {
          return this.props.updateAd(formValues);
        }
        return this.props.addAd(formValues);
      })
      .then(() => {
        if (this.props.errorMessage) {
          throw new SubmissionError({ _error: this.props.errorMessage });
        } else {
          this.props.push('/dashboard/ads?prompt=no');
        }
      });
  };

  onCategoryChange = (event) => {
    // this.props.getAdOccupiedPositions({ categoryId: event.target.value });
  };

  onCancel = () => {
    if (this.state.uploadedAds.length > 0) {
      this.props.removeS3Objects({
        objectsToRemove: [...this.state.uploadedAds],
        type: 'ad',
      });
    }
    this.props.push('/dashboard/ads?prompt=no');
  };

  onUploaderOpen = (e) => {
    e.preventDefault();
    this.setState({
      uploaderOpen: true,
    });
  };

  onUploaderClose = () => {
    this.setState({
      uploaderOpen: false,
      isUploadedAdValid: this.validateUploadedAds(
        this.state.uploadedAds,
        this.state.newUpload
      ),
    });
  };

  countWords = (str) => {
    return str.split(/ +|\n+/).length;
  };

  onDescriptionChange = (e) => {
    const description = e.target.value.trim();
    this.setState({
      descriptionWords: description === '' ? 0 : this.countWords(description),
    });
  };

  addUploadedAd = ({ requestOptions, type }) => {
    let uploadedAds = [...this.state.uploadedAds];
    Object.keys(requestOptions).forEach((key) => {
      uploadedAds = uploadedAds.filter((e) => e.key !== key);
      uploadedAds.push({
        key,
        name: requestOptions[key].fileName,
        deleteOptions: requestOptions[key].deleteOptions,
        type,
      });
    });
    let isUploadedAdValid = this.validateUploadedAds(
      uploadedAds,
      this.state.newUpload
    );
    this.setState({
      uploadedAds: [...uploadedAds],
      isImageUpdated: true,
      isUploadedAdValid,
    });
  };

  renderTextField = ({ input, label, meta: { touched, error }, ...custom }) => (
    <TextField
      label={label}
      error={touched && !!error}
      helperText={touched && error}
      variant="outlined"
      margin="none"
      required
      fullWidth
      {...input}
      {...custom}
    />
  );

  renderCheckbox = ({ input, label, disabled }) => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={input.value ? true : false}
            onChange={input.onChange}
            disabled={disabled}
          />
        }
        label={label}
      />
    );
  };

  renderDateTimePicker = ({ input, label, maxDate, maxDateMessage }) => {
    return (
      <MobileDateTimePicker
        slotProps={{ textField: { variant: 'standard', fullWidth: true } }}
        ampm={false}
        value={input.value}
        onChange={(value) => input.onChange(value)}
        inputFormat="EEE, MMM dd, yyyy HH:mm"
        showTodayButton
        label={label}
        maxDate={maxDate}
        maxDateMessage={maxDateMessage}
      />
    );
  };

  renderCountdown = (countdownObj) => {
    return <span>{formatCountdown(countdownObj)}</span>;
  };

  renderCategoryOptions = (categories) => {
    return categories.map((category) => {
      return (
        <MenuItem key={category.id} value={category.id}>
          {category.name}
        </MenuItem>
      );
    });
  };

  renderClientOptions = (clients) => {
    return clients.map((client) => {
      return (
        <MenuItem key={client.id} value={client.id}>
          {client.companyName}
        </MenuItem>
      );
    });
  };

  renderPositionOptions = (availablePositions) => {
    return availablePositions.map((position) => {
      return (
        <MenuItem key={position} value={position}>
          {position}
        </MenuItem>
      );
    });
  };

  renderCheckboxAndDateTimePicker = ({
    checkboxFieldName,
    checkboxLabel,
    showDatePicker,
    dateFieldName,
    dateLabel,
    countdownDate,
    onDateChange,
    onCheckboxChange,
    maxDate,
    maxDateMessage,
    disabled,
  }) => {
    const { classes } = this.props;
    const handleDateChange = (value) => {
      if (typeof onDateChange === 'function') {
        onDateChange(value);
      }
      setTimeout(() => {
        if (this[dateFieldName].isStopped()) {
          this[dateFieldName].start();
        }
      }, 1000);
    };
    return (
      <>
        <Grid
          item
          xs={12}
          md={showDatePicker ? 3 : 12}
          xl={showDatePicker ? 2 : 12}
        >
          <Field
            id={checkboxFieldName}
            name={checkboxFieldName}
            label={checkboxLabel}
            component={this.renderCheckbox}
            onChange={(value) => {
              if (typeof onCheckboxChange === 'function') {
                onCheckboxChange(value.target.checked);
              }
            }}
            disabled={disabled}
          />
        </Grid>

        {showDatePicker && (
          <>
            <Grid item xs={12} md={7} xl={9} className={classes.countdown}>
              <Countdown
                date={countdownDate}
                renderer={this.renderCountdown}
                ref={(ref) => (this[dateFieldName] = ref)}
              />
            </Grid>
            <Grid item xs={12} md={5} xl={3}>
              <Field
                id={dateFieldName}
                name={dateFieldName}
                label={dateLabel}
                component={this.renderDateTimePicker}
                maxDate={maxDate}
                maxDateMessage={maxDateMessage}
                onChange={(value) => {
                  handleDateChange(value);
                }}
              />
            </Grid>
            <Grid
              container
              item
              xs={12}
              md={7}
              xl={9}
              alignItems="center"
              justifyContent="space-evenly"
            >
              {[1, 2, 4, 8].map((week) => {
                return (
                  <Button
                    key={week}
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      const value = endOfDay(addDays(new Date(), week * 7));
                      this.props.dispatch(
                        change('createAd', dateFieldName, value)
                      );
                      handleDateChange(value);
                    }}
                  >
                    {week}w
                  </Button>
                );
              })}
            </Grid>
          </>
        )}
      </>
    );
  };

  render() {
    const {
      awsS3UploadKit,
      handleSubmit,
      classes,
      categories,
      clients,
      pristine,
      submitting,
      valid,
      error,
      requestOptions,
      isPublished,
      cutOffDate,
      publishHistory,
      availablePositions,
      isDirty,
    } = this.props;

    const {
      originalLocalUrl,
      newUpload,
      uploadedAds,
      descriptionWords,
      isImageUpdated,
      isUploadedAdValid,
    } = this.state;

    const originalUrl = originalLocalUrl
      ? originalLocalUrl
      : awsS3UploadKit.original?.getOptions.url;

    const originalPreviewUrl = originalLocalUrl
      ? originalLocalUrl
      : requestOptions.original?.url;

    return (
      <>
        <CssBaseline />
        <Paper elevation={1} className={classes.paper}>
          <Typography component="h1" variant="h6" className={classes.title}>
            {this.isEditing ? 'Edit' : 'Create New'} Ad
          </Typography>
          <Grid container spacing={4} direction="row" justifyContent="center">
            <Grid item xs={12} sm={7}>
              <form
                className={classes.form}
                onSubmit={handleSubmit(this.onSubmit)}
              >
                <Grid container spacing={3}>
                  <Grid item sm={9} xs={12}>
                    <Field
                      id="originalFileName"
                      label="File Name"
                      name="originalFileName"
                      disabled
                      component={this.renderTextField}
                    />
                  </Grid>
                  <Grid item sm={3} xs={12} align="end">
                    <Button
                      color="primary"
                      type="button"
                      variant="contained"
                      onClick={this.onUploaderOpen}
                    >
                      Open Uploader
                    </Button>
                  </Grid>
                  <DashboardModal
                    uppy={this.uppy}
                    showLinkToFileUploadResult={false}
                    showRemoveButtonAfterComplete
                    proudlyDisplayPoweredByUppy={false}
                    note="Please choose an image (*.jpg, *.jpeg, *.png)"
                    open={this.state.uploaderOpen}
                    onRequestClose={this.onUploaderClose}
                  />
                  <Grid item xs={12}>
                    <CropperDialog
                      aspectRatio={3 / 1}
                      currentImage={requestOptions.cropped}
                      isSrcNewUpload={!!newUpload}
                      generatePreview={{ maxWidth: 100, quality: 0.1 }}
                      maxWidth={3600}
                      onCropSuccess={(preview) => {
                        this.addUploadedAd({
                          requestOptions: awsS3UploadKit.cropped,
                          type: 'image/jpeg',
                        });
                        this.setState({ preview });
                      }}
                      src={originalUrl}
                      awsS3UploadKit={awsS3UploadKit.cropped}
                      title="Crop image"
                      tooltip="Ad"
                    />
                  </Grid>
                  {this.isEditing && (
                    <Grid item xs={12}>
                      <Field
                        id="id"
                        label="Ad ID"
                        name="id"
                        disabled
                        component={this.renderTextField}
                        required={false}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Field
                      id="categoryId"
                      label="Category"
                      name="categoryId"
                      placeholder="Choose a category"
                      onChange={this.onCategoryChange}
                      component={this.renderTextField}
                      select
                      disabled={this.isEditing}
                    >
                      {this.renderCategoryOptions(categories)}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      id="clientId"
                      label="Client"
                      name="clientId"
                      placeholder="Choose a client"
                      component={this.renderTextField}
                      select
                      disabled={this.isEditing}
                    >
                      {this.renderClientOptions(clients)}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      id="sourceUrl"
                      name="sourceUrl"
                      label="Source URL"
                      placeholder="Enter the original source URL"
                      component={this.renderTextField}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      id="description"
                      label="Description"
                      name="description"
                      placeholder="Enter Description"
                      multiline
                      helperText={
                        descriptionWords > 0 && `${descriptionWords} word(s)`
                      }
                      required={false}
                      onChange={this.onDescriptionChange}
                      component={this.renderTextField}
                    />
                  </Grid>
                  {this.isEditing && (
                    <>
                      <Grid item xs={12}>
                        <Field
                          id="sourceUrlCount"
                          label="Source URL Clicks"
                          name="sourceUrlCount"
                          disabled
                          component={this.renderTextField}
                          required={false}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          id="createdAt"
                          label="Created At"
                          name="createdAt"
                          disabled
                          component={this.renderTextField}
                          required={false}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          id="updatedAt"
                          label="Updated At"
                          name="updatedAt"
                          disabled
                          component={this.renderTextField}
                          required={false}
                        />
                      </Grid>
                    </>
                  )}
                  {this.renderCheckboxAndDateTimePicker({
                    checkboxFieldName: 'isPublished',
                    checkboxLabel: 'Published',
                    showDatePicker: isPublished,
                    dateFieldName: 'cutOffDate',
                    dateLabel: 'Cut-off At',
                    countdownDate: cutOffDate,
                    maxDate: new Date('2100-01-01'),
                  })}
                  {isPublished && (
                    <Grid item xs={6}>
                      <Field
                        id="position"
                        label="Position"
                        name="position"
                        placeholder="Choose a position"
                        component={this.renderTextField}
                        select
                      >
                        {this.renderPositionOptions(availablePositions)}
                      </Field>
                    </Grid>
                  )}
                  {this.isEditing && (
                    <>
                      <Grid item xs={12}>
                        <HistoryChart
                          publishHistory={publishHistory}
                          showPosition
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
                <Button
                  className={classes.button}
                  color="primary"
                  disabled={
                    (pristine && uploadedAds.length === 0) ||
                    submitting ||
                    !valid ||
                    (!this.isEditing && !isUploadedAdValid) ||
                    (this.isEditing && newUpload && !isUploadedAdValid)
                  }
                  type="submit"
                  variant="contained"
                >
                  {this.isEditing ? 'Save' : 'Submit'}
                </Button>
                <Button
                  className={classes.button}
                  type="button"
                  variant="contained"
                  onClick={this.onCancel}
                >
                  Cancel
                </Button>
              </form>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Previewer
                imageUrl={originalPreviewUrl}
                widthThreshold={2160}
                tooltip="Original image"
              />
            </Grid>
          </Grid>
        </Paper>
        <Prompt
          when={isDirty || isImageUpdated}
          message={(location, action) => {
            return action === 'PUSH' && location.search.includes('prompt=no')
              ? true
              : 'There are unsaved changes. Do you wish to discard them?';
          }}
        />
        <Snackbar
          autoHideDuration={3000}
          onClose={() => this.setState({ errorMessage: null })}
          open={!!error || !!this.state.errorMessage}
        >
          <Alert severity="error">{error || this.state.errorMessage}</Alert>
        </Snackbar>
        <Backdrop className={classes.backdrop} open={submitting}>
          <CircularProgress />
        </Backdrop>
      </>
    );
  }
}

const validate = (values) => {
  const errors = {};
  errors.originalFileName = required(values.originalFileName);
  errors.sourceUrl = required(values.sourceUrl);
  if (values.isPublished) {
    errors.position =
      !values.position || values.position < 0 ? 'Required' : undefined;
  }
  return errors;
};

const selector = formValueSelector('createAd');

const mapStateToProps = (state) => {
  const editingAd = getEditingAd(state);
  return {
    isPublished: selector(state, 'isPublished'),
    cutOffDate: selector(state, 'cutOffDate'),
    errorMessage: getAdsErrorMessage(state),
    categories: selectCategories(state),
    clients: getClients(state),
    initialValues: {
      id: editingAd.id,
      originalFileName: editingAd.image ? editingAd.image.original.name : null,
      categoryId: editingAd.category?.id,
      clientId: editingAd.client?.id,
      description: editingAd.description,
      sourceUrl: editingAd.sourceUrl,
      sourceUrlCount: editingAd.sourceUrlCount,
      likesCount: editingAd.likesCount,
      position: editingAd.position,
      isPublished: editingAd.publishTime > 0,
      cutOffDate:
        editingAd.cutOffTime > 0
          ? new Date(editingAd.cutOffTime)
          : endOfDay(addDays(new Date(), state.ads.preferences.cutOffTimeDs)),
      createdAt: formatDateTime(editingAd.createdAt),
      updatedAt: formatDateTime(editingAd.updatedAt),
    },
    requestOptions: editingAd.requestOptions ? editingAd.requestOptions : {},
    publishHistory: editingAd.publishHistory,
    dataUrl: state.base64.dataUrl,
    dataUrlLoading: state.base64.loading,
    awsS3UploadKit: selectAwsS3UploadKitAd(state),
    availablePositions: getAdsAvailablePositions(state, editingAd.position),
    isDirty: isDirty('createAd')(state),
  };
};

export default compose(
  connect(mapStateToProps, {
    loadAd,
    loadCategories,
    loadClients,
    push,
    getAdAwsS3UploadKit,
    getAdOccupiedPositions,
    addAd,
    updateAd,
    removeS3Objects,
    clearEditingAd,
    unloadClients,
    unloadCategories,
  }),
  reduxForm({ form: 'createAd', validate, enableReinitialize: true }),
  withStyles(styles)
)(AdDetail);
