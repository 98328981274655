import * as actionTypes from './types';

export const getAdAwsS3UploadKit =
  (payload) =>
  (dispatch, getState, { mernApi }) => {
    dispatch({ type: actionTypes.GET_AD_SIGNED_REQUEST_OPTIONS });
    return mernApi
      .post('/api/paid-content/signed-request-options', payload)
      .then(
        (response) => {
          dispatch({
            type: actionTypes.GET_AD_SIGNED_REQUEST_OPTIONS_SUCCESS,
            payload: response.data,
          });
          return Promise.resolve(response.data);
        },
        (err) => {
          dispatch({
            type: actionTypes.GET_AD_SIGNED_REQUEST_OPTIONS_FAIL,
            payload: err.response.data.error.message,
          });
        }
      );
  };

// type can be 'asset' or 'ad'
export const removeS3Objects =
  ({ objectsToRemove, type }) =>
  (dispatch, getState, { mernApi }) => {
    dispatch({
      type: actionTypes.REMOVE_S3_OBJECTS,
      payload: { type },
    });
    return Promise.all(
      objectsToRemove.map((image) => {
        const { deleteOptions } = image;
        return mernApi
          .delete(deleteOptions.url, { headers: deleteOptions.headers })
          .then(
            (response) => {
              // ignore
              return null;
            },
            (err) => {
              console.log(err);
            }
          );
      })
    ).then(() => {
      dispatch({
        type: actionTypes.REMOVE_S3_OBJECTS_SUCCESS,
        payload: { type },
      });
      return null;
    });
  };
