import { configureStore } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { createReduxHistoryContext } from 'redux-first-history';
import createRootReducer from './reducers';
import mernApi from './apis/mern';

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({
    history: createBrowserHistory(),
  });

const configureAppStore = (preloadedState) => {
  const store = configureStore({
    reducer: createRootReducer(routerReducer),
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: { mernApi },
        },
      }).concat(routerMiddleware),
    preloadedState,
  });

  return { store, history: createReduxHistory(store) };
};

export default configureAppStore;
