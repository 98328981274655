const _ = require('lodash');

export const PUBLISH_STATUS = [
  { id: 'published', name: 'Published' },
  { id: 'scheduled', name: 'Scheduled' },
];

export const COVER_IMAGE_TYPE = {
  TOP: 'top',
  HIGHLIGHT_ONE: 'highlight-one',
  HIGHLIGHT_TWO: 'highlight-two',
};

export const COVER_IMAGE_TYPE_TO_LABEL = {
  [COVER_IMAGE_TYPE.TOP]: 'Top Image',
  [COVER_IMAGE_TYPE.HIGHLIGHT_ONE]: 'Highlight Image 1',
  [COVER_IMAGE_TYPE.HIGHLIGHT_TWO]: 'Highlight Image 2',
};

export const COVER_IMAGE_TYPE_OPTIONS = _.keys(COVER_IMAGE_TYPE_TO_LABEL).map(
  (key) => ({
    id: key,
    name: COVER_IMAGE_TYPE_TO_LABEL[key],
  })
);
