import { normalize } from 'normalizr';
import * as actionTypes from '../actions/types';
import * as schemas from '../schemas';
import { getPreferences } from '../../utils';

const INITIAL_STATE = {
  entities: { assets: {}, categories: {} },
  allIds: [],
  loading: false,
  preferences: getPreferences('assets'),
  error: null,
  message: null,
  editing: {},
  counter: {},
  summary: [],
};

const assetsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GENERATE_ASSET_SUMMARY:
    case actionTypes.REMOVE_ASSET:
    case actionTypes.RESTORE_ASSETS:
    case actionTypes.REMOVE_IMAGE:
    case actionTypes.ADD_ASSET:
    case actionTypes.DUPLICATE_ASSET:
    case actionTypes.UPDATE_ASSET:
    case actionTypes.UPDATE_IMAGE_ORDERS:
    case actionTypes.LOAD_ASSET:
    case actionTypes.LOAD_ASSETS: {
      return { ...state, loading: true, error: null };
    }

    case actionTypes.LOAD_ASSETS_SUCCESS: {
      const assets = action.payload.assets;
      const data = normalize(assets, schemas.assetListSchema);
      return {
        ...state,
        loading: false,
        allIds: data.result,
        entities: data.entities,
        preferences: {
          ...getPreferences('assets'), // reset filters
          ...action.payload.preferences,
        },
        counter: action.payload.counter,
      };
    }

    case actionTypes.GENERATE_ASSET_SUMMARY_SUCCESS: {
      return {
        ...state,
        summary: action.payload,
        loading: false,
      };
    }

    case actionTypes.DUPLICATE_ASSET_SUCCESS:
      return {
        ...state,
        loading: false,
        message: 'An asset has been duplicated',
      };

    case actionTypes.ADD_ASSET_SUCCESS: {
      return {
        ...state,
        allIds: [action.payload.id, ...state.allIds],
        entities: {
          ...state.entities,
          assets: {
            ...state.entities.assets,
            [action.payload.id]: action.payload,
          },
          categories: {
            ...state.entities.categories,
            [action.payload.category.id]: action.payload.category,
          },
        },
        loading: false,
        message: 'An asset has been added',
      };
    }

    case actionTypes.RESTORE_ASSETS_SUCCESS: {
      const { message, restoredAssets } = action.payload;
      const assets = {};
      restoredAssets.forEach((asset) => {
        assets[asset.id] = asset;
      });
      return {
        ...state,
        entities: {
          ...state.entities,
          assets: {
            ...state.entities.assets,
            ...assets,
          },
        },
        loading: false,
        message,
      };
    }

    case actionTypes.UPDATE_ASSET_SUCCESS: {
      return {
        ...state,
        entities: {
          ...state.entities,
          assets: {
            ...state.entities.assets,
            [action.payload.id]: action.payload,
          },
          categories: {
            ...state.entities.categories,
            [action.payload.category.id]: action.payload.category,
          },
        },
        loading: false,
        editing: action.payload,
        message: 'An asset has been updated',
      };
    }

    case actionTypes.UPDATE_IMAGE_ORDERS_SUCCESS: {
      return {
        ...state,
        entities: {
          ...state.entities,
          assets: {
            ...state.entities.assets,
            [action.payload.id]: action.payload,
          },
          categories: {
            ...state.entities.categories,
            [action.payload.category.id]: action.payload.category,
          },
        },
        loading: false,
        message: 'Image orders has been updated',
        editing: action.payload,
      };
    }

    case actionTypes.LOAD_ASSET_SUCCESS: {
      return {
        ...state,
        editing: action.payload,
        loading: false,
      };
    }

    case actionTypes.CLEAR_EDITING_ASSET: {
      return {
        ...state,
        editing: {},
      };
    }

    case actionTypes.REMOVE_ASSET_SUCCESS: {
      const deletedId = action.payload.deletedId;
      const { [deletedId]: removed, ...assets } = state.entities.assets;
      return {
        ...state,
        allIds: state.allIds.filter((id) => id !== deletedId),
        entities: {
          ...state.entities,
          assets,
        },
        message: 'An asset has been removed',
      };
    }

    case actionTypes.REMOVE_IMAGE_SUCCESS: {
      return {
        ...state,
        editing: action.payload.asset,
        loading: false,
        message: 'An image has been removed',
      };
    }

    case actionTypes.GENERATE_ASSET_SUMMARY_FAIL:
    case actionTypes.ADD_ASSET_FAIL:
    case actionTypes.DUPLICATE_ASSET_FAIL:
    case actionTypes.UPDATE_ASSET_FAIL:
    case actionTypes.UPDATE_IMAGE_ORDERS_FAIL:
    case actionTypes.REMOVE_ASSET_FAIL:
    case actionTypes.RESTORE_ASSETS_FAIL:
    case actionTypes.REMOVE_IMAGE_FAIL:
    case actionTypes.LOAD_ASSET_FAIL:
    case actionTypes.LOAD_ASSETS_FAIL: {
      return { ...state, loading: false, error: action.payload };
    }

    case actionTypes.CLEAR_ASSET_MESSAGE: {
      return { ...state, message: null };
    }

    case actionTypes.CLEAR_ASSET_ERROR_MESSAGE: {
      return { ...state, error: null };
    }

    case actionTypes.UPDATE_PREFERENCES: {
      const { key, subKey, value } = action.payload;
      if (key === 'assets') {
        return {
          ...state,
          preferences: { ...state.preferences, [subKey]: value },
        };
      }
      return state;
    }

    case actionTypes.RESET_PREFERENCES: {
      return { ...state, preferences: { ...action.payload.assets } };
    }

    default:
      return state;
  }
};

export default assetsReducer;
