import * as actionTypes from './types';

export const loadAds =
  (payload) =>
  (dispatch, getState, { mernApi }) => {
    dispatch({ type: actionTypes.LOAD_ADS });
    const {
      filters,
      page = 0,
      pageSize = 10,
      orderBy,
      orderDirection,
      search,
    } = payload;
    const skip = page * pageSize;
    const sort = orderBy
      ? `${orderDirection === 'desc' ? '-' : ''}${
          orderBy.field === 'sourceUrl' ? 'sourceUrlCount' : orderBy.field
        }`
      : '-createdAt';
    const params = {
      sort,
      limit: pageSize,
      skip,
      isAdmin: true, // FIXME: Remove this when we have separated route for admin
    };
    const preferences = {
      page,
      pageSize,
    };
    if (orderBy?.field) {
      preferences.orderBy = orderBy.field;
    }
    if (orderDirection) {
      preferences.orderDirection = orderDirection;
    }
    filters.forEach((filter) => {
      if (filter.column.field === 'category.name') {
        params.categoryId = filter.value;
        preferences.categoryId = filter.value;
      }
      if (filter.column.field === 'client.companyName') {
        params.clientId = filter.value;
        preferences.clientId = filter.value;
      }
      if (filter.column.field === 'isPublished') {
        params.isPublished = filter.value.startsWith('published');
        preferences.publishStatus = filter.value;
        if (filter.value === 'published-active') {
          params.cutOffStatus = 'active';
        } else if (filter.value === 'published-expired') {
          params.cutOffStatus = 'expired';
        }
      }
    });
    let searchText = search.trim();
    if (searchText) {
      params.searchText = searchText;
      preferences.searchText = searchText;
    }
    return mernApi.get('/api/paid-content', { params }).then(
      (response) => {
        dispatch({
          type: actionTypes.LOAD_ADS_SUCCESS,
          payload: {
            ...response.data,
            preferences,
          },
        });
      },
      (err) => {
        dispatch({
          type: actionTypes.LOAD_ADS_FAIL,
          payload: err.response.data.error.message,
        });
      }
    );
  };

export const loadAd =
  (adId) =>
  (dispatch, getState, { mernApi }) => {
    dispatch({ type: actionTypes.LOAD_AD });
    return mernApi.get(`/api/paid-content/${adId}`).then(
      (response) => {
        dispatch({
          type: actionTypes.LOAD_AD_SUCCESS,
          payload: response.data.ad,
        });
      },
      (err) => {
        dispatch({
          type: actionTypes.LOAD_AD_FAIL,
          payload: err.response.data.error.message,
        });
      }
    );
  };

export const removeAd =
  (adId) =>
  (dispatch, getState, { mernApi }) => {
    dispatch({ type: actionTypes.REMOVE_AD });
    return mernApi.delete(`/api/paid-content/${adId}`).then(
      (response) => {
        dispatch({
          type: actionTypes.REMOVE_AD_SUCCESS,
          payload: { ...response.data, deletedId: adId },
        });
      },
      (err) => {
        dispatch({
          type: actionTypes.REMOVE_AD_FAIL,
          payload: err.response.data.error.message,
        });
      }
    );
  };

export const getAdOccupiedPositions =
  (payload) =>
  (dispatch, getState, { mernApi }) => {
    dispatch({ type: actionTypes.GET_AD_OCCUPIED_POSITIONS });
    return mernApi
      .get('/api/paid-content/occupied-positions', { params: payload })
      .then(
        (response) => {
          dispatch({
            type: actionTypes.GET_AD_OCCUPIED_POSITIONS_SUCCESS,
            payload: response.data,
          });
          return Promise.resolve(response.data);
        },
        (err) => {
          dispatch({
            type: actionTypes.GET_AD_OCCUPIED_POSITIONS_FAIL,
            payload: err.response.data.error.message,
          });
        }
      );
  };

export const addAd =
  (formValues) =>
  (dispatch, getState, { mernApi }) => {
    dispatch({ type: actionTypes.ADD_AD });
    return mernApi.post('/api/paid-content', formValues).then(
      (response) => {
        dispatch({
          type: actionTypes.ADD_AD_SUCCESS,
          payload: response.data.ad,
        });
        return Promise.resolve(response.data);
      },
      (err) => {
        dispatch({
          type: actionTypes.ADD_AD_FAIL,
          payload: err.response.data.error.message,
        });
      }
    );
  };

export const updateAd =
  (formValues) =>
  (dispatch, getState, { mernApi }) => {
    dispatch({ type: actionTypes.UPDATE_AD });
    return mernApi.put(`/api/paid-content/${formValues.id}`, formValues).then(
      (response) => {
        dispatch({
          type: actionTypes.UPDATE_AD_SUCCESS,
          payload: response.data.ad,
        });
        return Promise.resolve(response.data);
      },
      (err) => {
        dispatch({
          type: actionTypes.UPDATE_AD_FAIL,
          payload: err.response.data.error.message,
        });
      }
    );
  };

export const clearEditingAd = () => {
  return {
    type: actionTypes.CLEAR_EDITING_AD,
  };
};

export const clearAdErrorMessage = () => {
  return {
    type: actionTypes.CLEAR_AD_ERROR_MESSAGE,
  };
};

export const clearAdMessage = () => {
  return {
    type: actionTypes.CLEAR_AD_MESSAGE,
  };
};
