import React, { useEffect, useMemo, useState } from 'react';
import {
  Alert,
  Backdrop,
  Button,
  Card,
  CircularProgress,
  CssBaseline,
  Grid,
  MenuItem,
  Snackbar,
  Typography,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { addDays, addSeconds, startOfDay } from 'date-fns';
import { useForm, useWatch } from 'react-hook-form';
import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';
import { Prompt, useParams, useLocation } from 'react-router-dom';
import _ from 'lodash';
import queryString from 'query-string';

import localforage from 'localforage';

import {
  selectCategories,
  getEditingAsset,
  getEditingNotificationMessage,
  getNotificationMessagesErrorMessage,
} from '../../store/selectors';
import {
  loadCategories,
  loadAsset,
  loadNotificationMessage,
  addNotificationMessage,
  updateNotificationMessage,
  clearEditingNotificationMessage,
  clearNotificationMessageError,
  unloadCategories,
  clearEditingAsset,
} from '../../store/actions';
import { formatDateTime } from '../../utils';

import ControllerDateTimePicker from '../../components/react-hook-form-wrappers/controller-date-time-picker';
import ControllerTextField from '../../components/react-hook-form-wrappers/controller-text-field';
import Previewer from '../../components/Previewer';
import YesNoDialog from '../../components/yes-no-dialog';
import useFormPersist from '../../components/custom-hooks/use-form-persist';
import usePrevious from '../../components/custom-hooks/use-previous';
import { TITLES } from './constants';

const styles = (theme) => ({
  container: {},
  title: {
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  backdrop: {
    zIndex: 999,
    backgroundColor: 'rgba(255, 255, 255, 0.6)',
  },
  countdown: {
    display: 'flex',
    alignItems: 'center',
  },
  copyright: {
    marginBottom: theme.spacing(4),
  },
});

let renderCount = 0;

const ControllerPublishDateTimePicker = ({ control, ...otherProps }) => {
  const publishDate = useWatch({ control, name: 'publishDate' });
  return (
    <ControllerDateTimePicker
      control={control}
      label={
        publishDate && publishDate.getTime() > Date.now()
          ? 'Will Be Pushed At'
          : 'Pushing Time'
      }
      {...otherProps}
    />
  );
};

const renderCategoryOptions = (categories) => {
  return _.map(categories, (category) => {
    return (
      <MenuItem key={category.id} value={category.id}>
        {category.name}
      </MenuItem>
    );
  });
};

const renderTitleOptions = (titles) => {
  return _.map(titles, (title) => {
    return (
      <MenuItem key={title.id} value={title.id}>
        {title.name}
      </MenuItem>
    );
  });
};

const NotificationMessageDetail = ({
  classes,
  initialValues,
  loadAsset,
  loadNotificationMessage,
  clearEditingAsset,
  loadCategories,
  isProcessing,
  categories,
  editingNotificationMessage,
  clearEditingNotificationMessage,
  clearNotificationMessageError,
  addNotificationMessage,
  updateNotificationMessage,
  errorMessage,
  push,
  assetToLink,
  topStoriesId,
}) => {
  const { notificationMessageId } = useParams();
  const isEditing = useMemo(
    () => !!notificationMessageId && notificationMessageId !== 'create',
    [notificationMessageId]
  );
  const { search: locationSearch } = useLocation();
  const assetId = useMemo(() => {
    return queryString.parse(locationSearch).assetId;
  }, [locationSearch]);

  const [showRestoreDialog, setShowRestoreDialog] = useState(false);

  const [isSaving, setIsSaving] = useState(false);
  const [assetUrl, setAssetUrl] = useState('');
  const previousInitialValues = usePrevious(initialValues);

  useEffect(() => {
    if (editingNotificationMessage?.asset) {
      setAssetUrl(
        editingNotificationMessage.asset.requestOptions[0].landscape.url
      );
    }
  }, [editingNotificationMessage]);

  // Remove items from localforage that are older than 10 days
  useEffect(() => {
    const now = Date.now();
    const tenDays = 10 * 24 * 60 * 60 * 1000; // 10 days
    localforage
      .iterate((value, key) => {
        const { __timestamp } = value;

        if (now - __timestamp >= tenDays) {
          localforage.removeItem(key);
        }
      })
      .then(() => {
        // ignore
      })
      .catch((err) => {
        console.log('[localforage.iterate] Error:', err);
      });
  }, []);

  useEffect(() => {
    loadCategories({ pageSize: 100 });
    if (isEditing) {
      loadNotificationMessage(notificationMessageId).then(() => {
        // make sure hide loader after creating new article
        setIsSaving(false);
      });
    } else {
      if (assetId) {
        loadAsset(assetId);
      }
    }

    renderCount = 0;

    return () => {
      clearEditingNotificationMessage();
      if (assetId) {
        clearEditingAsset();
      }
      unloadCategories();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationMessageId]);

  renderCount += 1;

  const [unlinkAsset, setUnlinkAsset] = useState(false);

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState: { isDirty, isSubmitting },
  } = useForm({
    defaultValues: {
      id: '',
      categoryId: '',
      title: 'WiB',
      body: '',
      publishDate: new Date(),
      createdAt: '',
      updatedAt: '',
      assetId: '',
    },
  });

  const { clearPersist, stopPersistInterval } = useFormPersist({
    name: notificationMessageId
      ? `notification-message-details-form-${notificationMessageId}`
      : 'notification-message-details-form',
    control,
    getValues,
    reset,
    onDataRestored: () => {
      setShowRestoreDialog(true);
    },
    timeout: 6 * 24 * 60 * 60 * 1000, // 6 days
    exclude: ['createdAt', 'updatedAt'],
  });

  useEffect(() => {
    if (initialValues && initialValues.id !== previousInitialValues?.id) {
      reset(initialValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues, reset]);

  useEffect(() => {
    if (_.isEmpty(assetToLink)) {
      return;
    }

    const valuesToSet = {
      assetId: assetToLink.id,
      categoryId: topStoriesId,
      body:
        assetToLink.category?.name === 'Cover Story'
          ? assetToLink.promotionalText
          : assetToLink.shortTitle,
    };

    if (assetToLink.publishTime >= Date.now()) {
      valuesToSet.publishDate = addSeconds(
        startOfDay(assetToLink.publishTime),
        25440
      );
    }

    if (
      assetToLink.category?.name === 'Cover Story' ||
      assetToLink.topStoryFrom <= 0 ||
      assetToLink.topStoryTo <= Date.now()
    ) {
      valuesToSet.categoryId = assetToLink.category?.id;
    }

    setAssetUrl(assetToLink.requestOptions[0].landscape.url);

    reset(valuesToSet, { keepDefaultValues: true });
  }, [assetToLink, topStoriesId, reset]);

  const onSubmit = async (data) => {
    setIsSaving(true);
    const formValues = { ...data };

    if (formValues.publishDate) {
      formValues.publishTime = formValues.publishDate.getTime();
    }

    if (unlinkAsset) {
      formValues.assetId = undefined;
    }

    try {
      if (isEditing) {
        await updateNotificationMessage(formValues);
      } else {
        await addNotificationMessage(formValues);
      }

      if (!errorMessage) {
        setIsSaving(false);
        reset();
        push('/dashboard/notifications?prompt=no');
      }
    } catch (err) {
      setIsSaving(false);
    }
  };

  const onCancel = () => {
    reset(initialValues);
    if (assetId) {
      push('/dashboard/assets?prompt=no');
    } else {
      push('/dashboard/notifications?prompt=no');
    }
  };

  const isPushed =
    !!editingNotificationMessage &&
    editingNotificationMessage.publishTime <= Date.now();

  return (
    <>
      <CssBaseline />
      <Grid className={classes.container}>
        <Grid container className={classes.title}>
          <Typography component="h1" variant="h6">
            {isEditing ? 'Edit' : 'Create New'} Notification Message
          </Typography>
        </Grid>
        <Grid container direction="row" justifyContent="center">
          <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            <Grid container item xs={12} direction="column">
              <Grid
                container
                justifyContent="space-around"
                direction={{ xs: 'row', md: 'row-reverse' }}
                style={{ paddingBottom: 24 }}
              >
                {!!assetUrl && !unlinkAsset && (
                  <Grid
                    container
                    item
                    xs={12}
                    md={4}
                    direction="row"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    style={{ marginBottom: 24 }}
                  >
                    {!isPushed && (
                      <Grid
                        container
                        item
                        xs={12}
                        md={8}
                        justifyContent="center"
                      >
                        <Button
                          type="button"
                          variant="contained"
                          color="secondary"
                          onClick={() => {
                            setUnlinkAsset(true);
                            setValue('assetId', '', {
                              shouldDirty: true,
                              shouldTouch: true,
                            });
                          }}
                        >
                          Unlink this asset
                        </Button>
                      </Grid>
                    )}
                    <Grid
                      container
                      item
                      xs={12}
                      md={8}
                      justifyContent="center"
                      style={{ marginTop: 24 }}
                    >
                      <Previewer
                        imageUrl={assetUrl}
                        tooltip="The detail screen of this asset will be opened when users tap on the notification message"
                      />
                    </Grid>
                  </Grid>
                )}
                <Grid
                  component={Card}
                  container
                  item
                  xs={12}
                  md={7}
                  gap={3}
                  style={{ padding: 24 }}
                >
                  <Grid item xs={12}>
                    Render count: {renderCount}
                  </Grid>
                  {isEditing && (
                    <Grid item xs={12}>
                      <ControllerTextField
                        control={control}
                        label="Notification Message ID"
                        name="id"
                        showCopyButton
                        required={false}
                        disabled
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <ControllerTextField
                      select
                      control={control}
                      label="Title"
                      name="title"
                      placeholder="Choose a title"
                      disabled={isPushed}
                    >
                      {renderTitleOptions(TITLES)}
                    </ControllerTextField>
                  </Grid>
                  <Grid item xs={12}>
                    <ControllerTextField
                      select
                      control={control}
                      label="Open In Tab"
                      name="categoryId"
                      placeholder="Choose a category"
                      disabled={isPushed}
                    >
                      {renderCategoryOptions(categories)}
                    </ControllerTextField>
                  </Grid>
                  <Grid item xs={12}>
                    <ControllerTextField
                      control={control}
                      label="Body"
                      name="body"
                      placeholder="Enter body"
                      multiline
                      countWords
                      showCopyButton
                      disabled={isPushed}
                    />
                  </Grid>
                  {isEditing && (
                    <Grid container item xs={12} spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <ControllerTextField
                          control={control}
                          label="Created At"
                          name="createdAt"
                          disabled
                          required={false}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ControllerTextField
                          control={control}
                          label="Updated At"
                          name="updatedAt"
                          disabled
                          required={false}
                        />
                      </Grid>
                    </Grid>
                  )}
                  <Grid item xs={12} md={5} xl={3}>
                    <ControllerPublishDateTimePicker
                      control={control}
                      name="publishDate"
                      maxDate={new Date('2100-01-01')}
                      disabled={isPushed}
                    />
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    md={5}
                    xl={3}
                    alignItems="center"
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={isPushed}
                      onClick={() => {
                        setValue('publishDate', new Date(), {
                          shouldDirty: true,
                          shouldTouch: true,
                        });
                      }}
                    >
                      Now
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              item
              justifyContent="space-around"
              direction={{ xs: 'row', md: 'row-reverse' }}
            >
              {!!assetUrl && !unlinkAsset && <Grid item xs={12} md={4} />}
              <Grid container item xs={12} md={7}>
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="center"
                  columnGap={3}
                >
                  <Button
                    color="primary"
                    disabled={isSubmitting || !isDirty || isSaving}
                    type="submit"
                    variant="contained"
                  >
                    {isEditing ? 'Save' : 'Submit'}
                  </Button>
                  <Button type="button" variant="contained" onClick={onCancel}>
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
      {showRestoreDialog && (
        <YesNoDialog
          open={showRestoreDialog}
          title="Restore?"
          message="There are unsaved changes from last edit. Do you want to restore the changes?"
          primaryButtonText="Yes"
          onPrimaryButtonClick={() => {
            setShowRestoreDialog(false);
          }}
          secondaryButtonText="No"
          onSecondaryButtonClick={() => {
            setShowRestoreDialog(false);
            // this will allow the dialog to close first
            setTimeout(() => {
              clearPersist();
              reset(initialValues);
            }, 100);
          }}
        />
      )}
      <Prompt
        when={isDirty}
        message={(location, action) => {
          return action === 'PUSH' && location.search.includes('prompt=no')
            ? true
            : 'There are unsaved changes. Do you wish to discard them?';
        }}
      />
      <Prompt
        when={!isDirty}
        message={() => {
          stopPersistInterval();
          clearPersist();
          return true;
        }}
      />
      <Snackbar
        autoHideDuration={5000}
        onClose={() => {
          clearNotificationMessageError();
        }}
        open={!!errorMessage}
      >
        <Alert
          severity="error"
          action={
            <Button
              size="small"
              color="secondary"
              onClick={() => {
                clearNotificationMessageError();
              }}
            >
              Dismiss
            </Button>
          }
        >
          {errorMessage}
        </Alert>
      </Snackbar>
      <Backdrop className={classes.backdrop} open={isProcessing || isSaving}>
        <CircularProgress />
      </Backdrop>
    </>
  );
};

const mapStateToProps = (state) => {
  const editingNotificationMessage = getEditingNotificationMessage(state);
  const asset = getEditingAsset(state);
  const categories = selectCategories(state);
  const errorMessage = getNotificationMessagesErrorMessage(state);

  const topStoriesId =
    _.find(categories, (category) => {
      return category.name === 'Top Stories';
    })?.id || '';

  let initialValues = null;
  if (!_.isEmpty(categories)) {
    if (_.isEmpty(editingNotificationMessage)) {
      initialValues = {
        id: '',
        title: 'WiB',
        body: '',
        categoryId: topStoriesId,
        publishDate: addSeconds(startOfDay(addDays(Date.now(), 1)), 25440), // Tomorrow @ 7:04 AM
        createdAt: '',
        updatedAt: '',
        assetId: '',
      };
    } else {
      initialValues = {
        id: editingNotificationMessage.id,
        title: editingNotificationMessage.title,
        body: editingNotificationMessage.body,
        categoryId: editingNotificationMessage.category?.id,
        publishDate: new Date(editingNotificationMessage.publishTime),
        createdAt: formatDateTime(editingNotificationMessage.createdAt),
        updatedAt: formatDateTime(editingNotificationMessage.updatedAt),
        assetId: editingNotificationMessage.asset?.id || '',
      };
    }
  }

  return {
    editingNotificationMessage,
    categories,
    errorMessage,
    assetToLink: asset,
    initialValues,
    topStoriesId,
  };
};

export default compose(
  connect(mapStateToProps, {
    loadAsset,
    loadCategories,
    loadNotificationMessage,
    addNotificationMessage,
    updateNotificationMessage,
    clearEditingAsset,
    clearEditingNotificationMessage,
    clearNotificationMessageError,
    unloadCategories,
    push,
  }),
  withStyles(styles)
)(NotificationMessageDetail);
