import { normalize } from 'normalizr';
import * as actionTypes from '../actions/types';
import * as schemas from '../schemas';
import { getPreferences } from '../../utils';

const INITIAL_STATE = {
  entities: { invoices: {} },
  allIds: [],
  loading: false,
  preferences: getPreferences('invoices'),
  error: null,
  message: null,
  editing: {},
};

const invoicesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.REMOVE_INVOICE:
    case actionTypes.ADD_INVOICE:
    case actionTypes.UPDATE_INVOICE:
    case actionTypes.GENERATE_PREVIEW_INVOICE:
    case actionTypes.LOAD_INVOICE:
    case actionTypes.LOAD_INVOICES: {
      return { ...state, loading: true, error: null };
    }

    case actionTypes.LOAD_INVOICES_SUCCESS: {
      const invoices = action.payload.invoices;
      const data = normalize(invoices, schemas.invoiceListSchema);
      return {
        ...state,
        loading: false,
        allIds: data.result,
        entities: data.entities,
        preferences: {
          ...getPreferences('invoices'), // reset filters
          ...action.payload.preferences,
        },
        totalCount: action.payload.invoicesCount,
        completedCount: action.payload.completedCount,
      };
    }

    case actionTypes.ADD_INVOICE_SUCCESS: {
      return {
        ...state,
        allIds: [...state.allIds, action.payload.id],
        entities: {
          ...state.entities,
          invoices: {
            ...state.entities.invoices,
            [action.payload.id]: action.payload,
          },
        },
        loading: false,
        message: 'An invoice has been added',
      };
    }

    case actionTypes.GENERATE_PREVIEW_INVOICE_SUCCESS: {
      return {
        ...state,
        editing: action.payload,
        loading: false,
      };
    }

    case actionTypes.LOAD_INVOICE_SUCCESS: {
      return {
        ...state,
        editing: action.payload,
        loading: false,
      };
    }

    case actionTypes.CLEAR_EDITING_INVOICE: {
      return {
        ...state,
        editing: {},
      };
    }

    case actionTypes.REMOVE_INVOICE_SUCCESS: {
      const deletedId = action.payload.deletedId;
      const { [deletedId]: removed, ...invoices } = state.entities.invoices;
      return {
        ...state,
        allIds: state.allIds.filter((id) => id !== deletedId),
        entities: {
          ...state.entities,
          invoices,
        },
        message: 'An invoice has been removed',
      };
    }

    case actionTypes.ADD_INVOICE_FAIL:
    case actionTypes.UPDATE_INVOICE_FAIL:
    case actionTypes.GENERATE_PREVIEW_INVOICE_FAIL:
    case actionTypes.REMOVE_INVOICE_FAIL:
    case actionTypes.LOAD_INVOICE_FAIL:
    case actionTypes.LOAD_INVOICES_FAIL: {
      return { ...state, loading: false, error: action.payload };
    }

    case actionTypes.UPDATE_INVOICE_SUCCESS: {
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.payload.id]: action.payload,
        },
        message: 'An invoice has been updated',
      };
    }

    case actionTypes.CLEAR_INVOICE_MESSAGE: {
      return { ...state, message: null };
    }

    case actionTypes.UPDATE_PREFERENCES: {
      const { key, subKey, value } = action.payload;
      if (key === 'invoices') {
        return {
          ...state,
          preferences: { ...state.preferences, [subKey]: value },
        };
      }
      return state;
    }

    case actionTypes.RESET_PREFERENCES: {
      return { ...state, preferences: { ...action.payload.invoices } };
    }

    default:
      return state;
  }
};

export default invoicesReducer;
