import { createSelector } from '@reduxjs/toolkit';
import { denormalize } from 'normalizr';
import * as schemas from '../schemas';

export const getAdsState = (state) => state.ads;

export const getAdsAllIds = (state) => state.ads.allIds;

export const getAdsEntities = (state) => state.ads.entities;

export const getAdsErrorMessage = (state) => state.ads.error;

export const getAdsMessage = (state) => state.ads.message;

export const getAdsCount = (state) => state.ads.totalCount;

export const getAdsCutOffCount = (state) => state.ads.cutOffCount;

export const getAdsTopStoriesCount = (state) => state.ads.topStoriesCount;

export const getAdsPublishedCount = (state) => state.ads.publishedCount;

export const getAdsAvailablePositions = (state, currentPosition) => {
  let occupied = state.ads.occupiedPositions;
  if (currentPosition) {
    occupied = occupied.filter((p) => p !== currentPosition);
  }
  let available = [];
  for (let i = 1; i <= 500; i++) {
    if (occupied.findIndex((o) => o === i) === -1) {
      available.push(i);
    }
  }
  return available;
};

export const getAds = createSelector(
  getAdsEntities,
  getAdsAllIds,
  (entities, allIds) => denormalize(allIds, schemas.adListSchema, entities)
);

export const getEditingAd = (state) => state.ads.editing;
