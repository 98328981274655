import React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import {
  selectCategories,
  selectCategoriesCount,
  selectCategoriesErrorMessage,
  selectCategoriesMessage,
} from '../../store/selectors';
import {
  loadCategories,
  addCategory,
  updateCategory,
  removeCategory,
  clearCategoryErrorMessage,
  clearCategoryMessage,
} from '../../store/actions';
import MaterialTable from '../../components/MaterialTable';
import { formatDateTime } from '../../utils';

class CategoryList extends React.Component {
  initialPage = -1;

  componentDidMount() {
    this.initialPage = this.props.initial.page;
  }

  createHandlerWrapper = (data, handler) =>
    new Promise((resolve, reject) => {
      handler(data).then(() => {
        if (!this.props.errorMessage) {
          resolve();
        }
        reject();
      });
    });

  onRowAdd = (data) => this.createHandlerWrapper(data, this.props.addCategory);

  onRowUpdate = (data) =>
    this.createHandlerWrapper(data, this.props.updateCategory);

  onRowDelete = (data) =>
    this.createHandlerWrapper(data, this.props.removeCategory);

  isDeletable = (data) => !data.isTopStories && data.name !== 'Cover Story';

  renderCreatedAt = (row) => {
    return formatDateTime(row.createdAt);
  };

  renderUpdatedAt = (row) => {
    return formatDateTime(row.updatedAt);
  };

  render() {
    const {
      errorMessage,
      message,
      loading,
      initial,
      clearCategoryErrorMessage,
      clearCategoryMessage,
      loadCategories,
    } = this.props;
    const columns = [
      {
        title: 'No.',
        field: 'ordinalNumber',
        type: 'numeric',
        align: 'left',
        defaultSort:
          initial.orderBy === 'ordinalNumber'
            ? initial.orderDirection
            : undefined,
      },
      {
        title: 'Name',
        field: 'name',
        defaultSort:
          initial.orderBy === 'name' ? initial.orderDirection : undefined,
      },
      {
        title: 'Published',
        field: 'isPublished',
        type: 'boolean',
        defaultSort:
          initial.orderBy === 'isPublished'
            ? initial.orderDirection
            : undefined,
      },
      {
        title: 'isTopStories',
        field: 'isTopStories',
        type: 'boolean',
        editable: 'never',
        sorting: false,
      },
      {
        title: 'Created At',
        field: 'createdAt',
        type: 'datetime',
        editable: 'never',
        render: this.renderCreatedAt,
        defaultSort:
          initial.orderBy === 'createdAt' ? initial.orderDirection : undefined,
      },
      {
        title: 'Updated At',
        field: 'updatedAt',
        type: 'datetime',
        editable: 'never',
        render: this.renderUpdatedAt,
        defaultSort:
          initial.orderBy === 'updatedAt' ? initial.orderDirection : undefined,
      },
    ];
    return (
      <>
        <MaterialTable
          title="Categories"
          columns={columns}
          data={(query) =>
            new Promise((resolve) => {
              if (this.initialPage > 0) {
                query.page = this.initialPage;
                this.initialPage = -1;
              }
              // orderby and orderDirection are missing from the initial query
              const orderBy = columns[query.orderByCollection[0].orderBy];
              const orderDirection = query.orderByCollection[0].orderDirection;
              loadCategories({ ...query, orderBy, orderDirection })
                .then(() => {
                  if (this.props.categories.length === 0 && query.page > 0) {
                    query.page = query.page - 1;
                    return loadCategories(query);
                  }
                  return null;
                })
                .then(() => {
                  resolve({
                    data: this.props.categories,
                    page: query.page,
                    totalCount: this.props.totalCount,
                  });
                });
            })
          }
          editable={{
            isDeletable: this.isDeletable,
            onRowAdd: this.onRowAdd,
            onRowUpdate: this.onRowUpdate,
            onRowDelete: this.onRowDelete,
          }}
          options={{
            initialPage: this.initialPage > 0 ? this.initialPage : 0,
            pageSize: this.props.initial.pageSize,
            emptyRowsWhenPaging: false,
          }}
          isLoading={loading}
        />
        <Snackbar
          open={!!errorMessage}
          autoHideDuration={7000}
          onClose={clearCategoryErrorMessage}
        >
          <Alert
            severity="error"
            action={
              <Button
                size="small"
                color="secondary"
                onClick={clearCategoryErrorMessage}
              >
                Dismiss
              </Button>
            }
          >
            {errorMessage}
          </Alert>
        </Snackbar>
        <Snackbar
          open={!!message}
          autoHideDuration={7000}
          onClose={clearCategoryMessage}
        >
          <Alert
            severity="success"
            action={
              <Button
                size="small"
                color="secondary"
                onClick={clearCategoryMessage}
              >
                Dismiss
              </Button>
            }
          >
            {message}
          </Alert>
        </Snackbar>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    errorMessage: selectCategoriesErrorMessage(state),
    message: selectCategoriesMessage(state),
    categories: selectCategories(state),
    totalCount: selectCategoriesCount(state),
    loading: state.categories.loading,
    initial: state.categories.preferences,
  };
};

export default compose(
  connect(mapStateToProps, {
    loadCategories,
    addCategory,
    updateCategory,
    removeCategory,
    clearCategoryErrorMessage,
    clearCategoryMessage,
  })
)(CategoryList);
