import React from 'react';
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import { Check, Link as LinkIcon, Minus, PlusBox } from 'mdi-material-ui';
import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';
import Countdown, { calcTimeDelta } from 'react-countdown';
import {
  getAds,
  getAdsCount,
  getAdsMessage,
  getAdsCutOffCount,
  getAdsPublishedCount,
  selectCategories,
  getClients,
} from '../../store/selectors';
import {
  loadAds,
  loadCategories,
  loadClients,
  removeAd,
  updateAd,
  clearAdMessage,
  unloadCategories,
  unloadClients,
} from '../../store/actions';
import MaterialTable from '../../components/MaterialTable';
import SelectFilterField from '../../components/SelectFilterField';
import { MTableToolbar } from '@material-table/core';
import { formatDateTime, formatCountdown, MAX_PAGE_SIZE } from '../../utils';
import { PUBLISH_STATUS } from './constants';

const styles = (theme) => ({
  ad: {
    width: 240,
    height: 80,
  },
  toolbarInfo: {
    padding: `0px ${theme.spacing(2)}`,
  },
  toolbarChip: {
    marginRight: theme.spacing(1),
  },
  isPublished: {
    marginLeft: theme.spacing(1),
  },
  tooltipText: {
    fontSize: 'inherit',
  },
});

class AdList extends React.Component {
  initialPage = -1;

  componentDidMount() {
    this.initialPage = this.props.initial.page;
    this.props.loadCategories({ pageSize: MAX_PAGE_SIZE });
    this.props.loadClients({ pageSize: MAX_PAGE_SIZE });
  }

  componentWillUnmount() {
    this.props.unloadCategories();
    this.props.unloadClients();
  }

  getData = (columns) => (query) =>
    new Promise((resolve) => {
      if (this.initialPage > 0) {
        query.page = this.initialPage;
        this.initialPage = -1;
      }
      // orderby and orderDirection are missing from the initial query
      const orderBy = columns[query.orderByCollection[0].orderBy];
      const orderDirection = query.orderByCollection[0].orderDirection;
      this.props
        .loadAds({ ...query, orderBy, orderDirection })
        .then(() => {
          if ((this.props.ads.length === 0) & (query.page > 0)) {
            query.page = query.page - 1;
            return this.props.loadAds(query);
          }
          return null;
        })
        .then(() => {
          resolve({
            data: this.props.ads,
            page: query.page,
            totalCount: this.props.totalCount,
          });
        });
    });

  renderToolbar = (props) => {
    const {
      classes,
      cutOffCount: activeCount = 0,
      totalCount = 0,
      publishedCount = 0,
    } = this.props;

    const unpublishedCount = totalCount - publishedCount;
    const expiredCount = publishedCount - activeCount;

    return (
      <div>
        <MTableToolbar {...props} />
        <div className={classes.toolbarInfo}>
          <Tooltip
            title={
              <>
                <Typography style={{ fontSize: 'inherit' }}>
                  <b>Published</b>: {publishedCount}
                </Typography>
                <Typography style={{ fontSize: 'inherit' }}>
                  <b>Unpublished</b>: {unpublishedCount}
                </Typography>
              </>
            }
          >
            <Chip
              label={`Total: ${totalCount}`}
              color="secondary"
              className={classes.toolbarChip}
            />
          </Tooltip>
          <Tooltip
            title={
              <>
                <Typography style={{ fontSize: 'inherit' }}>
                  <b>Active</b>: {activeCount}
                </Typography>
                <Typography style={{ fontSize: 'inherit' }}>
                  <b>Expired</b>: {expiredCount}
                </Typography>
              </>
            }
          >
            <Chip
              label={`Published: ${publishedCount}`}
              color="secondary"
              className={classes.toolbarChip}
            />
          </Tooltip>
          <Chip
            label={`Active: ${activeCount}`}
            color="secondary"
            className={classes.toolbarChip}
          />
        </div>
      </div>
    );
  };

  renderAd = (row) => {
    const { classes } = this.props;
    return (
      <Tooltip
        style={{ fontSize: 'inherit' }}
        title={
          <>
            <Typography className={classes.tooltipText}>
              <b>Description</b>: {row.description ? row.description : 'N/A'}
            </Typography>
          </>
        }
      >
        <Badge badgeContent={row.likesCount} color="secondary">
          <Avatar
            src={row.requestOptions.cropped.url}
            variant="rounded"
            className={this.props.classes.ad}
          />
        </Badge>
      </Tooltip>
    );
  };

  renderIsPublished = (row) => {
    return row.publishTime > 0 ? (
      <Tooltip
        title={
          <>
            {row.cutOffTime <= Date.now() && (
              <Typography style={{ fontSize: 'inherit' }}>
                <b>Not displayed in mobile app</b>
              </Typography>
            )}
            <Typography style={{ fontSize: 'inherit' }}>
              <b>Publish Time</b>: {formatDateTime(row.publishTime)}
            </Typography>
            <Typography style={{ fontSize: 'inherit' }}>
              <b>Cut-off Time</b>: {formatDateTime(row.cutOffTime)}
            </Typography>
          </>
        }
      >
        <Check
          className={this.props.classes.isPublished}
          color={row.cutOffTime <= Date.now() ? 'secondary' : 'primary'}
        />
      </Tooltip>
    ) : (
      <Tooltip title="Not published">
        <Minus className={this.props.classes.isPublished} />
      </Tooltip>
    );
  };

  renderDateTime = (date) => {
    return formatDateTime(date);
  };

  renderCountdown = (countdownObj) => {
    return (
      <Typography style={{ fontSize: 'inherit' }}>
        <b>Countdown</b>: {formatCountdown(countdownObj)}
      </Typography>
    );
  };

  renderStartEndDate = (startDate, endDate) => {
    return (
      <Tooltip
        title={
          <>
            <Countdown date={endDate} renderer={this.renderCountdown} />
            <Typography style={{ fontSize: 'inherit' }}>
              <b>Start</b>: {formatDateTime(startDate)}
            </Typography>
            <Typography style={{ fontSize: 'inherit' }}>
              <b>End</b>: {formatDateTime(endDate)}
            </Typography>
            <Typography style={{ fontSize: 'inherit' }}>
              <b>Duration</b>:{' '}
              {formatCountdown(
                calcTimeDelta(endDate, { now: () => startDate })
              )}
            </Typography>
          </>
        }
      >
        <Typography
          color={endDate <= Date.now() ? 'secondary' : 'inherit'}
          style={{ fontSize: 'inherit' }}
        >
          {formatDateTime(endDate)}
        </Typography>
      </Tooltip>
    );
  };

  renderSourceUrl = (row) => {
    return row.sourceUrl ? (
      <Badge badgeContent={row.sourceUrlCount} color="secondary">
        <IconButton
          target="_blank"
          rel="noopener noreferrer"
          href={row.sourceUrl}
          size="large"
        >
          <LinkIcon />
        </IconButton>
      </Badge>
    ) : null;
  };

  renderPublishStatusFilter = (props) => {
    return (
      <SelectFilterField
        items={PUBLISH_STATUS}
        initialValue={this.props.initial.publishStatus}
        onFilterChanged={(value) => {
          props.onFilterChanged(props.columnDef.tableData.id, value);
        }}
        labelKey="name"
      />
    );
  };

  renderCategoryFilter = (props) => {
    const { categories, initial } = this.props;
    return (
      <SelectFilterField
        items={categories}
        initialValue={initial.categoryId}
        onFilterChanged={(value) => {
          props.onFilterChanged(props.columnDef.tableData.id, value);
        }}
        labelKey="name"
      />
    );
  };

  renderClientFilter = (props) => {
    const { clients, initial } = this.props;
    return (
      <SelectFilterField
        items={clients}
        initialValue={initial.clientId}
        onFilterChanged={(value) => {
          props.onFilterChanged(props.columnDef.tableData.id, value);
        }}
        labelKey="companyName"
      />
    );
  };

  render() {
    const { removeAd, push, message, clearAdMessage, initial } = this.props;
    const columns = [
      {
        title: 'Ad',
        field: 'requestOptions.cropped.url',
        render: this.renderAd,
        filtering: false,
        width: 150,
        sorting: false,
      },
      {
        title: 'Published',
        field: 'isPublished',
        type: 'boolean',
        render: this.renderIsPublished,
        filterComponent: this.renderPublishStatusFilter,
        defaultFilter: initial.publishStatus,
        sorting: false,
      },
      {
        title: 'Cut-off At',
        field: 'cutOffTime',
        type: 'datetime',
        editable: 'never',
        filtering: false,
        render: (row) =>
          this.renderStartEndDate(row.publishTime, row.cutOffTime),
        defaultSort:
          initial.orderBy === 'cutOffTime' ? initial.orderDirection : undefined,
      },
      {
        title: 'Client',
        field: 'client.companyName',
        filterComponent: this.renderClientFilter,
        sorting: false,
        defaultFilter: initial.clientId,
      },
      {
        title: 'Category',
        field: 'category.name',
        filterComponent: this.renderCategoryFilter,
        sorting: false,
        defaultFilter: initial.categoryId,
      },
      {
        title: 'Position No.',
        field: 'position',
        type: 'numeric',
        filtering: false,
        // hidden: !initial.categoryId && !initial.clientId,
        defaultSort:
          initial.orderBy === 'position' ? initial.orderDirection : undefined,
      },
      {
        title: 'Original Source/Clicks',
        field: 'sourceUrl',
        render: this.renderSourceUrl,
        filtering: false,
        defaultSort:
          initial.orderBy === 'sourceUrl' ? initial.orderDirection : undefined,
      },
      {
        title: 'Created At',
        field: 'createdAt',
        type: 'datetime',
        editable: 'never',
        filtering: false,
        render: (row) => this.renderDateTime(row.createdAt),
        defaultSort:
          initial.orderBy === 'createdAt' ? initial.orderDirection : undefined,
      },
      {
        title: 'Updated At',
        field: 'updatedAt',
        type: 'datetime',
        editable: 'never',
        filtering: false,
        render: (row) => this.renderDateTime(row.updatedAt),
        defaultSort:
          initial.orderBy === 'updatedAt' ? initial.orderDirection : undefined,
      },
    ];
    return (
      <>
        <MaterialTable
          title="Ads"
          components={{
            Toolbar: this.renderToolbar,
          }}
          columns={columns}
          data={this.getData(columns)}
          onRowClick={(event, row) => this.props.push(`ads/${row.id}`)}
          editable={{
            onRowDelete: ({ id }) => removeAd(id),
          }}
          actions={[
            {
              icon: () => <PlusBox color="secondary" />,
              tooltip: 'Add New Ad',
              isFreeAction: true,
              onClick: () => push('ads/create'),
            },
          ]}
          options={{
            filtering: true,
            initialPage: this.initialPage > 0 ? this.initialPage : 0,
            pageSize: this.props.initial.pageSize,
            searchText: this.props.initial.searchText,
            padding: 'dense',
            emptyRowsWhenPaging: false,
          }}
        />

        <Snackbar
          open={!!message}
          autoHideDuration={7000}
          onClose={clearAdMessage}
        >
          <Alert
            severity="success"
            action={
              <Button size="small" color="secondary" onClick={clearAdMessage}>
                Dismiss
              </Button>
            }
          >
            {message}
          </Alert>
        </Snackbar>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ads: getAds(state),
    categories: selectCategories(state),
    clients: getClients(state),
    initial: state.ads.preferences,
    totalCount: getAdsCount(state),
    cutOffCount: getAdsCutOffCount(state),
    publishedCount: getAdsPublishedCount(state),
    message: getAdsMessage(state),
  };
};

export default compose(
  connect(mapStateToProps, {
    loadAds,
    loadCategories,
    loadClients,
    removeAd,
    updateAd,
    push,
    clearAdMessage,
    unloadCategories,
    unloadClients,
  }),
  withStyles(styles)
)(AdList);
