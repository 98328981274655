export const AD_PRICES_ORDER_BY_OPTIONS = [
  { id: 'createdAt', name: 'Created At' },
  { id: 'position', name: 'Position' },
  { id: 'priceRate', name: 'Price Rate' },
  { id: 'updatedAt', name: 'Updated At' },
];

export const ADS_ORDER_BY_OPTIONS = [
  { id: 'createdAt', name: 'Created At' },
  { id: 'cutOffTime', name: 'Cut-off At' },
  { id: 'position', name: 'Position' },
  { id: 'sourceUrl', name: 'Original Source/Clicks' },
  { id: 'updatedAt', name: 'Updated At' },
];

export const CATEGORIES_ORDER_BY_OPTIONS = [
  { id: 'createdAt', name: 'Created At' },
  { id: 'isPublished', name: 'Published' },
  { id: 'name', name: 'Name' },
  { id: 'ordinalNumber', name: 'No.' },
  { id: 'updatedAt', name: 'Updated At' },
];

export const CLIENTS_ORDER_BY_OPTIONS = [
  { id: 'companyName', name: 'Company' },
  { id: 'createdAt', name: 'Created At' },
  { id: 'email', name: 'Email' },
  { id: 'phone', name: 'Phone' },
  { id: 'updatedAt', name: 'Updated At' },
];

export const IMAGES_ORDER_BY_OPTIONS = [
  { id: 'createdAt', name: 'Created At' },
  { id: 'cutOffTime', name: 'Cut-off At' },
  { id: 'requestOptions[0].square.url', name: 'Image/Likes' },
  { id: 'sourceUrl', name: 'Original Source/Clicks' },
  { id: 'topStoryTo', name: 'TopStory To Category At' },
  { id: 'updatedAt', name: 'Updated At' },
];

export const INVOICES_ORDER_BY_OPTIONS = [
  { id: 'createdAt', name: 'Created At' },
  { id: 'isCompleted', name: 'Completed' },
  { id: 'from', name: 'Date From' },
  { id: 'to', name: 'Date To' },
  { id: 'discount', name: 'Discount' },
  { id: 'no', name: 'No.' },
  { id: 'totalPrice', name: 'Total Price' },
  { id: 'updatedAt', name: 'Updated At' },
];

export const NOTIFICATION_MESSAGES_ORDER_BY_OPTIONS = [
  { id: 'createdAt', name: 'Created At' },
  { id: 'title', name: 'Title' },
  { id: 'body', name: 'Body' },
  { id: 'publishTime', name: 'Pushing Time' },
  { id: 'updatedAt', name: 'Updated At' },
];

export const USERS_ORDER_BY_OPTIONS = [
  { id: 'createdAt', name: 'Created At' },
  { id: 'email', name: 'Email' },
  { id: 'firstName', name: 'First Name' },
  { id: 'lastName', name: 'Last Name' },
  { id: 'platform', name: 'Platform' },
  { id: 'status', name: 'Status' },
  { id: 'role', name: 'Role' },
  { id: 'updatedAt', name: 'Updated At' },
];

export const ORDER_DIRECTION_OPTIONS = [
  { id: 'asc', name: 'Ascending' },
  { id: 'desc', name: 'Descending' },
];

export const NUMBER_OPTIONS = [...Array(300).keys()].slice(1).map((i) => {
  return { id: i, name: i };
});
