import * as actionTypes from './types';
import { endOfMonth, startOfMonth } from 'date-fns';

export const loadAssets =
  (payload) =>
  (dispatch, getState, { mernApi }) => {
    dispatch({ type: actionTypes.LOAD_ASSETS });
    const {
      filters,
      page = 0,
      pageSize = 10,
      orderBy,
      orderDirection,
      search = '',
      nowMs,
      isDeleted,
    } = payload;
    const skip = page * pageSize;
    const sort = orderBy
      ? `${orderDirection === 'desc' ? '-' : ''}${
          orderBy.field === 'requestOptions[0].square.url'
            ? 'likesCount'
            : orderBy.field === 'sourceUrl'
            ? 'sourceUrlCount'
            : orderBy.field
        }`
      : '-createdAt';
    const countParams = { nowMs, isDeleted };
    let shouldCount = true;
    const params = {
      sort,
      limit: pageSize,
      skip,
      isAdmin: true, // FIXME: Remove this when we have separated route for admin
      includeTest: true,
      nowMs,
      isDeleted,
    };
    const preferences = {
      page,
      pageSize,
    };
    if (orderBy?.field) {
      preferences.orderBy = orderBy.field;
    }
    if (orderDirection) {
      preferences.orderDirection = orderDirection;
    }

    filters.forEach((filter) => {
      if (filter.column.field === 'category.name') {
        if (
          getState().categories.entities[filter.value]?.isTopStories ||
          filter.value === '5f458e32a1dc589a1275852d' // FIXME: workaround
        ) {
          params.isTopStory = true;
        } else {
          params.categoryId = filter.value;
        }
        countParams.categoryId = filter.value;
        preferences.categoryId = filter.value;
      }
      if (filter.column.field === 'client.companyName') {
        params.clientId = filter.value;
        countParams.clientId = filter.value;
        preferences.clientId = filter.value;
      }
      if (filter.column.field === 'isPublished') {
        shouldCount = false;
        params.isPublished = filter.value.startsWith('published');
        preferences.publishStatus = filter.value;
        if (filter.value === 'published-active') {
          params.cutOffStatus = 'active';
        } else if (filter.value === 'published-inactive') {
          params.cutOffStatus = 'inactive';
        } else if (filter.value === 'published-expired') {
          params.cutOffStatus = 'expired';
        }
      }
      if (filter.column.field === 'isCompleted') {
        shouldCount = false;
        params.isCompleted = filter.value === 'completed';
        preferences.completionStatus = filter.value;
      }
      if (filter.column.field === 'status') {
        shouldCount = false;
        if (filter.value === 'out-of-date') {
          params.isOutOfDate = true;
        } else if (filter.value === 'up-to-date') {
          params.isOutOfDate = false;
        } else if (filter.value === 'has-video') {
          params.hasVideo = true;
        } else if (filter.value === 'testing-asset') {
          params.testOnly = true;
        } else if (filter.value === 'legacy-asset') {
          params.legacyOnly = true;
        } else if (filter.value === 'duplicated-asset') {
          params.duplicatedOnly = true;
        }
        preferences.status = filter.value;
      }
      if (filter.column.field === 'publishTime') {
        if (filter.value) {
          params.publishTimeStart = startOfMonth(filter.value).getTime();
          params.publishTimeEnd = endOfMonth(filter.value).getTime();
          preferences.publishTime = filter.value;
        }
      }
    });

    let searchText = search.trim();
    if (searchText) {
      params.searchText = searchText;
      preferences.searchText = searchText;
    }
    const promises = [mernApi.get('/api/assets', { params })];
    if (shouldCount) {
      promises.push(
        mernApi.get('/api/assets/count', { params: { ...countParams } })
      );
    }
    return Promise.all(promises).then(
      ([assets, counter]) => {
        if (!counter) {
          counter = {};
        }
        dispatch({
          type: actionTypes.LOAD_ASSETS_SUCCESS,
          payload: {
            ...assets.data,
            preferences,
            counter: { ...counter.data, total: assets.data.assetsCount },
          },
        });
      },
      (err) => {
        dispatch({
          type: actionTypes.LOAD_ASSETS_FAIL,
          payload: err.response.data.error.message,
        });
      }
    );
  };

export const generatePublishingSummary =
  (nowMs) =>
  (dispatch, getState, { mernApi }) => {
    dispatch({ type: actionTypes.GENERATE_ASSET_SUMMARY });
    return mernApi.get('/api/assets/summary', { params: { nowMs } }).then(
      (response) => {
        dispatch({
          type: actionTypes.GENERATE_ASSET_SUMMARY_SUCCESS,
          payload: response.data.summary,
        });
      },
      (err) => {
        dispatch({
          type: actionTypes.GENERATE_ASSET_SUMMARY_FAIL,
          payload: err.response.data.error.message,
        });
      }
    );
  };

export const loadAsset =
  (assetId) =>
  (dispatch, getState, { mernApi }) => {
    dispatch({ type: actionTypes.LOAD_ASSET });
    return mernApi
      .get(`/api/assets/${assetId}`, {
        params: {
          isAdmin: true, // FIXME: remove me
        },
      })
      .then(
        (response) => {
          dispatch({
            type: actionTypes.LOAD_ASSET_SUCCESS,
            payload: response.data.asset,
          });
        },
        (err) => {
          dispatch({
            type: actionTypes.LOAD_ASSET_FAIL,
            payload: err.response.data.error.message,
          });
        }
      );
  };

export const duplicateAsset =
  (assetId) =>
  (dispatch, getState, { mernApi }) => {
    dispatch({ type: actionTypes.DUPLICATE_ASSET });
    return mernApi.post(`/api/assets/${assetId}/duplicate`).then(
      (response) => {
        dispatch({
          type: actionTypes.DUPLICATE_ASSET_SUCCESS,
          payload: response.data.asset,
        });
        return Promise.resolve(response.data.asset);
      },
      (err) => {
        dispatch({
          type: actionTypes.DUPLICATE_ASSET_FAIL,
          payload: err.response.data.error.message,
        });
        return Promise.reject(err);
      }
    );
  };

export const removeAsset =
  (assetId) =>
  (dispatch, getState, { mernApi }) => {
    dispatch({ type: actionTypes.REMOVE_ASSET });
    return mernApi.delete(`/api/assets/${assetId}`).then(
      (response) => {
        dispatch({
          type: actionTypes.REMOVE_ASSET_SUCCESS,
          payload: { ...response.data, deletedId: assetId },
        });
      },
      (err) => {
        dispatch({
          type: actionTypes.REMOVE_ASSET_FAIL,
          payload: err.response.data.error.message,
        });
      }
    );
  };

export const restoreAssets =
  (assetIds) =>
  (dispatch, getState, { mernApi }) => {
    dispatch({ type: actionTypes.RESTORE_ASSETS });
    return mernApi.post(`/api/assets/restore`, { assetIds }).then(
      (response) => {
        dispatch({
          type: actionTypes.RESTORE_ASSETS_SUCCESS,
          payload: { ...response.data },
        });
      },
      (err) => {
        dispatch({
          type: actionTypes.RESTORE_ASSETS_FAIL,
          payload: err.response.data.error.message,
        });
      }
    );
  };

export const removeImage =
  (assetId, imageIndex) =>
  (dispatch, getState, { mernApi }) => {
    dispatch({ type: actionTypes.REMOVE_IMAGE });
    return mernApi
      .delete(`/api/assets/${assetId}/image`, { params: { imageIndex } })
      .then(
        (response) => {
          dispatch({
            type: actionTypes.REMOVE_IMAGE_SUCCESS,
            payload: { ...response.data, imageIndex },
          });
        },
        (err) => {
          dispatch({
            type: actionTypes.REMOVE_IMAGE_FAIL,
            payload: err.response.data.error.message,
          });
        }
      );
  };

export const addAsset =
  (formValues) =>
  (dispatch, getState, { mernApi }) => {
    dispatch({ type: actionTypes.ADD_ASSET });
    return mernApi.post('/api/assets', formValues).then(
      (response) => {
        dispatch({
          type: actionTypes.ADD_ASSET_SUCCESS,
          payload: response.data.asset,
        });
        return Promise.resolve(response.data);
      },
      (err) => {
        dispatch({
          type: actionTypes.ADD_ASSET_FAIL,
          payload: err.response.data.error.message,
        });
        return Promise.reject(err);
      }
    );
  };

export const updateAsset =
  (formValues) =>
  (dispatch, getState, { mernApi }) => {
    dispatch({ type: actionTypes.UPDATE_ASSET });
    return mernApi.put(`/api/assets/${formValues.assetId}`, formValues).then(
      (response) => {
        dispatch({
          type: actionTypes.UPDATE_ASSET_SUCCESS,
          payload: response.data.asset,
        });
        return Promise.resolve(response.data);
      },
      (err) => {
        dispatch({
          type: actionTypes.UPDATE_ASSET_FAIL,
          payload: err.response.data.error.message,
        });
        return Promise.reject(err);
      }
    );
  };

export const updateImageOrders =
  ({ assetId, orders }) =>
  (dispatch, getState, { mernApi }) => {
    dispatch({ type: actionTypes.UPDATE_IMAGE_ORDERS });
    return mernApi.put(`/api/assets/${assetId}/image-orders`, { orders }).then(
      (response) => {
        dispatch({
          type: actionTypes.UPDATE_IMAGE_ORDERS_SUCCESS,
          payload: response.data.asset,
        });
        return Promise.resolve(response.data);
      },
      (err) => {
        dispatch({
          type: actionTypes.UPDATE_IMAGE_ORDERS_FAIL,
          payload: err.response.data.error.message,
        });
        return Promise.reject(err);
      }
    );
  };

export const clearEditingAsset = () => {
  return {
    type: actionTypes.CLEAR_EDITING_ASSET,
  };
};

export const clearAssetErrorMessage = () => {
  return {
    type: actionTypes.CLEAR_ASSET_ERROR_MESSAGE,
  };
};

export const clearAssetMessage = () => {
  return {
    type: actionTypes.CLEAR_ASSET_MESSAGE,
  };
};
