import { format } from 'date-fns';

export const formatDateTime = (date) => {
  return date ? format(new Date(date), 'MMM/dd/yyyy, HH:mm:ss') : '';
};

export const formatPostedDate = (date) => {
  return date ? format(new Date(date), 'MMM dd, yy') : null;
};

export const formatLongPostedDate = (date) => {
  return date ? format(new Date(date), 'MMM dd, yyyy') : null;
};

export const formatFullDateTime = (date) => {
  return date ? format(new Date(date), 'EEE, MMM/dd/yyyy, HH:mm:ss') : null;
};

export const formatDate = (date) => {
  return date ? format(new Date(date), 'MMM/dd/yyyy') : null;
};

export const dateToInvoiceNo = (date) => {
  return date ? format(new Date(date), 'yyyyMMddHHmmss') : null;
};

export const formatFloatNumber = (number) => {
  return number.toFixed(2);
};

export const formatPercent = (number) => {
  return `${number.toFixed(0)} %`;
};
