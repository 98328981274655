import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { selectCategories } from '../../store/selectors';

const useCoverStory = () => {
  const categories = useSelector(selectCategories);

  const coverStory = useMemo(() => {
    return _.find(categories, { name: 'Cover Story' });
  }, [categories]);

  return coverStory;
};

export default useCoverStory;
