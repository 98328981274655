import * as actionTypes from './types';

export const loadAdPrices =
  (payload) =>
  (dispatch, getState, { mernApi }) => {
    dispatch({ type: actionTypes.LOAD_AD_PRICES });
    const {
      filters,
      page = 0,
      pageSize = 10,
      orderBy,
      orderDirection,
      search,
    } = payload;
    const skip = page * pageSize;
    const sort = orderBy
      ? `${orderDirection === 'desc' ? '-' : ''}${orderBy.field}`
      : 'position';
    const params = { sort, limit: pageSize, skip };
    const preferences = {
      page,
      pageSize,
    };
    if (orderBy?.field) {
      preferences.orderBy = orderBy.field;
    }
    if (orderDirection) {
      preferences.orderDirection = orderDirection;
    }
    filters.forEach((filter) => {
      if (filter.column.field === 'category.id') {
        params.categoryId = filter.value;
        preferences.categoryId = filter.value;
      }
    });
    let searchText = search.trim();
    if (searchText) {
      params.searchText = searchText;
      preferences.searchText = searchText;
    }
    return mernApi.get('/api/prices', { params }).then(
      (response) => {
        dispatch({
          type: actionTypes.LOAD_AD_PRICES_SUCCESS,
          payload: {
            ...response.data,
            preferences,
          },
        });
      },
      (err) => {
        dispatch({
          type: actionTypes.LOAD_AD_PRICES_FAIL,
          payload: err.response.data.error.message,
        });
      }
    );
  };

export const addAdPrice =
  (formValues) =>
  (dispatch, getState, { mernApi }) => {
    dispatch({ type: actionTypes.ADD_AD_PRICE });
    return mernApi.post('/api/prices', formValues).then(
      (response) => {
        dispatch({
          type: actionTypes.ADD_AD_PRICE_SUCCESS,
          payload: response.data.adPrice,
        });
        return Promise.resolve(response.data);
      },
      (err) => {
        dispatch({
          type: actionTypes.ADD_AD_PRICE_FAIL,
          payload: err.response.data.error.message,
        });
      }
    );
  };

export const updateAdPrice =
  (formValues) =>
  (dispatch, getState, { mernApi }) => {
    dispatch({ type: actionTypes.UPDATE_AD_PRICE });
    return mernApi.put(`/api/prices/${formValues.id}`, formValues).then(
      (response) => {
        dispatch({
          type: actionTypes.UPDATE_AD_PRICE_SUCCESS,
          payload: response.data.adPrice,
        });
        return Promise.resolve(response.data);
      },
      (err) => {
        dispatch({
          type: actionTypes.UPDATE_AD_PRICE_FAIL,
          payload: err.response.data.error.message,
        });
      }
    );
  };

export const removeAdPrice =
  ({ id: adPriceId }) =>
  (dispatch, getState, { mernApi }) => {
    dispatch({ type: actionTypes.REMOVE_AD_PRICE });
    return mernApi.delete(`/api/prices/${adPriceId}`).then(
      (response) => {
        dispatch({
          type: actionTypes.REMOVE_AD_PRICE_SUCCESS,
          payload: { ...response.data, deletedId: adPriceId },
        });
      },
      (err) => {
        dispatch({
          type: actionTypes.REMOVE_AD_PRICE_FAIL,
          payload: err.response.data.error.message,
        });
      }
    );
  };

export const unloadAdPrices = () => {
  return {
    type: actionTypes.UNLOAD_AD_PRICES,
  };
};

export const clearAdPriceErrorMessage = () => {
  return {
    type: actionTypes.CLEAR_AD_PRICE_ERROR_MESSAGE,
  };
};

export const clearAdPriceMessage = () => {
  return {
    type: actionTypes.CLEAR_AD_PRICE_MESSAGE,
  };
};
