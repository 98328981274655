import * as actionTypes from '../actions/types';
import { getPreferences } from '../../utils';

const INITIAL_STATE = {
  entities: {},
  allIds: [],
  loading: false,
  totalCount: 0,
  preferences: getPreferences('users'),
  editing: {},
  error: null,
  message: null,
};

const usersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.LOAD_USER:
    case actionTypes.LOAD_USERS: {
      return { ...state, loading: true, error: null };
    }

    case actionTypes.LOAD_USERS_SUCCESS: {
      const users = action.payload.users;
      const result = users.reduce(
        (result, user) => {
          return {
            entities: {
              ...result.entities,
              [user.id]: user,
            },
            allIds: [...result.allIds, user.id],
          };
        },
        { entities: {}, allIds: [] }
      );

      return {
        ...state,
        loading: false,
        entities: result.entities,
        allIds: result.allIds,
        totalCount: action.payload.usersCount,
        preferences: {
          ...getPreferences('users'), // reset filters
          ...action.payload.preferences,
        },
      };
    }

    case actionTypes.LOAD_USER_SUCCESS: {
      return {
        ...state,
        editing: action.payload,
      };
    }

    case actionTypes.CLEAR_EDITING_USER: {
      return {
        ...state,
        editing: {},
      };
    }

    case actionTypes.LOAD_USER_FAIL:
    case actionTypes.LOAD_USERS_FAIL: {
      return { ...state, loading: false };
    }

    case actionTypes.UPDATE_USER:
    case actionTypes.REMOVE_USER: {
      return { ...state, error: null, message: null };
    }

    case actionTypes.UPDATE_USER_FAIL:
    case actionTypes.REMOVE_USER_FAIL: {
      return { ...state, error: action.payload };
    }

    case actionTypes.UPDATE_USER_SUCCESS: {
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.payload.id]: action.payload,
        },
        message: 'A user has been updated',
      };
    }

    case actionTypes.REMOVE_USER_SUCCESS: {
      const deletedId = action.payload.deletedId;
      const { [deletedId]: removed, ...users } = state.entities;
      return {
        ...state,
        allIds: state.allIds.filter((id) => id !== deletedId),
        entities: {
          ...users,
        },
        message: 'A user has been removed',
      };
    }

    case actionTypes.CLEAR_USER_MESSAGE: {
      return { ...state, message: null };
    }

    case actionTypes.CLEAR_USER_ERROR_MESSAGE: {
      return { ...state, error: null };
    }

    case actionTypes.UPDATE_PREFERENCES: {
      const { key, subKey, value } = action.payload;
      if (key === 'users') {
        return {
          ...state,
          preferences: { ...state.preferences, [subKey]: value },
        };
      }
      return state;
    }

    case actionTypes.RESET_PREFERENCES: {
      return { ...state, preferences: { ...action.payload.users } };
    }

    default:
      return state;
  }
};

export default usersReducer;
