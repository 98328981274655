import React, { useCallback, useEffect, useState } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Button,
  Card,
  Collapse,
  Grid,
  IconButton,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import AwsS3 from '@uppy/aws-s3';
import Uppy from '@uppy/core';
import { DashboardModal } from '@uppy/react';
import { saveAs } from 'file-saver';
import localforage from 'localforage';
import _ from 'lodash';
import {
  ChevronDown as ChevronDownIcon,
  Image as ImageIcon,
} from 'mdi-material-ui';
import { useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import CropperDialog from '../../../components/CropperDialog';
import Previewer from '../../../components/Previewer';
import ControllerCheckbox from '../../../components/react-hook-form-wrappers/controller-checkbox';
import ControllerTextField from '../../../components/react-hook-form-wrappers/controller-text-field';
import YesNoDialog from '../../../components/yes-no-dialog';
import { removeS3Objects } from '../../../store/actions';
import { getAssetAwsS3UploadKit } from '../../../store/apis/helpers';
import { IMAGE_MODE_OPTIONS, TEXT_STYLE } from '../constants';
import ReplicateTextField from './replicate-text-field';
import StyledTextField from './styled-text-field';

require('@uppy/core/dist/style.css');
require('@uppy/dashboard/dist/style.css');

const createUppy = ({ removeS3ObjectsAction, getValues, setValue, index }) => {
  return new Uppy({
    restrictions: {
      maxNumberOfFiles: 1,
      minNumberOfFiles: 1,
      allowedFileTypes: ['image/png', 'image/jpeg', 'image/jpg'],
    },
  })
    .use(AwsS3, {
      getUploadParameters(file) {
        return getAssetAwsS3UploadKit({
          fileName: `${file.name}.${file.extension}`,
        }).then(({ awsS3UploadKit }) => {
          // temporarily store the new awsS3UploadKit in the form
          // so that we can use it later
          setValue(`sections[${index}].temp.awsS3UploadKit`, awsS3UploadKit);
          return {
            method: 'PUT',
            url: awsS3UploadKit.original.default.putOptions.url,
          };
        });
      },
    })
    .on('complete', (result) => {
      if (result.successful.length === 1) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setValue(`sections[${index}].temp.localOriginal`, {
            url: reader.result,
            type: result.successful[0].type,
          });
        };
        reader.readAsDataURL(result.successful[0].data);
      } else if (result.failed.length > 0) {
        setValue(`sections[${index}].temp`, undefined);
      }
    })
    .on('file-removed', (file, reason) => {
      if (reason === 'removed-by-user' && file.progress.uploadComplete) {
        const newAwsS3UploadKit = getValues(
          `sections[${index}].temp.awsS3UploadKit`
        );
        setValue(`sections[${index}].temp`, undefined);
        if (!_.isEmpty(newAwsS3UploadKit)) {
          removeS3ObjectsAction({
            objectsToRemove: [
              {
                deleteOptions: newAwsS3UploadKit.original.default.deleteOptions,
              },
            ],
            type: 'asset',
          });
        }
      }
    });
};

const renderOptions = (options) => {
  return options.map((option) => {
    return (
      <MenuItem key={option.id} value={option.id}>
        {option.name}
      </MenuItem>
    );
  });
};

const VideoDetailFields = ({
  control,
  index,
  watchField,
  getValues,
  setValue,
  resetField,
  setError,
  clearErrors,
  disabled,
}) => {
  const hasVideo = useWatch({ control, name: watchField });

  if (!hasVideo) {
    return null;
  }

  const onYouTubeUrlBlur = (e) => {
    const url = e.target.value.trim();
    if (!url) {
      resetField(`sections[${index}].videoIds`);
      return;
    }
    try {
      const youtubeUrl = new URL(url);
      const videoIds = youtubeUrl.host.includes('youtu.be')
        ? youtubeUrl.pathname.split('/')[1]
        : youtubeUrl.searchParams.get('v');
      setValue(`sections[${index}].videoIds`, videoIds);
    } catch (e) {
      setError(`sections[${index}].youTubeUrl`, {
        type: 'custom',
        message: 'Invalid YouTube URL',
      });
    }
  };

  const getThumbnail = () => {
    const videoId = getValues(`sections[${index}].videoIds`);
    if (!videoId) {
      setError(`sections[${index}].youTubeUrl`, {
        type: 'custom',
        message: 'Please enter a valid YouTube URL',
      });
      return;
    }
    const thumbnailsUrl = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
    saveAs(thumbnailsUrl, `${videoId}.jpg`);
  };

  return (
    <>
      <Grid item md={8} xs={10}>
        <ControllerTextField
          control={control}
          label={`YouTube URL ${index + 1}`}
          name={`sections[${index}].youTubeUrl`}
          placeholder="Enter YouTube URL"
          showCopyButton
          onBlur={onYouTubeUrlBlur}
          autoComplete="off"
          clearErrors={clearErrors}
          disabled={disabled}
        />
      </Grid>
      <Grid item md={1} xs={2}>
        <Tooltip title="YouTube Thumbnail">
          <span>
            <IconButton onClick={getThumbnail} size="large" disabled={disabled}>
              <ImageIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Grid>
      <Grid item md={6} xs={12}>
        <ControllerTextField
          control={control}
          label={`Video ID ${index + 1}`}
          name={`sections[${index}].videoIds`}
          placeholder="Enter video ID"
          showCopyButton
          clearErrors={clearErrors}
          disabled
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <ControllerTextField
          control={control}
          label={`Video Credit ${index + 1}`}
          name={`sections[${index}].videoCredit`}
          placeholder="Enter video credit"
          showCopyButton
          countWords
          disabled={disabled}
        />
      </Grid>
    </>
  );
};

const WatermarkImageCropperDialog = ({
  control,
  watchCopyrightField,
  watchCreditField,
  ...otherProps
}) => {
  const copyright = useWatch({ control, name: watchCopyrightField });
  const credit = useWatch({ control, name: watchCreditField });

  return (
    <CropperDialog
      watermarkCopyright={copyright}
      watermarkCredit={credit}
      {...otherProps}
    />
  );
};

const SectionFields = ({
  control,
  isEditing,
  getValues,
  setValue,
  resetField,
  setError,
  clearErrors,
  onDeleteSectionConfirmed,
  index,
  showOriginalImage,
  openUploaderOnInitialRender,
  disabled,
}) => {
  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);
  const dispatch = useDispatch();

  const removeS3ObjectsAction = useCallback(
    ({ objectsToRemove, type }) => {
      return dispatch(removeS3Objects({ objectsToRemove, type }));
    },
    [dispatch]
  );

  const [uppy] = useState(() =>
    createUppy({
      getValues,
      setValue,
      removeS3ObjectsAction,
      index,
    })
  );
  const [openUploader, setOpenUploader] = useState(false);

  const initialRequestOptions = useWatch({
    control,
    name: `sections[${index}].initialRequestOptions`,
  });

  const awsS3UploadKit = useWatch({
    control,
    name: `sections[${index}].awsS3UploadKit`,
  });

  const localOriginalUrl = useWatch({
    control,
    name: `sections[${index}].localOriginalUrl`,
  });

  const croppedImageUrl = useWatch({
    control,
    name: `sections[${index}].croppedImageUrl`,
  });

  const expanded = useWatch({
    control,
    name: `sections[${index}].expanded`,
    defaultValue: true,
  });

  const originalFileName = useWatch({
    control,
    name: `sections[${index}].originalFileName`,
  });

  useEffect(() => {
    if (openUploaderOnInitialRender) {
      setOpenUploader(true);
    }

    return () => {
      if (uppy) {
        uppy.close();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addCroppedImagesToUploadedImages = ({ mode, type }) => {
    const uploadedImages = getValues(`sections[${index}].uploadedImages`);
    let croppedImageUrl = getValues(`sections[${index}].croppedImageUrl`);

    if (_.isNil(croppedImageUrl)) {
      croppedImageUrl = {};
    }

    croppedImageUrl[mode] = awsS3UploadKit[mode].default.getOptions.url;

    // remove existing images with same mode
    const images = _.filter(uploadedImages, (image) => image.mode !== mode);

    // add new images
    Object.keys(awsS3UploadKit[mode]).forEach((key) => {
      images.push({
        mode,
        key,
        deleteOptions: awsS3UploadKit[mode][key].deleteOptions,
        name: awsS3UploadKit[mode][key].fileName,
        type,
      });
    });

    setValue(`sections[${index}].croppedImageUrl`, croppedImageUrl);

    setValue(`sections[${index}].uploadedImages`, images, {
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  const handleDashboardModalClose = () => {
    const temp = getValues(`sections[${index}].temp`);
    if (temp && temp.localOriginal && temp.awsS3UploadKit) {
      // remove existing uploaded images
      const uploadedImages = getValues(`sections[${index}].uploadedImages`);
      if (!_.isEmpty(uploadedImages)) {
        removeS3ObjectsAction({
          objectsToRemove: [...uploadedImages],
          type: 'asset',
        });
      }

      setValue(
        `sections[${index}].uploadedImages`,
        [
          {
            mode: 'original',
            key: 'default',
            name: temp.awsS3UploadKit.original.default.fileName,
            type: temp.localOriginal.type,
            deleteOptions: temp.awsS3UploadKit.original.default.deleteOptions,
          },
        ],
        { shouldDirty: true, shouldTouch: true }
      );
      setValue(
        `sections[${index}].originalFileName`,
        temp.awsS3UploadKit.original.default.fileName,
        { shouldDirty: true, shouldTouch: true }
      );
      setValue(`sections[${index}].awsS3UploadKit`, temp.awsS3UploadKit);
      setValue(`sections[${index}].localOriginalUrl`, temp.localOriginal.url);
      setValue(`sections[${index}].croppedImageUrl`, {});

      // Store original image in localforage which will be used in CropperDialog
      localforage.setItem(temp.awsS3UploadKit.original.default.fileName, {
        imageSrc: temp.localOriginal.url,
        __timestamp: Date.now(),
      });
    }

    setValue(`sections[${index}].temp`, undefined);
    setOpenUploader(false);
  };

  const handleAccordionChange = (event, isExpanded) => {
    setValue(`sections[${index}].expanded`, isExpanded);
  };

  const originalUrl =
    localOriginalUrl || awsS3UploadKit?.original?.default?.getOptions?.url;

  const originalPreviewUrl =
    localOriginalUrl || initialRequestOptions?.original?.url;

  const accordionSummaryUrl =
    croppedImageUrl?.square || initialRequestOptions?.square?.url;

  return (
    <Grid
      container
      direction={{ xs: 'row', md: 'row-reverse' }}
      justifyContent="space-around"
      style={{ paddingBottom: 24 }}
    >
      {showOriginalImage && (
        <Grid
          container
          item
          xs={12}
          md={4}
          direction="row"
          justifyContent="center"
          style={{ marginBottom: 24 }}
        >
          <Grid item xs={12}>
            {showOriginalImage && (
              <Collapse in={expanded}>
                <Previewer
                  imageUrl={originalPreviewUrl}
                  widthThreshold={2160}
                  heightThreshold={2780}
                  tooltip={`Original image ${index + 1}`}
                />
              </Collapse>
            )}
          </Grid>
        </Grid>
      )}
      <Grid container component={Card} item xs={12} md={7}>
        <Accordion expanded={expanded} onChange={handleAccordionChange}>
          <AccordionSummary expandIcon={<ChevronDownIcon />}>
            <Grid container alignItems="center" paddingLeft={1}>
              <Collapse orientation="horizontal" in={!expanded}>
                <Avatar
                  variant="square"
                  src={accordionSummaryUrl}
                  style={{ marginRight: 16 }}
                >
                  <ImageIcon />
                </Avatar>
              </Collapse>
              <Typography>{`Section ${index + 1}`}</Typography>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container item gap={3} padding={1}>
              <Grid container item spacing={3}>
                <Grid item md={8} xs={12}>
                  <ControllerTextField
                    control={control}
                    label={`File Name ${index + 1}`}
                    name={`sections[${index}].originalFileName`}
                    disabled
                  />
                </Grid>
                <Grid item md={2} xs={6}>
                  <Button
                    color="primary"
                    type="button"
                    variant="contained"
                    fullWidth
                    onClick={() => setOpenUploader(true)}
                    disabled={disabled}
                  >
                    Open Uploader
                  </Button>
                </Grid>
                {index !== 0 && (
                  <Grid item md={2} xs={6}>
                    <Button
                      color="secondary"
                      type="button"
                      variant="contained"
                      fullWidth
                      onClick={() => {
                        setShowDeleteConfirmDialog(true);
                      }}
                      disabled={disabled}
                    >
                      Delete
                    </Button>
                  </Grid>
                )}
                <DashboardModal
                  uppy={uppy}
                  showLinkToFileUploadResult={false}
                  showRemoveButtonAfterComplete
                  proudlyDisplayPoweredByUppy={false}
                  note="Please choose an image (*.jpg, *.jpeg, *.png)"
                  style={{ zIndex: 99999 }}
                  open={openUploader}
                  onRequestClose={handleDashboardModalClose}
                />
              </Grid>
              <Grid
                container
                item
                spacing={3}
                style={{ marginTop: 0, marginBottom: 16 }}
              >
                <Grid item xs={12} sm={2}>
                  <CropperDialog
                    aspectRatio={16 / 10}
                    currentImage={initialRequestOptions?.landscape}
                    generatePreview={{ maxWidth: 100, quality: 0.1 }}
                    isSrcNewUpload={!!localOriginalUrl || !isEditing}
                    onCropSuccess={(preview) => {
                      addCroppedImagesToUploadedImages({
                        mode: 'landscape',
                        type: 'image/jpeg',
                      });
                      if (preview) {
                        setValue(`sections[${index}].imagePreview`, preview);
                      }
                    }}
                    awsS3UploadKit={awsS3UploadKit?.landscape}
                    originalFileName={originalFileName}
                    src={originalUrl}
                    croppedImageUrl={croppedImageUrl?.landscape}
                    title="Crop landscape image"
                    tooltip={`Landscape ${index + 1}`}
                    widthThreshold={2160}
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <CropperDialog
                    aspectRatio={7 / 9}
                    currentImage={initialRequestOptions?.portrait}
                    isSrcNewUpload={!!localOriginalUrl || !isEditing}
                    maxWidth={2160}
                    onCropSuccess={() => {
                      addCroppedImagesToUploadedImages({
                        mode: 'portrait',
                        type: 'image/jpeg',
                      });
                    }}
                    awsS3UploadKit={awsS3UploadKit?.portrait}
                    originalFileName={originalFileName}
                    src={originalUrl}
                    croppedImageUrl={croppedImageUrl?.portrait}
                    title="Crop portrait image"
                    tooltip={`Portrait ${index + 1}`}
                    widthThreshold={2160}
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <CropperDialog
                    aspectRatio={1}
                    currentImage={initialRequestOptions?.square}
                    isSrcNewUpload={!!localOriginalUrl || !isEditing}
                    onCropSuccess={() => {
                      addCroppedImagesToUploadedImages({
                        mode: 'square',
                        type: 'image/jpeg',
                      });
                    }}
                    awsS3UploadKit={awsS3UploadKit?.square}
                    screenRatio={1 / 3}
                    originalFileName={originalFileName}
                    src={originalUrl}
                    croppedImageUrl={croppedImageUrl?.square}
                    title="Crop image for Favourite screen"
                    tooltip={`Favourite screen ${index + 1}`}
                    widthThreshold={720}
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <CropperDialog
                    autoCropArea={0.998}
                    currentImage={initialRequestOptions?.full}
                    isSrcNewUpload={!!localOriginalUrl || !isEditing}
                    maxWidth={3600}
                    onCropSuccess={() => {
                      addCroppedImagesToUploadedImages({
                        mode: 'full',
                        type: 'image/jpeg',
                      });
                    }}
                    awsS3UploadKit={awsS3UploadKit?.full}
                    originalFileName={originalFileName}
                    src={originalUrl}
                    croppedImageUrl={croppedImageUrl?.full}
                    title="Crop image for Fullscreen"
                    tooltip={`Fullscreen ${index + 1}`}
                    widthThreshold={2160}
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <WatermarkImageCropperDialog
                    control={control}
                    aspectRatio={16 / 10}
                    currentImage={initialRequestOptions?.watermark}
                    isSrcNewUpload={!!localOriginalUrl || !isEditing}
                    onCropSuccess={() => {
                      addCroppedImagesToUploadedImages({
                        mode: 'watermark',
                        type: 'image/jpeg',
                      });
                    }}
                    awsS3UploadKit={awsS3UploadKit?.watermark}
                    originalFileName={originalFileName}
                    src={originalUrl}
                    croppedImageUrl={croppedImageUrl?.watermark}
                    title="Crop and watermark"
                    tooltip={`Watermark image ${index + 1}`}
                    watermark
                    watchCopyrightField={`sections[${index}].imageCopyright`}
                    watchCreditField={`sections[${index}].imageCredit`}
                    disabled={disabled}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <ControllerTextField
                  select
                  control={control}
                  label={`Image Mode ${index + 1}`}
                  name={`sections[${index}].imageMode`}
                  placeholder="Choose an image mode"
                  disabled={disabled}
                >
                  {renderOptions(IMAGE_MODE_OPTIONS)}
                </ControllerTextField>
              </Grid>
              <Grid container item xs={12} spacing={3}>
                <Grid item md={3} xs={12}>
                  <ControllerCheckbox
                    control={control}
                    name={`sections[${index}].hasVideo`}
                    label="Use Video"
                    disabled={disabled}
                  />
                </Grid>
                <VideoDetailFields
                  control={control}
                  index={index}
                  watchField={`sections[${index}].hasVideo`}
                  getValues={getValues}
                  setValue={setValue}
                  resetField={resetField}
                  setError={setError}
                  clearErrors={clearErrors}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12}>
                <ControllerTextField
                  control={control}
                  label={`Caption ${index + 1}`}
                  name={`sections[${index}].caption`}
                  placeholder="Enter caption"
                  multiline
                  countWords
                  showCopyButton
                  required={false}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12}>
                <ControllerTextField
                  control={control}
                  label={`Image Credit ${index + 1}`}
                  name={`sections[${index}].imageCredit`}
                  placeholder="Enter image credit"
                  countWords
                  showCopyButton
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12}>
                <ReplicateTextField
                  control={control}
                  setValue={setValue}
                  sameAsField={`sections[${index}].caption`}
                  label={`Image Alternative Text ${index + 1}`}
                  name={`sections[${index}].imageAltText`}
                  placeholder="Enter image alternative text"
                  showCopyButton
                  disabled
                  required={false}
                />
              </Grid>
              <Grid item xs={12}>
                <StyledTextField
                  control={control}
                  label={`Title ${index + 1}`}
                  name={`sections[${index}].titleOne`}
                  watchStyleField={`sections[${index}].titleOneStyle`}
                  placeholder="Enter title"
                  multiline
                  countWords
                  showCopyButton
                  required={index === 0}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12}>
                <ControllerTextField
                  select
                  control={control}
                  label={`Title Style ${index + 1}`}
                  name={`sections[${index}].titleOneStyle`}
                  placeholder="Choose a style for title"
                  required={index === 0}
                  disabled={disabled}
                >
                  {renderOptions(TEXT_STYLE)}
                </ControllerTextField>
              </Grid>
              <Grid item xs={12}>
                <ControllerTextField
                  control={control}
                  label={`Description ${index + 1}`}
                  name={`sections[${index}].descriptionOne`}
                  placeholder="Enter description"
                  multiline
                  countWords
                  showCopyButton
                  required={index === 0}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12}>
                <StyledTextField
                  control={control}
                  label={`Additional Title ${index + 1}`}
                  name={`sections[${index}].titleTwo`}
                  watchStyleField={`sections[${index}].titleTwoStyle`}
                  placeholder="Enter additional title"
                  multiline
                  countWords
                  showCopyButton
                  required={false}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12}>
                <ControllerTextField
                  select
                  control={control}
                  label={`Additional Title Style ${index + 1}`}
                  name={`sections[${index}].titleTwoStyle`}
                  placeholder="Choose a style for additional title"
                  required={false}
                  disabled={disabled}
                >
                  {renderOptions(TEXT_STYLE)}
                </ControllerTextField>
              </Grid>
              <Grid item xs={12}>
                <ControllerTextField
                  control={control}
                  label={`Additional Description ${index + 1}`}
                  name={`sections[${index}].descriptionTwo`}
                  placeholder="Enter additional description"
                  multiline
                  countWords
                  showCopyButton
                  required={false}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12}>
                <ControllerTextField
                  control={control}
                  label={`Citation ${index + 1}`}
                  name={`sections[${index}].citation`}
                  placeholder="Enter citation"
                  multiline
                  countWords
                  showCopyButton
                  required={false}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12}>
                <ControllerTextField
                  control={control}
                  label={`Source URL ${index + 1}`}
                  name={`sections[${index}].sourceUrl`}
                  placeholder="Enter source URL"
                  showCopyButton
                  required={false}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12}>
                <ControllerTextField
                  control={control}
                  label={`Image Copyright ${index + 1}`}
                  name={`sections[${index}].imageCopyright`}
                  placeholder="Enter image copyright"
                  countWords
                  showCopyButton
                  required={false}
                  disabled={disabled}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      {showDeleteConfirmDialog && (
        <YesNoDialog
          open={showDeleteConfirmDialog}
          title="Confirm"
          message="Are you sure you want to delete this section. This cannot be undone!"
          primaryButtonText="No"
          onPrimaryButtonClick={() => {
            setShowDeleteConfirmDialog(false);
          }}
          secondaryButtonText="Yes "
          onSecondaryButtonClick={() => {
            onDeleteSectionConfirmed(index);
          }}
        />
      )}
    </Grid>
  );
};

export default SectionFields;
