import * as actionTypes from '../actions/types';

const INITIAL_STATE = {
  loading: false,
  dataUrl: '',
};

const base64Reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.LOAD_BASE64: {
      return {
        ...state,
        loading: true,
      };
    }

    case actionTypes.LOAD_BASE64_SUCCESS: {
      return {
        ...state,
        loading: false,
        dataUrl: action.payload.dataUrl,
      };
    }

    case actionTypes.LOAD_BASE64_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case actionTypes.UNLOAD_BASE64: {
      return { loading: false, dataUrl: '' };
    }

    default: {
      return state;
    }
  }
};

export default base64Reducer;
