import { createSelector } from '@reduxjs/toolkit';

export const getAdPricesState = (state) => state.adPrices;

export const getAdPricesAllIds = (state) => state.adPrices.allIds;

export const getAdPricesEntities = (state) => state.adPrices.entities;

export const getAdPricesErrorMessage = (state) => state.adPrices.error;

export const getAdPricesMessage = (state) => state.adPrices.message;

export const getAdPricesCount = (state) => state.adPrices.totalCount;

export const getAdPrices = createSelector(
  getAdPricesEntities,
  getAdPricesAllIds,
  (entities, allIds) => allIds.map((id) => entities[id])
);
