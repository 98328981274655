import React, { useEffect, useMemo, useState } from 'react';
import {
  Alert,
  IconButton,
  InputAdornment,
  Snackbar,
  TextField,
  Tooltip,
} from '@mui/material';
import { ContentCopy as ContentCopyIcon } from 'mdi-material-ui';
import { Controller, useWatch } from 'react-hook-form';
import copy from 'copy-to-clipboard';
import _ from 'lodash';

const ControllerTextField = ({
  control,
  countWords,
  label,
  maxWordCount = 0,
  name,
  onBlur,
  onFocus,
  rules,
  InputProps,
  inputClassName,
  // if showCopyButton true and the field value is not empty,
  // show a copy button on the right side of the input
  showCopyButton = false,
  clearErrors,
  ...otherProps
}) => {
  const [isFocus, setIsFocus] = useState(false);
  const [message, setMessage] = useState(null);
  const [wordCount, setWordCount] = useState(0);

  const value = useWatch({ control, name });

  useEffect(() => {
    let timer;
    if (countWords && isFocus) {
      timer = setTimeout(() => {
        const str = _.trim(value);
        const count = _.isEmpty(str) ? 0 : _.split(str, / +|\n+/).length;
        setWordCount(count);
      }, 400);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [countWords, isFocus, value]);

  useEffect(() => {
    if (clearErrors) {
      // clear custom error message
      clearErrors(name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const labelToUse = useMemo(() => {
    if (countWords && isFocus) {
      const wording = wordCount > 1 ? 'words' : 'word';
      return `${label} - ${wordCount}${
        maxWordCount > 0 ? `/${maxWordCount}` : ''
      } ${wording}`;
    }

    return label;
  }, [label, wordCount, maxWordCount, countWords, isFocus]);

  const handleOnBlur = (e) => {
    setIsFocus(false);
    if (onBlur) {
      onBlur(e);
    }
  };

  const handleOnFocus = (e) => {
    setIsFocus(true);
    if (onFocus) {
      onFocus(e);
    }
  };

  const handleCopyClick = () => {
    copy(value);

    setMessage(`${label} has been copied to the clipboard`);
  };

  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { ref, ...otherFields }, fieldState: { error } }) => {
          return (
            <TextField
              {...otherFields}
              label={labelToUse}
              inputRef={ref}
              error={
                !!error ||
                (countWords && maxWordCount > 0 && wordCount > maxWordCount)
              }
              helperText={error?.message}
              variant="outlined"
              margin="none"
              required
              fullWidth
              onBlur={handleOnBlur}
              onFocus={handleOnFocus}
              InputProps={{
                endAdornment:
                  showCopyButton && !!value ? (
                    <InputAdornment position="end">
                      <Tooltip title={`Copy ${label}`}>
                        <IconButton
                          onClick={handleCopyClick}
                          tabIndex={-1}
                          size="large"
                        >
                          <ContentCopyIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ) : null,
                className: inputClassName,
                ...InputProps,
              }}
              {...otherProps}
            />
          );
        }}
      />
      <Snackbar
        autoHideDuration={3000}
        open={!!message}
        onClose={() => {
          setMessage(null);
        }}
      >
        <Alert severity="success">{message}</Alert>
      </Snackbar>
    </>
  );
};

export default ControllerTextField;
