import React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

const Copyright = () => {
  return (
    <div
      style={{
        marginTop: 48,
        marginBottom: 16,
      }}
    >
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        {new Date().getFullYear()}{' '}
        <Link underline="none" color="inherit" href="https://worldinbeauty.com">
          World in Beauty<sup style={{ fontSize: 7 }}>TM</sup>
        </Link>
      </Typography>
      <Typography variant="body2" color="textSecondary" align="center">
        Developed by t-ho
      </Typography>
      {/* <Typography variant="body2" color="textSecondary" align="center">
        {'Developed by '}
        <Link underline="none" color="inherit" href="https://github.com/t-ho">
          t-ho
        </Link>
      </Typography> */}
    </div>
  );
};

export default Copyright;
