import * as actionTypes from '../actions/types';
import { getPreferences } from '../../utils';

const INITIAL_STATE = {
  entities: {},
  allIds: [],
  preferences: getPreferences('adPrices'),
  loading: false,
  totalCount: 0,
  error: null,
  message: null,
};

const adPricesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.LOAD_AD_PRICES: {
      return { ...state, loading: true, error: null };
    }

    case actionTypes.LOAD_AD_PRICES_SUCCESS: {
      const adPrices = action.payload.adPrices;
      const result = adPrices.reduce(
        (result, adPrice) => {
          return {
            entities: {
              ...result.entities,
              [adPrice.id]: adPrice,
            },
            allIds: [...result.allIds, adPrice.id],
          };
        },
        { entities: {}, allIds: [] }
      );

      return {
        ...state,
        loading: false,
        entities: result.entities,
        allIds: result.allIds,
        preferences: {
          ...getPreferences('adPrices'), // reset filters
          ...action.payload.preferences,
        },
        totalCount: action.payload.adPricesCount,
      };
    }

    case actionTypes.UNLOAD_AD_PRICES: {
      return {
        ...INITIAL_STATE,
      };
    }

    case actionTypes.LOAD_AD_PRICES_FAIL: {
      return { ...state, loading: false };
    }

    case actionTypes.ADD_AD_PRICE:
    case actionTypes.UPDATE_AD_PRICE:
    case actionTypes.REMOVE_AD_PRICE: {
      return { ...state, error: null, message: null };
    }

    case actionTypes.ADD_AD_PRICE_SUCCESS: {
      return {
        ...state,
        allIds: [...state.allIds, action.payload.id],
        entities: {
          ...state.entities,
          [action.payload.id]: action.payload,
        },
        message: 'A new adPrice has been added',
      };
    }

    case actionTypes.ADD_AD_PRICE_FAIL:
    case actionTypes.UPDATE_AD_PRICE_FAIL:
    case actionTypes.REMOVE_AD_PRICE_FAIL: {
      return { ...state, error: action.payload };
    }

    case actionTypes.UPDATE_AD_PRICE_SUCCESS: {
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.payload.id]: action.payload,
        },
        message: 'A adPrice has been updated',
      };
    }

    case actionTypes.REMOVE_AD_PRICE_SUCCESS: {
      const deletedId = action.payload.deletedId;
      const { [deletedId]: removed, ...adPrices } = state.entities;
      return {
        ...state,
        allIds: state.allIds.filter((id) => id !== deletedId),
        entities: {
          ...adPrices,
        },
        message: 'A adPrice has been removed',
      };
    }

    case actionTypes.CLEAR_AD_PRICE_MESSAGE: {
      return { ...state, message: null };
    }

    case actionTypes.CLEAR_AD_PRICE_ERROR_MESSAGE: {
      return { ...state, error: null };
    }

    case actionTypes.UPDATE_PREFERENCES: {
      const { key, subKey, value } = action.payload;
      if (key === 'adPrices') {
        return {
          ...state,
          preferences: { ...state.preferences, [subKey]: value },
        };
      }
      return state;
    }

    case actionTypes.RESET_PREFERENCES: {
      return { ...state, preferences: { ...action.payload.adPrices } };
    }

    default:
      return state;
  }
};

export default adPricesReducer;
