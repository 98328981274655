import React from 'react';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Snackbar from '@mui/material/Snackbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import { CheckAll, ClockCheckOutline, PlusBox } from 'mdi-material-ui';
import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';
import {
  getNotificationMessages,
  getNotificationMessagesCount,
  getNotificationMessagesMessage,
} from '../../store/selectors';
import {
  loadNotificationMessages,
  removeNotificationMessage,
  updateNotificationMessage,
  clearNotificationMessageMessage,
} from '../../store/actions';
import MaterialTable from '../../components/MaterialTable';
import SelectFilterField from '../../components/SelectFilterField';
import { MTableToolbar } from '@material-table/core';
import { formatDateTime, formatFullDateTime } from '../../utils';
import { PUBLISH_STATUS } from './constants';

const styles = (theme) => ({
  ad: {
    width: 240,
    height: 80,
  },
  toolbarInfo: {
    padding: `0px ${theme.spacing(2)}`,
  },
  toolbarChip: {
    marginRight: theme.spacing(1),
  },
  isPublished: {
    marginLeft: theme.spacing(1),
  },
  tooltipText: {
    fontSize: 'inherit',
  },
  primaryImage: {
    marginTop: 4,
    borderRadius: 4,
    width: 32,
    height: 32,
  },
});

class NotificationMessageList extends React.Component {
  initialPage = -1;

  componentDidMount() {
    this.initialPage = this.props.initial.page;
  }

  getData = (columns) => (query) =>
    new Promise((resolve) => {
      if (this.initialPage > 0) {
        query.page = this.initialPage;
        this.initialPage = -1;
      }
      // orderby and orderDirection are missing from the initial query
      const orderBy = columns[query.orderByCollection[0].orderBy];
      const orderDirection = query.orderByCollection[0].orderDirection;
      this.props
        .loadNotificationMessages({ ...query, orderBy, orderDirection })
        .then(() => {
          if (
            (this.props.notificationMessages.length === 0) &
            (query.page > 0)
          ) {
            query.page = query.page - 1;
            return this.props.loadNotificationMessages(query);
          }
          return null;
        })
        .then(() => {
          resolve({
            data: this.props.notificationMessages,
            page: query.page,
            totalCount: this.props.totalCount,
          });
        });
    });

  renderToolbar = (props) => {
    const { classes, totalCount = 0 } = this.props;

    return (
      <div>
        <MTableToolbar {...props} />
        <div className={classes.toolbarInfo}>
          <Chip
            label={`Total: ${totalCount}`}
            color="secondary"
            className={classes.toolbarChip}
          />
        </div>
      </div>
    );
  };

  renderAsset = ({ asset }) => {
    const { classes } = this.props;
    return asset ? (
      <Tooltip
        style={{ fontSize: 'inherit' }}
        title={
          <>
            <Typography className={classes.tooltipText}>
              <b>Asset ID</b>: {asset.id}
            </Typography>
            {!!asset.shortTitle && (
              <Typography className={classes.tooltipText}>
                <b>Short Title</b>: {asset.shortTitle}
              </Typography>
            )}
            {!!asset.promotionalText && asset.promoteInTopStories && (
              <Typography className={classes.tooltipText}>
                <b>Promotional Text</b>: {asset.promotionalText}
              </Typography>
            )}
            <Typography className={classes.tooltipText}>
              <b>Title 1</b>: {asset.sections[0].titleOne}
            </Typography>
            <Typography className={classes.tooltipText}>
              <b>Description 1</b>: {asset.sections[0].descriptionOne}
            </Typography>
            <Typography className={classes.tooltipText}>
              <b>Tags</b>: {asset.tags.join(', ')}
            </Typography>
            <Typography className={classes.tooltipText}>
              <b>Image Credit 1</b>: {asset.sections[0].imageCredit}
            </Typography>
            {!!asset.sections[0].imageCopyright && (
              <Typography className={classes.tooltipText}>
                <b>Image Copyright 1</b>: {asset.sections[0].imageCopyright}
              </Typography>
            )}
          </>
        }
      >
        <img
          src={asset.requestOptions[0].square.url}
          className={classes.primaryImage}
          alt="Primary"
        />
      </Tooltip>
    ) : null;
  };

  renderClient = ({ asset }) => {
    return asset && asset.client ? (
      <Typography>{asset.client.companyName}</Typography>
    ) : null;
  };

  renderIsPublished = (row) => {
    return row.publishTime <= Date.now() ? (
      <Tooltip title="Pushed">
        <CheckAll className={this.props.classes.isPublished} color="primary" />
      </Tooltip>
    ) : (
      <Tooltip title="Scheduled">
        <ClockCheckOutline className={this.props.classes.isPublished} />
      </Tooltip>
    );
  };

  renderDateTime = (date) => {
    return formatDateTime(date);
  };

  renderFullDateTime = (date) => {
    return formatFullDateTime(date);
  };

  renderPublishStatusFilter = (props) => {
    return (
      <SelectFilterField
        items={PUBLISH_STATUS}
        initialValue={this.props.initial.publishStatus}
        onFilterChanged={(value) => {
          props.onFilterChanged(props.columnDef.tableData.id, value);
        }}
        labelKey="name"
      />
    );
  };

  render() {
    const {
      removeNotificationMessage,
      push,
      message,
      clearNotificationMessageMessage,
      initial,
    } = this.props;
    const columns = [
      {
        title: 'Title',
        field: 'title',
        type: 'string',
        filtering: false,
        maxWidth: 100,
        defaultSort:
          initial.orderBy === 'title' ? initial.orderDirection : undefined,
      },
      {
        title: 'Client',
        field: 'asset',
        render: this.renderClient,
        sorting: false,
        filtering: false,
      },
      {
        title: 'Opening Asset',
        field: 'asset',
        render: this.renderAsset,
        maxWidth: 120,
        sorting: false,
        filtering: false,
      },
      {
        title: 'Body',
        field: 'body',
        type: 'string',
        filtering: false,
        defaultSort:
          initial.orderBy === 'body' ? initial.orderDirection : undefined,
      },
      {
        title: 'Open In Tab',
        field: 'category.name',
        type: 'string',
        sorting: false,
        filtering: false,
      },
      {
        title: 'Status',
        field: 'isPublished',
        type: 'boolean',
        render: this.renderIsPublished,
        filterComponent: this.renderPublishStatusFilter,
        defaultFilter: initial.publishStatus,
        sorting: false,
        maxWidth: 180,
      },
      {
        title: 'Pushing Time',
        field: 'publishTime',
        type: 'datetime',
        filtering: false,
        defaultSort:
          initial.orderBy === 'publishTime'
            ? initial.orderDirection
            : undefined,
        render: (row) => this.renderFullDateTime(row.publishTime),
      },
      {
        title: 'Created At',
        field: 'createdAt',
        type: 'datetime',
        editable: 'never',
        filtering: false,
        render: (row) => this.renderDateTime(row.createdAt),
        defaultSort:
          initial.orderBy === 'createdAt' ? initial.orderDirection : undefined,
      },
      {
        title: 'Updated At',
        field: 'updatedAt',
        type: 'datetime',
        editable: 'never',
        filtering: false,
        render: (row) => this.renderDateTime(row.updatedAt),
        defaultSort:
          initial.orderBy === 'updatedAt' ? initial.orderDirection : undefined,
      },
    ];
    return (
      <>
        <MaterialTable
          title="Notification Messages"
          components={{
            Toolbar: this.renderToolbar,
          }}
          columns={columns}
          data={this.getData(columns)}
          onRowClick={(event, row) =>
            this.props.push(`notifications/${row.id}`)
          }
          editable={{
            onRowDelete: ({ id }) => removeNotificationMessage(id),
          }}
          actions={[
            {
              icon: () => <PlusBox color="secondary" />,
              tooltip: 'Add New Notification Message',
              isFreeAction: true,
              onClick: () => push('notifications/create'),
            },
          ]}
          options={{
            filtering: true,
            initialPage: this.initialPage > 0 ? this.initialPage : 0,
            pageSize: this.props.initial.pageSize,
            searchText: this.props.initial.searchText,
            padding: 'dense',
            emptyRowsWhenPaging: false,
          }}
        />

        <Snackbar
          open={!!message}
          autoHideDuration={7000}
          onClose={clearNotificationMessageMessage}
        >
          <Alert
            severity="success"
            action={
              <Button
                size="small"
                color="secondary"
                onClick={clearNotificationMessageMessage}
              >
                Dismiss
              </Button>
            }
          >
            {message}
          </Alert>
        </Snackbar>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    notificationMessages: getNotificationMessages(state),
    initial: state.notificationMessages.preferences,
    totalCount: getNotificationMessagesCount(state),
    message: getNotificationMessagesMessage(state),
  };
};

export default compose(
  connect(mapStateToProps, {
    loadNotificationMessages,
    removeNotificationMessage,
    updateNotificationMessage,
    push,
    clearNotificationMessageMessage,
  }),
  withStyles(styles)
)(NotificationMessageList);
