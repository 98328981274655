import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import { push } from 'redux-first-history';
import {
  Account,
  Bell,
  Cog,
  Logout,
  Github,
  Menu as MenuIcon,
} from 'mdi-material-ui';
import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import _ from 'lodash';

import {
  getIsSignedIn,
  getCurrentUser,
  getSignedInWith,
} from '../store/selectors';
import { signOut } from '../store/actions';

import useScrollTrigger from '@mui/material/useScrollTrigger';

const ShowOnScroll = ({ children }) => {
  const trigger = useScrollTrigger({ threshold: 32, disableHysteresis: true });
  return (
    <Slide in={trigger} direction="up">
      <span>{children}</span>
    </Slide>
  );
};

const styles = (theme) => ({
  secondaryBar: {
    zIndex: 0,
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  menuButton: {
    marginLeft: -theme.spacing(1),
  },
  iconButtonAvatar: {
    padding: 4,
  },
});

const Header = ({
  authProvider,
  classes,
  me,
  onDrawerToggle,
  pathname,
  push,
  routes: routeCategories,
  signOut,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const onMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onMenuClose = () => {
    setAnchorEl(null);
  };

  const onProfileClick = () => {
    setAnchorEl(null);
    push('/dashboard/profile');
  };

  const onPreferencesClick = () => {
    setAnchorEl(null);
    push('/dashboard/preferences');
  };

  const brand = useMemo(() => {
    let brand = '';
    _.forEach(routeCategories, ({ routes }) => {
      _.forEach(routes, ({ name, path }) => {
        if (pathname.indexOf(path) > -1) {
          brand = name;
        }
      });
    });
    return brand;
  }, [pathname, routeCategories]);

  return (
    <React.Fragment>
      <AppBar color="primary" position="sticky" elevation={0}>
        <Toolbar variant="dense">
          <Grid container spacing={1} alignItems="center">
            <Hidden xlUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={onDrawerToggle}
                  className={classes.menuButton}
                  size="large"
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            <Grid item xs>
              <ShowOnScroll>
                <Typography color="inherit" variant="h6">
                  {brand}
                </Typography>
              </ShowOnScroll>
            </Grid>
            <Grid item>
              <Tooltip title="Alerts • No alerts">
                <IconButton color="inherit" size="large">
                  <Bell />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title={`${me.firstName} ${me.lastName}`}>
                <IconButton
                  color="inherit"
                  className={classes.iconButtonAvatar}
                  aria-controls="avatar-menu"
                  aria-haspopup="true"
                  onClick={onMenuOpen}
                  size="large"
                >
                  <Avatar
                    src={
                      me.profilePictureUrl
                        ? me.profilePictureUrl
                        : me.provider[authProvider].picture
                    }
                    alt="My Avatar"
                    className={classes.avatar}
                  />
                </IconButton>
              </Tooltip>
              <Menu
                id="avatar-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={onMenuClose}
                keepMounted
              >
                <MenuItem onClick={onProfileClick}>
                  <ListItemIcon>
                    <Account />
                  </ListItemIcon>
                  <ListItemText primary="Profile" />
                </MenuItem>
                <MenuItem onClick={onPreferencesClick}>
                  <ListItemIcon>
                    <Cog />
                  </ListItemIcon>
                  <ListItemText primary="Preferences" />
                </MenuItem>
                <MenuItem onClick={signOut}>
                  <ListItemIcon>
                    <Logout />
                  </ListItemIcon>
                  <ListItemText primary="Log out" />
                </MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <AppBar
        component="div"
        className={classes.secondaryBar}
        color="primary"
        position="static"
        elevation={0}
      >
        <Toolbar variant="dense">
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h6">
                {brand}
              </Typography>
            </Grid>
            <Grid item>
              <Tooltip title="Landing page">
                <Button
                  className={classes.button}
                  variant="outlined"
                  color="inherit"
                  size="small"
                  target="_blank"
                  href="https://worldinbeauty.com"
                >
                  Landing Page
                </Button>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Github">
                <IconButton
                  color="inherit"
                  target="_blank"
                  href="https://github.com/t-ho/rmg"
                  size="large"
                >
                  <Github />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  onDrawerToggle: PropTypes.func.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object),
};

const mapStateToProps = (state) => {
  return {
    isSignedIn: getIsSignedIn(state),
    me: getCurrentUser(state),
    authProvider: getSignedInWith(state),
    pathname: state.router.location.pathname,
  };
};

export default compose(
  connect(mapStateToProps, { signOut, push }),
  withStyles(styles)
)(Header);
