export const MAX_PAGE_SIZE = 1000;
export const PREFERENCES_STORAGE_ID = 'preferences';

export const DEFAULT_PREFERENCES = {
  ads: {
    categoryId: '',
    clientId: '',
    publishStatus: '',
    page: 0,
    pageSize: 5,
    searchText: '',
    orderBy: 'createdAt',
    orderDirection: 'desc',
    cutOffTimeDs: 28, // 28 days
  },
  adPrices: {
    categoryId: '',
    page: 0,
    pageSize: 10,
    searchText: '',
    orderBy: 'position',
    orderDirection: 'asc',
  },
  assets: {
    categoryId: '',
    clientId: '',
    credit: '',
    publishStatus: '',
    publishTime: null,
    completionStatus: '',
    status: '',
    page: 0,
    pageSize: 5,
    searchText: '',
    orderBy: 'updatedAt',
    orderDirection: 'desc',
    tags: '',
    cutOffTimeDs: 224, // 224 days
    topStoriesTimeDs: 28, // 28 days
    autoSaveIntervalS: 45, // 45 seconds
  },
  categories: {
    page: 0,
    pageSize: 20,
    searchText: '',
    orderBy: 'ordinalNumber',
    orderDirection: 'asc',
  },
  clients: {
    page: 0,
    pageSize: 10,
    searchText: '',
    orderBy: 'createdAt',
    orderDirection: 'desc',
  },
  invoices: {
    clientId: '',
    completionStatus: '',
    page: 0,
    pageSize: 10,
    searchText: '',
    orderBy: 'createdAt',
    orderDirection: 'desc',
  },
  notificationMessages: {
    publishStatus: '',
    page: 0,
    pageSize: 20,
    searchText: '',
    orderBy: 'publishTime',
    orderDirection: 'desc',
    autoSaveIntervalS: 15, // 15 seconds
  },
  coverImages: {
    type: '',
    publishStatus: '',
    page: 0,
    pageSize: 5,
    searchText: '',
    orderBy: 'updatedAt',
    orderDirection: 'desc',
    autoSaveIntervalS: 15, // 15 seconds
  },
  reports: {
    clientId: '',
    page: 0,
    pageSize: 10,
    searchText: '',
    orderBy: 'createdAt',
    orderDirection: 'desc',
  },
  users: {
    platform: '',
    role: '',
    status: '',
    isEditor: '',
    page: 0,
    pageSize: 10,
    searchText: '',
    subscriptionStatus: '',
    orderBy: 'createdAt',
    orderDirection: 'desc',
  },
};
