import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@mui/material/CssBaseline';
import Hidden from '@mui/material/Hidden';
import { withStyles } from '@mui/styles';
import { Switch, Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import Navigator from '../components/Navigator';
import Header from '../components/Header';
import ProtectedRoute from '../components/accessControl/ProtectedRoute';
import { getRouteCategories } from '../store/selectors';

const drawerWidth = 256;

const styles = (theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('xl')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  app: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    flex: 1,
    padding: theme.spacing(2),
  },
});

const Dashboard = ({ classes, routeCategories }) => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((currentState) => !currentState);
  };

  const renderSwitchRoutes = (routeCategories) => (
    <Switch>
      {routeCategories.map(({ routes }) =>
        routes.map(
          ({
            path,
            requiresRole,
            requiresPermissions,
            customPermissionCheck,
            component,
          }) => (
            <ProtectedRoute
              path={path}
              requiresRole={requiresRole}
              requiresPermissions={requiresPermissions}
              customPermissionCheck={customPermissionCheck}
              component={component}
            />
          )
        )
      )}
      <Route path="/">
        <Redirect to="/dashboard/profile" />
      </Route>
    </Switch>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <nav className={classes.drawer}>
        <Hidden xlUp implementation="js">
          <Navigator
            PaperProps={{ style: { width: drawerWidth } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
          />
        </Hidden>
        <Hidden xlDown implementation="css">
          <Navigator PaperProps={{ style: { width: drawerWidth } }} />
        </Hidden>
      </nav>
      <div className={classes.app}>
        <Header onDrawerToggle={handleDrawerToggle} routes={routeCategories} />
        <main className={classes.main}>
          {renderSwitchRoutes(routeCategories)}
        </main>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  routeCategories: getRouteCategories(state),
});

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  connect(mapStateToProps, {}),
  withStyles(styles)
)(Dashboard);
