import * as actionTypes from './types';

export const countOrphanFiles =
  () =>
  (dispatch, getState, { mernApi }) => {
    dispatch({ type: actionTypes.COUNT_ORPHAN_FILES });
    return mernApi.get('/api/admin/list-tmp-bucket').then(
      (response) => {
        dispatch({
          type: actionTypes.COUNT_ORPHAN_FILES_SUCCESS,
          payload: response.data,
        });
      },
      (err) => {
        dispatch({
          type: actionTypes.COUNT_ORPHAN_FILES_FAIL,
          payload: err.response.data.error.message,
        });
      }
    );
  };

export const cleanUpStorage =
  (payload) =>
  (dispatch, getState, { mernApi }) => {
    dispatch({ type: actionTypes.CLEAN_UP_STORAGE });
    return mernApi.post('/api/admin/delete-tmp-bucket', payload).then(
      (response) => {
        dispatch({
          type: actionTypes.CLEAN_UP_STORAGE_SUCCESS,
          payload: response.data,
        });
      },
      (err) => {
        dispatch({
          type: actionTypes.CLEAN_UP_STORAGE_FAIL,
          payload: err.response.data.error.message,
        });
      }
    );
  };

export const clearAdminMessages = () => {
  return { type: actionTypes.CLEAR_ADMIN_MESSAGES };
};

export const getAppVersion =
  (payload) =>
  (dispatch, getState, { mernApi }) => {
    dispatch({ type: actionTypes.GET_APP_VERSION });
    return mernApi.get('/api/app-version').then(
      (response) => {
        dispatch({
          type: actionTypes.GET_APP_VERSION_SUCCESS,
          payload: response.data,
        });
      },
      (err) => {
        dispatch({
          type: actionTypes.GET_APP_VERSION_FAIL,
          payload: err.response.data.error.message,
        });
      }
    );
  };
