import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { withStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import { Crop } from 'mdi-material-ui';
import Cropper from 'react-cropper';
import Uppy from '@uppy/core';
import AwsS3 from '@uppy/aws-s3';
import Jimp from 'jimp';
import localforage from 'localforage';

import 'cropperjs/dist/cropper.css';
import Previewer from './Previewer';
import _ from 'lodash';

const styles = (theme) => ({
  croppedImagePaper: {
    padding: theme.spacing(1),
  },
  croppedImage: {
    width: '100%',
  },
  backdrop: {
    zIndex: 999,
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
  },
  watermarkText: {
    margin: theme.spacing(1),
  },
});

const createUppy = () => {
  return new Uppy({
    restrictions: {
      maxNumberOfFiles: 1,
      minNumberOfFiles: 1,
      allowedFileTypes: ['image/png', 'image/jpeg', 'image/jpg'],
    },
  }).use(AwsS3, {});
};

const CropperDialog = ({
  aspectRatio,
  autoCropArea,
  classes,
  currentImage,
  disabled,
  generatePreview,
  imageType,
  isSrcNewUpload,
  maxWidth,
  onCropSuccess,
  quality,
  awsS3UploadKit,
  screenRatio,
  src,
  title,
  tooltip,
  watermark,
  watermarkCopyright,
  watermarkCredit,
  widthThreshold,
  croppedImageUrl,
  originalFileName,
  cropButtonStyle,
}) => {
  const [uppy] = useState(() => createUppy());
  const cropperRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [localCroppedImageUrl, setLocalCroppedImageUrl] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [isCropperReady, setIsCropperReady] = useState(false);
  const [message, setMessage] = useState({ isSuccess: false, content: null });
  const [darkLogo, setDarkLogo] = useState(false);
  const [darkText, setDarkText] = useState(false);
  const [addCredit, setAddCredit] = useState(true);
  const [addCopyright, setAddCopyright] = useState(false);
  const [addCenterLogo, setAddCenterLogo] = useState(false);
  const [watermarkText, setWatermarkText] = useState('');

  const [srcToUse, setSrcToUse] = useState({
    src: null,
    isLocal: false,
    fileName: null,
  });

  // As showing cropper with big file image is heavy task and it will make the UI slow,
  // we will show it when after the dialog is opened.
  const [showCropper, setShowCropper] = useState(false);

  useEffect(() => {
    if (!open && showCropper) {
      // reset showCropper state when dialog is closed
      setShowCropper(false);
    }

    // We use these timers to make sure that the cropper is loaded after the dialog is opened.
    // This will make the UI more responsive.
    let determineSrcTimer = null;
    let showCropperTimer = null;

    if (open) {
      determineSrcTimer = setTimeout(() => {
        setWatermarkText(generateWatermarkText(addCredit, addCopyright));

        if (!_.startsWith(src, 'data:image') && !!originalFileName) {
          if (
            !srcToUse.src ||
            !srcToUse.isLocal ||
            srcToUse.fileName !== originalFileName
          ) {
            // check if image is in localforage (which was stored when uploading
            // new original image in section-fields file)
            localforage
              .getItem(originalFileName)
              .then((data) => {
                if (data?.imageSrc) {
                  setSrcToUse({
                    src: data.imageSrc,
                    isLocal: true,
                    fileName: originalFileName,
                  });
                } else {
                  setSrcToUse({
                    src,
                    isLocal: false,
                    fileName: originalFileName,
                  });
                }
              })
              .catch(() => {
                setSrcToUse({
                  src,
                  isLocal: false,
                  fileName: originalFileName,
                });
              });
          }
        } else {
          setSrcToUse({ src, isLocal: true, fileName: originalFileName });
        }
      }, 200);

      // Wait for the dialog is opened
      showCropperTimer = setTimeout(() => {
        setShowCropper(true);
      }, 400);
    }

    return () => {
      clearTimeout(determineSrcTimer);
      clearTimeout(showCropperTimer);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  let fileUrl = null;

  useEffect(() => {
    return () => {
      if (uppy) {
        uppy.close();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (awsS3UploadKit && uppy) {
      uppy.getPlugin('AwsS3')?.setOptions({
        getUploadParameters(file) {
          return {
            method: 'PUT',
            url: awsS3UploadKit[file.meta.key].putOptions.url,
          };
        },
      });
      setLocalCroppedImageUrl(null);
    }
  }, [awsS3UploadKit, uppy]);

  useEffect(() => {
    if (!watermarkCopyright) {
      setAddCopyright(false);
    }
  }, [watermarkCopyright]);

  const generateWatermarkText = (hasCredit, hasCopyright) => {
    let text = '';
    if (hasCredit && hasCopyright) {
      text = `© ${watermarkCopyright} | Credit: ${watermarkCredit}`;
    } else {
      if (hasCredit) {
        text = `Credit: ${watermarkCredit}`;
      } else if (hasCopyright) {
        text = `© ${watermarkCopyright}`;
      }
    }
    return text;
  };

  const onOpenClick = () => {
    setIsCropperReady(false);
    setOpen(true);
  };

  const onCloseClick = () => {
    setOpen(false);
  };

  const crop = (rOptions) => {
    let options = {
      width: rOptions.screenWidth
        ? rOptions.screenWidth * screenRatio
        : maxWidth * screenRatio,
      imageSmoothingEnabled: true,
      imageSmoothingQuality: 'high',
      fillColor: '#fff',
    };

    return new Promise((resolve, reject) => {
      cropperRef.current.cropper.getCroppedCanvas(options).toBlob(
        (blob) => {
          if (!blob) {
            reject('Empty blob');
          }
          resolve(blob);
        },
        imageType,
        watermark ? 1 : quality
      );
    });
  };

  const combineCenterLogo = (imgTpl, imgRaw) => {
    let imgBigLogo = {
      path: darkLogo
        ? '/watermark-big-blue-logo.png'
        : '/watermark-big-logo.png',
      opacity: 0.3,
      width: 0,
      height: 0,
      x: 0,
      y: 0,
    };

    return (
      Jimp.read(imgBigLogo.path)
        // scale the logo
        .then((logoTpl) => {
          const width = Math.floor(imgRaw.width / 2.5);
          const height = Math.floor(
            (width * logoTpl.getHeight()) / logoTpl.getWidth()
          );
          return logoTpl.scaleToFit(width, height);
        })
        .then((logoTpl) => {
          imgBigLogo.width = logoTpl.getWidth();
          imgBigLogo.height = logoTpl.getHeight();
          imgBigLogo.x = Math.floor((imgRaw.width - imgBigLogo.width) / 2);
          imgBigLogo.y = Math.floor((imgRaw.height - imgBigLogo.height) / 2);
          logoTpl.opacity(imgBigLogo.opacity);
          return imgTpl.composite(logoTpl, imgBigLogo.x, imgBigLogo.y, {
            mode: Jimp.BLEND_SOURCE_OVER,
          });
        })
    );
  };

  const combineText = (imgTpl, imgRaw) => {
    let text = generateWatermarkText(addCredit, addCopyright);
    let textData = {
      text,
      maxWidth: 0,
      maxHeight: 0,
      x: 0,
      y: 0,
    };

    let imgSmallLogo = {
      path: darkText
        ? '/watermark-small-blue-logo.png'
        : '/watermark-small-logo.png',
      opacity: 1,
      width: 0,
      height: 0,
      x: 0,
      y: 0,
    };

    // combine the small logo
    return (
      Jimp.read(imgSmallLogo.path)
        .then((logoTpl) => {
          return logoTpl.scaleToFit(96, 54);
        })
        .then((logoTpl) => {
          imgSmallLogo.width = logoTpl.getWidth();
          imgSmallLogo.height = logoTpl.getHeight();
          imgSmallLogo.x = 32;
          imgSmallLogo.y =
            imgRaw.height -
            imgSmallLogo.height -
            Math.floor(imgRaw.height / 10);
          logoTpl.opacity(imgSmallLogo.opacity);
          return imgTpl.composite(logoTpl, imgSmallLogo.x, imgSmallLogo.y, [
            Jimp.BLEND_DESTINATION_OVER,
            0.2,
            0.2,
          ]);
        })

        //load font
        .then((tpl) => {
          imgTpl = tpl;
          const baseUrl =
            window.location.protocol + '//' + window.location.host;
          const font = darkText
            ? 'open-sans-16-black.fnt'
            : 'open-sans-16-white.fnt';
          return Jimp.loadFont(`${baseUrl}/${font}`);
        })
        //add text
        .then((font) => {
          textData.maxWidth = 960;
          textData.maxHeight = imgSmallLogo.height + 20;
          textData.x = 32 + imgSmallLogo.width;
          textData.y = imgSmallLogo.y - 10;
          return imgTpl.print(
            font,
            textData.x,
            textData.y,
            {
              text: textData.text,
              alignmentX: Jimp.HORIZONTAL_ALIGN_LEFT,
              alignmentY: Jimp.VERTICAL_ALIGN_MIDDLE,
            },
            textData.maxWidth,
            textData.maxHeight
          );
        })
    );
  };

  const addWatermark = (imgUrl) => {
    let imgRaw = {
      path: imgUrl,
      quality: quality * 100,
      width: 0,
      height: 0,
    };

    //read template
    return (
      Jimp.read(imgRaw.path)
        // combine big logo in the center
        .then((tpl) => {
          imgRaw.width = tpl.getWidth();
          imgRaw.height = tpl.getHeight();
          if (addCenterLogo) {
            return combineCenterLogo(tpl, imgRaw);
          }
          return tpl;
        })
        .then((tpl) => {
          if (addCredit || addCopyright) {
            return combineText(tpl, imgRaw);
          }
          return tpl;
        })

        .then((tpl) => {
          return tpl.quality(imgRaw.quality).getBufferAsync(Jimp.MIME_JPEG);
        })
        .then((buffer) => {
          return new Blob([new Uint8Array(buffer)], { type: Jimp.MIME_JPEG });
        })
    );
  };

  const cropAndUpload = (key, rOptions) => {
    return crop(rOptions)
      .then((blob) => {
        if (watermark) {
          return addWatermark(window.URL.createObjectURL(blob));
        }
        return blob;
      })
      .then((blob) => {
        uppy.addFile({
          name: rOptions.fileName,
          type: imageType,
          data: blob,
          meta: { key },
        });
        return uppy.upload();
      })
      .then((result) => {
        if (result.successful.length > 0) {
          if (key === 'default') {
            window.URL.revokeObjectURL(fileUrl);
            fileUrl = window.URL.createObjectURL(result.successful[0].data);
          }
          return uppy.removeFile(result.successful[0].id);
        } else if (result.failed.length > 0) {
          uppy.removeFile(result.failed[0].id);
          throw new Error(
            `Unable to crop and upload image ${rOptions.fileName}. Please try again!`
          );
        }
      });
  };

  const onCropClick = () => {
    setUploading(true);
    let preview = null;
    if (generatePreview) {
      let options = {
        maxWidth: generatePreview.maxWidth,
        imageSmoothingEnabled: false,
        imageSmoothingQuality: 'low',
        fillColor: '#fff',
      };
      if (cropperRef.current?.cropper) {
        preview = cropperRef.current.cropper
          .getCroppedCanvas(options)
          .toDataURL('image/jpeg', generatePreview.quality);
      }
    }

    Object.keys(awsS3UploadKit)
      .reduce((sequence, key) => {
        return sequence.then(() => {
          return cropAndUpload(key, awsS3UploadKit[key]);
        });
      }, Promise.resolve())
      .then(() => {
        if (onCropSuccess) {
          onCropSuccess(preview);
        }

        setLocalCroppedImageUrl(fileUrl);
        setMessage({
          isSuccess: true,
          content: 'Image has been cropped and uploaded successfully',
        });
        setUploading(false);
        setOpen(false);
      })
      .catch((err) => {
        setMessage({
          isSuccess: false,
          content: err,
        });
        setUploading(false);
        setOpen(false);
      });
  };

  const onCropperReady = (e) => {
    setIsCropperReady(true);
  };

  const onDarkTextCheckboxChange = (e) => {
    setDarkText(e.target.checked);
  };

  const onDarkLogoCheckboxChange = (e) => {
    setDarkLogo(e.target.checked);
  };

  const onBigCenterLogoCheckboxChange = (e) => {
    setAddCenterLogo(e.target.checked);
  };

  const onCreditCheckboxChange = (e) => {
    setAddCredit(e.target.checked);
    setWatermarkText(generateWatermarkText(e.target.checked, addCopyright));
  };

  const onCopyrightCheckboxChange = (e) => {
    setAddCopyright(e.target.checked);
    setWatermarkText(generateWatermarkText(addCredit, e.target.checked));
  };

  const resetMessage = () => {
    setMessage({ isSuccess: false, message: null });
  };

  let displayUrl = localCroppedImageUrl || croppedImageUrl;
  if (!displayUrl && !isSrcNewUpload) {
    displayUrl = currentImage?.url;
  }

  let iconButtonTooltip = 'Open cropper';
  if (!src) {
    iconButtonTooltip = 'Please upload an original image first';
  } else {
    if (watermark) {
      if (!watermarkCredit) {
        iconButtonTooltip = 'Please enter the credit first';
      }
    }
  }

  return (
    <>
      <div>
        <Grid container direction="column" alignItems="center">
          <Previewer
            imageUrl={displayUrl}
            widthThreshold={widthThreshold}
            tooltip={tooltip}
          />
          <Tooltip title={disabled ? '' : iconButtonTooltip}>
            <span>
              <IconButton
                aria-label="Crop"
                color="primary"
                onClick={onOpenClick}
                disabled={!src || (watermark && !watermarkCredit) || disabled}
                size="large"
                style={cropButtonStyle}
              >
                <Crop />
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
        <Dialog
          open={open}
          maxWidth="md"
          fullWidth={true}
          onClose={onCloseClick}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <Backdrop
              className={classes.backdrop}
              open={!isCropperReady || uploading}
            >
              <CircularProgress />
            </Backdrop>
            <Grid container spacing={1} direction="row" justifyContent="center">
              {!!watermark && (
                <>
                  <Grid container direction="row" justifyContent="center">
                    <Grid item>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={onCreditCheckboxChange}
                            checked={addCredit}
                            disabled={!watermarkCredit}
                          />
                        }
                        label="Credit"
                      />
                    </Grid>
                    <Grid item>
                      <Tooltip
                        title={
                          !watermarkCopyright
                            ? 'Please enter the copyright first'
                            : ''
                        }
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={onCopyrightCheckboxChange}
                              checked={addCopyright}
                              disabled={!watermarkCopyright}
                            />
                          }
                          label="Copyright"
                        />
                      </Tooltip>
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={onDarkTextCheckboxChange}
                            checked={darkText}
                            disabled={!addCredit && !addCopyright}
                          />
                        }
                        label="Dark Text"
                      />
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={onBigCenterLogoCheckboxChange}
                            checked={addCenterLogo}
                          />
                        }
                        label="Big Center Logo"
                      />
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={onDarkLogoCheckboxChange}
                            checked={darkLogo}
                            disabled={!addCenterLogo}
                          />
                        }
                        label="Blue Logo"
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" justifyContent="center">
                    <TextField
                      value={watermarkText}
                      label={
                        !!watermarkText ? 'Watermark Text' : 'No Watermark Text'
                      }
                      variant="outlined"
                      size="small"
                      disabled
                      fullWidth
                      className={classes.watermarkText}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12} sm={6} xl={7}>
                {showCropper && !!srcToUse.src && (
                  <Cropper
                    ref={cropperRef}
                    src={srcToUse.src}
                    style={{ width: '90%' }}
                    aspectRatio={aspectRatio}
                    autoCropArea={autoCropArea}
                    zoomable={false}
                    preview=".img-preview"
                    checkCrossOrigin={false}
                    checkOrientation={true}
                    viewMode={2}
                    crossOrigin="anonymous"
                    ready={onCropperReady}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={2}>
                <div
                  className="img-preview"
                  style={{
                    width: '100%',
                    height: 200,
                    maxHeight: 200,
                    overflow: 'hidden',
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={onCloseClick}
              variant="contained"
              disabled={uploading || !isCropperReady}
            >
              Cancel
            </Button>
            <Button
              onClick={onCropClick}
              color="primary"
              variant="contained"
              disabled={uploading || !isCropperReady}
            >
              Crop and Upload
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      <Snackbar
        open={!!message.content}
        autoHideDuration={7000}
        onClose={resetMessage}
      >
        <Alert
          severity={message.isSuccess ? 'success' : 'error'}
          action={
            <Button size="small" color="secondary" onClick={resetMessage}>
              Dismiss
            </Button>
          }
        >
          {message.content}
        </Alert>
      </Snackbar>
    </>
  );
};

CropperDialog.propTypes = {
  aspectRatio: PropTypes.number,
  autoCropArea: PropTypes.number,
  currentImage: PropTypes.any,
  generatePreview: PropTypes.any,
  imageType: PropTypes.string,
  isSrcNewUpload: PropTypes.bool,
  maxWidth: PropTypes.number,
  onCropSuccess: PropTypes.func,
  quality: PropTypes.number,
  awsS3UploadKit: PropTypes.any,
  screenRatio: PropTypes.number,
  src: PropTypes.string,
  title: PropTypes.string,
  tooltip: PropTypes.string,
  watermark: PropTypes.bool,
  watermarkCredit: PropTypes.string,
  watermarkCopyright: PropTypes.string,
  widthThreshold: PropTypes.number,
  disabled: PropTypes.bool,
};

CropperDialog.defaultProps = {
  autoCropArea: 1,
  generatePreview: null,
  imageType: 'image/jpeg',
  isSrcNewUpload: true,
  quality: 0.8,
  screenRatio: 1,
  title: 'Crop image',
  tooltip: 'Cropped image',
};

export default withStyles(styles)(CropperDialog);
