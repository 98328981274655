import React, { useCallback, useEffect, useState } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Button,
  Card,
  Collapse,
  Grid,
  Typography,
} from '@mui/material';
import AwsS3 from '@uppy/aws-s3';
import Uppy from '@uppy/core';
import { DashboardModal } from '@uppy/react';
import localforage from 'localforage';
import _ from 'lodash';
import {
  ChevronDown as ChevronDownIcon,
  Image as ImageIcon,
} from 'mdi-material-ui';
import { useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import CropperDialog from '../../components/CropperDialog';
import Previewer from '../../components/Previewer';
import ControllerTextField from '../../components/react-hook-form-wrappers/controller-text-field';
import { removeS3Objects } from '../../store/actions';
import { getCoverImageAwsS3UploadKit } from '../../store/apis/helpers';

require('@uppy/core/dist/style.css');
require('@uppy/dashboard/dist/style.css');

const createUppy = ({ removeS3ObjectsAction, getValues, setValue }) => {
  return new Uppy({
    restrictions: {
      maxNumberOfFiles: 1,
      minNumberOfFiles: 1,
      allowedFileTypes: ['image/png', 'image/jpeg', 'image/jpg'],
    },
  })
    .use(AwsS3, {
      getUploadParameters(file) {
        return getCoverImageAwsS3UploadKit({
          fileName: `${file.name}.${file.extension}`,
        }).then(({ awsS3UploadKit }) => {
          // temporarily store the new awsS3UploadKit in the form
          // so that we can use it later
          setValue(`coverImage.temp.awsS3UploadKit`, awsS3UploadKit);
          return {
            method: 'PUT',
            url: awsS3UploadKit.original.putOptions.url,
          };
        });
      },
    })
    .on('complete', (result) => {
      if (result.successful.length === 1) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setValue(`coverImage.temp.localOriginal`, {
            url: reader.result,
            type: result.successful[0].type,
          });
        };
        reader.readAsDataURL(result.successful[0].data);
      } else if (result.failed.length > 0) {
        setValue(`coverImage.temp`, undefined);
      }
    })
    .on('file-removed', (file, reason) => {
      if (reason === 'removed-by-user' && file.progress.uploadComplete) {
        const newAwsS3UploadKit = getValues(`coverImage.temp.awsS3UploadKit`);
        setValue(`coverImage.temp`, undefined);
        if (!_.isEmpty(newAwsS3UploadKit)) {
          removeS3ObjectsAction({
            objectsToRemove: [
              {
                deleteOptions: newAwsS3UploadKit.original.deleteOptions,
              },
            ],
            type: 'asset',
          });
        }
      }
    });
};

const CoverImageUploader = ({
  control,
  isEditing,
  getValues,
  setValue,
  showOriginalImage,
  openUploaderOnInitialRender,
  heightThreshold,
  widthThreshold,
  aspectRatio,
  screenRatio = 1,
  disabled,
}) => {
  const dispatch = useDispatch();

  const removeS3ObjectsAction = useCallback(
    ({ objectsToRemove, type }) => {
      return dispatch(removeS3Objects({ objectsToRemove, type }));
    },
    [dispatch]
  );

  const [uppy] = useState(() =>
    createUppy({
      getValues,
      setValue,
      removeS3ObjectsAction,
    })
  );
  const [openUploader, setOpenUploader] = useState(false);

  const initialRequestOptions = useWatch({
    control,
    name: `coverImage.initialRequestOptions`,
  });

  const awsS3UploadKit = useWatch({
    control,
    name: `coverImage.awsS3UploadKit`,
  });

  const localOriginalUrl = useWatch({
    control,
    name: `coverImage.localOriginalUrl`,
  });

  const croppedImageUrl = useWatch({
    control,
    name: `coverImage.croppedImageUrl`,
  });

  const expanded = useWatch({
    control,
    name: `coverImage.expanded`,
    defaultValue: true,
  });

  const originalFileName = useWatch({
    control,
    name: `coverImage.originalFileName`,
  });

  useEffect(() => {
    if (openUploaderOnInitialRender) {
      setOpenUploader(true);
    }

    return () => {
      if (uppy) {
        uppy.close();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addCroppedImagesToUploadedImages = ({ mode, type }) => {
    const uploadedImages = getValues(`coverImage.uploadedImages`);
    let croppedImageUrl = getValues(`coverImage.croppedImageUrl`);

    if (_.isNil(croppedImageUrl)) {
      croppedImageUrl = {};
    }

    croppedImageUrl[mode] = awsS3UploadKit[mode].default.getOptions.url;

    // remove existing images with same mode
    const images = _.filter(uploadedImages, (image) => image.mode !== mode);

    // add new images
    Object.keys(awsS3UploadKit[mode]).forEach((key) => {
      images.push({
        mode,
        key,
        deleteOptions: awsS3UploadKit[mode][key].deleteOptions,
        name: awsS3UploadKit[mode][key].fileName,
        type,
      });
    });

    setValue(`coverImage.croppedImageUrl`, croppedImageUrl);

    setValue(`coverImage.uploadedImages`, images, {
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  const handleDashboardModalClose = () => {
    const temp = getValues(`coverImage.temp`);
    if (temp && temp.localOriginal && temp.awsS3UploadKit) {
      // remove existing uploaded images
      const uploadedImages = getValues(`coverImage.uploadedImages`);
      if (!_.isEmpty(uploadedImages)) {
        removeS3ObjectsAction({
          objectsToRemove: [...uploadedImages],
          type: 'asset',
        });
      }

      setValue(
        `coverImage.uploadedImages`,
        [
          {
            mode: 'original',
            key: 'default',
            name: temp.awsS3UploadKit.original.fileName,
            type: temp.localOriginal.type,
            deleteOptions: temp.awsS3UploadKit.original.deleteOptions,
          },
        ],
        { shouldDirty: true, shouldTouch: true }
      );
      setValue(
        `coverImage.originalFileName`,
        temp.awsS3UploadKit.original.fileName,
        { shouldDirty: true, shouldTouch: true }
      );
      setValue(`coverImage.awsS3UploadKit`, temp.awsS3UploadKit);
      setValue(`coverImage.localOriginalUrl`, temp.localOriginal.url);
      setValue(`coverImage.croppedImageUrl`, {});

      // Store original image in localforage which will be used in CropperDialog
      localforage.setItem(temp.awsS3UploadKit.original.fileName, {
        imageSrc: temp.localOriginal.url,
        __timestamp: Date.now(),
      });
    }

    setValue(`coverImage.temp`, undefined);
    setOpenUploader(false);
  };

  const handleAccordionChange = (event, isExpanded) => {
    setValue(`coverImage.expanded`, isExpanded);
  };

  const originalUrl =
    localOriginalUrl || awsS3UploadKit?.original?.getOptions?.url;

  const originalPreviewUrl =
    localOriginalUrl || initialRequestOptions?.original?.url;

  const accordionSummaryUrl =
    croppedImageUrl?.default || initialRequestOptions?.cropped?.url;

  return (
    <Grid
      container
      direction={{ xs: 'row', md: 'row-reverse' }}
      justifyContent="space-around"
      style={{ paddingBottom: 24 }}
    >
      {showOriginalImage && (
        <Grid
          container
          item
          xs={12}
          md={4}
          direction="row"
          justifyContent="center"
          style={{ marginBottom: 24 }}
        >
          <Grid item xs={12}>
            {showOriginalImage && (
              <Collapse in={expanded}>
                <Previewer
                  imageUrl={originalPreviewUrl}
                  widthThreshold={widthThreshold}
                  heightThreshold={heightThreshold}
                  tooltip="Original cover image"
                />
              </Collapse>
            )}
          </Grid>
        </Grid>
      )}
      <Grid container component={Card} item xs={12} md={7}>
        <Accordion
          expanded={expanded}
          onChange={handleAccordionChange}
          style={{ width: '100%' }}
        >
          <AccordionSummary expandIcon={<ChevronDownIcon />}>
            <Grid container alignItems="center" paddingLeft={1}>
              <Collapse orientation="horizontal" in={!expanded}>
                <Avatar
                  variant="square"
                  src={accordionSummaryUrl}
                  style={{ marginRight: 16 }}
                >
                  <ImageIcon />
                </Avatar>
              </Collapse>
              <Typography>Cover Image</Typography>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container item gap={3} padding={1}>
              <Grid container item spacing={3}>
                <Grid item md={8} xs={12}>
                  <ControllerTextField
                    control={control}
                    label="File Name"
                    name={`coverImage.originalFileName`}
                    disabled
                    required={false}
                  />
                </Grid>
                <Grid item md={2} xs={6}>
                  <Button
                    color="primary"
                    type="button"
                    variant="contained"
                    fullWidth
                    onClick={() => setOpenUploader(true)}
                    disabled={disabled}
                  >
                    Open Uploader
                  </Button>
                </Grid>
                <DashboardModal
                  uppy={uppy}
                  showLinkToFileUploadResult={false}
                  showRemoveButtonAfterComplete
                  proudlyDisplayPoweredByUppy={false}
                  note="Please choose an image (*.jpg, *.jpeg, *.png)"
                  style={{ zIndex: 99999 }}
                  open={openUploader}
                  onRequestClose={handleDashboardModalClose}
                />
              </Grid>
              <Grid container item style={{ marginTop: 0, marginBottom: 16 }}>
                <Grid item xs={12}>
                  <CropperDialog
                    aspectRatio={aspectRatio}
                    currentImage={initialRequestOptions?.cropped}
                    generatePreview={{ maxWidth: 100, quality: 0.1 }}
                    isSrcNewUpload={!!localOriginalUrl || !isEditing}
                    onCropSuccess={() => {
                      addCroppedImagesToUploadedImages({
                        mode: 'cropped',
                        type: 'image/jpeg',
                      });
                    }}
                    awsS3UploadKit={awsS3UploadKit?.cropped}
                    originalFileName={originalFileName}
                    src={originalUrl}
                    croppedImageUrl={croppedImageUrl?.cropped}
                    title="Crop cover image"
                    tooltip="Cover image"
                    widthThreshold={widthThreshold}
                    disabled={disabled}
                    cropButtonStyle={{ marginTop: 32 }}
                    screenRatio={screenRatio}
                  />
                </Grid>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};

export default CoverImageUploader;
