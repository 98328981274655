import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import { withStyles } from '@mui/styles';
import { compose } from '@reduxjs/toolkit';

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2),
    width: '100%',
  },
  image: {
    width: '100%',
  },
  imageSize: {
    textAlign: 'center',
    marginTop: theme.spacing(1),
  },
  warning: {
    color: theme.palette.secondary.main,
  },
});

const Previewer = ({
  classes,
  imageUrl,
  heightThreshold,
  tooltip,
  widthThreshold,
}) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const onImageLoad = ({ target: img }) => {
    setWidth(img.naturalWidth);
    setHeight(img.naturalHeight);
  };

  return (
    <Tooltip title={tooltip} placement="top">
      <Paper className={classes.paper}>
        <img
          className={classes.image}
          alt="Preview"
          src={imageUrl || '/placeholder.png'}
          onLoad={imageUrl ? onImageLoad : null}
        />
        {imageUrl && width > 0 && (
          <div className={classes.imageSize}>
            <span>{`W: ${width} px - H: ${height} px`}</span>
            <br />
            {(width < widthThreshold || height < heightThreshold) && (
              <span className={classes.warning}>
                Warning: Low resolution image!
              </span>
            )}
            {width < widthThreshold && (
              <span className={classes.warning}>
                &nbsp;The width should be &gt;= {widthThreshold} px.
              </span>
            )}
            {height < heightThreshold && (
              <span className={classes.warning}>
                &nbsp;The height should be &gt;= {heightThreshold} px.
              </span>
            )}
          </div>
        )}
      </Paper>
    </Tooltip>
  );
};

Previewer.propTypes = {
  heightThreshold: PropTypes.number,
  widthThreshold: PropTypes.number,
  imageUrl: PropTypes.string,
  tooltip: PropTypes.string.isRequired,
};

export default compose(withStyles(styles))(Previewer);
