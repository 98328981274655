import * as actionTypes from './types';

export const loadNotificationMessages =
  (payload) =>
  (dispatch, getState, { mernApi }) => {
    dispatch({ type: actionTypes.LOAD_NOTIFICATION_MESSAGES });
    const {
      filters,
      page = 0,
      pageSize = 10,
      orderBy,
      orderDirection,
      search,
    } = payload;
    const skip = page * pageSize;
    const sort = orderBy
      ? `${orderDirection === 'desc' ? '-' : ''}${orderBy.field}`
      : '-publishTime';
    const params = {
      sort,
      limit: pageSize,
      skip,
    };
    const preferences = {
      page,
      pageSize,
    };
    if (orderBy?.field) {
      preferences.orderBy = orderBy.field;
    }
    if (orderDirection) {
      preferences.orderDirection = orderDirection;
    }
    filters.forEach((filter) => {
      if (filter.column.field === 'isPublished') {
        params.isPublished = filter.value === 'published';
        preferences.publishStatus = filter.value;
      }
    });
    let searchText = search.trim();
    if (searchText) {
      params.searchText = searchText;
      preferences.searchText = searchText;
    }
    return mernApi.get('/api/notifications/messages', { params }).then(
      (response) => {
        dispatch({
          type: actionTypes.LOAD_NOTIFICATION_MESSAGES_SUCCESS,
          payload: {
            ...response.data,
            preferences,
          },
        });
      },
      (err) => {
        dispatch({
          type: actionTypes.LOAD_NOTIFICATION_MESSAGES_FAIL,
          payload: err.response.data.error.message,
        });
      }
    );
  };

export const loadNotificationMessage =
  (notificationMessageId) =>
  (dispatch, getState, { mernApi }) => {
    dispatch({ type: actionTypes.LOAD_NOTIFICATION_MESSAGE });
    return mernApi
      .get(`/api/notifications/messages/${notificationMessageId}`)
      .then(
        (response) => {
          dispatch({
            type: actionTypes.LOAD_NOTIFICATION_MESSAGE_SUCCESS,
            payload: response.data.notificationMessage,
          });
        },
        (err) => {
          dispatch({
            type: actionTypes.LOAD_NOTIFICATION_MESSAGE_FAIL,
            payload: err.response.data.error.message,
          });
        }
      );
  };

export const removeNotificationMessage =
  (notificationMessageId) =>
  (dispatch, getState, { mernApi }) => {
    dispatch({ type: actionTypes.REMOVE_NOTIFICATION_MESSAGE });
    return mernApi
      .delete(`/api/notifications/messages/${notificationMessageId}`)
      .then(
        (response) => {
          dispatch({
            type: actionTypes.REMOVE_NOTIFICATION_MESSAGE_SUCCESS,
            payload: { ...response.data, deletedId: notificationMessageId },
          });
        },
        (err) => {
          dispatch({
            type: actionTypes.REMOVE_NOTIFICATION_MESSAGE_FAIL,
            payload: err.response.data.error.message,
          });
        }
      );
  };

export const addNotificationMessage =
  (formValues) =>
  (dispatch, getState, { mernApi }) => {
    dispatch({ type: actionTypes.ADD_NOTIFICATION_MESSAGE });
    return mernApi.post('/api/notifications/messages', formValues).then(
      (response) => {
        dispatch({
          type: actionTypes.ADD_NOTIFICATION_MESSAGE_SUCCESS,
          payload: response.data.notificationMessage,
        });
        return Promise.resolve(response.data);
      },
      (err) => {
        dispatch({
          type: actionTypes.ADD_NOTIFICATION_MESSAGE_FAIL,
          payload: err.response.data.error.message,
        });
      }
    );
  };

export const updateNotificationMessage =
  (formValues) =>
  (dispatch, getState, { mernApi }) => {
    dispatch({ type: actionTypes.UPDATE_NOTIFICATION_MESSAGE });
    return mernApi
      .put(`/api/notifications/messages/${formValues.id}`, formValues)
      .then(
        (response) => {
          dispatch({
            type: actionTypes.UPDATE_NOTIFICATION_MESSAGE_SUCCESS,
            payload: response.data.notificationMessage,
          });
          return Promise.resolve(response.data);
        },
        (err) => {
          dispatch({
            type: actionTypes.UPDATE_NOTIFICATION_MESSAGE_FAIL,
            payload: err.response.data.error.message,
          });
        }
      );
  };

export const clearEditingNotificationMessage = () => {
  return {
    type: actionTypes.CLEAR_EDITING_NOTIFICATION_MESSAGE,
  };
};

export const clearNotificationMessageMessage = () => {
  return {
    type: actionTypes.CLEAR_NOTIFICATION_MESSAGE_MESSAGE,
  };
};

export const clearNotificationMessageError = () => {
  return {
    type: actionTypes.CLEAR_NOTIFICATION_MESSAGE_ERROR,
  };
};
