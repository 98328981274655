import React from 'react';
import PropTypes from 'prop-types';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import Tooltip from '@mui/material/Tooltip';
import { withStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { ArrowDownBoldOutline, ArrowUpBoldOutline } from 'mdi-material-ui';
import YouTube from 'react-youtube';

import ExportPreviewDialog from './ExportPreviewDialog';

import { clearAssetErrorMessage, updateImageOrders } from '../store/actions';
import { getAssetsLoading, getAssetsErrorMessage } from '../store/selectors';
import {
  formatPostedDate,
  formatLongPostedDate,
  generateCreditStr,
  generateActionBarUrl,
} from '../utils';

const styles = (theme) => ({
  backdrop: {
    zIndex: 999,
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
  },
  dialogContent: { display: 'flex', justifyContent: 'center' },
  container: { height: 720, maxWidth: 1150 },
  phone: {
    width: 350,
    height: 700,
    maxHeight: 700,
    display: 'flex',
    flexDirection: 'column',
  },
  statusBar: { width: '100%' },
  body: {
    width: '100%',
    flexGrow: 1,
    height: '100%',
    overflow: 'auto',
    border: '0.5px solid #E4E4E4',
  },
  promotionalBanner: {
    border: '0.5px solid #E4E4E4',
    margin: '4px',
    display: 'flex',
    flexDirection: 'row',
  },
  promotionalImage: {
    width: 100,
  },
  promotionalBannerRight: {
    flex: 1,
    fontSize: '11px',
    padding: '6px 2px 6px 6px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  promotionalText: {
    width: '100%',
    maxHeight: 50,
    overflow: 'hidden',
  },
  promotionalButton: {
    color: '#000096',
    border: '1px solid #000096',
    borderRadius: 3,
    padding: '2px 6px',
    marginRight: '6px',
    marginBottom: '2px',
  },
  readMoreButton: {
    color: '#000096',
    border: '1px solid #000096',
    borderRadius: 3,
    padding: '2px 6px',
    marginTop: '10px',
  },
  image: {
    width: '100%',
    padding: '0px',
    borderBottom: '0.5px solid #E4E4E4',
  },
  arrowButtons: {
    position: 'relative',
    top: -78,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    minHeight: 72,
  },
  actionBar: {
    width: '100%',
    borderBottom: '0.5px solid #E4E4E4',
  },
  caption: {
    margin: '2px 16px 0px 16px',
    lineHeight: '14px',
    fontSize: '11px',
    padding: '0px',
    color: 'gray',
  },
  coverStoryCredit: {
    margin: '0px 16px 16px 16px',
    lineHeight: '14px',
    fontSize: '11px',
    padding: '0px',
    color: 'gray',
  },
  coverStoryCreditWithoutCaption: {
    margin: '2px 16px 16px 16px',
    lineHeight: '14px',
    fontSize: '11px',
    padding: '0px',
    color: 'gray',
  },
  credit: {
    margin: '2px 16px 16px 16px',
    lineHeight: '14px',
    fontSize: '11px',
    padding: '0px',
    color: 'gray',
  },
  citation: {
    whiteSpace: 'pre-wrap',
    margin: '0px 16px 18px 16px',
    lineHeight: '14px',
    fontSize: '11px',
    fontStyle: 'italic',
    padding: '0px',
    color: 'gray',
  },
  shortTitle: {
    margin: '0px 16px 0px 16px',
  },
  homePublishTime: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '2px 16px 0px 16px',
    lineHeight: '14px',
    fontSize: '11px',
    padding: '0px',
    color: 'gray',
  },
  title: {
    margin: '0px 16px 16px 16px',
    whiteSpace: 'pre-wrap',
  },
  normal: {},
  italic: {
    fontStyle: 'italic',
  },
  bold: {
    fontWeight: 'bold',
  },
  boldItalic: {
    fontWeight: 'bold',
    fontStyle: 'italic',
  },
  author: {
    margin: '0px 16px 16px 16px',
    fontWeight: 'bold',
    fontStyle: 'italic',
    color: 'gray',
    fontSize: '13px',
  },
  description: {
    whiteSpace: 'pre-wrap',
    margin: '0px 16px 18px 16px',
  },
  publishTime: {
    fontSize: '11px',
    margin: '0px 16px 16px 16px',
    color: 'gray',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  sectionDiscoverButton: {
    textAlign: 'center',
    margin: '0px 8px 18px 8px',
    '& span': {
      textTransform: 'capitalize',
    },
  },
  discoverButton: {
    textAlign: 'center',
    margin: '8px 8px 8px 8px',
  },
});

class PreviewDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      showArrow: false,
      showImagesOnly: false,
      orders: [],
      canSave: false,
      showExportPreviewDialog: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.asset !== this.props.asset) {
      this.resetState();
    }
    if (prevProps.open !== this.props.open && this.props.open) {
      this.resetState();
    }
  }

  resetState = () => {
    if (!isEmpty(this.props.asset)) {
      const orders = Array(this.props.asset.sections.length)
        .fill(null)
        .map((_, i) => i);
      this.setState({
        showArrow: false,
        showImagesOnly: false,
        orders,
        canSave: false,
      });
    }
  };

  onOpenClick = () => {
    this.resetState();
    this.setState({ open: true });
  };

  onCloseClick = (event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return;
    }
    this.setState({ open: false });
    this.props.onClose();
  };

  onSaveClick = () => {
    this.props
      .updateImageOrders({
        assetId: this.props.asset.id,
        orders: this.state.orders,
      })
      .then(() => {
        if (!this.props.errorMessage) {
          this.props.onChangeImageOrdersSuccess();
          this.setState({ open: false });
          this.props.onClose();
        }
      })
      .catch(() => {
        // ignore
      });
  };

  onShowArrowChange = (e) => {
    this.setState({ showArrow: e.target.checked });
  };

  onShowImagesOnlyChange = (e) => {
    this.setState({ showImagesOnly: e.target.checked });
  };

  swapOrder = (i, j) => {
    const length = this.state.orders.length;
    if (i < 0 || i >= length || j < 0 || j >= length) {
      return;
    }
    const orders = [...this.state.orders];
    [orders[i], orders[j]] = [orders[j], orders[i]];
    this.setState({ orders, canSave: true });
  };

  onMoveUp = (e, index) => {
    this.swapOrder(index, index - 1);
  };

  onMoveDown = (e, index) => {
    this.swapOrder(index, index + 1);
  };

  onExportPreviewClick = () => {
    this.setState({ showExportPreviewDialog: true });
  };

  // This is used to render a legacy asset which has only one section,
  // and doesn't have image caption
  renderClippingDetail = (actionBarUrl, asset, classes) => {
    let displayCredit = '';
    if (!isEmpty(asset)) {
      displayCredit = generateCreditStr(asset.sections[0]);
    }
    return (
      <>
        {!isEmpty(asset) && (
          <div>
            <img
              src={asset.requestOptions[0].portrait.url}
              alt="primary"
              className={classes.image}
            />
            <img
              src={actionBarUrl}
              alt="action-bar"
              className={classes.actionBar}
            />
            <div className={classes.credit}>{displayCredit}</div>
            <div className={`${classes.title} ${classes.bold}`}>
              {asset.sections[0].titleOne}
            </div>
            {asset.showAuthor && (
              <div className={classes.author}>{asset.author}</div>
            )}
            <div className={classes.description}>
              {asset.sections[0].descriptionOne}
            </div>
          </div>
        )}
        <div className={classes.publishTime}>
          <span>
            Published {formatLongPostedDate(asset.publishTime || Date.now())}
          </span>
        </div>
        {!isEmpty(asset) && asset.sourceUrl && (
          <div className={classes.discoverButton}>
            <Button
              aria-label="Discover More"
              color="primary"
              size="small"
              target="_blank"
              rel="noopener noreferrer"
              href={asset.sourceUrl}
            >
              Discover More
            </Button>
          </div>
        )}
      </>
    );
  };

  renderAssetDetail = (actionBarUrl, asset, classes) => {
    const { showArrow, showImagesOnly, orders } = this.state;

    const canReplaceFirst =
      isEmpty(asset) || orders.length < 2
        ? false
        : asset.sections.length >= 2 &&
          asset.sections[orders[1]].titleOne &&
          asset.sections[orders[1]].descriptionOne;

    const videoOpts = {
      width: 350,
      height: 350 / (16 / 9),
    };

    return (
      <>
        {!isEmpty(asset) &&
          orders.map((order, index) => {
            const item = asset.sections[order];
            const requestOptions = asset.requestOptions[order];
            const displayedCredit = generateCreditStr(item);
            return item ? (
              <div key={index}>
                {item.hasVideo ? (
                  <YouTube videoId={item.video.ids[0]} opts={videoOpts} />
                ) : (
                  <img
                    src={requestOptions[item.imageMode].url}
                    alt="primary"
                    className={classes.image}
                  />
                )}
                {showArrow && (
                  <Tooltip title={showArrow ? index + 1 : ''}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-evenly"
                      alignItems="center"
                      className={classes.arrowButtons}
                    >
                      {(index > 1 || (index === 1 && canReplaceFirst)) && (
                        <Grid item sm={2}>
                          <Tooltip title="Go Up" placement="left">
                            <IconButton
                              color="secondary"
                              type="button"
                              onClick={(e) => this.onMoveUp(e, index)}
                              size="large"
                            >
                              <ArrowUpBoldOutline fontSize="large" />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      )}
                      {((index > 0 && index < asset.sections.length - 1) ||
                        (index === 0 && canReplaceFirst)) && (
                        <Grid item sm={2}>
                          <Tooltip title="Go Down" placement="right">
                            <IconButton
                              color="secondary"
                              type="button"
                              onClick={(e) => this.onMoveDown(e, index)}
                              size="large"
                            >
                              <ArrowDownBoldOutline fontSize="large" />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      )}
                    </Grid>
                  </Tooltip>
                )}
                {!showImagesOnly && (
                  <>
                    {index === 0 && (
                      <img
                        src={actionBarUrl}
                        alt="action-bar"
                        className={classes.actionBar}
                      />
                    )}
                    {!!item.caption && (
                      <div className={classes.caption}>{item.caption}</div>
                    )}
                    {!!displayedCredit && (
                      <div
                        className={
                          !!item.caption
                            ? classes.coverStoryCredit
                            : classes.coverStoryCreditWithoutCaption
                        }
                      >
                        {displayedCredit}
                      </div>
                    )}
                    <div
                      className={`${classes.title} ${
                        classes[item.titleOneStyle]
                      }`}
                    >
                      {item.titleOne}
                    </div>
                    {index === 0 && asset.showAuthor && (
                      <div className={classes.author}>{asset.author}</div>
                    )}
                    {!!item.descriptionOne && (
                      <div className={classes.description}>
                        {item.descriptionOne}
                      </div>
                    )}
                    {!!item.titleTwo && (
                      <div
                        className={`${classes.title} ${
                          classes[item.titleTwoStyle]
                        }`}
                      >
                        {item.titleTwo}
                      </div>
                    )}
                    {!!item.descriptionTwo && (
                      <div className={classes.description}>
                        {item.descriptionTwo}
                      </div>
                    )}
                    {!!item.citation && (
                      <div className={classes.citation}>{item.citation}</div>
                    )}
                    {item.sourceUrl && (
                      <div className={classes.sectionDiscoverButton}>
                        <Button
                          aria-label="Discover More"
                          color="primary"
                          size="small"
                          target="_blank"
                          rel="noopener noreferrer"
                          href={item.sourceUrl}
                        >
                          Discover More
                        </Button>
                      </div>
                    )}
                  </>
                )}
              </div>
            ) : null;
          })}
        {!showImagesOnly && (
          <>
            <div className={classes.publishTime}>
              <span>
                Published{' '}
                {formatLongPostedDate(asset.publishTime || Date.now())}
              </span>
            </div>
            {!isEmpty(asset) && asset.sourceUrl && (
              <div className={classes.discoverButton}>
                <Button
                  aria-label="Discover More"
                  color="primary"
                  size="small"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={asset.sourceUrl}
                >
                  Discover More
                </Button>
              </div>
            )}
          </>
        )}
      </>
    );
  };

  renderCheckboxes = () => {
    const { readOnly } = this.props;
    const { showArrow, showImagesOnly } = this.state;
    return (
      <>
        {!readOnly && (
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={this.onShowArrowChange}
                  checked={showArrow}
                />
              }
              label="Show arrange arrows"
            />
            <FormControlLabel
              control={
                <Checkbox
                  onChange={this.onShowImagesOnlyChange}
                  checked={showImagesOnly}
                />
              }
              label="Show images only"
            />
          </FormGroup>
        )}
      </>
    );
  };

  renderCoverStory = (actionBarUrl) => {
    const { asset, classes } = this.props;

    return (
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        className={classes.container}
      >
        {/* Home screen - promotional banner */}
        {asset.promoteInTopStories && (
          <Grid item>
            <div className={classes.phone}>
              <img
                src="/screenshots/home-screen_status-bar_top-stories.png"
                alt="status-bar"
                className={classes.statusBar}
              />
              <div className={classes.body}>
                {!isEmpty(asset) && (
                  <div className={classes.promotionalBanner}>
                    <img
                      src={asset.requestOptions[0].square.url}
                      alt="primary"
                      className={classes.promotionalImage}
                    />
                    <div className={classes.promotionalBannerRight}>
                      <div
                        className={`${classes.promotionalText} ${
                          classes[asset.promotionalTextStyle]
                        }`}
                      >
                        {asset.promotionalText}
                      </div>
                      <div className={classes.promotionalButton}>
                        Read Cover Story
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Grid>
        )}

        {/* Cover story */}
        <Grid item>
          <div className={classes.phone}>
            <img
              src="/screenshots/home-screen_status-bar_cover-story.png"
              alt="status-bar"
              className={classes.statusBar}
            />
            <div className={classes.body}>
              {this.renderAssetDetail(actionBarUrl, asset, classes)}
            </div>
          </div>
        </Grid>
        {this.renderCheckboxes()}
      </Grid>
    );
  };

  renderNormalAsset = (actionBarUrl) => {
    const { asset, classes } = this.props;
    const videoOpts = {
      width: 350,
      height: 350 / (16 / 9),
    };

    return (
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        className={classes.container}
      >
        {/* Home Screen */}
        <Grid item>
          <div className={classes.phone}>
            <img
              src="/screenshots/home-screen_status-bar_top-stories.png"
              alt="status-bar"
              className={classes.statusBar}
            />
            <div className={classes.body}>
              {!isEmpty(asset) && (
                <div>
                  {asset.sections[0].hasVideo ? (
                    <YouTube
                      videoId={asset.sections[0].video.ids[0]}
                      opts={videoOpts}
                    />
                  ) : (
                    <img
                      src={asset.requestOptions[0].landscape.url}
                      alt="primary"
                      className={classes.image}
                    />
                  )}
                  <div className={classes.shortTitle}>{asset.shortTitle}</div>
                  <div className={classes.homePublishTime}>
                    <span>
                      Published{' '}
                      {formatPostedDate(asset.publishTime || Date.now())}
                    </span>
                    {asset.sections[0].hasVideo && (
                      <span className={classes.readMoreButton}>Read more</span>
                    )}
                  </div>
                  <img
                    src={actionBarUrl}
                    alt="action-bar"
                    className={classes.actionBar}
                  />
                </div>
              )}
            </div>
          </div>
        </Grid>

        {/* Detail Screen */}
        <Grid item>
          <div className={classes.phone}>
            <img
              src="/screenshots/detail-screen_status-bar.png"
              alt="status-bar"
              className={classes.statusBar}
            />
            <div className={classes.body}>
              {/* {asset.isLegacy
                ? this.renderClippingDetail(actionBarUrl, asset, classes)
                : this.renderAssetDetail(actionBarUrl, asset, classes)} */}

              {this.renderAssetDetail(actionBarUrl, asset, classes)}
            </div>
          </div>
        </Grid>

        {!asset.isLegacy && this.renderCheckboxes()}
      </Grid>
    );
  };

  render() {
    const { canSave, open, showArrow, showExportPreviewDialog } = this.state;

    const {
      asset,
      classes,
      errorMessage,
      tooltip,
      disabled,
      processing,
      mobileAppConfig,
      readOnly,
      noExport,
      hidePreviewButton,
    } = this.props;

    const actionBarUrl = generateActionBarUrl({ mobileAppConfig, asset });

    return (
      <>
        {!hidePreviewButton && (
          <Tooltip title={tooltip}>
            <span>
              <Button
                aria-label="Preview"
                color="primary"
                onClick={this.onOpenClick}
                variant="contained"
                disabled={isEmpty(asset) || disabled}
              >
                Preview
              </Button>
            </span>
          </Tooltip>
        )}
        <Dialog
          open={open || this.props.open}
          maxWidth={'lg'}
          fullWidth
          onClose={this.onCloseClick}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Preview</DialogTitle>
          {!isEmpty(asset) && (
            <DialogContent className={classes.dialogContent}>
              {asset.category.name === 'Cover Story'
                ? this.renderCoverStory(actionBarUrl)
                : this.renderNormalAsset(actionBarUrl)}
            </DialogContent>
          )}
          <DialogActions>
            {!noExport && (
              <Button
                onClick={this.onExportPreviewClick}
                variant="contained"
                disabled={processing}
              >
                Export Preview
              </Button>
            )}
            <Button
              onClick={this.onCloseClick}
              variant="contained"
              disabled={processing}
            >
              {!showArrow && !canSave ? 'Close' : 'Cancel'}
            </Button>
            {!isEmpty(asset) && !readOnly && !asset.isLegacy && (
              <Button
                onClick={this.onSaveClick}
                color="primary"
                variant="contained"
                disabled={processing || (!showArrow && !canSave)}
              >
                Save
              </Button>
            )}
          </DialogActions>
          <Backdrop className={classes.backdrop} open={processing}>
            <CircularProgress />
          </Backdrop>
        </Dialog>

        {showExportPreviewDialog && (
          <ExportPreviewDialog
            open={showExportPreviewDialog}
            asset={asset}
            onClose={() => {
              this.setState({ showExportPreviewDialog: false });
            }}
          />
        )}

        <Snackbar
          open={!!errorMessage}
          autoHideDuration={7000}
          onClose={() => this.props.clearAssetErrorMessage()}
        >
          <Alert
            severity={'error'}
            action={
              <Button
                size="small"
                color="secondary"
                onClick={() => {
                  this.props.clearAssetErrorMessage();
                }}
              >
                Dismiss
              </Button>
            }
          >
            {errorMessage}
          </Alert>
        </Snackbar>
      </>
    );
  }
}

PreviewDialog.propTypes = {
  asset: PropTypes.any,
  disabled: PropTypes.bool,
  tooltip: PropTypes.string,
  onChangeImageOrdersSuccess: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  readOnly: PropTypes.bool,
  hidePreviewButton: PropTypes.bool,
};

PreviewDialog.defaultProps = {
  disabled: false,
  open: false,
  readOnly: false,
  hidePreviewButton: false,
  onChangeImageOrdersSuccess: () => {},
  onClose: () => {},
};

const mapStateToProps = (state) => {
  return {
    processing: getAssetsLoading(state),
    errorMessage: getAssetsErrorMessage(state),
    mobileAppConfig: state.admin.appVersion.config,
  };
};

export default compose(
  connect(mapStateToProps, {
    clearAssetErrorMessage,
    updateImageOrders,
  }),
  withStyles(styles)
)(PreviewDialog);
