import { normalize } from 'normalizr';
import * as actionTypes from '../actions/types';
import * as schemas from '../schemas';
import { getPreferences } from '../../utils';

const INITIAL_STATE = {
  entities: { ads: {} },
  allIds: [],
  loading: false,
  preferences: getPreferences('ads'),
  error: null,
  message: null,
  editing: {},
  occupiedPositions: [],
};

const adsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.REMOVE_AD:
    case actionTypes.ADD_AD:
    case actionTypes.UPDATE_AD:
    case actionTypes.LOAD_AD:
    case actionTypes.LOAD_ADS: {
      return { ...state, loading: true, error: null };
    }

    case actionTypes.LOAD_ADS_SUCCESS: {
      const ads = action.payload.ads;
      const data = normalize(ads, schemas.adListSchema);
      return {
        ...state,
        loading: false,
        allIds: data.result,
        entities: data.entities,
        preferences: {
          ...getPreferences('ads'), // reset filters
          ...action.payload.preferences,
        },
        totalCount: action.payload.adsCount,
        cutOffCount: action.payload.cutOffCount,
        topStoriesCount: action.payload.topStoriesCount,
        publishedCount: action.payload.publishedCount,
      };
    }

    case actionTypes.ADD_AD_SUCCESS: {
      return {
        ...state,
        allIds: [...state.allIds, action.payload.id],
        entities: {
          ...state.entities,
          ads: {
            ...state.entities.ads,
            [action.payload.id]: action.payload,
          },
        },
        loading: false,
        message: 'An ad has been added',
      };
    }

    case actionTypes.UPDATE_AD_SUCCESS: {
      return {
        ...state,
        entities: {
          ...state.entities,
          ads: {
            ...state.entities.ads,
            [action.payload.id]: action.payload,
          },
        },
        loading: false,
        message: 'An ad has been updated',
      };
    }

    case actionTypes.LOAD_AD_SUCCESS: {
      return {
        ...state,
        editing: action.payload,
      };
    }

    case actionTypes.GET_AD_OCCUPIED_POSITIONS_SUCCESS: {
      return {
        ...state,
        occupiedPositions: action.payload.occupiedPositions,
      };
    }

    case actionTypes.CLEAR_EDITING_AD: {
      return {
        ...state,
        editing: {},
      };
    }

    case actionTypes.REMOVE_AD_SUCCESS: {
      const deletedId = action.payload.deletedId;
      const { [deletedId]: removed, ...ads } = state.entities.ads;
      return {
        ...state,
        allIds: state.allIds.filter((id) => id !== deletedId),
        entities: {
          ...state.entities,
          ads,
        },
        message: 'An ad has been removed',
      };
    }

    case actionTypes.ADD_AD_FAIL:
    case actionTypes.UPDATE_AD_FAIL:
    case actionTypes.REMOVE_AD_FAIL:
    case actionTypes.LOAD_AD_FAIL:
    case actionTypes.LOAD_ADS_FAIL: {
      return { ...state, loading: false, error: action.payload };
    }

    case actionTypes.CLEAR_AD_MESSAGE: {
      return { ...state, message: null };
    }

    case actionTypes.UPDATE_PREFERENCES: {
      const { key, subKey, value } = action.payload;
      if (key === 'ads') {
        return {
          ...state,
          preferences: { ...state.preferences, [subKey]: value },
        };
      }
      return state;
    }

    case actionTypes.RESET_PREFERENCES: {
      return { ...state, preferences: { ...action.payload.ads } };
    }

    default:
      return state;
  }
};

export default adsReducer;
