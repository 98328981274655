import { schema } from 'normalizr';

export const categorySchema = new schema.Entity('categories');
export const assetSchema = new schema.Entity('assets', {
  category: categorySchema,
});
export const assetListSchema = new schema.Array(assetSchema);

export const adSchema = new schema.Entity('ads');
export const adListSchema = new schema.Array(adSchema);

export const invoiceSchema = new schema.Entity('invoices');
export const invoiceListSchema = new schema.Array(invoiceSchema);

export const reportSchema = new schema.Entity('reports');
export const reportListSchema = new schema.Array(reportSchema);

export const notificationMessageSchema = new schema.Entity(
  'notificationMessages'
);
export const notificationMessageListSchema = new schema.Array(
  notificationMessageSchema
);

export const coverImageSchema = new schema.Entity('coverImages');
export const coverImageListSchema = new schema.Array(coverImageSchema);
