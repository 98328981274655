import React from 'react';

import { MTableToolbar } from '@material-table/core';
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Snackbar from '@mui/material/Snackbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import { compose } from '@reduxjs/toolkit';
import { CheckAll, ClockCheckOutline, Minus } from 'mdi-material-ui';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';

import MaterialTable from '../../components/MaterialTable';
import SelectFilterField from '../../components/SelectFilterField';
import {
  clearCoverImageError,
  clearCoverImageMessage,
  loadCoverImages,
  removeCoverImage,
  updateCoverImage,
} from '../../store/actions';
import {
  getCoverImages,
  getCoverImagesCount,
  getCoverImagesErrorMessage,
  getCoverImagesMessage,
} from '../../store/selectors';
import { formatDateTime, formatFullDateTime } from '../../utils';
import {
  COVER_IMAGE_TYPE,
  COVER_IMAGE_TYPE_TO_LABEL,
  COVER_IMAGE_TYPE_OPTIONS,
  PUBLISH_STATUS,
} from './constants';

const styles = (theme) => ({
  topImage: {
    width: 240,
    height: 110,
  },
  highlightImage: {
    width: 86,
    height: 110,
  },
  toolbarInfo: {
    padding: `0px ${theme.spacing(2)}`,
  },
  toolbarChip: {
    marginRight: theme.spacing(1),
  },
  isPublished: {
    marginLeft: theme.spacing(1),
  },
  tooltipText: {
    fontSize: 'inherit',
  },
  primaryImage: {
    marginTop: 4,
    borderRadius: 4,
    width: 32,
    height: 32,
  },
});

class CoverImageList extends React.Component {
  initialPage = -1;

  componentDidMount() {
    this.initialPage = this.props.initial.page;
  }

  getData = (columns) => (query) =>
    new Promise((resolve) => {
      if (this.initialPage > 0) {
        query.page = this.initialPage;
        this.initialPage = -1;
      }
      // orderby and orderDirection are missing from the initial query
      const orderBy = columns[query.orderByCollection[0].orderBy];
      const orderDirection = query.orderByCollection[0].orderDirection;
      this.props
        .loadCoverImages({ ...query, orderBy, orderDirection })
        .then(() => {
          if ((this.props.coverImages.length === 0) & (query.page > 0)) {
            query.page = query.page - 1;
            return this.props.loadCoverImages(query);
          }
          return null;
        })
        .then(() => {
          resolve({
            data: this.props.coverImages,
            page: query.page,
            totalCount: this.props.totalCount,
          });
        });
    });

  renderToolbar = (props) => {
    const { classes, totalCount = 0 } = this.props;

    return (
      <div>
        <MTableToolbar {...props} />
        <div className={classes.toolbarInfo}>
          <Chip
            label={`Total: ${totalCount}`}
            color="secondary"
            className={classes.toolbarChip}
          />
        </div>
      </div>
    );
  };

  renderImage = (row) => {
    const { classes } = this.props;
    return (
      <Tooltip
        style={{ fontSize: 'inherit' }}
        title={
          <>
            <Typography className={classes.tooltipText}>
              {COVER_IMAGE_TYPE_TO_LABEL[row.type]}
            </Typography>
          </>
        }
      >
        <Avatar
          src={row.requestOptions.cropped.url}
          variant="rounded"
          className={
            row.type === COVER_IMAGE_TYPE.TOP
              ? this.props.classes.topImage
              : this.props.classes.highlightImage
          }
        />
      </Tooltip>
    );
  };

  renderAsset = ({ asset }) => {
    const { classes } = this.props;
    return asset ? (
      <Tooltip
        style={{ fontSize: 'inherit' }}
        title={
          <>
            <Typography className={classes.tooltipText}>
              <b>Asset ID</b>: {asset.id}
            </Typography>
            {!!asset.shortTitle && (
              <Typography className={classes.tooltipText}>
                <b>Short Title</b>: {asset.shortTitle}
              </Typography>
            )}
            {!!asset.promotionalText && asset.promoteInTopStories && (
              <Typography className={classes.tooltipText}>
                <b>Promotional Text</b>: {asset.promotionalText}
              </Typography>
            )}
            <Typography className={classes.tooltipText}>
              <b>Title 1</b>: {asset.sections[0].titleOne}
            </Typography>
            <Typography className={classes.tooltipText}>
              <b>Description 1</b>: {asset.sections[0].descriptionOne}
            </Typography>
            <Typography className={classes.tooltipText}>
              <b>Tags</b>: {asset.tags.join(', ')}
            </Typography>
            <Typography className={classes.tooltipText}>
              <b>Image Credit 1</b>: {asset.sections[0].imageCredit}
            </Typography>
            {!!asset.sections[0].imageCopyright && (
              <Typography className={classes.tooltipText}>
                <b>Image Copyright 1</b>: {asset.sections[0].imageCopyright}
              </Typography>
            )}
          </>
        }
      >
        <img
          src={asset.requestOptions[0].square.url}
          className={classes.primaryImage}
          alt="Primary"
        />
      </Tooltip>
    ) : null;
  };

  renderIsPublished = (row) => {
    if (row.publishTime <= 0) {
      return (
        <Tooltip title="Not published">
          <Minus className={this.props.classes.isPublished} />
        </Tooltip>
      );
    }

    return row.publishTime > 0 && row.publishTime <= Date.now() ? (
      <Tooltip title="Published">
        <CheckAll className={this.props.classes.isPublished} color="primary" />
      </Tooltip>
    ) : (
      <Tooltip title="Scheduled">
        <ClockCheckOutline className={this.props.classes.isPublished} />
      </Tooltip>
    );
  };

  renderDateTime = (date) => {
    return formatDateTime(date);
  };

  renderFullDateTime = (date) => {
    return formatFullDateTime(date);
  };

  renderTypeFilter = (props) => {
    return (
      <SelectFilterField
        items={COVER_IMAGE_TYPE_OPTIONS}
        initialValue={this.props.initial.type}
        onFilterChanged={(value) => {
          props.onFilterChanged(props.columnDef.tableData.id, value);
        }}
        labelKey="name"
      />
    );
  };

  renderPublishStatusFilter = (props) => {
    return (
      <SelectFilterField
        items={PUBLISH_STATUS}
        initialValue={this.props.initial.publishStatus}
        onFilterChanged={(value) => {
          props.onFilterChanged(props.columnDef.tableData.id, value);
        }}
        labelKey="name"
      />
    );
  };

  render() {
    const {
      removeCoverImage,
      message,
      errorMessage,
      clearCoverImageMessage,
      clearCoverImageError,
      initial,
    } = this.props;
    const columns = [
      {
        title: 'Image',
        field: 'requestOptions.cropped.url',
        render: this.renderImage,
        filtering: false,
        width: 150,
        sorting: false,
      },
      {
        title: 'Type',
        field: 'type',
        type: 'string',
        render: (row) => COVER_IMAGE_TYPE_TO_LABEL[row.type],
        filterComponent: this.renderTypeFilter,
        defaultFilter: initial.type,
        maxWidth: 200,
        defaultSort:
          initial.orderBy === 'type' ? initial.orderDirection : undefined,
      },
      {
        title: 'Linked Article',
        field: 'asset',
        render: this.renderAsset,
        maxWidth: 120,
        sorting: false,
        filtering: false,
      },
      {
        title: 'Article Category',
        field: 'asset.category.name',
        type: 'string',
        sorting: false,
        filtering: false,
      },
      {
        title: 'Status',
        field: 'isPublished',
        type: 'boolean',
        render: this.renderIsPublished,
        filterComponent: this.renderPublishStatusFilter,
        defaultFilter: initial.publishStatus,
        sorting: false,
        maxWidth: 180,
      },
      {
        title: 'Publish Time',
        field: 'publishTime',
        type: 'datetime',
        filtering: false,
        defaultSort:
          initial.orderBy === 'publishTime'
            ? initial.orderDirection
            : undefined,
        render: (row) => this.renderFullDateTime(row.publishTime),
      },
      {
        title: 'Created At',
        field: 'createdAt',
        type: 'datetime',
        editable: 'never',
        filtering: false,
        render: (row) => this.renderDateTime(row.createdAt),
        defaultSort:
          initial.orderBy === 'createdAt' ? initial.orderDirection : undefined,
      },
      {
        title: 'Updated At',
        field: 'updatedAt',
        type: 'datetime',
        editable: 'never',
        filtering: false,
        render: (row) => this.renderDateTime(row.updatedAt),
        defaultSort:
          initial.orderBy === 'updatedAt' ? initial.orderDirection : undefined,
      },
    ];
    return (
      <>
        <MaterialTable
          title="Cover Images"
          components={{
            Toolbar: this.renderToolbar,
          }}
          columns={columns}
          data={this.getData(columns)}
          onRowClick={(_event, row) =>
            this.props.push(`cover-images/${row.id}`)
          }
          editable={{
            onRowDelete: ({ id }) => {
              return removeCoverImage(id).then((_result) => {
                if (this.props.errorMessage) {
                  return Promise.reject();
                }
              });
            },
          }}
          options={{
            filtering: true,
            initialPage: this.initialPage > 0 ? this.initialPage : 0,
            pageSize: this.props.initial.pageSize,
            searchText: this.props.initial.searchText,
            padding: 'dense',
            emptyRowsWhenPaging: false,
          }}
        />

        <Snackbar
          open={!!message || !!errorMessage}
          autoHideDuration={7000}
          onClose={() => {
            if (errorMessage) {
              clearCoverImageError();
            }
            if (message) {
              clearCoverImageMessage();
            }
          }}
        >
          <Alert
            severity={errorMessage ? 'error' : 'success'}
            action={
              <Button
                size="small"
                color="secondary"
                onClick={() => {
                  if (errorMessage) {
                    clearCoverImageError();
                  }
                  if (message) {
                    clearCoverImageMessage();
                  }
                }}
              >
                Dismiss
              </Button>
            }
          >
            {message || errorMessage}
          </Alert>
        </Snackbar>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    coverImages: getCoverImages(state),
    initial: state.coverImages.preferences,
    totalCount: getCoverImagesCount(state),
    message: getCoverImagesMessage(state),
    errorMessage: getCoverImagesErrorMessage(state),
  };
};

export default compose(
  connect(mapStateToProps, {
    loadCoverImages,
    removeCoverImage,
    updateCoverImage,
    push,
    clearCoverImageMessage,
    clearCoverImageError,
  }),
  withStyles(styles)
)(CoverImageList);
