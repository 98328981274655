import * as actionTypes from './types';
import { MAX_PAGE_SIZE } from '../../utils';

export const loadClients =
  (payload) =>
  (dispatch, getState, { mernApi }) => {
    dispatch({ type: actionTypes.LOAD_CLIENTS });
    const {
      page = 0,
      pageSize = 10,
      orderBy,
      orderDirection,
      search,
      filters,
    } = payload;
    const skip = page * pageSize;
    const sort = orderBy
      ? `${orderDirection === 'desc' ? '-' : ''}${orderBy.field}`
      : 'companyName';
    const params = { sort, limit: pageSize, skip };
    const preferences = {
      page:
        pageSize === MAX_PAGE_SIZE ? getState().clients.preferences.page : page,
      pageSize:
        pageSize === MAX_PAGE_SIZE
          ? getState().clients.preferences.pageSize
          : pageSize,
    };
    if (orderBy?.field) {
      preferences.orderBy = orderBy.field;
    }
    if (orderDirection) {
      preferences.orderDirection = orderDirection;
    }
    if (filters) {
      filters.forEach((filter) => {
        const { field } = filter.column;
        params[field] = filter.value;
        preferences[field] = filter.value;
      });
    }
    if (search) {
      let searchText = search.trim();
      if (searchText) {
        params.searchText = searchText;
        preferences.searchText = searchText;
      }
    }
    return mernApi.get('/api/clients', { params }).then(
      (response) => {
        dispatch({
          type: actionTypes.LOAD_CLIENTS_SUCCESS,
          payload: { ...response.data, preferences },
        });
      },
      (err) => {
        dispatch({
          type: actionTypes.LOAD_CLIENTS_FAIL,
          payload: err.response.data.error.message,
        });
      }
    );
  };

export const loadClient =
  (clientId) =>
  (dispatch, getState, { mernApi }) => {
    dispatch({ type: actionTypes.LOAD_CLIENT });
    return mernApi.get(`/api/clients/${clientId}`).then(
      (response) => {
        dispatch({
          type: actionTypes.LOAD_CLIENT_SUCCESS,
          payload: response.data.client,
        });
      },
      (err) => {
        dispatch({
          type: actionTypes.LOAD_CLIENT_FAIL,
          payload: err.response.data.error.message,
        });
      }
    );
  };

export const addClient =
  (formValues) =>
  (dispatch, getState, { mernApi }) => {
    dispatch({ type: actionTypes.ADD_CLIENT });
    return mernApi.post('/api/clients', formValues).then(
      (response) => {
        dispatch({
          type: actionTypes.ADD_CLIENT_SUCCESS,
          payload: response.data.client,
        });
        return Promise.resolve(response.data);
      },
      (err) => {
        dispatch({
          type: actionTypes.ADD_CLIENT_FAIL,
          payload: err.response.data.error.message,
        });
      }
    );
  };

export const updateClient =
  (formValues) =>
  (dispatch, getState, { mernApi }) => {
    dispatch({ type: actionTypes.UPDATE_CLIENT });
    return mernApi.put(`/api/clients/${formValues.id}`, formValues).then(
      (response) => {
        dispatch({
          type: actionTypes.UPDATE_CLIENT_SUCCESS,
          payload: response.data.client,
        });
        return Promise.resolve(response.data);
      },
      (err) => {
        dispatch({
          type: actionTypes.UPDATE_CLIENT_FAIL,
          payload: err.response.data.error.message,
        });
      }
    );
  };

export const removeClient =
  ({ id: clientId }) =>
  (dispatch, getState, { mernApi }) => {
    dispatch({ type: actionTypes.REMOVE_CLIENT });
    return mernApi.delete(`/api/clients/${clientId}`).then(
      (response) => {
        dispatch({
          type: actionTypes.REMOVE_CLIENT_SUCCESS,
          payload: { ...response.data, deletedId: clientId },
        });
      },
      (err) => {
        dispatch({
          type: actionTypes.REMOVE_CLIENT_FAIL,
          payload: err.response.data.error.message,
        });
      }
    );
  };

export const clearEditingClient = () => {
  return {
    type: actionTypes.CLEAR_EDITING_CLIENT,
  };
};

export const unloadClients = () => {
  return {
    type: actionTypes.UNLOAD_CLIENTS,
  };
};

export const clearClientErrorMessage = () => {
  return {
    type: actionTypes.CLEAR_CLIENT_ERROR_MESSAGE,
  };
};

export const clearClientMessage = () => {
  return {
    type: actionTypes.CLEAR_CLIENT_MESSAGE,
  };
};
