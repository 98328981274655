import * as constants from './constants';
import { merge, cloneDeep } from 'lodash';

let preferences = undefined;

export const getPreferences = (key) => {
  if (!preferences) {
    preferences = JSON.parse(
      localStorage.getItem(constants.PREFERENCES_STORAGE_ID)
    );
    // make sure we have a defaulf preferences
    if (preferences) {
      preferences = merge({}, constants.DEFAULT_PREFERENCES, preferences);
    }
  }
  if (!preferences) {
    preferences = JSON.parse(JSON.stringify(constants.DEFAULT_PREFERENCES));
    resetPreferences();
  }

  const newPreferences = cloneDeep(preferences);

  if (key) {
    return newPreferences[key];
  }
  return newPreferences;
};

export const setPreferences = (payload, key) => {
  if (!preferences) {
    preferences = JSON.parse(
      localStorage.getItem(constants.PREFERENCES_STORAGE_ID)
    );
  }
  if (!preferences) {
    preferences = JSON.parse(JSON.stringify(constants.DEFAULT_PREFERENCES));
  }
  preferences = { ...preferences, [key]: { ...preferences[key], ...payload } };
  localStorage.setItem(
    constants.PREFERENCES_STORAGE_ID,
    JSON.stringify(preferences)
  );
};

export const resetPreferences = () => {
  preferences = JSON.parse(JSON.stringify(constants.DEFAULT_PREFERENCES));
  localStorage.setItem(
    constants.PREFERENCES_STORAGE_ID,
    JSON.stringify(constants.DEFAULT_PREFERENCES)
  );
};
