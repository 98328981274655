import { createSelector } from '@reduxjs/toolkit';

export const getClientsState = (state) => state.clients;

export const getClientsAllIds = (state) => state.clients.allIds;

export const getClientsEntities = (state) => state.clients.entities;

export const getClientsErrorMessage = (state) => state.clients.error;

export const getClientsMessage = (state) => state.clients.message;

export const getClientsCount = (state) => state.clients.totalCount;

export const selectClientsLoading = (state) => state.clients.loading;

export const getClients = createSelector(
  getClientsEntities,
  getClientsAllIds,
  (entities, allIds) => allIds.map((id) => entities[id])
);

export const getEditingClient = (state) => state.clients.editing;
