import {
  Account,
  AccountCircle,
  AccountMultiple,
  AccountTie,
  Advertisements,
  Bell,
  Cash,
  Cog,
  FileChart,
  Harddisk,
  Image,
  ImageMultiple,
  NewspaperVariant,
  Receipt,
} from 'mdi-material-ui';
import UserList from './containers/Users/UserList';
import UserDetail from './containers/Users/UserDetail';
import ClientList from './containers/Clients/ClientList';
import ClientDetail from './containers/Clients/ClientDetail';
import AdDetail from './containers/Ads/AdDetail';
import AdList from './containers/Ads/AdList';
import AdPriceList from './containers/AdPrices/AdPriceList';
import InvoiceDetail from './containers/Invoices/InvoiceDetail';
import InvoiceList from './containers/Invoices/InvoiceList';
import CategoryList from './containers/Categories/CategoryList';
import AssetList from './containers/Assets/AssetList';
import AssetDetail from './containers/Assets/asset-detail/asset-detail';
import Profile from './containers/Auth/Profile';
import CleanUpStorage from './containers/Admin/CleanUpStorage';
import Preferences from './containers/Preferences/Preferences';
import NotificationMessageList from './containers/NotificationMessages/NotificationMessageList';
import NotificationMessageDetail from './containers/NotificationMessages/notification-message-detail';
import ReportDetail from './containers/Reports/ReportDetail';
import ReportList from './containers/Reports/ReportList';
import CoverImageDetail from './containers/cover-images/cover-image-detail';
import CoverImageList from './containers/cover-images/CoverImageList';

const routeCategories = [
  {
    id: 'manage',
    name: 'Manage',
    isHidden: false,
    routes: [
      {
        id: 'adDetail',
        name: 'Ad Detail',
        path: '/dashboard/ads/:adId',
        component: AdDetail,
        requiresPermissions: ['adInsert', 'adRead', 'adModify'],
        isHidden: true,
        customPermissionCheck: null,
        icon: Advertisements,
      },
      {
        id: 'adPrices',
        name: 'Ad Prices',
        path: '/dashboard/prices',
        component: AdPriceList,
        requiresPermissions: ['adPriceInsert', 'adPriceRead', 'adPriceModify'],
        customPermissionCheck: null,
        icon: Cash,
      },
      {
        id: 'ads',
        name: 'Ads',
        path: '/dashboard/ads',
        component: AdList,
        requiresPermissions: ['adInsert', 'adRead', 'adModify'],
        customPermissionCheck: null,
        icon: Advertisements,
      },
      {
        id: 'assetDetail',
        name: 'Article Detail',
        path: '/dashboard/assets/:assetId',
        component: AssetDetail,
        customPermissionCheck: ({ currentUser }) => {
          return (
            (currentUser.permissions.assetRead &&
              currentUser.permissions.assetInsert &&
              currentUser.permissions.assetModify) ||
            currentUser.permissions.assetUnlimitedRead
          );
        },
        isHidden: true,
        icon: NewspaperVariant,
      },
      {
        id: 'assets',
        name: 'Articles',
        path: '/dashboard/assets',
        component: AssetList,
        customPermissionCheck: ({ currentUser }) => {
          return (
            (currentUser.permissions.assetRead &&
              currentUser.permissions.assetInsert &&
              currentUser.permissions.assetModify) ||
            currentUser.permissions.assetUnlimitedRead
          );
        },
        icon: NewspaperVariant,
      },
      {
        id: 'categories',
        name: 'Categories',
        path: '/dashboard/categories',
        component: CategoryList,
        requiresPermissions: [
          'categoryInsert',
          'categoryRead',
          'categoryModify',
        ],
        customPermissionCheck: null,
        icon: ImageMultiple,
      },
      {
        id: 'clientDetail',
        name: 'Client Detail',
        path: '/dashboard/clients/:clientId',
        component: ClientDetail,
        requiresPermissions: ['clientInsert', 'clientRead', 'clientModify'],
        isHidden: true,
        customPermissionCheck: null,
        icon: AccountCircle,
      },
      {
        id: 'clients',
        name: 'Clients',
        path: '/dashboard/clients',
        component: ClientList,
        requiresPermissions: ['clientInsert', 'clientRead', 'clientModify'],
        customPermissionCheck: null,
        icon: AccountTie,
      },
      {
        id: 'coverImageDetail',
        name: 'Cover Image Detail',
        path: '/dashboard/cover-images/:coverImageId',
        component: CoverImageDetail,
        requiresPermissions: [
          'coverImageInsert',
          'coverImageRead',
          'coverImageModify',
        ],
        isHidden: true,
        customPermissionCheck: null,
        icon: Image,
      },
      {
        id: 'coverImages',
        name: 'Cover Images',
        path: '/dashboard/cover-images',
        component: CoverImageList,
        requiresPermissions: [
          'coverImageInsert',
          'coverImageRead',
          'coverImageModify',
        ],
        customPermissionCheck: null,
        icon: Image,
      },
      {
        id: 'invoiceDetail',
        name: 'Invoice Detail',
        path: '/dashboard/invoices/:invoiceId',
        component: InvoiceDetail,
        requiresPermissions: ['invoiceInsert', 'invoiceRead', 'invoiceModify'],
        isHidden: true,
        customPermissionCheck: null,
        icon: Image,
      },
      {
        id: 'invoices',
        name: 'Invoices',
        path: '/dashboard/invoices',
        component: InvoiceList,
        requiresPermissions: ['invoiceInsert', 'invoiceRead', 'invoiceModify'],
        customPermissionCheck: null,
        icon: Receipt,
      },
      {
        id: 'notificationMessageDetail',
        name: 'Notification Message Detail',
        path: '/dashboard/notifications/:notificationMessageId',
        component: NotificationMessageDetail,
        requiresPermissions: [
          'notificationMessageInsert',
          'notificationMessageRead',
          'notificationMessageModify',
        ],
        isHidden: true,
        customPermissionCheck: null,
        icon: Bell,
      },
      {
        id: 'notificationMessages',
        name: 'Notification Messages',
        path: '/dashboard/notifications',
        component: NotificationMessageList,
        requiresPermissions: [
          'notificationMessageInsert',
          'notificationMessageRead',
          'notificationMessageModify',
        ],
        customPermissionCheck: null,
        icon: Bell,
      },
      {
        id: 'reportDetail',
        name: 'Report Detail',
        path: '/dashboard/reports/:reportId',
        component: ReportDetail,
        requiresPermissions: ['assetInsert', 'assetRead', 'assetModify'],
        isHidden: true,
        customPermissionCheck: null,
        icon: AccountMultiple,
      },
      {
        id: 'reports',
        name: 'Reports',
        path: '/dashboard/reports',
        component: ReportList,
        requiresPermissions: ['reportInsert', 'reportRead', 'reportModify'],
        customPermissionCheck: null,
        icon: FileChart,
      },
      {
        id: 'userDetail',
        name: 'User Detail',
        path: '/dashboard/users/:userId',
        component: UserDetail,
        requiresPermissions: ['userInsert', 'userRead', 'userModify'],
        isHidden: true,
        customPermissionCheck: null,
        icon: AccountCircle,
      },
      {
        id: 'users',
        name: 'Users',
        path: '/dashboard/users',
        component: UserList,
        requiresPermissions: ['userInsert', 'userRead', 'userModify'],
        customPermissionCheck: null,
        icon: AccountMultiple,
      },
    ],
  },
  {
    id: 'accountSettings',
    name: 'Account Settings',
    isHidden: true,
    routes: [
      {
        id: 'profile',
        name: 'Profile',
        path: '/dashboard/profile',
        component: Profile,
        icon: Account,
      },
      {
        id: 'preferences',
        name: 'Preferences',
        path: '/dashboard/preferences',
        component: Preferences,
        icon: Cog,
      },
    ],
  },
  {
    id: 'tools',
    name: 'Tools',
    isHidden: false,
    routes: [
      {
        id: 'cleanUpStorage',
        name: 'Clean Up Storage',
        path: '/dashboard/admin/cleanupstorage',
        requiresRole: 'root',
        component: CleanUpStorage,
        icon: Harddisk,
      },
    ],
  },
];

export default routeCategories;
