import React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from '@mui/material';

const YesNoDialog = ({
  open,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  title,
  primaryButtonText = 'Yes',
  secondaryButtonText = 'No',
  message,
  children,
  maxWidth = 'md',
}) => {
  return (
    <Dialog open={open} maxWidth={maxWidth}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {!children && !!message && <Typography>{message}</Typography>}
        {!!children && children}
      </DialogContent>
      <DialogActions>
        {!!onSecondaryButtonClick && (
          <Button variant="outlined" onClick={onSecondaryButtonClick}>
            {secondaryButtonText}
          </Button>
        )}
        {!!onPrimaryButtonClick && (
          <Button
            variant="contained"
            onClick={onPrimaryButtonClick}
            color="primary"
          >
            {primaryButtonText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default YesNoDialog;
