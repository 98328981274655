import React from 'react';
import Alert from '@mui/material/Alert';
import {
  Badge,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  IconButton,
  Snackbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { MobileDateTimePicker, MobileDatePicker } from '@mui/x-date-pickers';
import {
  BellCancelOutline,
  BellPlus,
  ImagePlus,
  ImageRemove,
  Check,
  CheckAll,
  ClockCheckOutline,
  ContentCopy,
  DeleteRestore,
  EyeOutline,
  Hexagram,
  Link as LinkIcon,
  Minus,
  PlusBox,
  ThermometerAlert,
  TestTube,
  ContentDuplicate,
  Youtube,
  ArchiveAlertOutline,
} from 'mdi-material-ui';
import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';
import Countdown, { calcTimeDelta } from 'react-countdown';
import { MTableToolbar } from '@material-table/core';
import copy from 'copy-to-clipboard';
import {
  getAssets,
  getAssetsCounter,
  getAssetsMessage,
  getEditingAsset,
  selectCategories,
  getClients,
  getAssetsLoading,
  getAssetsErrorMessage,
  getCurrentUser,
} from '../../store/selectors';
import {
  loadAsset,
  loadAssets,
  loadCategories,
  loadClients,
  removeAsset,
  restoreAssets,
  updateAsset,
  clearAssetMessage,
  clearAssetErrorMessage,
  clearEditingAsset,
} from '../../store/actions';
import MaterialTable from '../../components/MaterialTable';
import SelectFilterField from '../../components/SelectFilterField';
import PublishingSummaryDialog from '../../components/PublishingSummaryDialog';
import PreviewDialog from '../../components/PreviewDialog';
import {
  formatDateTime,
  formatCountdown,
  formatFullDateTime,
  MAX_PAGE_SIZE,
} from '../../utils';
import {
  ADMIN_ASSET_STATUSES,
  ASSET_STATUSES,
  ADMIN_PUBLISH_STATUS,
  COMPLETION_STATUS,
  PUBLISH_STATUS,
} from './constants';

const styles = (theme) => ({
  imageListContainer: {
    width: 150,
    height: 100,
  },
  primaryImage: {
    borderRadius: 4,
    width: 100,
  },
  secondaryImage: {
    marginLeft: 3,
    width: 47,
    borderRadius: 4,
  },
  toolbarInfo: {
    padding: `0px ${theme.spacing(2)}`,
  },
  toolbarChip: {
    marginRight: theme.spacing(1),
  },
  isPublished: {
    marginLeft: theme.spacing(1),
  },
  inCompleted: {
    marginLeft: theme.spacing(1),
  },
  outOfDate: {
    color: theme.palette.warning.main,
  },
  tooltipText: {
    fontSize: 'inherit',
  },
  dateTimePicker: {
    marginBottom: 8,
    marginRight: 8,
    width: 250,
  },
});

class AssetList extends React.Component {
  initialPage = -1;
  state = {
    localMessage: null,
    selectedEffectiveDate: new Date(),
    localErrorMessage: null,
    openPreview: false,
    showDeletedAssetsOnly: false,
    isAdmin: false,
    isEditor: false,
  };
  tableRef = React.createRef();

  componentDidMount() {
    this.props.loadCategories({ pageSize: MAX_PAGE_SIZE });
    this.initialPage = this.props.initial.page;

    let isAdmin = false;
    let isEditor = false;
    const { currentUser } = this.props;
    if (currentUser) {
      isAdmin = currentUser.role === 'admin' || currentUser.role === 'root';
      isEditor =
        currentUser.permissions.assetInsert &&
        currentUser.permissions.assetModify &&
        currentUser.permissions.assetRead;

      this.setState({ isAdmin, isEditor });
    }

    if (isAdmin || isEditor) {
      this.props.loadClients({ pageSize: MAX_PAGE_SIZE });
    }
  }

  getData = (columns) => (query) =>
    new Promise((resolve) => {
      query.isDeleted = this.state.showDeletedAssetsOnly;
      query.nowMs = this.state.selectedEffectiveDate.getTime();
      if (this.initialPage > 0) {
        query.page = this.initialPage;
        this.initialPage = -1;
      }
      // orderby and orderDirection are missing from the initial query
      const orderBy = columns[query.orderByCollection[0].orderBy];
      const orderDirection = query.orderByCollection[0].orderDirection;
      this.props
        .loadAssets({ ...query, orderBy, orderDirection })
        .then(() => {
          if (this.props.assets.length === 0 && query.page > 0) {
            if (this.props.counter.total > 0) {
              if (this.props.counter.total > query.pageSize) {
                query.page =
                  Math.floor(this.props.counter.total / query.pageSize) - 1;
              } else {
                query.page = 0;
              }
              return this.props.loadAssets(query);
            }
            query.page = 0;
          }
          return null;
        })
        .then(() => {
          // const now = Date.now();
          resolve({
            // data: this.props.assets.map((image) => {
            // image.topStoryTo =
            //   image.topStoryTo > now
            //     ? new Date(image.topStoryTo)
            //     : null;
            //   return image;
            // }),
            data: this.props.assets,
            page: query.page,
            totalCount: this.props.counter.total,
          });
        });
    });

  onCopy = (event, row) => {
    copy(row.id);
    this.setState({
      localMessage: `Asset ID "${row.id}" has been copied to the clipboard`,
    });
  };

  onPreview = (event, row) => {
    this.props.loadAsset(row.id);
    this.setState({ openPreview: true });
  };

  onRestore = (event, row) => {
    this.props.restoreAssets([row.id]).then(() => {
      this.tableRef.current && this.tableRef.current.onQueryChange();
    });
  };

  onSnackbarClose = () => {
    this.props.clearAssetMessage();
    this.props.clearAssetErrorMessage();
    this.setState({ localMessage: null, localErrorMessage: null });
  };

  onShowDeletedAssetsChange = (e) => {
    this.setState({ showDeletedAssetsOnly: e.target.checked });
    this.tableRef.current && this.tableRef.current.onQueryChange();
  };

  onEffectiveDatetimeChange = (selectedDate) => {
    this.setState({ selectedEffectiveDate: selectedDate });
    this.tableRef.current && this.tableRef.current.onQueryChange();
  };

  onNowClick = () => {
    this.setState({ selectedEffectiveDate: new Date() });
    this.tableRef.current && this.tableRef.current.onQueryChange();
  };

  onCreatePushNotification = (event, row) => {
    if (row.publishTime > 0 && row.cutOffTime > Date.now()) {
      this.props.push(`notifications/create?assetId=${row.id}`);
    } else {
      this.props.clearAssetMessage();
      this.setState({
        localErrorMessage:
          'Cannot create push notification with an expired or not-published asset.',
        localMessage: null,
      });
    }
  };

  onCreateCoverImage = (event, row) => {
    if (row.publishTime > 0) {
      this.props.push(`cover-images/create?assetId=${row.id}`);
    } else {
      this.props.clearAssetMessage();
      this.setState({
        localErrorMessage:
          'Cannot create cover image with an unpublished asset.',
        localMessage: null,
      });
    }
  };

  renderToolbar = (props) => {
    const { classes, counter, isLoading } = this.props;
    const { showDeletedAssetsOnly, isAdmin, isEditor } = this.state;

    return (
      <div>
        <MTableToolbar {...props} />
        <div className={classes.toolbarInfo}>
          {(isAdmin || isEditor) && (
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <MobileDateTimePicker
                slotProps={{ textField: { variant: 'standard' } }}
                ampm={false}
                value={this.state.selectedEffectiveDate}
                onChange={this.onEffectiveDatetimeChange}
                format="EEE, MMM dd, yyyy HH:mm"
                showTodayButton
                label="Effective Datetime"
                className={classes.dateTimePicker}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={this.onNowClick}
                disabled={isLoading}
              >
                Now
              </Button>
            </Grid>
          )}
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Tooltip
              title={
                counter.publishedAll !== undefined &&
                counter.notPublished !== undefined ? (
                  <>
                    <Typography style={{ fontSize: 'inherit' }}>
                      <b>Published - All</b>: {counter.publishedAll}
                    </Typography>
                    <Typography style={{ fontSize: 'inherit' }}>
                      <b>Not Published</b>: {counter.notPublished}
                    </Typography>
                  </>
                ) : (
                  <Typography style={{ fontSize: 'inherit' }}>
                    <b>Total result</b>: {counter.total}
                  </Typography>
                )
              }
            >
              <Chip
                label={`Total: ${counter.total}`}
                color="secondary"
                className={classes.toolbarChip}
              />
            </Tooltip>
            {(isAdmin || isEditor) &&
              !showDeletedAssetsOnly &&
              counter.publishedActive !== undefined && (
                <Tooltip
                  title={
                    <>
                      <Typography style={{ fontSize: 'inherit' }}>
                        <b>Published - All</b>: {counter.publishedAll}
                      </Typography>
                      <Typography style={{ fontSize: 'inherit' }}>
                        <b>Published - Active</b>: {counter.publishedActive}
                      </Typography>
                      <Typography style={{ fontSize: 'inherit' }}>
                        <b>Published - Expired</b>: {counter.publishedExpired}
                      </Typography>
                      <Typography style={{ fontSize: 'inherit' }}>
                        <b>Published - Scheduled</b>:&nbsp;
                        {counter.publishedInactive}
                      </Typography>
                    </>
                  }
                >
                  <Chip
                    label={`Published - Active: ${counter.publishedActive}`}
                    color="secondary"
                    className={classes.toolbarChip}
                  />
                </Tooltip>
              )}
            {(isAdmin || isEditor) &&
              !showDeletedAssetsOnly &&
              counter.topStoriesActive !== undefined && (
                <Tooltip
                  title={
                    <>
                      <Typography style={{ fontSize: 'inherit' }}>
                        <b>Top Stories - All</b>: {counter.topStoriesAll}
                      </Typography>
                      <Typography style={{ fontSize: 'inherit' }}>
                        <b>Top Stories - Active</b>: {counter.topStoriesActive}
                      </Typography>
                      <Typography style={{ fontSize: 'inherit' }}>
                        <b>TopStories - Scheduled</b>:&nbsp;
                        {counter.topStoriesInactive}
                      </Typography>
                    </>
                  }
                >
                  <Chip
                    label={`Top Stories - Active: ${counter.topStoriesActive}`}
                    color="secondary"
                    className={classes.toolbarChip}
                  />
                </Tooltip>
              )}
            {(isAdmin || isEditor) &&
              !showDeletedAssetsOnly &&
              counter.notPublishedUpToDate !== undefined && (
                <Tooltip
                  title={
                    <>
                      <Typography style={{ fontSize: 'inherit' }}>
                        <b>Not published - All</b>: {counter.notPublishedAll}
                      </Typography>
                      <Typography style={{ fontSize: 'inherit' }}>
                        <b>Not published - Out-Of-Date</b>:{' '}
                        {counter.notPublishedOutOfDate}
                      </Typography>
                      <Typography style={{ fontSize: 'inherit' }}>
                        <b>Not published - Up-To-Date</b>:{' '}
                        {counter.notPublishedUpToDate}
                      </Typography>
                    </>
                  }
                >
                  <Chip
                    label={`Not Published - Up-To-Date: ${counter.notPublishedUpToDate}`}
                    color="secondary"
                    className={classes.toolbarChip}
                  />
                </Tooltip>
              )}
            <PublishingSummaryDialog />
            <Grid style={{ flex: 1 }}></Grid>
            {(isAdmin || isEditor) && (
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={this.onShowDeletedAssetsChange}
                    checked={this.state.showDeletedAssetsOnly}
                  />
                }
                label="Show Deleted Assets Only"
              />
            )}
          </Grid>
        </div>
      </div>
    );
  };

  renderAsset = (row) => {
    const { classes } = this.props;
    return (
      <Tooltip
        style={{ fontSize: 'inherit' }}
        title={
          <>
            <Typography className={classes.tooltipText}>
              <b>Asset ID</b>: {row.id}
            </Typography>
            <Typography className={classes.tooltipText}>
              <b>Total Sections</b>: {row.sections.length}
            </Typography>
            {row.totalVideos > 0 && (
              <Typography className={classes.tooltipText}>
                <b>Total Videos</b>: {row.totalVideos}
              </Typography>
            )}
            {!!row.shortTitle && (
              <Typography className={classes.tooltipText}>
                <b>Short Title</b>: {row.shortTitle}
              </Typography>
            )}
            {!!row.promotionalText && row.promoteInTopStories && (
              <Typography className={classes.tooltipText}>
                <b>Promotional Text</b>: {row.promotionalText}
              </Typography>
            )}
            <Typography className={classes.tooltipText}>
              <b>Title 1</b>: {row.sections[0].titleOne}
            </Typography>
            {row.showAuthor && (
              <Typography className={classes.tooltipText}>
                <b>Author</b>: {row.author}
              </Typography>
            )}
            <Typography className={classes.tooltipText}>
              <b>Description 1</b>: {row.sections[0].descriptionOne}
            </Typography>
            <Typography className={classes.tooltipText}>
              <b>Tags</b>: {row.tags.join(', ')}
            </Typography>
            <Typography className={classes.tooltipText}>
              <b>Image Credit 1</b>: {row.sections[0].imageCredit}
            </Typography>
            {!!row.sections[0].imageCopyright && (
              <Typography className={classes.tooltipText}>
                <b>Image Copyright 1</b>: {row.sections[0].imageCopyright}
              </Typography>
            )}
          </>
        }
      >
        <Badge
          badgeContent={row.likesCount}
          color="secondary"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <div className={this.props.classes.imageListContainer}>
            <Grid container direction="row" justifyContent="space-evenly">
              <Grid item xs={8}>
                <img
                  src={row.requestOptions[0].square.url}
                  className={this.props.classes.primaryImage}
                  alt="Primary"
                />
              </Grid>
              <Grid item xs={4}>
                <Grid container direction="row" alignContent="space-between">
                  {row.requestOptions.map((requestOption, index) => {
                    return index !== 0 && index <= 2 ? (
                      <Grid item xs={12} key={index}>
                        <img
                          src={requestOption.square.url}
                          className={this.props.classes.secondaryImage}
                          alt="Secondary"
                        />
                      </Grid>
                    ) : null;
                  })}
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Badge>
      </Tooltip>
    );
  };

  renderFavouritesCount = (row) => {
    return <span style={{ marginLeft: 24 }}>{row.favouritesCount}</span>;
  };

  renderIsPublished = (row) => {
    const effectiveTime = this.state.selectedEffectiveDate.getTime();

    return row.publishTime > 0 ? (
      <Tooltip
        title={
          <>
            {row.publishTime > effectiveTime ? (
              <Typography style={{ fontSize: 'inherit' }}>
                <b>Published - Scheduled</b>
                <br />
                <b>Will be published</b>
              </Typography>
            ) : row.cutOffTime <= effectiveTime ? (
              <Typography style={{ fontSize: 'inherit' }}>
                <b>Published - Expired</b>
                <br />
                <b>Not displayed in the mobile app</b>
              </Typography>
            ) : (
              <Typography style={{ fontSize: 'inherit' }}>
                <b>Published - Active</b>
                <br />
                <b>Displayed in the mobile app</b>
              </Typography>
            )}
            <Typography style={{ fontSize: 'inherit' }}>
              <b>Publish Time</b>: {formatDateTime(row.publishTime)}
            </Typography>
            <Typography style={{ fontSize: 'inherit' }}>
              <b>Cut-off Time</b>: {formatDateTime(row.cutOffTime)}
            </Typography>
          </>
        }
      >
        {row.publishTime > effectiveTime ? (
          <ClockCheckOutline
            className={this.props.classes.isPublished}
            color="primary"
          />
        ) : row.cutOffTime > effectiveTime ? (
          <CheckAll
            className={this.props.classes.isPublished}
            color="primary"
          />
        ) : (
          <Check className={this.props.classes.isPublished} color="secondary" />
        )}
      </Tooltip>
    ) : (
      <Tooltip title="Not published">
        <Minus className={this.props.classes.isPublished} />
      </Tooltip>
    );
  };

  renderIncompleted = (row) => {
    return !row.isCompleted ? (
      <Tooltip title="Incompleted">
        <Hexagram
          className={this.props.classes.inCompleted}
          color="secondary"
        />
      </Tooltip>
    ) : null;
  };

  renderStatus = (row) => {
    return (
      <>
        {row.totalVideos > 0 && (
          <Tooltip title={`${row.totalVideos} video(s)`}>
            <Youtube color="error" />
          </Tooltip>
        )}
        {row.isLegacy && (
          <Tooltip title="Legacy Asset">
            <ArchiveAlertOutline color="disabled" />
          </Tooltip>
        )}
        {row.isOutOfDate && (
          <Tooltip title="Out-Of-Date">
            <ThermometerAlert className={this.props.classes.outOfDate} />
          </Tooltip>
        )}
        {row.isTested && (
          <Tooltip title="Tested Asset">
            <TestTube color="primary" />
          </Tooltip>
        )}
        {row.copyOf && (
          <Tooltip title="Duplicated Asset">
            <ContentDuplicate color="disabled" />
          </Tooltip>
        )}
      </>
    );
  };

  renderDateTime = (date) => {
    return formatDateTime(date);
  };

  renderFullDateTime = (date) => {
    return formatFullDateTime(date);
  };

  renderCountdown = (countdownObj, hideOnExpired) => {
    const { completed } = countdownObj;
    if (hideOnExpired && completed) {
      return null;
    }
    return (
      <Typography style={{ fontSize: 'inherit' }}>
        <b>Countdown</b>: {formatCountdown(countdownObj)}
      </Typography>
    );
  };

  renderStartEndDate = (
    startTime,
    endTime,
    hideCountdownOnExpired,
    showClock
  ) => {
    const effectiveTime = this.state.selectedEffectiveDate.getTime();
    const delta = effectiveTime - Date.now();
    return (
      <Tooltip
        title={
          <>
            {startTime <= effectiveTime && (
              <Countdown
                now={() => Date.now() + delta}
                date={endTime}
                renderer={(countdownObj) =>
                  this.renderCountdown(countdownObj, hideCountdownOnExpired)
                }
              />
            )}
            <Typography style={{ fontSize: 'inherit' }}>
              <b>Start</b>: {formatDateTime(startTime)}
            </Typography>
            <Typography style={{ fontSize: 'inherit' }}>
              <b>End</b>: {formatDateTime(endTime)}
            </Typography>
            <Typography style={{ fontSize: 'inherit' }}>
              <b>Duration</b>:{' '}
              {formatCountdown(
                calcTimeDelta(endTime, { now: () => startTime })
              )}
            </Typography>
          </>
        }
      >
        <Typography
          color={endTime <= effectiveTime ? 'secondary' : 'inherit'}
          style={{ fontSize: 'inherit' }}
        >
          {showClock && startTime > effectiveTime && <span>&#128344; </span>}
          {formatDateTime(endTime)}
        </Typography>
      </Tooltip>
    );
  };

  renderSourceUrl = (row) => {
    return row.sourceUrl ? (
      <Badge badgeContent={row.sourceUrlCount} color="secondary">
        <IconButton
          target="_blank"
          rel="noopener noreferrer"
          href={row.sourceUrl}
          size="large"
        >
          <LinkIcon />
        </IconButton>
      </Badge>
    ) : null;
  };

  renderCategoryFilter = (props) => {
    const { categories, initial } = this.props;
    return (
      <SelectFilterField
        items={categories}
        initialValue={initial.categoryId}
        onFilterChanged={(value) => {
          props.onFilterChanged(props.columnDef.tableData.id, value);
        }}
        labelKey="name"
      />
    );
  };

  renderClientFilter = (props) => {
    const { clients, initial } = this.props;
    return (
      <SelectFilterField
        items={clients}
        initialValue={initial.clientId}
        onFilterChanged={(value) => {
          props.onFilterChanged(props.columnDef.tableData.id, value);
        }}
        labelKey="companyName"
        noneOption
      />
    );
  };

  renderCompletionStatusFilter =
    ({ isAdminOrEditor }) =>
    (props) => {
      const completionStatuses = isAdminOrEditor ? COMPLETION_STATUS : [];
      return (
        <SelectFilterField
          items={completionStatuses}
          initialValue={this.props.initial.completionStatus}
          onFilterChanged={(value) => {
            props.onFilterChanged(props.columnDef.tableData.id, value);
          }}
          labelKey="name"
        />
      );
    };

  renderStatusFilter =
    ({ isAdminOrEditor }) =>
    (props) => {
      const assetStatuses = isAdminOrEditor
        ? ADMIN_ASSET_STATUSES
        : ASSET_STATUSES;

      return (
        <SelectFilterField
          items={assetStatuses}
          initialValue={this.props.initial.status}
          onFilterChanged={(value) => {
            props.onFilterChanged(props.columnDef.tableData.id, value);
          }}
          labelKey="name"
        />
      );
    };

  renderPublishStatusFilter =
    ({ isAdminOrEditor }) =>
    (props) => {
      const publishStatuses = isAdminOrEditor
        ? ADMIN_PUBLISH_STATUS
        : PUBLISH_STATUS;

      return (
        <SelectFilterField
          items={publishStatuses}
          initialValue={this.props.initial.publishStatus}
          onFilterChanged={(value) => {
            props.onFilterChanged(props.columnDef.tableData.id, value);
          }}
          labelKey="name"
        />
      );
    };

  renderPublishTimeFilter = (props) => {
    return (
      <MobileDatePicker
        slotProps={{
          textField: {
            variant: 'standard',
            placeholder: 'All',
          },
          actionBar: {
            actions: ['clear', 'accept'],
          },
        }}
        value={this.props.initial.publishTime}
        onAccept={(selectedDate) => {
          props.onFilterChanged(
            props.columnDef.tableData.id,
            selectedDate ? selectedDate.getTime() : null
          );
        }}
        format="MMM-yyyy"
        views={['month', 'year']}
      />
    );
  };

  render() {
    const {
      errorMessage,
      initial,
      isLoading,
      message,
      previewAsset,
      push,
      removeAsset,
    } = this.props;
    const { isAdmin, isEditor } = this.state;

    const columns = [
      {
        title: 'Asset/Likes',
        field: 'requestOptions[0].square.url',
        render: this.renderAsset,
        filtering: false,
        maxWidth: 180,
        defaultSort:
          initial.orderBy === 'requestOptions[0].square.url'
            ? initial.orderDirection
            : undefined,
      },
      {
        title: 'Favourites',
        field: 'favouritesCount',
        render: this.renderFavouritesCount,
        filtering: false,
        defaultSort:
          initial.orderBy === 'favouritesCount'
            ? initial.orderDirection
            : undefined,
      },
      {
        title: 'Status',
        field: 'status',
        type: 'boolean',
        render: this.renderStatus,
        filterComponent: this.renderStatusFilter({
          isAdminOrEditor: isAdmin || isEditor,
        }),
        defaultFilter: initial.status,
        sorting: false,
      },
      {
        title: 'Published',
        field: 'isPublished',
        type: 'boolean',
        render: this.renderIsPublished,
        filterComponent: this.renderPublishStatusFilter({
          isAdminOrEditor: isAdmin || isEditor,
        }),
        defaultFilter: initial.publishStatus,
        sorting: false,
      },
      {
        title: 'Publish Time',
        field: 'publishTime',
        type: 'datetime',
        minWidth: 140,
        editable: 'never',
        defaultSort:
          initial.orderBy === 'publishTime'
            ? initial.orderDirection
            : undefined,
        render: (row) => this.renderFullDateTime(row.publishTime),
        filterComponent: this.renderPublishTimeFilter,
        defaultFilter: initial.publishTime,
      },
      {
        title: 'Cut-off At',
        field: 'cutOffTime',
        type: 'datetime',
        editable: 'never',
        filtering: false,
        render: (row) =>
          this.renderStartEndDate(row.publishTime, row.cutOffTime, false),
        defaultSort:
          initial.orderBy === 'cutOffTime' ? initial.orderDirection : undefined,
      },
      {
        title: 'Incompleted',
        field: 'isCompleted',
        type: 'boolean',
        render: this.renderIncompleted,
        filterComponent: this.renderCompletionStatusFilter({
          isAdminOrEditor: isAdmin || isEditor,
        }),
        defaultFilter: initial.completionStatus,
        sorting: false,
      },
      {
        title: 'Category',
        field: 'category.name',
        filterComponent: this.renderCategoryFilter,
        sorting: false,
        defaultFilter: initial.categoryId,
      },
      {
        title: 'Client',
        field: 'client.companyName',
        filterComponent: this.renderClientFilter,
        sorting: false,
        defaultFilter: initial.clientId,
        cellStyle: {
          display: isAdmin || isEditor ? 'table-cell' : 'none',
        },
        headerStyle: {
          display: isAdmin || isEditor ? 'table-cell' : 'none',
        },
      },
      {
        title: 'TopStory To Category At',
        field: 'topStoryTo',
        type: 'datetime',
        editable: 'never',
        filtering: false,
        render: (row) =>
          this.renderStartEndDate(row.topStoryFrom, row.topStoryTo, true, true),
        defaultSort:
          initial.orderBy === 'topStoryTo' ? initial.orderDirection : undefined,
      },
      {
        title: 'Original Source/Clicks',
        field: 'sourceUrl',
        render: this.renderSourceUrl,
        filtering: false,
        defaultSort:
          initial.orderBy === 'sourceUrl' ? initial.orderDirection : undefined,
      },
      {
        title: 'Created At',
        field: 'createdAt',
        type: 'datetime',
        editable: 'never',
        filtering: false,
        defaultSort:
          initial.orderBy === 'createdAt' ? initial.orderDirection : undefined,
        render: (row) => this.renderDateTime(row.createdAt),
      },
      {
        title: 'Updated At',
        field: 'updatedAt',
        type: 'datetime',
        editable: 'never',
        filtering: false,
        defaultSort:
          initial.orderBy === 'updatedAt' ? initial.orderDirection : undefined,
        render: (row) => this.renderDateTime(row.updatedAt),
      },
    ];

    return (
      <>
        <MaterialTable
          title="Assets"
          tableRef={this.tableRef}
          components={{
            Toolbar: this.renderToolbar,
          }}
          columns={columns}
          data={this.getData(columns)}
          onRowClick={(event, row) => {
            if (!row.isDeleted) {
              push(`assets/${row.id}`);
            }
          }}
          editable={{
            isDeletable: ({ isDeleted }) => !isDeleted,
            isDeleteHidden: () => !this.state.isAdmin && !this.state.isEditor,
            onRowDelete: ({ id }) => {
              return removeAsset(id).then((result) => {
                if (this.props.errorMessage) {
                  return Promise.reject();
                }
              });
            },
          }}
          actions={[
            {
              icon: () => <PlusBox color="secondary" />,
              tooltip: 'Add New Article',
              isFreeAction: true,
              hidden: !this.state.isAdmin && !this.state.isEditor,
              // onClick: this.onMenuOpen,
              onClick: () => push('assets/create'),
            },
            (rowData) => {
              if (!rowData.isDeleted && this.state.isAdmin) {
                const enable =
                  rowData.publishTime > 0 && rowData.cutOffTime > Date.now();
                return {
                  icon: () =>
                    enable ? (
                      <BellPlus color="primary" />
                    ) : (
                      <BellCancelOutline />
                    ),
                  tooltip: enable
                    ? `Create Push Notification - "${
                        rowData.category.name === 'Cover Story'
                          ? rowData.promotionalText
                          : rowData.shortTitle
                      }"`
                    : 'Asset is expired or not published',
                  onClick: this.onCreatePushNotification,
                };
              }
            },
            (rowData) => {
              if (!rowData.isDeleted && this.state.isAdmin) {
                const enable = rowData.publishTime > 0;
                return {
                  icon: () =>
                    enable ? <ImagePlus color="primary" /> : <ImageRemove />,
                  tooltip: enable
                    ? `Create Cover Image`
                    : 'Asset is not published',
                  onClick: this.onCreateCoverImage,
                };
              }
            },
            (rowData) => {
              if (!rowData.isDeleted) {
                return {
                  icon: () => <EyeOutline />,
                  tooltip: `Preview`,
                  onClick: this.onPreview,
                };
              }
            },
            (rowData) => {
              if (!rowData.isDeleted) {
                return {
                  icon: () => <ContentCopy />,
                  tooltip: `Copy Asset ID - "${rowData.id}"`,
                  onClick: this.onCopy,
                };
              }
            },
            (rowData) => {
              if (
                rowData.isDeleted &&
                (this.state.isAdmin || this.state.isEditor)
              ) {
                return {
                  icon: () => (
                    <DeleteRestore color={isLoading ? 'disabled' : 'primary'} />
                  ),
                  tooltip: 'Restore',
                  onClick: this.onRestore,
                  disabled: isLoading,
                };
              }
            },
          ]}
          options={{
            filtering: true,
            initialPage: this.initialPage > 0 ? this.initialPage : 0,
            pageSize: this.props.initial.pageSize,
            searchText: this.props.initial.searchText,
            // padding: 'dense',
            emptyRowsWhenPaging: false,
            rowStyle: (rowData) => ({
              backgroundColor: rowData.isDeleted
                ? 'rgba(255, 0, 0, 0.1)'
                : rowData.copyOf
                ? '#EEE'
                : '#FFF',
            }),
            cellStyle: {
              padding: '8px 16px 8px 16px',
            },
          }}
        />

        <Snackbar
          open={
            !!message ||
            !!errorMessage ||
            !!this.state.localMessage ||
            !!this.state.localErrorMessage
          }
          autoHideDuration={7000}
          onClose={this.onSnackbarClose}
        >
          <Alert
            severity={
              errorMessage || this.state.localErrorMessage ? 'error' : 'success'
            }
            action={
              <Button
                size="small"
                color="secondary"
                onClick={this.onSnackbarClose}
              >
                Dismiss
              </Button>
            }
          >
            {errorMessage ||
              message ||
              this.state.localErrorMessage ||
              this.state.localMessage}
          </Alert>
        </Snackbar>
        <PreviewDialog
          asset={previewAsset}
          onClose={() => {
            this.setState({ openPreview: false });
            this.props.clearEditingAsset();
          }}
          open={this.state.openPreview}
          readOnly
          noExport={!isAdmin && !isEditor}
          hidePreviewButton
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    assets: getAssets(state),
    previewAsset: getEditingAsset(state),
    categories: selectCategories(state),
    clients: getClients(state),
    initial: state.assets.preferences,
    counter: getAssetsCounter(state),
    message: getAssetsMessage(state),
    errorMessage: getAssetsErrorMessage(state),
    isLoading: getAssetsLoading(state),
    currentUser: getCurrentUser(state),
  };
};

export default compose(
  connect(mapStateToProps, {
    loadCategories,
    loadClients,
    loadAsset,
    loadAssets,
    removeAsset,
    restoreAssets,
    updateAsset,
    push,
    clearAssetMessage,
    clearAssetErrorMessage,
    clearEditingAsset,
  }),
  withStyles(styles)
)(AssetList);
