import * as actionTypes from '../actions/types';

const INITIAL_STATE = {
  asset: [], // kit for uploading assets
  ad: {}, // kit for uploading ads
  error: null,
};

const awsS3UploadKitReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_AD_SIGNED_REQUEST_OPTIONS:
    case actionTypes.REMOVE_IMAGE: {
      return { ...state, error: null };
    }

    case actionTypes.GET_AD_SIGNED_REQUEST_OPTIONS_SUCCESS: {
      return {
        ...state,
        ad: action.payload,
      };
    }

    case actionTypes.REMOVE_S3_OBJECTS_SUCCESS: {
      if (action.payload.type === 'ad') {
        return { ...state, ad: {} };
      }

      return state;
    }

    case actionTypes.CLEAR_EDITING_ASSET: {
      return {
        ...state,
        asset: [],
      };
    }

    case actionTypes.CLEAR_EDITING_AD: {
      return {
        ...state,
        ad: {},
      };
    }

    case actionTypes.REMOVE_IMAGE_FAIL: {
      return { ...state, error: action.payload };
    }

    default:
      return state;
  }
};

export default awsS3UploadKitReducer;
