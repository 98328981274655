export const SIGN_UP = '[Auth] Sign Up';
export const SIGN_UP_SUCCESS = '[Auth] Sign Up Success';
export const SIGN_UP_FAIL = '[Auth] Sign Up Fail';

export const SIGN_IN = '[Auth] Sign In';
export const SIGN_IN_SUCCESS = '[Auth] Sign In Success';
export const SIGN_IN_FAIL = '[Auth] Sign In Fail';

export const FACEBOOK_SIGN_IN = '[Auth] Facebook Sign In';
export const FACEBOOK_SIGN_IN_SUCCESS = '[Auth] Facebook Sign In Success';
export const FACEBOOK_SIGN_IN_FAIL = '[Auth] Facebook Sign In Fail';

export const GOOGLE_SIGN_IN = '[Auth] Google Sign In';
export const GOOGLE_SIGN_IN_SUCCESS = '[Auth] Google Sign In Success';
export const GOOGLE_SIGN_IN_FAIL = '[Auth] Google Sign In Fail';

export const TRY_LOCAL_SIGN_IN = '[Auth] Try Local Sign In';
export const TRY_LOCAL_SIGN_IN_SUCCESS = '[Auth] Try Local Sign In Success';
export const TRY_LOCAL_SIGN_IN_FAIL = '[Auth] Try Local Sign In Fail';

export const SIGN_OUT = '[Auth] Sign Out';
export const SIGN_OUT_SUCCESS = '[Auth] Sign Out Success';

export const SET_ATTEMPTED_PATH = '[Auth] Set Attempted Path';

export const VERIFY_EMAIL = '[Auth] Verify Email';
export const VERIFY_EMAIL_SUCCESS = '[Auth] Verify Email Success';
export const VERIFY_EMAIL_FAIL = '[Auth] Verify Email Fail';

export const REQUEST_VERIFICATION_EMAIL = '[Auth] Request Verification Email';
export const REQUEST_VERIFICATION_EMAIL_SUCCESS =
  '[Auth] Request Verification Email Success';
export const REQUEST_VERIFICATION_EMAIL_FAIL =
  '[Auth] Request Verification Email Fail';

export const REQUEST_PASSWORD_RESET = '[Auth] Request Password Reset';
export const REQUEST_PASSWORD_RESET_SUCCESS =
  '[Auth] Request Password Reset Success';
export const REQUEST_PASSWORD_RESET_FAIL = '[Auth] Request Password Reset Fail';

export const RESET_PASSWORD = '[Auth] Reset Password';
export const RESET_PASSWORD_SUCCESS = '[Auth] Reset Password Success';
export const RESET_PASSWORD_FAIL = '[Auth] Reset Password Fail';

export const UNLOAD_AUTH_PAGE = '[Auth] Unload Auth Page';

// Assets
export const LOAD_ASSETS = '[Assets] Load Assets';
export const LOAD_ASSETS_SUCCESS = '[Assets] Load Assets Success';
export const LOAD_ASSETS_FAIL = '[Assets] Load Assets Fail';

export const LOAD_ASSET = '[Assets] Load Asset';
export const LOAD_ASSET_SUCCESS = '[Assets] Load Asset Success';
export const LOAD_ASSET_FAIL = '[Assets] Load Asset Fail';

export const ADD_ASSET = '[Assets] Add Asset';
export const ADD_ASSET_SUCCESS = '[Assets] Add Asset Success';
export const ADD_ASSET_FAIL = '[Assets] Add Asset Fail';

export const DUPLICATE_ASSET = '[Assets] Duplicate Asset';
export const DUPLICATE_ASSET_SUCCESS = '[Assets] Duplicate Asset Success';
export const DUPLICATE_ASSET_FAIL = '[Assets] Duplicate Asset Fail';

export const UPDATE_ASSET = '[Assets] Update Asset';
export const UPDATE_ASSET_SUCCESS = '[Assets] Update Asset Success';
export const UPDATE_ASSET_FAIL = '[Assets] Update Asset Fail';

export const UPDATE_IMAGE_ORDERS = '[Assets] Update Image Orders';
export const UPDATE_IMAGE_ORDERS_SUCCESS =
  '[Assets] Update Image Orders Success';
export const UPDATE_IMAGE_ORDERS_FAIL = '[Assets] Update Image Orders Fail';

export const REMOVE_ASSET = '[Assets] Remove Asset';
export const REMOVE_ASSET_SUCCESS = '[Assets] Remove Asset Success';
export const REMOVE_ASSET_FAIL = '[Assets] Remove Asset Fail';

export const RESTORE_ASSETS = '[Assets] Restore Assets';
export const RESTORE_ASSETS_SUCCESS = '[Assets] Restore Assets Success';
export const RESTORE_ASSETS_FAIL = '[Assets] Restore Assets Fail';

export const REMOVE_IMAGE = '[Assets] Remove Image';
export const REMOVE_IMAGE_SUCCESS = '[Assets] Remove Image Success';
export const REMOVE_IMAGE_FAIL = '[Assets] Remove Image Fail';

export const CLEAR_EDITING_ASSET = '[Assets] Clear Editing Asset';
export const CLEAR_ASSET_ERROR_MESSAGE = '[Assets] Clear Error Message';
export const CLEAR_ASSET_MESSAGE = '[Assets] Clear Message';

export const GENERATE_ASSET_SUMMARY = '[Assets] Generate Summary';
export const GENERATE_ASSET_SUMMARY_SUCCESS =
  '[Assets] Generate Summary Success';
export const GENERATE_ASSET_SUMMARY_FAIL = '[Assets] Generate Summary Fail';

export const LOAD_BASE64 = '[Base64] Load';
export const LOAD_BASE64_SUCCESS = '[Base64] Load Success';
export const LOAD_BASE64_FAIL = '[Base64] Load Fail';

export const UNLOAD_BASE64 = '[Base64] Unload';

// Price Rates
export const LOAD_AD_PRICES = '[Price Rates] Load Price Rates';
export const LOAD_AD_PRICES_SUCCESS = '[Price Rates] Load Price Rates Success';
export const LOAD_AD_PRICES_FAIL = '[Price Rates] Load Price Rates Fail';

export const UNLOAD_AD_PRICES = '[Price Rates] Unload Price Rates';

export const ADD_AD_PRICE = '[Price Rates] Add Price Rate';
export const ADD_AD_PRICE_SUCCESS = '[Price Rates] Add Price Rate Success';
export const ADD_AD_PRICE_FAIL = '[Price Rates] Add Price Rate Fail';

export const UPDATE_AD_PRICE = '[Price Rates] Update Price Rate';
export const UPDATE_AD_PRICE_SUCCESS =
  '[Price Rates] Update Price Rate Success';
export const UPDATE_AD_PRICE_FAIL = '[Price Rates] Update Price Rate Fail';

export const REMOVE_AD_PRICE = '[Price Rates] Remove Price Rate';
export const REMOVE_AD_PRICE_SUCCESS =
  '[Price Rates] Remove Price Rate Success';
export const REMOVE_AD_PRICE_FAIL = '[Price Rates] Remove Price Rate Fail';

export const CLEAR_AD_PRICE_ERROR_MESSAGE = '[Price Rates] Clear Error Message';
export const CLEAR_AD_PRICE_MESSAGE = '[Price Rates] Clear Message';

// Categories
export const LOAD_CATEGORIES = '[Categories] Load Categories';
export const LOAD_CATEGORIES_SUCCESS = '[Categories] Load Categories Success';
export const LOAD_CATEGORIES_FAIL = '[Categories] Load Categories Fail';

export const UNLOAD_CATEGORIES = '[Categories] Unload Categories';

export const ADD_CATEGORY = '[Categories] Add Category';
export const ADD_CATEGORY_SUCCESS = '[Categories] Add Category Success';
export const ADD_CATEGORY_FAIL = '[Categories] Add Category Fail';

export const UPDATE_CATEGORY = '[Categories] Update Category';
export const UPDATE_CATEGORY_SUCCESS = '[Categories] Update Category Success';
export const UPDATE_CATEGORY_FAIL = '[Categories] Update Category Fail';

export const REMOVE_CATEGORY = '[Categories] Remove Category';
export const REMOVE_CATEGORY_SUCCESS = '[Categories] Remove Category Success';
export const REMOVE_CATEGORY_FAIL = '[Categories] Remove Category Fail';

export const CLEAR_CATEGORY_ERROR_MESSAGE = '[Categories] Clear Error Message';
export const CLEAR_CATEGORY_MESSAGE = '[Categories] Clear Message';

// Users
export const LOAD_USERS = '[Users] Load Users';
export const LOAD_USERS_SUCCESS = '[Users] Load Users Success';
export const LOAD_USERS_FAIL = '[Users] Load Users Fail';

export const LOAD_USER = '[Users] Load User';
export const LOAD_USER_SUCCESS = '[Users] Load User Success';
export const LOAD_USER_FAIL = '[Users] Load User Fail';

export const UPDATE_USER = '[Users] Update User';
export const UPDATE_USER_SUCCESS = '[Users] Update User Success';
export const UPDATE_USER_FAIL = '[Users] Update User Fail';

export const REMOVE_USER = '[Users] Remove User';
export const REMOVE_USER_SUCCESS = '[Users] Remove User Success';
export const REMOVE_USER_FAIL = '[Users] Remove User Fail';

export const CLEAR_EDITING_USER = '[Users] Clear Editing User';
export const CLEAR_USER_ERROR_MESSAGE = '[Users] Clear Error Message';
export const CLEAR_USER_MESSAGE = '[Users] Clear Message';

// Admin
export const COUNT_ORPHAN_FILES = '[Admin] Count Orphan Files';
export const COUNT_ORPHAN_FILES_SUCCESS = '[Admin] Count Orphan Files Success';
export const COUNT_ORPHAN_FILES_FAIL = '[Admin] Count Orphan Files Fail';

export const CLEAN_UP_STORAGE = '[Admin] Clean Up Storage';
export const CLEAN_UP_STORAGE_SUCCESS = '[Admin] Clean Up Storage Success';
export const CLEAN_UP_STORAGE_FAIL = '[Admin] Clean Up Storage Fail';

export const CLEAR_ADMIN_MESSAGES = '[Admin] Clear Messages';

export const GET_APP_VERSION = '[Admin] Get App Version';
export const GET_APP_VERSION_SUCCESS = '[Admin] Get App Version Success';
export const GET_APP_VERSION_FAIL = '[Admin] Get App Version Fail';

// Ads
export const LOAD_ADS = '[Ads] Load Ads';
export const LOAD_ADS_SUCCESS = '[Ads] Load Ads Success';
export const LOAD_ADS_FAIL = '[Ads] Load Ads Fail';

export const LOAD_AD = '[Ads] Load Ad';
export const LOAD_AD_SUCCESS = '[Ads] Load Ad Success';
export const LOAD_AD_FAIL = '[Ads] Load Ad Fail';

export const ADD_AD = '[Ads] Add Ad';
export const ADD_AD_SUCCESS = '[Ads] Add Ad Success';
export const ADD_AD_FAIL = '[Ads] Add Ad Fail';

export const UPDATE_AD = '[Ads] Update Ad';
export const UPDATE_AD_SUCCESS = '[Ads] Update Ad Success';
export const UPDATE_AD_FAIL = '[Ads] Update Ad Fail';

export const REMOVE_AD = '[Ads] Remove Ad';
export const REMOVE_AD_SUCCESS = '[Ads] Remove Ad Success';
export const REMOVE_AD_FAIL = '[Ads] Remove Ad Fail';

export const CLEAR_EDITING_AD = '[Ads] Clear Editing Ad';
export const CLEAR_AD_ERROR_MESSAGE = '[Ads] Clear Error Message';
export const CLEAR_AD_MESSAGE = '[Ads] Clear Message';

export const GET_AD_SIGNED_REQUEST_OPTIONS = '[Ads] Get Signed Request Options';
export const GET_AD_SIGNED_REQUEST_OPTIONS_SUCCESS =
  '[Ads] Get Signed Request Options Success';
export const GET_AD_SIGNED_REQUEST_OPTIONS_FAIL =
  '[Ads] Get Signed Request Options Fail';

export const GET_AD_OCCUPIED_POSITIONS = '[Ads] Get occupied positions';
export const GET_AD_OCCUPIED_POSITIONS_SUCCESS =
  '[Ads] Get occupied positions Success';
export const GET_AD_OCCUPIED_POSITIONS_FAIL =
  '[Ads] Get occupied positions Fail';

// AWS S3
export const REMOVE_S3_OBJECTS = '[AwsS3] Remove S3 Objects';
export const REMOVE_S3_OBJECTS_SUCCESS = '[AwsS3] Remove S3 Objects Success';

// Client
export const LOAD_CLIENTS = '[Clients] Load Clients';
export const LOAD_CLIENTS_SUCCESS = '[Clients] Load Clients Success';
export const LOAD_CLIENTS_FAIL = '[Clients] Load Clients Fail';

export const UNLOAD_CLIENTS = '[Clients] Unload Clients';

export const LOAD_CLIENT = '[Clients] Load Client';
export const LOAD_CLIENT_SUCCESS = '[Clients] Load Client Success';
export const LOAD_CLIENT_FAIL = '[Clients] Load Client Fail';

export const ADD_CLIENT = '[Clients] Add Client';
export const ADD_CLIENT_SUCCESS = '[Clients] Add Client Success';
export const ADD_CLIENT_FAIL = '[Clients] Add Client Fail';

export const UPDATE_CLIENT = '[Clients] Update Client';
export const UPDATE_CLIENT_SUCCESS = '[Clients] Update Client Success';
export const UPDATE_CLIENT_FAIL = '[Clients] Update Client Fail';

export const REMOVE_CLIENT = '[Clients] Remove Client';
export const REMOVE_CLIENT_SUCCESS = '[Clients] Remove Client Success';
export const REMOVE_CLIENT_FAIL = '[Clients] Remove Client Fail';

export const CLEAR_EDITING_CLIENT = '[Clients] Clear Editing Client';
export const CLEAR_CLIENT_ERROR_MESSAGE = '[Clients] Clear Error Message';
export const CLEAR_CLIENT_MESSAGE = '[Clients] Clear Message';

// Invoice
export const LOAD_INVOICES = '[Invoices] Load Invoices';
export const LOAD_INVOICES_SUCCESS = '[Invoices] Load Invoices Success';
export const LOAD_INVOICES_FAIL = '[Invoices] Load Invoices Fail';

export const LOAD_INVOICE = '[Invoices] Load Invoice';
export const LOAD_INVOICE_SUCCESS = '[Invoices] Load Invoice Success';
export const LOAD_INVOICE_FAIL = '[Invoices] Load Invoice Fail';

export const GENERATE_PREVIEW_INVOICE = '[Invoices] Generate Preview Invoice';
export const GENERATE_PREVIEW_INVOICE_SUCCESS =
  '[Invoices] Generate Preview Invoice Success';
export const GENERATE_PREVIEW_INVOICE_FAIL =
  '[Invoices] Generate Preview Invoice Fail';

export const ADD_INVOICE = '[Invoices] Add Invoice';
export const ADD_INVOICE_SUCCESS = '[Invoices] Add Invoice Success';
export const ADD_INVOICE_FAIL = '[Invoices] Add Invoice Fail';

export const UPDATE_INVOICE = '[Invoices] Update Invoice';
export const UPDATE_INVOICE_SUCCESS = '[Invoices] Update Invoice Success';
export const UPDATE_INVOICE_FAIL = '[Invoices] Update Invoice Fail';

export const REMOVE_INVOICE = '[Invoices] Remove Invoice';
export const REMOVE_INVOICE_SUCCESS = '[Invoices] Remove Invoice Success';
export const REMOVE_INVOICE_FAIL = '[Invoices] Remove Invoice Fail';

export const CLEAR_EDITING_INVOICE = '[Invoices] Clear Editing Invoice';
export const CLEAR_INVOICE_ERROR_MESSAGE = '[Invoices] Clear Error Message';
export const CLEAR_INVOICE_MESSAGE = '[Invoices] Clear Message';

// Report
export const LOAD_REPORTS = '[Reports] Load Reports';
export const LOAD_REPORTS_SUCCESS = '[Reports] Load Reports Success';
export const LOAD_REPORTS_FAIL = '[Reports] Load Reports Fail';

export const LOAD_REPORT = '[Reports] Load Report';
export const LOAD_REPORT_SUCCESS = '[Reports] Load Report Success';
export const LOAD_REPORT_FAIL = '[Reports] Load Report Fail';

export const GENERATE_PREVIEW_REPORT = '[Reports] Generate Preview Report';
export const GENERATE_PREVIEW_REPORT_SUCCESS =
  '[Reports] Generate Preview Report Success';
export const GENERATE_PREVIEW_REPORT_FAIL =
  '[Reports] Generate Preview Report Fail';

export const ADD_REPORT = '[Reports] Add Report';
export const ADD_REPORT_SUCCESS = '[Reports] Add Report Success';
export const ADD_REPORT_FAIL = '[Reports] Add Report Fail';

export const REMOVE_REPORT = '[Reports] Remove Report';
export const REMOVE_REPORT_SUCCESS = '[Reports] Remove Report Success';
export const REMOVE_REPORT_FAIL = '[Reports] Remove Report Fail';

export const CLEAR_PREVIEW_REPORT = '[Reports] Clear Preview Report';
export const CLEAR_EDITING_REPORT = '[Reports] Clear Editing Report';
export const CLEAR_REPORT_ERROR_MESSAGE = '[Reports] Clear Error Message';
export const CLEAR_REPORT_MESSAGE = '[Reports] Clear Message';

// Notification Message
export const LOAD_NOTIFICATION_MESSAGES =
  '[Notification Messages] Load Notification-Messages';
export const LOAD_NOTIFICATION_MESSAGES_SUCCESS =
  '[Notification Messages] Load Notification-Messages Success';
export const LOAD_NOTIFICATION_MESSAGES_FAIL =
  '[Notification Messages] Load Notification-Messages Fail';

export const LOAD_NOTIFICATION_MESSAGE =
  '[Notification Messages] Load Notification-Message';
export const LOAD_NOTIFICATION_MESSAGE_SUCCESS =
  '[Notification Messages] Load Notification-Message Success';
export const LOAD_NOTIFICATION_MESSAGE_FAIL =
  '[Notification Messages] Load Notification-Message Fail';

export const ADD_NOTIFICATION_MESSAGE =
  '[Notification Messages] Add Notification-Message';
export const ADD_NOTIFICATION_MESSAGE_SUCCESS =
  '[Notification Messages] Add Notification-Message Success';
export const ADD_NOTIFICATION_MESSAGE_FAIL =
  '[Notification Messages] Add Notification-Message Fail';

export const UPDATE_NOTIFICATION_MESSAGE =
  '[Notification Messages] Update Notification-Message';
export const UPDATE_NOTIFICATION_MESSAGE_SUCCESS =
  '[Notification Messages] Update Notification-Message Success';
export const UPDATE_NOTIFICATION_MESSAGE_FAIL =
  '[Notification Messages] Update Notification-Message Fail';

export const REMOVE_NOTIFICATION_MESSAGE =
  '[Notification Messages] Remove Notification-Message';
export const REMOVE_NOTIFICATION_MESSAGE_SUCCESS =
  '[Notification Messages] Remove Notification-Message Success';
export const REMOVE_NOTIFICATION_MESSAGE_FAIL =
  '[Notification Messages] Remove Notification-Message Fail';

export const CLEAR_EDITING_NOTIFICATION_MESSAGE =
  '[Notification Messages] Clear Editing Notification Messagge';
export const CLEAR_NOTIFICATION_MESSAGE_MESSAGE =
  '[Notification Messages] Clear notification-Message message';

export const CLEAR_NOTIFICATION_MESSAGE_ERROR =
  '[Notification Messages] Clear notification-Message error';

// Cover Images
export const LOAD_COVER_IMAGES = '[Cover Images] Load Cover-Images';
export const LOAD_COVER_IMAGES_SUCCESS =
  '[Cover Images] Load Cover-Images Success';
export const LOAD_COVER_IMAGES_FAIL = '[Cover Images] Load Cover-Images Fail';

export const LOAD_COVER_IMAGE = '[Cover Images] Load Cover-Image';
export const LOAD_COVER_IMAGE_SUCCESS =
  '[Cover Images] Load Cover-Image Success';
export const LOAD_COVER_IMAGE_FAIL = '[Cover Images] Load Cover-Image Fail';

export const ADD_COVER_IMAGE = '[Cover Images] Add Cover-Image';
export const ADD_COVER_IMAGE_SUCCESS = '[Cover Images] Add Cover-Image Success';
export const ADD_COVER_IMAGE_FAIL = '[Cover Images] Add Cover-Image Fail';

export const UPDATE_COVER_IMAGE = '[Cover Images] Update Cover-Image';
export const UPDATE_COVER_IMAGE_SUCCESS =
  '[Cover Images] Update Cover-Image Success';
export const UPDATE_COVER_IMAGE_FAIL = '[Cover Images] Update Cover-Image Fail';

export const REMOVE_COVER_IMAGE = '[Cover Images] Remove Cover-Image';
export const REMOVE_COVER_IMAGE_SUCCESS =
  '[Cover Images] Remove Cover-Image Success';
export const REMOVE_COVER_IMAGE_FAIL = '[Cover Images] Remove Cover-Image Fail';

export const CLEAR_EDITING_COVER_IMAGE =
  '[Cover Images] Clear Editing Notification Messagge';
export const CLEAR_COVER_IMAGE_MESSAGE =
  '[Cover Images] Clear notification-Message message';

export const CLEAR_COVER_IMAGE_ERROR =
  '[Cover Images] Clear notification-Message error';

// Preferences
export const UPDATE_PREFERENCES = '[Preferences] Update Preferences';
export const RESET_PREFERENCES = '[Preferences] Reset Preferences';
