import React from 'react';
import { withStyles } from '@mui/styles';
import { useWatch } from 'react-hook-form';

import { TEXT_FIELD_STYLE_DEF } from '../constants';
import ControllerTextField from '../../../components/react-hook-form-wrappers/controller-text-field';

const StyledTextField = ({
  classes,
  control,
  watchStyleField,
  ...otherProps
}) => {
  const styleName = useWatch({ control, name: watchStyleField });
  return (
    <ControllerTextField
      control={control}
      inputClassName={classes[styleName]}
      {...otherProps}
    />
  );
};

export default withStyles(TEXT_FIELD_STYLE_DEF)(StyledTextField);
