import { createSelector } from '@reduxjs/toolkit';
import { denormalize } from 'normalizr';
import * as schemas from '../schemas';

export const getNotificationMessagesState = (state) =>
  state.notificationMessages;

export const getNotificationMessagesAllIds = (state) =>
  state.notificationMessages.allIds;

export const getNotificationMessagesEntities = (state) =>
  state.notificationMessages.entities;

export const getNotificationMessagesErrorMessage = (state) =>
  state.notificationMessages.error;

export const getNotificationMessagesMessage = (state) =>
  state.notificationMessages.message;

export const getNotificationMessagesCount = (state) =>
  state.notificationMessages.totalCount;

export const selectNotificationMessagesPreferences = (state) =>
  state.notificationMessages.preferences;

export const getNotificationMessages = createSelector(
  getNotificationMessagesEntities,
  getNotificationMessagesAllIds,
  (entities, allIds) =>
    denormalize(allIds, schemas.notificationMessageListSchema, entities)
);

export const getEditingNotificationMessage = (state) =>
  state.notificationMessages.editing;
