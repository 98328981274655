import { createSelector } from '@reduxjs/toolkit';
import { denormalize } from 'normalizr';
import * as schemas from '../schemas';

export const getReportsState = (state) => state.reports;

export const getReportsAllIds = (state) => state.reports.allIds;

export const getReportsEntities = (state) => state.reports.entities;

export const getReportsErrorMessage = (state) => state.reports.error;

export const getReportsMessage = (state) => state.reports.message;

export const getReportsCount = (state) => state.reports.totalCount;

export const getReports = createSelector(
  getReportsEntities,
  getReportsAllIds,
  (entities, allIds) => denormalize(allIds, schemas.reportListSchema, entities)
);

export const getEditingReport = (state) => state.reports.editing;

export const getPreviewReport = (state) => state.reports.preview;
