import * as actionTypes from './types';

export const loadCoverImages =
  (payload) =>
  (dispatch, getState, { mernApi }) => {
    dispatch({ type: actionTypes.LOAD_COVER_IMAGES });
    const {
      filters,
      page = 0,
      pageSize = 10,
      orderBy,
      orderDirection,
      search,
    } = payload;
    const skip = page * pageSize;
    const sort = orderBy
      ? `${orderDirection === 'desc' ? '-' : ''}${orderBy.field}`
      : '-publishTime';
    const params = {
      sort,
      limit: pageSize,
      skip,
    };
    const preferences = {
      page,
      pageSize,
    };
    if (orderBy?.field) {
      preferences.orderBy = orderBy.field;
    }
    if (orderDirection) {
      preferences.orderDirection = orderDirection;
    }
    filters.forEach((filter) => {
      if (filter.column.field === 'isPublished') {
        params.isPublished = filter.value === 'published';
        preferences.publishStatus = filter.value;
      }
      if (filter.column.field === 'type') {
        params.type = filter.value;
        preferences.type = filter.value;
      }
    });
    let searchText = search.trim();
    if (searchText) {
      params.searchText = searchText;
      preferences.searchText = searchText;
    }
    return mernApi.get('/api/cover-images', { params }).then(
      (response) => {
        dispatch({
          type: actionTypes.LOAD_COVER_IMAGES_SUCCESS,
          payload: {
            ...response.data,
            preferences,
          },
        });
      },
      (err) => {
        dispatch({
          type: actionTypes.LOAD_COVER_IMAGES_FAIL,
          payload: err.response.data.error.message,
        });
      }
    );
  };

export const loadCoverImage =
  (coverImageId) =>
  (dispatch, getState, { mernApi }) => {
    dispatch({ type: actionTypes.LOAD_COVER_IMAGE });
    return mernApi.get(`/api/cover-images/${coverImageId}`).then(
      (response) => {
        dispatch({
          type: actionTypes.LOAD_COVER_IMAGE_SUCCESS,
          payload: response.data.coverImage,
        });
      },
      (err) => {
        dispatch({
          type: actionTypes.LOAD_COVER_IMAGE_FAIL,
          payload: err.response.data.error.message,
        });
      }
    );
  };

export const removeCoverImage =
  (coverImageId) =>
  (dispatch, getState, { mernApi }) => {
    dispatch({ type: actionTypes.REMOVE_COVER_IMAGE });
    return mernApi.delete(`/api/cover-images/${coverImageId}`).then(
      (response) => {
        dispatch({
          type: actionTypes.REMOVE_COVER_IMAGE_SUCCESS,
          payload: { ...response.data, deletedId: coverImageId },
        });
      },
      (err) => {
        dispatch({
          type: actionTypes.REMOVE_COVER_IMAGE_FAIL,
          payload: err.response.data.error.message,
        });
      }
    );
  };

export const addCoverImage =
  (formValues) =>
  (dispatch, getState, { mernApi }) => {
    dispatch({ type: actionTypes.ADD_COVER_IMAGE });
    return mernApi.post('/api/cover-images', formValues).then(
      (response) => {
        dispatch({
          type: actionTypes.ADD_COVER_IMAGE_SUCCESS,
          payload: response.data.coverImage,
        });
        return Promise.resolve(response.data);
      },
      (err) => {
        dispatch({
          type: actionTypes.ADD_COVER_IMAGE_FAIL,
          payload: err.response.data.error.message,
        });
      }
    );
  };

export const updateCoverImage =
  (formValues) =>
  (dispatch, getState, { mernApi }) => {
    dispatch({ type: actionTypes.UPDATE_COVER_IMAGE });
    return mernApi.put(`/api/cover-images/${formValues.id}`, formValues).then(
      (response) => {
        dispatch({
          type: actionTypes.UPDATE_COVER_IMAGE_SUCCESS,
          payload: response.data.coverImage,
        });
        return Promise.resolve(response.data);
      },
      (err) => {
        dispatch({
          type: actionTypes.UPDATE_COVER_IMAGE_FAIL,
          payload: err.response.data.error.message,
        });
      }
    );
  };

export const clearEditingCoverImage = () => {
  return {
    type: actionTypes.CLEAR_EDITING_COVER_IMAGE,
  };
};

export const clearCoverImageMessage = () => {
  return {
    type: actionTypes.CLEAR_COVER_IMAGE_MESSAGE,
  };
};

export const clearCoverImageError = () => {
  return {
    type: actionTypes.CLEAR_COVER_IMAGE_ERROR,
  };
};
