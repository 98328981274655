import * as actionTypes from './types';
import { DEFAULT_PREFERENCES } from '../../utils';

export const updatePreferences = (key, subKey, value) => {
  return {
    type: actionTypes.UPDATE_PREFERENCES,
    payload: { key, subKey, value },
  };
};

export const resetPreferences = () => {
  const defaultPreferences = JSON.parse(JSON.stringify(DEFAULT_PREFERENCES));
  return {
    type: actionTypes.RESET_PREFERENCES,
    payload: defaultPreferences,
  };
};
