export const generateCreditStr = ({ imageCopyright, imageCredit } = {}) => {
  let creditStr = 'Credit: Unknown';
  if (imageCopyright && imageCredit) {
    creditStr = `© ${imageCopyright} | Credit: ${imageCredit}`;
  } else {
    if (imageCredit) {
      creditStr = `Credit: ${imageCredit}`;
    } else if (imageCopyright) {
      creditStr = `© ${imageCopyright}`;
    }
  }
  return creditStr;
};
