import React from 'react';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { withStyles } from '@mui/styles';
import {
  addDays,
  addMonths,
  endOfDay,
  endOfMonth,
  format,
  startOfDay,
  startOfMonth,
  differenceInMilliseconds,
} from 'date-fns';
import { isMobile } from 'react-device-detect';
import {
  change,
  Field,
  reduxForm,
  SubmissionError,
  formValueSelector,
  isDirty,
} from 'redux-form';
import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';
import { Prompt } from 'react-router-dom';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { isUndefined, isEmpty } from 'lodash';
import {
  getClients,
  getReportsErrorMessage,
  getEditingReport,
  getPreviewReport,
  getClientsEntities,
} from '../../store/selectors';
import {
  addReport,
  clearEditingReport,
  clearPreviewReport,
  loadReport,
  loadClients,
  generatePreviewReport,
  unloadClients,
} from '../../store/actions';
import { required, MAX_PAGE_SIZE, formatDateTime } from '../../utils';
import PdfDocument from '../../components/PdfDocument';
import { ORDER_BY, REPORT_TYPES } from './constants';

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  button: {
    margin: theme.spacing(3, 4, 3, 0),
  },
  backdrop: {
    zIndex: 999,
    backgroundColor: 'rgba(255, 255, 255, 0.6)',
  },
  pdfViewer: {
    width: '100%',
    height: '75vh',
  },
  note: {
    color: theme.palette.secondary.main,
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
});

const fromUser = {
  companyName: 'Reputation Media Group Inc.',
  phone: '+1 778 998 2727',
  address: {
    street: '1005-590 Nicola Street',
    city: 'Vancouver',
    postalCode: 'V6G 3J8',
    province: 'British Columbia',
    country: 'Canada',
  },
  email: 'contact@worldinbeauty.com',
};

class ReportDetail extends React.Component {
  state = {
    errorMessage: null,
    isViewOnly: false,
  };

  componentDidMount() {
    this.reportId = this.props.match.params.reportId;
    this.props.loadClients({ pageSize: MAX_PAGE_SIZE });
    if (this.reportId !== 'create') {
      this.setState({ isViewOnly: true });
      this.props.loadReport(this.reportId);
    }
  }

  componentWillUnmount() {
    this.props.clearEditingReport();
    this.props.clearPreviewReport();
    this.props.unloadClients();
  }

  createPayload = ({ fromDate, toDate, ...rest }) => {
    return {
      ...rest,
      fromTime: startOfDay(fromDate).getTime(),
      toTime: endOfDay(toDate).getTime(),
    };
  };

  onSubmit = ({ ...formValues }) => {
    this.props.clearPreviewReport();
    formValues = this.createPayload(formValues);
    return Promise.resolve()
      .then(() => {
        return this.props.addReport(formValues);
      })
      .then(() => {
        if (this.props.errorMessage) {
          throw new SubmissionError({ _error: this.props.errorMessage });
        } else {
          this.setState({ isViewOnly: true });
        }
      });
  };

  onPreview = () => {
    const {
      formValues: { ...payload },
    } = this.props;
    this.props.clearPreviewReport();
    this.props.generatePreviewReport(this.createPayload(payload)).then(() => {
      if (this.props.errorMessage) {
        this.setState({ errorMessage: this.props.errorMessage });
      }
    });
  };

  onCancel = () => {
    this.props.push('/dashboard/reports?prompt=no');
  };

  setFromToDate = (date) => {
    const fromDate = startOfMonth(date);
    const toDate = endOfMonth(date);
    this.props.dispatch(change('createReport', 'fromDate', fromDate));
    this.props.dispatch(change('createReport', 'toDate', toDate));
  };

  onLastMonthPress = () => {
    const thisDayLastMonth = addMonths(new Date(), -1);
    this.setFromToDate(thisDayLastMonth);
  };

  onThisMonthPress = () => {
    const today = new Date();
    this.setFromToDate(today);
  };

  onNextMonthPress = () => {
    const thisDayNextMonth = addMonths(new Date(), 1);
    this.setFromToDate(thisDayNextMonth);
  };

  onChange = () => {
    this.props.clearPreviewReport();
  };

  renderTextField = ({ input, label, meta: { touched, error }, ...custom }) => (
    <TextField
      label={label}
      error={touched && !!error}
      helperText={touched && error}
      variant="outlined"
      margin="none"
      required
      fullWidth
      {...input}
      {...custom}
    />
  );

  renderDatePicker = ({
    input,
    label,
    maxDate,
    maxDateMessage,
    disabled,
    showTodayButton,
  }) => {
    return (
      <MobileDatePicker
        slotProps={{ textField: { variant: 'standard', fullWidth: true } }}
        value={input.value}
        onChange={(value) => input.onChange(value)}
        format="EEE, MMM dd, yyyy"
        label={label}
        maxDate={maxDate}
        maxDateMessage={maxDateMessage}
        disabled={disabled}
        showTodayButton={showTodayButton}
      />
    );
  };

  renderClientOptions = (clients) => {
    return clients.map((client) => {
      return (
        <MenuItem key={client.id} value={client.id}>
          {client.companyName}
        </MenuItem>
      );
    });
  };

  renderEmailOptions = (emails) => {
    return emails.map((email, i) => {
      return (
        <MenuItem key={`email${i}`} value={i}>
          {email}
        </MenuItem>
      );
    });
  };

  renderPhoneOptions = (phones) => {
    return phones.map((phone, i) => {
      return (
        <MenuItem key={`phone${i}`} value={i}>
          {phone}
        </MenuItem>
      );
    });
  };

  renderOrderDirectionOptions = (orderDirections) => {
    return orderDirections.map((direction) => {
      return (
        <MenuItem key={direction.id} value={direction.id}>
          {direction.name}
        </MenuItem>
      );
    });
  };

  renderTypeOptions = (types) => {
    return types.map((type) => {
      return (
        <MenuItem key={type.id} value={type.id}>
          {type.name}
        </MenuItem>
      );
    });
  };

  render() {
    const {
      editingReport,
      previewReport,
      handleSubmit,
      classes,
      clients,
      clientsEntities,
      formValues,
      pristine,
      processing,
      valid,
      error,
      isDirty,
      mobileAppConfig,
    } = this.props;

    const { isViewOnly } = this.state;

    const report = isViewOnly ? editingReport : previewReport;

    let title =
      isViewOnly && report.assets
        ? `${report.client.companyName}_Editorial-Report${
            report.type === 'cover-story' ? '_Cover-Story' : ''
          }_${format(new Date(report.createdAt), 'MMM-dd-yyyy')}`
        : 'Preview';

    let subject =
      report.type === 'cover-story'
        ? 'Cover Story'
        : report.type === 'normal'
        ? 'Normal Categories'
        : 'All Categories';

    return (
      <>
        <CssBaseline />
        <Paper elevation={1} className={classes.paper}>
          <Typography variant="h6" className={classes.title}>
            {isViewOnly ? 'Report Details' : 'Create New Report'}
          </Typography>
          <Grid container spacing={4} direction="row" justifyContent="center">
            <Grid item xs={12} sm={4}>
              <form
                className={classes.form}
                onSubmit={handleSubmit(this.onSubmit)}
              >
                <Grid container spacing={3}>
                  {clients.length > 0 && (
                    <Grid item xs={12}>
                      <Field
                        id="clientId"
                        label="Client"
                        name="clientId"
                        placeholder="Choose a client"
                        component={this.renderTextField}
                        select
                        disabled={isViewOnly}
                        onChange={this.onChange}
                      >
                        {this.renderClientOptions(clients)}
                      </Field>
                    </Grid>
                  )}
                  {!isViewOnly &&
                    formValues.clientId &&
                    clientsEntities[formValues.clientId].emails.length > 0 && (
                      <Grid item xs={12}>
                        <Field
                          id="emailIndex"
                          label="Email"
                          name="emailIndex"
                          placeholder="Choose an email address"
                          component={this.renderTextField}
                          select
                          disabled={isViewOnly}
                          onChange={this.onChange}
                        >
                          {this.renderEmailOptions(
                            clientsEntities[formValues.clientId].emails
                          )}
                        </Field>
                      </Grid>
                    )}
                  {!isViewOnly &&
                    formValues.clientId &&
                    clientsEntities[formValues.clientId].phones.length > 0 && (
                      <Grid item xs={12}>
                        <Field
                          id="phoneIndex"
                          label="Phone"
                          name="phoneIndex"
                          placeholder="Choose a phone number"
                          component={this.renderTextField}
                          select
                          disabled={isViewOnly}
                          onChange={this.onChange}
                        >
                          {this.renderPhoneOptions(
                            clientsEntities[formValues.clientId].phones
                          )}
                        </Field>
                      </Grid>
                    )}
                  {isViewOnly && report.client && (
                    <>
                      {report.client.email && (
                        <Grid item xs={12}>
                          <Field
                            id="email"
                            label="Email"
                            name="email"
                            component={this.renderTextField}
                            disabled={true}
                          />
                        </Grid>
                      )}
                      {report.client.phone && (
                        <Grid item xs={12}>
                          <Field
                            id="phone"
                            label="Phone"
                            name="phone"
                            component={this.renderTextField}
                            disabled={true}
                          />
                        </Grid>
                      )}
                    </>
                  )}
                  <Grid item xs={12}>
                    <Field
                      id="type"
                      label="Report Type"
                      name="type"
                      placeholder="Choose a report type"
                      component={this.renderTextField}
                      select
                      disabled={isViewOnly}
                      onChange={this.onChange}
                    >
                      {this.renderTypeOptions(REPORT_TYPES)}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      id="fromDate"
                      name="fromDate"
                      label="Date From"
                      component={this.renderDatePicker}
                      disabled={isViewOnly}
                      maxDate={formValues.toDate}
                      onChange={this.onChange}
                      showTodayButton={
                        differenceInMilliseconds(
                          endOfDay(formValues.toDate),
                          startOfDay(Date.now())
                        ) >= 0
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      id="toDate"
                      name="toDate"
                      label="Date To"
                      component={this.renderDatePicker}
                      disabled={isViewOnly}
                      onChange={this.onChange}
                      showTodayButton
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      id="orderBy"
                      label="Order Direction"
                      name="orderBy"
                      placeholder="Choose an order direction"
                      component={this.renderTextField}
                      select
                      disabled={isViewOnly}
                      onChange={this.onChange}
                    >
                      {this.renderOrderDirectionOptions(ORDER_BY)}
                    </Field>
                  </Grid>
                  {isViewOnly && (
                    <>
                      <Grid item xs={12}>
                        <Field
                          id="createdAt"
                          label="Created At"
                          name="createdAt"
                          disabled
                          component={this.renderTextField}
                        />
                      </Grid>
                    </>
                  )}
                  {/* <Grid
                    container
                    item
                    xs={12}
                    alignItems="center"
                    justify="space-evenly"
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={this.onLastMonthPress}
                    >
                      Last Month
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={this.onThisMonthPress}
                    >
                      This Month
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={this.onNextMonthPress}
                    >
                      Next Month
                    </Button>
                  </Grid> */}
                  {!isEmpty(report) && (
                    <Grid item xs={12}>
                      <Typography className={classes.note}>
                        Note: It takes a few minutes to generate and display the
                        report. Please be patient!
                      </Typography>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    {report && report.assets && (
                      <PDFDownloadLink
                        document={
                          <PdfDocument
                            report={report}
                            author={fromUser}
                            title={title}
                            subject={subject}
                            isPreview={!isViewOnly}
                            mobileAppConfig={mobileAppConfig}
                          />
                        }
                        fileName={`Digital_${title}.pdf`}
                      >
                        {({ blob, url, loading, error }) =>
                          loading
                            ? 'Generating the digital report...'
                            : isViewOnly
                            ? 'Download the Digital Report!'
                            : 'Download the preview of Digital Report!'
                        }
                      </PDFDownloadLink>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    {report && report.assets && (
                      <PDFDownloadLink
                        document={
                          <PdfDocument
                            report={report}
                            author={fromUser}
                            title={title}
                            subject={subject}
                            isPreview={!isViewOnly}
                            mobileAppConfig={mobileAppConfig}
                            printable={true}
                          />
                        }
                        fileName={`Printable_${title}.pdf`}
                      >
                        {({ blob, url, loading, error }) =>
                          loading
                            ? 'Generating the printable report...'
                            : isViewOnly
                            ? 'Download the Printable Report!'
                            : 'Download the preview of Printable Report!'
                        }
                      </PDFDownloadLink>
                    )}
                  </Grid>
                </Grid>
                {!isViewOnly && (
                  <>
                    <Button
                      className={classes.button}
                      type="button"
                      variant="contained"
                      disabled={pristine || processing || !valid}
                      onClick={this.onPreview}
                    >
                      Preview
                    </Button>
                    <Button
                      className={classes.button}
                      color="primary"
                      disabled={pristine || processing || !valid}
                      type="submit"
                      variant="contained"
                    >
                      Generate & Save
                    </Button>
                  </>
                )}
                <Button
                  className={classes.button}
                  type="button"
                  variant="contained"
                  onClick={this.onCancel}
                >
                  {isViewOnly ? 'Go Back' : 'Cancel'}
                </Button>
              </form>
            </Grid>
            {!isMobile && (
              <Grid container item xs={12} sm={7} direction="column">
                {report && report.assets && (
                  <>
                    <Grid item>
                      <PDFViewer className={classes.pdfViewer}>
                        <PdfDocument
                          report={report}
                          author={fromUser}
                          title={title}
                          subject={subject}
                          isPreview={!isViewOnly}
                          mobileAppConfig={mobileAppConfig}
                        />
                      </PDFViewer>
                    </Grid>
                  </>
                )}
              </Grid>
            )}
          </Grid>
        </Paper>
        <Prompt
          when={!isViewOnly && isDirty}
          message={(location, action) => {
            return action === 'PUSH' && location.search.includes('prompt=no')
              ? true
              : 'There are unsaved changes. Do you wish to discard them?';
          }}
        />
        <Snackbar
          autoHideDuration={3000}
          onClose={() => this.setState({ errorMessage: null })}
          open={!!error || !!this.state.errorMessage}
        >
          <Alert severity="error">{error || this.state.errorMessage}</Alert>
        </Snackbar>
        <Backdrop className={classes.backdrop} open={processing}>
          <CircularProgress />
        </Backdrop>
      </>
    );
  }
}

const validate = (values, { clientsEntities }) => {
  const errors = {};
  errors.clientId = required(values.clientId);
  if (clientsEntities[values.clientId]) {
    if (clientsEntities[values.clientId].emails.length > 0) {
      errors.emailIndex = isUndefined(values.emailIndex)
        ? 'Required'
        : undefined;
    }
    if (clientsEntities[values.clientId].phones.length > 0) {
      errors.phoneIndex = isUndefined(values.phoneIndex)
        ? 'Required'
        : undefined;
    }
  }
  return errors;
};

const selector = formValueSelector('createReport');

const mapStateToProps = (state) => {
  const formValues = selector(
    state,
    'clientId',
    'emailIndex',
    'fromDate',
    'orderBy',
    'phoneIndex',
    'toDate',
    'type'
  );
  const editingReport = getEditingReport(state);
  return {
    formValues,
    errorMessage: getReportsErrorMessage(state),
    clients: getClients(state),
    clientsEntities: getClientsEntities(state),
    previewReport: getPreviewReport(state),
    mobileAppConfig: state.admin.appVersion.config,
    editingReport,
    initialValues: {
      clientId: editingReport.client?.id,
      email: editingReport.client?.email,
      phone: editingReport.client?.phone,
      type: editingReport.type,
      fromDate: editingReport.fromTime
        ? new Date(editingReport.fromTime)
        : startOfDay(addDays(Date.now(), -7)),
      toDate: editingReport.toTime
        ? new Date(editingReport.toTime)
        : endOfDay(new Date()),
      orderBy: editingReport.orderBy ? editingReport.orderBy : '-publishTime',
      createdAt: formatDateTime(editingReport.createdAt),
    },
    processing: state.reports.loading,
    isDirty: isDirty('createReport')(state),
  };
};

export default compose(
  connect(mapStateToProps, {
    addReport,
    clearEditingReport,
    clearPreviewReport,
    loadReport,
    loadClients,
    push,
    generatePreviewReport,
    unloadClients,
  }),
  reduxForm({ form: 'createReport', validate, enableReinitialize: true }),
  withStyles(styles)
)(ReportDetail);
