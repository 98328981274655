import React from 'react';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import { useForm } from 'react-hook-form';

import { getError, getProcessed } from '../store/selectors';
import { emailPattern } from '../utils';
import Copyright from './Copyright';
import ControllerTextField from './react-hook-form-wrappers/controller-text-field';

const styles = (theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(2),
    width: theme.spacing(20),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(4, 0, 2),
  },
});

const RequestTokenForm = ({
  classes,
  title,
  isProcessed,
  errorMessage,
  tokenPurpose,
  onSubmit,
}) => {
  const {
    control,
    formState: { isSubmitted, isSubmitting, isValid },
    handleSubmit,
  } = useForm({
    defaultValues: {
      email: '',
    },
  });

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img
          className={classes.logo}
          alt="WiB blue trademark logo"
          src="/wib-blue-tm-logo-1000x500.png"
        />
        <Typography component="h1" variant="h5" color="primary">
          {title}
        </Typography>
        <form
          className={classes.form}
          onSubmit={handleSubmit((formValues) => {
            return onSubmit({ ...formValues, tokenPurpose });
          })}
        >
          <ControllerTextField
            name="email"
            label="Email Address"
            control={control}
            rules={{
              required: 'Required',
              pattern: {
                value: emailPattern,
                message: 'Invalid email address',
              },
            }}
            disabled={isProcessed && !errorMessage}
          />
          <Button
            className={classes.submit}
            color="primary"
            disabled={
              isSubmitting ||
              (isSubmitted && !isValid) ||
              (isProcessed && !errorMessage)
            }
            fullWidth
            type="submit"
            variant="contained"
          >
            Submit
          </Button>
          <Grid container>
            <Grid item xs>
              <Link underline="none" href="/signin" variant="body2">
                Sign In
              </Link>
            </Grid>
            <Grid item>
              <Link underline="none" href="/register" variant="body2">
                {"Don't have an account? Register"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Copyright />
      <Snackbar open={!!errorMessage}>
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
      <Snackbar open={isProcessed && !errorMessage}>
        <Alert severity="success">
          An email has been sent to your email address
        </Alert>
      </Snackbar>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    errorMessage: getError(state),
    isProcessed: getProcessed(state),
  };
};

export default compose(
  connect(mapStateToProps),
  withStyles(styles)
)(RequestTokenForm);
