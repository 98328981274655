import React from 'react';
import PropTypes from 'prop-types';
import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';
import { ContentSave, EyeOutline } from 'mdi-material-ui';
import { compose } from '@reduxjs/toolkit';
import { withStyles } from '@mui/styles';

const styles = (theme) => ({
  fab: {
    position: 'fixed',
    right: 48,
    bottom: 48,
  },
});

const FabSavePreview = ({
  classes,
  onPreview,
  onSave,
  showPreviewButton,
  type,
  disabled,
}) => {
  return (
    <>
      {!showPreviewButton && (
        <Tooltip title="Save">
          <Fab
            type={type}
            color="primary"
            size="medium"
            aria-label="Save"
            className={classes.fab}
            onClick={onSave}
            disabled={disabled}
          >
            <ContentSave />
          </Fab>
        </Tooltip>
      )}
      {!!showPreviewButton && (
        <Tooltip title="Preview">
          <Fab
            type={type}
            color="secondary"
            size="medium"
            aria-label="Preview"
            className={classes.fab}
            onClick={onPreview}
            disabled={disabled}
          >
            <EyeOutline />
          </Fab>
        </Tooltip>
      )}
    </>
  );
};

FabSavePreview.propTypes = {
  type: PropTypes.string,
  showPreviewButton: PropTypes.bool,
  onSave: PropTypes.func,
  onPreview: PropTypes.func,
  disabled: PropTypes.bool,
};

export default compose(withStyles(styles))(FabSavePreview);
