import React, { useEffect, useState } from 'react';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';
import { Eye, EyeOff } from 'mdi-material-ui';
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import { useForm } from 'react-hook-form';

import { signUp, unloadAuthPage } from '../../store/actions';
import { selectAuthProcessing, getError } from '../../store/selectors';
import { emailPattern } from '../../utils';
import ControllerTextField from '../../components/react-hook-form-wrappers/controller-text-field';
import Copyright from '../../components/Copyright';

const styles = (theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(2),
    width: theme.spacing(20),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(4, 0, 2),
  },
});

const Register = ({
  classes,
  errorMessage,
  history,
  signUp,
  unloadAuthPage,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    return () => {
      unloadAuthPage();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    control,
    formState: { isSubmitted, isSubmitting, isValid },
    handleSubmit,
  } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
    },
  });

  const onSubmit = ({ confirmPassword, ...formValues }) => {
    return signUp(formValues).then(({ success }) => {
      if (success) {
        history.push('/signin');
      }
    });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img
          className={classes.logo}
          alt="WiB blue trademark logo"
          src="/wib-blue-tm-logo-1000x500.png"
        />
        <Typography component="h1" variant="h5" color="primary">
          Register
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <ControllerTextField
                name="firstName"
                label="First Name"
                autoComplete="given-name"
                control={control}
                rules={{
                  required: 'Required',
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ControllerTextField
                name="lastName"
                label="Last Name"
                autoComplete="family-name"
                control={control}
                rules={{
                  required: 'Required',
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <ControllerTextField
                name="email"
                label="Email Address"
                control={control}
                rules={{
                  required: 'Required',
                  pattern: {
                    value: emailPattern,
                    message: 'Invalid email address',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <ControllerTextField
                name="password"
                label="Password"
                control={control}
                rules={{
                  required: 'Required',
                  minLength: {
                    value: 8,
                    message: 'Must be at least 8 characters',
                  },
                }}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          setShowPassword((currentState) => !currentState)
                        }
                        size="large"
                      >
                        {showPassword ? <EyeOff /> : <Eye />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <ControllerTextField
                name="confirmPassword"
                label="Confirm Password"
                control={control}
                rules={{
                  validate: {
                    required: (value) => !!value || 'Required',
                    equalsToPassword: (value, formValues) =>
                      value === formValues.password ||
                      'Passwords did not match',
                  },
                }}
                type={showConfirmPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          setShowConfirmPassword(
                            (currentState) => !currentState
                          )
                        }
                        size="large"
                      >
                        {showConfirmPassword ? <EyeOff /> : <Eye />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Button
            className={classes.submit}
            color="primary"
            disabled={isSubmitting || (isSubmitted && !isValid)}
            fullWidth
            type="submit"
            variant="contained"
          >
            Register
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link underline="none" href="/signin" variant="body2">
                {'Already have an account? Sign in'}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Copyright />
      <Snackbar open={!!errorMessage}>
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    isProcessing: selectAuthProcessing(state),
    errorMessage: getError(state),
  };
};

export default compose(
  connect(mapStateToProps, { signUp, unloadAuthPage }),
  withStyles(styles)
)(Register);
