import React, { useEffect, useState } from 'react';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Link from '@mui/material/Link';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';
import { Eye, EyeOff } from 'mdi-material-ui';
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import { isMobile } from 'react-device-detect';
import { useForm } from 'react-hook-form';

import { resetPassword, unloadAuthPage } from '../../store/actions';
import { getError, getProcessed } from '../../store/selectors';
import { emailPattern } from '../../utils';
import Copyright from '../../components/Copyright';
import ControllerTextField from '../../components/react-hook-form-wrappers/controller-text-field';

const styles = (theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(2),
    width: theme.spacing(20),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(4, 0, 2),
  },
});

const ResetPassword = ({
  classes,
  errorMessage,
  isProcessed,
  match,
  resetPassword,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    return () => {
      unloadAuthPage();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    control,
    formState: { isSubmitted, isSubmitting, isValid },
    handleSubmit,
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onSubmit = (formValues) => {
    const resetToken = match.params.token;
    return resetPassword(formValues, resetToken);
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img
          className={classes.logo}
          alt="WiB blue trademark logo"
          src="/wib-blue-tm-logo-1000x500.png"
        />
        <Typography component="h1" variant="h5" color="primary">
          Reset Your Password
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <ControllerTextField
                name="email"
                label="Email Address"
                control={control}
                disabled={isProcessed && !errorMessage}
                rules={{
                  required: 'Required',
                  pattern: {
                    value: emailPattern,
                    message: 'Invalid email address',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <ControllerTextField
                name="password"
                label="New Password"
                control={control}
                disabled={isProcessed && !errorMessage}
                rules={{
                  required: 'Required',
                  minLength: {
                    value: 8,
                    message: 'Must be at least 8 characters',
                  },
                }}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          setShowPassword((currentState) => !currentState)
                        }
                        size="large"
                      >
                        {showPassword ? <EyeOff /> : <Eye />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Button
            className={classes.submit}
            color="primary"
            disabled={
              isSubmitting ||
              (isSubmitted && !isValid) ||
              (isProcessed && !errorMessage)
            }
            fullWidth
            type="submit"
            variant="contained"
          >
            Submit
          </Button>
          {isMobile ? (
            <Grid container>
              <Grid item xs></Grid>
              <Grid item>
                <Link
                  underline="none"
                  href="https://worldinbeauty.app.link/applink"
                  variant="body2"
                >
                  Sign In
                </Link>
              </Grid>
            </Grid>
          ) : (
            <Grid container>
              <Grid item xs>
                <Link underline="none" href="/signin" variant="body2">
                  Sign In
                </Link>
              </Grid>
              <Grid item>
                <Link
                  underline="none"
                  href="/request-password-reset"
                  variant="body2"
                >
                  Forgot password?
                </Link>
              </Grid>
            </Grid>
          )}
        </form>
      </div>
      <Copyright />
      <Snackbar open={!!errorMessage}>
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
      <Snackbar open={isProcessed && !errorMessage}>
        <Alert
          severity="success"
          action={
            <Link
              underline="none"
              href={
                isMobile ? 'https://worldinbeauty.app.link/applink' : '/signin'
              }
              variant="body2"
            >
              Sign In
            </Link>
          }
        >
          Your password has been reset successfully
        </Alert>
      </Snackbar>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    isProcessed: getProcessed(state),
    errorMessage: getError(state),
  };
};

export default compose(
  connect(mapStateToProps, { resetPassword, unloadAuthPage }),
  withStyles(styles)
)(ResetPassword);
