import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Font, StyleSheet, Text, View } from '@react-pdf/renderer';
import _ from 'lodash';
import { formatDate } from '../utils';

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: '/Roboto-Medium.ttf',
      fontWeight: 500,
    },
    {
      src: '/Roboto-MediumItalic.ttf',
      fontWeight: 500,
      fontStyle: 'italic',
    },
    {
      src: '/Roboto-Regular.ttf',
      fontWeight: 400,
    },
    {
      src: '/Roboto-Italic.ttf',
      fontWeight: 400,
      fontStyle: 'italic',
    },
  ],
});

Font.registerHyphenationCallback((word) => [word]);

const styles = StyleSheet.create({
  container: {
    width: 220,
  },
  tableStyle: {
    width: 220,
    display: 'table',
  },
  tableRowStyle: {
    flexDirection: 'row',
  },
  tableCellHeaderStyle: {
    textAlign: 'center',
    margin: '4 2',
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: 10,
  },
  tableCellStyle: {
    textAlign: 'center',
    margin: '4 2',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 10,
  },
});

const PdfInfoTable = ({ asset, style, reportTime, index, tableWidth }) => {
  const createTableRow = (
    headerName,
    value,
    headerCellWidth,
    valueCellWidth,
    headerOnly,
    key
  ) => {
    let tableColHeaderStyle = {
      width: `${headerCellWidth}%`,
      borderStyle: 'solid',
      borderColor: '#E4E4E4',
      borderWidth: 1,
      backgroundColor: '#BEBEBE',
    };

    let tableColStyle = {
      width: `${valueCellWidth}%`,
      borderStyle: 'solid',
      borderColor: '#E4E4E4',
      borderWidth: 1,
    };

    return (
      <View style={styles.tableRowStyle} key={key} fixed>
        <View style={tableColHeaderStyle}>
          <Text style={styles.tableCellHeaderStyle}>{headerName}</Text>
        </View>

        {!headerOnly && (
          <View style={tableColStyle}>
            <Text style={styles.tableCellStyle}>{value}</Text>
          </View>
        )}
      </View>
    );
  };

  const renderPublishTime = (publishHistory) => {
    const publishTimes = publishHistory.filter((history) => {
      return history.from >= reportTime.from && history.from <= reportTime.to;
    });

    if (publishTimes.length === 0) {
      return createTableRow('Publish Time', 'N/A', 32, 68);
    }

    return publishTimes.map((publishTime, index) => {
      return createTableRow(
        index === 0 ? 'Publish Time' : '',
        `${formatDate(publishTime.from)} - ${formatDate(publishTime.to)}`,
        32,
        68,
        false,
        publishTime.from
      );
    });
  };

  const renderTopStoryTime = (topStoryHistory) => {
    const topStoryTimes = topStoryHistory.filter((history) => {
      return history.from >= reportTime.from && history.from <= reportTime.to;
    });

    if (topStoryTimes.length === 0) {
      return null;
    }

    return topStoryTimes.map((topStoryTime, index) => {
      return createTableRow(
        index === 0 ? 'TopStory Time' : '',
        `${formatDate(topStoryTime.from)} - ${formatDate(topStoryTime.to)}`,
        32,
        68,
        false,
        topStoryTime.from
      );
    });
  };

  const { totalImages, totalVideos } = useMemo(() => {
    let images = 0;
    let videos = 0;
    _.forEach(asset.sections, (section) => {
      if (section.hasVideo) {
        videos++;
      } else {
        images++;
      }
    });

    return { totalImages: images, totalVideos: videos };
  }, [asset.sections]);

  return (
    <View style={{ ...styles.container, ...style }}>
      <View style={{ ...styles.tableStyle, width: tableWidth }}>
        {index >= 0 && createTableRow(`Article ${index + 1}`, '', 100, 0, true)}
        {createTableRow('ID', asset.id, 32, 68)}
        {createTableRow('Category', asset.category.name, 32, 68)}
        {totalImages > 0 &&
          createTableRow('No. of Images', totalImages, 32, 68)}
        {totalVideos > 0 &&
          createTableRow('No. of Videos', totalVideos, 32, 68)}
        {renderPublishTime(asset.publishHistory)}
        {renderTopStoryTime(asset.topStoryHistory)}
      </View>
    </View>
  );
};

PdfInfoTable.propTypes = {
  asset: PropTypes.any.isRequired,
  reportTime: PropTypes.shape({
    from: PropTypes.number,
    to: PropTypes.number,
  }).isRequired,
};

export default PdfInfoTable;
