import { createSelector } from '@reduxjs/toolkit';
import { denormalize } from 'normalizr';
import * as schemas from '../schemas';
import { calcTimeDelta } from 'react-countdown';
import { formatCountdown } from '../../utils';

export const getInvoicesState = (state) => state.invoices;

export const getInvoicesAllIds = (state) => state.invoices.allIds;

export const getInvoicesEntities = (state) => state.invoices.entities;

export const getInvoicesErrorMessage = (state) => state.invoices.error;

export const getInvoicesMessage = (state) => state.invoices.message;

export const getInvoicesCount = (state) => state.invoices.totalCount;

export const getInvoicesCompletedCount = (state) =>
  state.invoices.completedCount;

export const getInvoices = createSelector(
  getInvoicesEntities,
  getInvoicesAllIds,
  (entities, allIds) => denormalize(allIds, schemas.invoiceListSchema, entities)
);

export const getEditingInvoice = (state) => state.invoices.editing;

export const getDerivedInvoice = createSelector(
  getEditingInvoice,
  (invoice) => {
    if (!invoice.ads) {
      return {};
    }
    invoice.ads = invoice.ads.map((ad) => {
      ad.publishHistory = ad.publishHistory.map((row, index) => {
        const timeDelta = calcTimeDelta(row.to, { now: () => row.from });
        const duration = formatCountdown(timeDelta, true);
        return { ...row, duration };
      });
      return ad;
    });
    return invoice;
  }
);
