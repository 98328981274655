import { createSelector } from '@reduxjs/toolkit';

export const getUsersState = (state) => state.users;

export const getUsersAllIds = (state) => state.users.allIds;

export const getUsersEntities = (state) => state.users.entities;

export const getUsersErrorMessage = (state) => state.users.error;

export const getUsersMessage = (state) => state.users.message;

export const getUsersCount = (state) => state.users.totalCount;

export const getUsers = createSelector(
  getUsersEntities,
  getUsersAllIds,
  (entities, allIds) => allIds.map((id) => entities[id])
);

export const getEditingUser = (state) => state.users.editing;
