import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Tooltip } from '@mui/material';
import { withStyles } from '@mui/styles';
import { push } from 'redux-first-history';
import { Apple, Android, Tag } from 'mdi-material-ui';
import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';

import { getRouteCategories } from '../store/selectors';

const styles = (theme) => ({
  categoryHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.white,
    fontSize: 16,
    fontWeight: theme.typography.fontWeightMedium,
  },
  item: {
    color: 'rgba(255, 255, 255, 0.7)',
    '&:hover,&:focus': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
  },
  itemCategory: {
    backgroundColor: '#232f3e',
    boxShadow: '0 -1px 0 #404854 inset',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  logo: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    marginRight: theme.spacing(2),
    border: '1px solid #404854',
  },
  drawerTitle: {
    color: theme.palette.common.white,
    fontSize: 20,
  },
  itemActiveItem: {
    color: theme.palette.secondary.main,
    backgroundColor: 'rgba(255, 255, 255, 0.08)',
  },
  itemPrimary: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightMedium,
  },
  itemIcon: {
    minWidth: 'auto',
    marginRight: theme.spacing(2),
    '& svg': {
      fontSize: 20,
    },
  },
  divider: {
    marginTop: theme.spacing(2),
    backgroundColor: '#404854',
  },
  supperscript: {
    fontSize: 7,
  },
});

const Navigator = ({
  appVersion,
  classes,
  pathname,
  routeCategories,
  onClose,
  push,
  ...other
}) => {
  return (
    <Drawer variant="permanent" onClose={onClose} {...other}>
      <List disablePadding onClick={onClose}>
        <ListItem
          className={clsx(
            classes.drawerTitle,
            classes.item,
            classes.itemCategory
          )}
        >
          <Avatar
            alt="Logo"
            src="/logo-512-circle.png"
            className={classes.logo}
          />
          {'World in Beauty'}
          <sup className={classes.supperscript}>TM</sup>
        </ListItem>
        <Tooltip title="Dashboard version">
          <ListItem className={clsx(classes.item, classes.itemCategory)}>
            <ListItemIcon className={classes.itemIcon}>
              <Tag />
            </ListItemIcon>
            <ListItemText
              classes={{
                primary: classes.itemPrimary,
              }}
            >
              {process.env.REACT_APP_VERSION ?? 'Dashboard Version'}
            </ListItemText>
          </ListItem>
        </Tooltip>
        {routeCategories.map((category) => {
          if (category.isHidden) return null;
          return (
            <React.Fragment key={category.id}>
              <ListItem className={classes.categoryHeader}>
                <ListItemText
                  classes={{
                    primary: classes.categoryHeaderPrimary,
                  }}
                >
                  {category.name}
                </ListItemText>
              </ListItem>
              {category.routes.map((route) => {
                if (route.isHidden) return null;
                return (
                  <ListItem
                    key={route.id}
                    button
                    className={clsx(
                      classes.item,
                      pathname.indexOf(route.path) > -1 &&
                        classes.itemActiveItem
                    )}
                    onClick={() => push(route.path)}
                  >
                    <ListItemIcon className={classes.itemIcon}>
                      <route.icon />
                    </ListItemIcon>
                    <ListItemText
                      classes={{
                        primary: classes.itemPrimary,
                      }}
                    >
                      {route.name}
                    </ListItemText>
                  </ListItem>
                );
              })}

              <Divider className={classes.divider} />
            </React.Fragment>
          );
        })}
        {appVersion.android && (
          <Tooltip title="Android version - Build number">
            <ListItem className={clsx(classes.item, classes.itemCategory)}>
              <ListItemIcon className={classes.itemIcon}>
                <Android />
              </ListItemIcon>
              <ListItemText
                classes={{
                  primary: classes.itemPrimary,
                }}
              >
                {`${appVersion.android.version} - ${appVersion.android.buildNumber}`}
              </ListItemText>
            </ListItem>
          </Tooltip>
        )}
        {appVersion.ios && (
          <Tooltip title="iOS version - Build number">
            <ListItem className={clsx(classes.item, classes.itemCategory)}>
              <ListItemIcon className={classes.itemIcon}>
                <Apple />
              </ListItemIcon>
              <ListItemText
                classes={{
                  primary: classes.itemPrimary,
                }}
              >
                {`${appVersion.ios.version} - ${appVersion.ios.buildNumber}`}
              </ListItemText>
            </ListItem>
          </Tooltip>
        )}
      </List>
    </Drawer>
  );
};

const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname,
  routeCategories: getRouteCategories(state),
  appVersion: state.admin.appVersion,
});

Navigator.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  connect(mapStateToProps, { push }),
  withStyles(styles)
)(Navigator);
