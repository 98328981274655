import * as actionTypes from './types';

export const loadUsers =
  (payload) =>
  (dispatch, getState, { mernApi }) => {
    dispatch({ type: actionTypes.LOAD_USERS });
    const {
      page = 0,
      pageSize = 10,
      orderBy,
      orderDirection,
      search,
      filters,
    } = payload;
    const skip = page * pageSize;
    const sort = orderBy
      ? `${orderDirection === 'desc' ? '-' : ''}${orderBy.field}`
      : 'email';
    const params = { sort, limit: pageSize, skip };
    const preferences = {
      page,
      pageSize,
    };
    if (orderBy?.field) {
      preferences.orderBy = orderBy.field;
    }
    if (orderDirection) {
      preferences.orderDirection = orderDirection;
    }
    filters.forEach((filter) => {
      const { field } = filter.column;
      if (field === 'subscription.isCanceled') {
        params.subscriptionStatus = filter.value;
        preferences.subscriptionStatus = filter.value;
      } else if (field === 'isEditor') {
        params.isEditor = filter.value === 'editor';
        preferences.isEditor = filter.value;
      } else {
        params[field] = filter.value;
        preferences[field] = filter.value;
      }
    });
    let searchText = search.trim();
    if (searchText) {
      params.searchText = searchText;
      preferences.searchText = searchText;
    }
    return mernApi.get('/api/users', { params }).then(
      (response) => {
        dispatch({
          type: actionTypes.LOAD_USERS_SUCCESS,
          payload: { ...response.data, preferences },
        });
      },
      (err) => {
        dispatch({
          type: actionTypes.LOAD_USERS_FAIL,
          payload: err.response.data.error.message,
        });
      }
    );
  };

export const loadUser =
  (userId) =>
  (dispatch, getState, { mernApi }) => {
    dispatch({ type: actionTypes.LOAD_USER });
    return mernApi.get(`/api/users/${userId}`).then(
      (response) => {
        dispatch({
          type: actionTypes.LOAD_USER_SUCCESS,
          payload: response.data.user,
        });
      },
      (err) => {
        dispatch({
          type: actionTypes.LOAD_USER_FAIL,
          payload: err.response.data.error.message,
        });
      }
    );
  };

export const updateUser =
  (formValues) =>
  (dispatch, getState, { mernApi }) => {
    dispatch({ type: actionTypes.UPDATE_USER });
    return mernApi.put(`/api/users/${formValues.id}`, formValues).then(
      (response) => {
        dispatch({
          type: actionTypes.UPDATE_USER_SUCCESS,
          payload: response.data.user,
        });
        return Promise.resolve(response.data);
      },
      (err) => {
        dispatch({
          type: actionTypes.UPDATE_USER_FAIL,
          payload: err.response.data.error.message,
        });
      }
    );
  };
// seeme

export const removeUser =
  ({ id: userId }) =>
  (dispatch, getState, { mernApi }) => {
    dispatch({ type: actionTypes.REMOVE_USER });
    return mernApi.delete(`/api/users/${userId}`).then(
      (response) => {
        dispatch({
          type: actionTypes.REMOVE_USER_SUCCESS,
          payload: { ...response.data, deletedId: userId },
        });
      },
      (err) => {
        dispatch({
          type: actionTypes.REMOVE_USER_FAIL,
          payload: err.response.data.error.message,
        });
      }
    );
  };

export const clearEditingUser = () => {
  return {
    type: actionTypes.CLEAR_EDITING_USER,
  };
};

export const clearUserErrorMessage = () => {
  return {
    type: actionTypes.CLEAR_USER_ERROR_MESSAGE,
  };
};

export const clearUserMessage = () => {
  return {
    type: actionTypes.CLEAR_USER_MESSAGE,
  };
};
