export const ORDER_BY = [
  { id: 'publishTime', name: 'Ascending Publish Time' },
  { id: '-publishTime', name: 'Descending Publish Time' },
];

export const REPORT_TYPES = [
  { id: 'all', name: 'All Categories' },
  { id: 'cover-story', name: 'Cover Story' },
  { id: 'normal', name: 'Normal Categories' },
];
