import * as actionTypes from '../actions/types';

const INITIAL_STATE = {
  appVersion: {},
  orphanFiles: 0,
  processing: false,
  message: '',
  errorMessage: '',
};

const adminReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.COUNT_ORPHAN_FILES:
    case actionTypes.CLEAN_UP_STORAGE:
    case actionTypes.GET_APP_VERSION: {
      return {
        ...state,
        processing: true,
      };
    }

    case actionTypes.COUNT_ORPHAN_FILES_SUCCESS: {
      return {
        ...state,
        processing: false,
        orphanFiles: action.payload.filesCount,
      };
    }

    case actionTypes.CLEAN_UP_STORAGE_SUCCESS: {
      return {
        ...state,
        processing: false,
        orphanFiles: 0,
        message: action.payload.message,
      };
    }

    case actionTypes.GET_APP_VERSION_SUCCESS: {
      return {
        ...state,
        processing: false,
        appVersion: action.payload,
      };
    }

    case actionTypes.COUNT_ORPHAN_FILES_FAIL:
    case actionTypes.CLEAN_UP_STORAGE_FAIL:
    case actionTypes.GET_APP_VERSION_FAIL: {
      return {
        ...state,
        processing: false,
        errorMessage: action.payload,
      };
    }

    case actionTypes.CLEAR_ADMIN_MESSAGES: {
      return {
        ...state,
        errorMessage: '',
        message: '',
      };
    }

    default: {
      return state;
    }
  }
};

export default adminReducer;
