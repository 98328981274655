import { MAX_PAGE_SIZE } from '../../utils';
import * as actionTypes from './types';

export const loadCategories =
  (payload) =>
  (dispatch, getState, { mernApi }) => {
    dispatch({ type: actionTypes.LOAD_CATEGORIES });
    const {
      page = 0,
      pageSize = 10,
      orderBy,
      orderDirection,
      search,
    } = payload;
    const skip = page * pageSize;
    const sort = orderBy
      ? `${orderDirection === 'desc' ? '-' : ''}${orderBy.field}`
      : 'ordinalNumber';
    const name = search && search.trim() ? search.trim() : undefined;
    const preferences = {
      page:
        pageSize === MAX_PAGE_SIZE
          ? getState().categories.preferences.page
          : page,
      pageSize:
        pageSize === MAX_PAGE_SIZE
          ? getState().categories.preferences.pageSize
          : pageSize,
    };

    if (orderBy?.field) {
      preferences.orderBy = orderBy.field;
    }
    if (orderDirection) {
      preferences.orderDirection = orderDirection;
    }

    const params = { name, sort, limit: pageSize, skip };
    return mernApi.get('/api/categories', { params }).then(
      (response) => {
        dispatch({
          type: actionTypes.LOAD_CATEGORIES_SUCCESS,
          payload: { ...response.data, preferences },
        });
      },
      (err) => {
        dispatch({
          type: actionTypes.LOAD_CATEGORIES_FAIL,
          payload: err.response.data.error.message,
        });
      }
    );
  };

export const addCategory =
  (formValues) =>
  (dispatch, getState, { mernApi }) => {
    dispatch({ type: actionTypes.ADD_CATEGORY });
    return mernApi.post('/api/categories', formValues).then(
      (response) => {
        dispatch({
          type: actionTypes.ADD_CATEGORY_SUCCESS,
          payload: response.data.category,
        });
        return Promise.resolve(response.data);
      },
      (err) => {
        dispatch({
          type: actionTypes.ADD_CATEGORY_FAIL,
          payload: err.response.data.error.message,
        });
      }
    );
  };

export const updateCategory =
  (formValues) =>
  (dispatch, getState, { mernApi }) => {
    dispatch({ type: actionTypes.UPDATE_CATEGORY });
    return mernApi.put(`/api/categories/${formValues.id}`, formValues).then(
      (response) => {
        dispatch({
          type: actionTypes.UPDATE_CATEGORY_SUCCESS,
          payload: response.data.category,
        });
        return Promise.resolve(response.data);
      },
      (err) => {
        dispatch({
          type: actionTypes.UPDATE_CATEGORY_FAIL,
          payload: err.response.data.error.message,
        });
      }
    );
  };

export const removeCategory =
  ({ id: categoryId }) =>
  (dispatch, getState, { mernApi }) => {
    dispatch({ type: actionTypes.REMOVE_CATEGORY });
    return mernApi.delete(`/api/categories/${categoryId}`).then(
      (response) => {
        dispatch({
          type: actionTypes.REMOVE_CATEGORY_SUCCESS,
          payload: { ...response.data, deletedId: categoryId },
        });
      },
      (err) => {
        dispatch({
          type: actionTypes.REMOVE_CATEGORY_FAIL,
          payload: err.response.data.error.message,
        });
      }
    );
  };

export const unloadCategories = () => {
  return {
    type: actionTypes.UNLOAD_CATEGORIES,
  };
};

export const clearCategoryErrorMessage = () => {
  return {
    type: actionTypes.CLEAR_CATEGORY_ERROR_MESSAGE,
  };
};

export const clearCategoryMessage = () => {
  return {
    type: actionTypes.CLEAR_CATEGORY_MESSAGE,
  };
};
