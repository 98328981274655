import React from 'react';
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import {
  change,
  Field,
  formValueSelector,
  reduxForm,
  SubmissionError,
} from 'redux-form';
import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';
import { addDays, endOfDay } from 'date-fns';
import Countdown from 'react-countdown';
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import _ from 'lodash';
import {
  getUsersErrorMessage,
  getEditingUser,
  getCurrentUser,
} from '../../store/selectors';
import {
  clearEditingUser,
  loadUser,
  requestVerificationEmail,
  updateUser,
} from '../../store/actions';
import { formatCountdown, formatDateTime } from '../../utils';
import { STATUS, ROLES } from './constants';

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  avatars: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
  },
  avatar: {
    width: theme.spacing(18),
    height: theme.spacing(18),
    margin: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(3, 4, 3, 0),
  },
  backdrop: {
    zIndex: 999,
    backgroundColor: 'rgba(255, 255, 255, 0.6)',
  },
  countdown: {
    display: 'flex',
    alignItems: 'center',
  },
});

class UserDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: null,
      isVerificationEmailSent: false,
    };
  }

  componentDidMount() {
    this.userId = this.props.match.params.userId;
    if (this.userId !== 'create') {
      this.isEditing = true;
      this.props.loadUser(this.userId);
    }
  }

  componentWillUnmount() {
    this.props.clearEditingUser();
  }

  onSubmit = ({ originalFileName, ...formValues }) => {
    return Promise.resolve()
      .then(() => {
        if (this.isEditing) {
          if (formValues.trialEndsAt) {
            formValues.trialEndsAt = endOfDay(formValues.trialEndsAt).getTime();
          }
          return this.props.updateUser(formValues);
        }
        // FIXME: Add a new user
        return Promise.resolve();
      })
      .then(() => {
        if (this.props.errorMessage) {
          throw new SubmissionError({ _error: this.props.errorMessage });
        } else {
          this.props.push('/dashboard/users');
        }
      });
  };

  onCancel = () => {
    this.props.push('/dashboard/users');
  };

  onResendVerificationEmail = () => {
    this.setState({ isVerificationEmailSent: true });
    this.props.requestVerificationEmail({
      email: this.props.email,
      tokenPurpose: 'verify-email',
    });
  };

  renderTextField = ({ input, label, meta: { touched, error }, ...custom }) => (
    <TextField
      label={label}
      error={touched && !!error}
      helperText={touched && error}
      variant="outlined"
      margin="none"
      required
      fullWidth
      {...input}
      {...custom}
    />
  );

  renderOptions = (options) => {
    return options.map((option) => {
      return (
        <MenuItem key={option.id} value={option.id}>
          {option.name}
        </MenuItem>
      );
    });
  };

  renderStatusOptions = () => {
    return this.renderOptions(STATUS);
  };

  renderRoleOptions = () => {
    return this.renderOptions(ROLES);
  };

  renderCheckbox = ({ input, label, disabled }) => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={input.value ? true : false}
            onChange={input.onChange}
            disabled={disabled}
          />
        }
        label={label}
      />
    );
  };

  renderCountdown = (countdownObj, prefix) => {
    const { completed } = countdownObj;
    if (completed) {
      return null;
    }
    return <span>{`${prefix} ${formatCountdown(countdownObj)}`}</span>;
  };

  renderDateTimePicker = ({ input, label, maxDate, maxDateMessage }) => {
    return (
      <MobileDateTimePicker
        slotProps={{ textField: { variant: 'standard', fullWidth: true } }}
        ampm={false}
        value={input.value}
        onChange={(value) => input.onChange(value)}
        inputFormat="EEE, MMM dd, yyyy HH:mm"
        showTodayButton
        label={label}
        maxDate={maxDate}
        maxDateMessage={maxDateMessage}
      />
    );
  };

  renderTrialTime = ({
    dateFieldName,
    dateLabel,
    countdownDate,
    onDateChange,
    maxDate,
    maxDateMessage,
  }) => {
    const { classes } = this.props;
    const handleDateChange = (value) => {
      if (typeof onDateChange === 'function') {
        onDateChange(value);
      }
      setTimeout(() => {
        if (this[dateFieldName].isStopped()) {
          this[dateFieldName].start();
        }
      }, 1000);
    };
    return (
      <>
        <Grid item xs={12} md={6} xl={4}>
          <Field
            id={dateFieldName}
            name={dateFieldName}
            label={dateLabel}
            component={this.renderDateTimePicker}
            maxDate={maxDate}
            maxDateMessage={maxDateMessage}
            onChange={(value) => {
              handleDateChange(value);
            }}
          />
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={6}
          xl={8}
          alignItems="center"
          justifyContent="space-evenly"
        >
          {[2, 4, 8, 12].map((week) => {
            return (
              <Button
                key={week}
                variant="outlined"
                color="primary"
                onClick={() => {
                  const value = endOfDay(addDays(new Date(), week * 7));
                  this.props.dispatch(
                    change('createUser', dateFieldName, value)
                  );
                  handleDateChange(value);
                }}
              >
                {week}w
              </Button>
            );
          })}
        </Grid>
        {!!countdownDate && (
          <Grid item xs={12} className={classes.countdown}>
            <Countdown
              date={countdownDate}
              renderer={(countdownObj) =>
                this.renderCountdown(countdownObj, 'Trial Countdown:')
              }
              ref={(ref) => (this[dateFieldName] = ref)}
            />
          </Grid>
        )}
      </>
    );
  };

  render() {
    const {
      handleSubmit,
      classes,
      pristine,
      submitting,
      valid,
      error,
      role,
      initialValues,
      status,
      subscriptionEndsAt,
      trialEndsAt,
      me,
    } = this.props;

    const provider = initialValues.provider ? initialValues.provider : {};
    const { apple, facebook, google, local } = provider;

    return (
      <>
        <CssBaseline />
        <Paper elevation={1} className={classes.paper}>
          <Typography component="h1" variant="h6" className={classes.title}>
            {this.isEditing ? 'Edit' : 'Create New'} User
          </Typography>
          <Grid container spacing={4} direction="row" justifyContent="center">
            <Grid item xs={12} sm={7}>
              <form
                className={classes.form}
                onSubmit={handleSubmit(this.onSubmit)}
              >
                <Grid container spacing={3}>
                  {this.isEditing && (
                    <Grid item xs={12}>
                      <Field
                        id="id"
                        label="User ID"
                        name="id"
                        disabled
                        component={this.renderTextField}
                        required={false}
                      />
                    </Grid>
                  )}
                  <Grid item xs={6}>
                    <Field
                      id="firstName"
                      label="First Name"
                      name="firstName"
                      disabled
                      component={this.renderTextField}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      id="lastName"
                      label="Last Name"
                      name="lastName"
                      disabled
                      component={this.renderTextField}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      id="email"
                      label="Email"
                      name="email"
                      disabled
                      component={this.renderTextField}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      id="platform"
                      label="Platform"
                      name="platform"
                      required={false}
                      disabled
                      component={this.renderTextField}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      id="deviceId"
                      label="Device ID"
                      name="deviceId"
                      required={false}
                      disabled
                      component={this.renderTextField}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      id="status"
                      label="Status"
                      name="status"
                      placeholder="Choose a status"
                      component={this.renderTextField}
                      select
                      disabled={me.id === initialValues.id}
                    >
                      {this.renderStatusOptions()}
                    </Field>
                  </Grid>
                  {status === 'unverified-email' && (
                    <Grid item xs={6}>
                      <Button
                        color="primary"
                        disabled={
                          submitting || this.state.isVerificationEmailSent
                        }
                        type="button"
                        variant="contained"
                        onClick={this.onResendVerificationEmail}
                      >
                        {this.state.isVerificationEmailSent
                          ? 'A verification email has been sent'
                          : 'Resend verification email'}
                      </Button>
                    </Grid>
                  )}
                  <Grid item xs={6}>
                    <Field
                      id="role"
                      label="Role"
                      name="role"
                      placeholder="Choose a role"
                      component={this.renderTextField}
                      select
                      disabled={
                        me.role !== 'root' || me.id === initialValues.id
                      }
                    >
                      {this.renderRoleOptions()}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      id="isEditor"
                      name="isEditor"
                      label="Editor"
                      component={this.renderCheckbox}
                      disabled={role === 'root' || role === 'admin'}
                    />
                  </Grid>
                  {this.renderTrialTime({
                    dateFieldName: 'trialEndsAt',
                    dateLabel: 'Trial Ends At',
                    countdownDate: trialEndsAt,
                    maxDate: new Date('2100-01-01'),
                  })}
                  <Grid item xs={12} sm={6}>
                    <Field
                      id="subscriptionStatus"
                      label="Subscription Status"
                      name="subscriptionStatus"
                      required={false}
                      disabled
                      component={this.renderTextField}
                    />
                  </Grid>
                  {!_.isEmpty(this.props.initialValues.subscription) && (
                    <>
                      <Grid item xs={12} sm={6}>
                        <Field
                          id="subscriptionStartsAt"
                          label="Subscription Starts At"
                          name="subscriptionStartsAt"
                          disabled
                          required={false}
                          component={this.renderTextField}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          id="subscriptionEndsAt"
                          label="Subscription Ends At"
                          name="subscriptionEndsAt"
                          required={false}
                          disabled
                          component={this.renderTextField}
                        />
                      </Grid>
                      {!!subscriptionEndsAt && (
                        <Grid item xs={12} sm={6} className={classes.countdown}>
                          <Countdown
                            date={new Date(subscriptionEndsAt)}
                            renderer={(countdownObj) =>
                              this.renderCountdown(
                                countdownObj,
                                'Subscription Countdown:'
                              )
                            }
                          />
                        </Grid>
                      )}
                    </>
                  )}
                  {this.isEditing && (
                    <>
                      <Grid item xs={12} sm={6}>
                        <Field
                          id="createdAt"
                          label="Created At"
                          name="createdAt"
                          required={false}
                          disabled
                          component={this.renderTextField}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          id="updatedAt"
                          label="Updated At"
                          name="updatedAt"
                          required={false}
                          disabled
                          component={this.renderTextField}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
                <Button
                  className={classes.button}
                  color="primary"
                  disabled={pristine || submitting || !valid}
                  type="submit"
                  variant="contained"
                >
                  {this.isEditing ? 'Save' : 'Submit'}
                </Button>
                <Button
                  className={classes.button}
                  type="button"
                  variant="contained"
                  onClick={this.onCancel}
                >
                  Cancel
                </Button>
              </form>
            </Grid>
            <Grid item xs={12} sm={4}>
              <div className={classes.avatars}>
                {!!local && (
                  <Tooltip title="Email Account">
                    <Avatar
                      src={initialValues.profilePictureUrl}
                      className={classes.avatar}
                    />
                  </Tooltip>
                )}
                {!!apple && (
                  <Tooltip title="Apple Account">
                    <Avatar src={apple.picture} className={classes.avatar} />
                  </Tooltip>
                )}
                {!!facebook && (
                  <Tooltip title="Facebook Account">
                    <Avatar src={facebook.picture} className={classes.avatar} />
                  </Tooltip>
                )}
                {!!google && (
                  <Tooltip title="Google Account">
                    <Avatar src={google.picture} className={classes.avatar} />
                  </Tooltip>
                )}
              </div>
            </Grid>
          </Grid>
        </Paper>
        <Snackbar
          autoHideDuration={3000}
          onClose={() => this.setState({ errorMessage: null })}
          open={!!error || !!this.state.errorMessage}
        >
          <Alert severity="error">{error || this.state.errorMessage}</Alert>
        </Snackbar>
        <Backdrop className={classes.backdrop} open={submitting}>
          <CircularProgress />
        </Backdrop>
      </>
    );
  }
}

const validate = (values) => {
  const errors = {};
  return errors;
};

const selector = formValueSelector('createUser');

const mapStateToProps = (state) => {
  const editingUser = getEditingUser(state);
  return {
    errorMessage: getUsersErrorMessage(state),
    email: selector(state, 'email'),
    trialEndsAt: selector(state, 'trialEndsAt'),
    status: selector(state, 'status'),
    subscriptionEndsAt: selector(state, 'subscriptionEndsAt'),
    role: selector(state, 'role'),
    initialValues: {
      id: editingUser.id,
      firstName: editingUser.firstName,
      lastName: editingUser.lastName,
      profilePictureUrl: editingUser.profilePictureUrl,
      email: editingUser.email,
      status: editingUser.status,
      role: editingUser.role,
      isEditor: editingUser.isEditor,
      permissions: editingUser.permissions,
      platform: editingUser.platform,
      deviceId: editingUser.deviceId,
      provider: editingUser.provider,
      createdAt: formatDateTime(editingUser.createdAt),
      updatedAt: formatDateTime(editingUser.updatedAt),
      trialEndsAt: new Date(editingUser.trialEndsAt),
      subscription: editingUser.subscription,
      subscriptionStatus: !_.isEmpty(editingUser.subscription)
        ? editingUser.subscription.isCanceled
          ? `Canceled (${editingUser.subscription.platform})`
          : `Active Paid (${editingUser.subscription.platform})`
        : editingUser.trialEndsAt <= Date.now()
        ? 'Not Subscribed'
        : 'Free Trial',
      subscriptionStartsAt: editingUser.subscription
        ? formatDateTime(editingUser.subscription.startTime)
        : null,
      subscriptionEndsAt: editingUser.subscription
        ? formatDateTime(editingUser.subscription.endTime)
        : null,
    },
    provider: editingUser.provider,
    me: getCurrentUser(state),
  };
};

export default compose(
  connect(mapStateToProps, {
    clearEditingUser,
    loadUser,
    push,
    requestVerificationEmail,
    updateUser,
  }),
  reduxForm({ form: 'createUser', validate, enableReinitialize: true }),
  withStyles(styles)
)(UserDetail);
