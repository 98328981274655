export const EDITOR = [{ id: 'editor', name: 'Editor' }];

export const PLATFORM = [
  { id: 'android', name: 'Android' },
  { id: 'ios', name: 'iOS' },
];

export const STATUS = [
  { id: 'active', name: 'Active' },
  { id: 'deleted', name: 'Deleted' },
  { id: 'disabled', name: 'Disabled' },
  { id: 'unverified-email', name: 'Unverified Email' },
];

export const ROLES = [
  { id: 'admin', name: 'Admin' },
  { id: 'root', name: 'Root' },
  { id: 'user', name: 'User' },
];

export const SUBSCRIPTION_STATUS = [
  { id: 'active', name: 'Active Paid' },
  { id: 'canceled', name: 'Canceled' },
  { id: 'free-trial', name: 'Free Trial' },
  { id: 'not-subscribed', name: 'Not Subscribed' },
];
