import { normalize } from 'normalizr';
import * as actionTypes from '../actions/types';
import * as schemas from '../schemas';
import { getPreferences } from '../../utils';

const INITIAL_STATE = {
  entities: { coverImages: {} },
  allIds: [],
  loading: false,
  preferences: getPreferences('coverImages'),
  error: null,
  message: null,
  editing: {},
};

const coverImagesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.REMOVE_COVER_IMAGE:
    case actionTypes.ADD_COVER_IMAGE:
    case actionTypes.UPDATE_COVER_IMAGE:
    case actionTypes.LOAD_COVER_IMAGE:
    case actionTypes.LOAD_COVER_IMAGES: {
      return { ...state, loading: true, error: null };
    }

    case actionTypes.LOAD_COVER_IMAGES_SUCCESS: {
      const coverImages = action.payload.coverImages;
      const data = normalize(coverImages, schemas.coverImageListSchema);
      return {
        ...state,
        loading: false,
        allIds: data.result,
        entities: data.entities,
        preferences: {
          ...getPreferences('coverImages'), // reset filters
          ...action.payload.preferences,
        },
        totalCount: action.payload.coverImagesCount,
      };
    }

    case actionTypes.ADD_COVER_IMAGE_SUCCESS: {
      return {
        ...state,
        allIds: [...state.allIds, action.payload.id],
        entities: {
          ...state.entities,
          coverImages: {
            ...state.entities.coverImages,
            [action.payload.id]: action.payload,
          },
        },
        loading: false,
        message: 'A cover-image has been added',
      };
    }

    case actionTypes.UPDATE_COVER_IMAGE_SUCCESS: {
      return {
        ...state,
        entities: {
          ...state.entities,
          coverImages: {
            ...state.entities.coverImages,
            [action.payload.id]: action.payload,
          },
        },
        loading: false,
        message: 'A cover-image has been updated',
      };
    }

    case actionTypes.LOAD_COVER_IMAGE_SUCCESS: {
      return {
        ...state,
        editing: action.payload,
      };
    }

    case actionTypes.REMOVE_COVER_IMAGE_SUCCESS: {
      const deletedId = action.payload.deletedId;
      const { [deletedId]: removed, ...coverImages } =
        state.entities.coverImages;
      return {
        ...state,
        allIds: state.allIds.filter((id) => id !== deletedId),
        entities: {
          ...state.entities,
          coverImages,
        },
        message: 'A cover-image has been removed',
      };
    }

    case actionTypes.ADD_COVER_IMAGE_FAIL:
    case actionTypes.UPDATE_COVER_IMAGE_FAIL:
    case actionTypes.REMOVE_COVER_IMAGE_FAIL:
    case actionTypes.LOAD_COVER_IMAGE_FAIL:
    case actionTypes.LOAD_COVER_IMAGES_FAIL: {
      return { ...state, loading: false, error: action.payload };
    }

    case actionTypes.CLEAR_EDITING_COVER_IMAGE: {
      return {
        ...state,
        editing: {},
      };
    }

    case actionTypes.CLEAR_COVER_IMAGE_MESSAGE: {
      return { ...state, message: null };
    }

    case actionTypes.CLEAR_COVER_IMAGE_ERROR: {
      return { ...state, error: null };
    }

    case actionTypes.UPDATE_PREFERENCES: {
      const { key, subKey, value } = action.payload;
      if (key === 'coverImages') {
        return {
          ...state,
          preferences: { ...state.preferences, [subKey]: value },
        };
      }
      return state;
    }

    case actionTypes.RESET_PREFERENCES: {
      return {
        ...state,
        preferences: { ...action.payload.coverImages },
      };
    }

    default:
      return state;
  }
};

export default coverImagesReducer;
