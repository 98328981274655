import * as actionTypes from './types';

export const loadReports =
  (payload) =>
  (dispatch, getState, { mernApi }) => {
    dispatch({ type: actionTypes.LOAD_REPORTS });
    const {
      filters,
      page = 0,
      pageSize = 10,
      orderBy,
      orderDirection,
      search,
    } = payload;
    const skip = page * pageSize;
    const sort = orderBy
      ? `${orderDirection === 'desc' ? '-' : ''}${
          orderBy.field === 'no' ? 'createdAt' : orderBy.field
        }`
      : '-createdAt';
    const params = { sort, limit: pageSize, skip };
    const preferences = {
      page,
      pageSize,
    };
    if (orderBy?.field) {
      preferences.orderBy = orderBy.field;
    }
    if (orderDirection) {
      preferences.orderDirection = orderDirection;
    }
    filters.forEach((filter) => {
      if (filter.column.field === 'client.companyName') {
        params.clientId = filter.value;
        preferences.clientId = filter.value;
      }
    });
    let searchText = search.trim();
    if (searchText) {
      params.searchText = searchText;
      preferences.searchText = searchText;
    }
    return mernApi.get('/api/reports', { params }).then(
      (response) => {
        dispatch({
          type: actionTypes.LOAD_REPORTS_SUCCESS,
          payload: {
            ...response.data,
            preferences,
          },
        });
      },
      (err) => {
        dispatch({
          type: actionTypes.LOAD_REPORTS_FAIL,
          payload: err.response.data.error.message,
        });
      }
    );
  };

export const loadReport =
  (reportId) =>
  (dispatch, getState, { mernApi }) => {
    dispatch({ type: actionTypes.LOAD_REPORT });
    return mernApi.get(`/api/reports/${reportId}`, { params: {} }).then(
      (response) => {
        dispatch({
          type: actionTypes.LOAD_REPORT_SUCCESS,
          payload: response.data.report,
        });
      },
      (err) => {
        dispatch({
          type: actionTypes.LOAD_REPORT_FAIL,
          payload: err.response.data.error.message,
        });
      }
    );
  };

export const removeReport =
  (reportId) =>
  (dispatch, getState, { mernApi }) => {
    dispatch({ type: actionTypes.REMOVE_REPORT });
    return mernApi.delete(`/api/reports/${reportId}`).then(
      (response) => {
        dispatch({
          type: actionTypes.REMOVE_REPORT_SUCCESS,
          payload: { ...response.data, deletedId: reportId },
        });
      },
      (err) => {
        dispatch({
          type: actionTypes.REMOVE_REPORT_FAIL,
          payload: err.response.data.error.message,
        });
      }
    );
  };

export const generatePreviewReport =
  (formValues) =>
  (dispatch, getState, { mernApi }) => {
    dispatch({ type: actionTypes.GENERATE_PREVIEW_REPORT });
    return mernApi.post('/api/reports/generate', formValues).then(
      (response) => {
        dispatch({
          type: actionTypes.GENERATE_PREVIEW_REPORT_SUCCESS,
          payload: response.data.report,
        });
        return Promise.resolve(response.data);
      },
      (err) => {
        dispatch({
          type: actionTypes.GENERATE_PREVIEW_REPORT_FAIL,
          payload: err.response.data.error.message,
        });
      }
    );
  };

export const addReport =
  (formValues) =>
  (dispatch, getState, { mernApi }) => {
    dispatch({ type: actionTypes.ADD_REPORT });
    formValues.save = true;
    return mernApi.post('/api/reports/generate', formValues).then(
      (response) => {
        dispatch({
          type: actionTypes.ADD_REPORT_SUCCESS,
          payload: response.data.report,
        });
        return Promise.resolve(response.data);
      },
      (err) => {
        dispatch({
          type: actionTypes.ADD_REPORT_FAIL,
          payload: err.response.data.error.message,
        });
      }
    );
  };

export const clearEditingReport = () => {
  return {
    type: actionTypes.CLEAR_EDITING_REPORT,
  };
};

export const clearPreviewReport = () => {
  return {
    type: actionTypes.CLEAR_PREVIEW_REPORT,
  };
};

export const clearReportErrorMessage = () => {
  return {
    type: actionTypes.CLEAR_REPORT_ERROR_MESSAGE,
  };
};

export const clearReportMessage = () => {
  return {
    type: actionTypes.CLEAR_REPORT_MESSAGE,
  };
};
