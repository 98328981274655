import React, { useMemo } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { getAttemptedPath, getIsSignedIn } from '../../store/selectors';
import { setAttemptedPath } from '../../store/actions';

/**
 * If user already logged in, redirect to default protected path.
 *
 */
const AnonymousRoute = ({
  attemptedPath,
  component: Component,
  isSignedIn,
  setAttemptedPath,
  ...rest
}) => {
  const redirectPath = useMemo(() => {
    if (isSignedIn && attemptedPath) {
      setAttemptedPath(null);
      return attemptedPath;
    }

    return '/';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSignedIn]);

  return (
    <Route
      {...rest}
      render={(props) =>
        isSignedIn ? <Redirect to={redirectPath} /> : <Component {...props} />
      }
    />
  );
};

const mapStateToProps = (state) => {
  return {
    isSignedIn: getIsSignedIn(state),
    attemptedPath: getAttemptedPath(state),
  };
};

export default connect(mapStateToProps, {
  setAttemptedPath,
})(AnonymousRoute);
