import mernApi from './mern';

export const getAssetAwsS3UploadKit = ({ fileName, assetId }) => {
  return mernApi
    .post('/api/assets/signed-request-options', { fileName, assetId })
    .then(
      (response) => {
        return Promise.resolve({ awsS3UploadKit: response.data });
      },
      (err) => {
        console.log(
          '🚀 ~ file: helpers.js:12 ~ getAssetAwsS3UploadKit ~ err:',
          err
        );
      }
    );
};

export const getCoverImageAwsS3UploadKit = ({ fileName, coverImageId }) => {
  return mernApi
    .post('/api/cover-images/signed-request-options', {
      fileName,
      coverImageId,
    })
    .then(
      (response) => {
        return Promise.resolve({ awsS3UploadKit: response.data });
      },
      (err) => {
        console.log(
          '🚀 ~ file: helpers.js:31 ~ getCoverImageAwsS3UploadKit ~ err:',
          err
        );
      }
    );
};
