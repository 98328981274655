import React, { useMemo } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { getCurrentUser, getIsSignedIn } from '../../store/selectors';
import { setAttemptedPath } from '../../store/actions';

/**
 * If requiresRole is set, the current user must have the same role or higher to continue.
 * If the current user is admin or root, he/she is authorized by default.
 * If the current user is normal user, then check his/her permssions.
 * After checking, if he/she is not authorized, redirect to default path.
 *
 */
const ProtectedRoute = ({
  component: Component,
  currentUser,
  isSignedIn,
  requiresRole,
  requiresPermissions,
  customPermissionCheck,
  location,
  setAttemptedPath,
  ...rest
}) => {
  const isAuthorized = useMemo(() => {
    if (!isSignedIn) {
      setAttemptedPath(location.pathname);
      return false;
    }

    if (requiresRole === 'root' && currentUser.role !== 'root') {
      return false;
    }

    if (
      requiresRole === 'admin' &&
      currentUser.role !== 'root' &&
      currentUser.role !== 'admin'
    ) {
      return false;
    }

    if (currentUser.role === 'root' || currentUser.role === 'admin') {
      return true;
    }

    if (customPermissionCheck) {
      return customPermissionCheck({ currentUser });
    }

    if (!requiresPermissions) {
      return true;
    }

    const perms = Array.isArray(requiresPermissions)
      ? requiresPermissions
      : [requiresPermissions];

    return perms.every((perm) => !!currentUser.permissions[perm]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isSignedIn,
    currentUser,
    requiresRole,
    requiresPermissions,
    customPermissionCheck,
  ]);

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthorized ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: getCurrentUser(state),
    isSignedIn: getIsSignedIn(state),
  };
};

export default connect(mapStateToProps, { setAttemptedPath })(ProtectedRoute);
