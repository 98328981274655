import React from 'react';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import { Field, reduxForm } from 'redux-form';
import { updatePreferences, resetPreferences } from '../../store/actions';
import * as utils from '../../utils';
import {
  AD_PRICES_ORDER_BY_OPTIONS,
  ADS_ORDER_BY_OPTIONS,
  IMAGES_ORDER_BY_OPTIONS,
  NUMBER_OPTIONS,
  ORDER_DIRECTION_OPTIONS,
  CATEGORIES_ORDER_BY_OPTIONS,
  CLIENTS_ORDER_BY_OPTIONS,
  INVOICES_ORDER_BY_OPTIONS,
  NOTIFICATION_MESSAGES_ORDER_BY_OPTIONS,
  USERS_ORDER_BY_OPTIONS,
} from './constants';

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  subtitle: {
    marginBottom: theme.spacing(2),
    color: 'rgba(0, 0, 0, 0.65)',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    paddingBottom: theme.spacing(2),
  },
  section: {
    marginBottom: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(3, 4, 3, 0),
  },
  backdrop: {
    zIndex: 999,
    backgroundColor: 'rgba(255, 255, 255, 0.6)',
  },
});

class Preferences extends React.Component {
  onResetPreferences = () => {
    utils.resetPreferences();
    this.props.resetPreferences();
  };

  updatePreferences = (key, subKey, value) => {
    const p = utils.getPreferences(key);
    p[subKey] = value;
    utils.setPreferences(p, key);
    this.props.updatePreferences(key, subKey, value);
  };

  renderTextField = ({ input, label, meta: { touched, error }, ...custom }) => {
    return (
      <TextField
        label={label}
        error={touched && !!error}
        helperText={touched && error}
        variant="outlined"
        margin="none"
        required
        fullWidth
        {...input}
        {...custom}
      />
    );
  };

  renderOptions = (options) => {
    return options.map((option) => {
      return (
        <MenuItem key={option.id} value={option.id}>
          {option.name}
        </MenuItem>
      );
    });
  };

  render() {
    const { classes, processing, clearAdminMessages, errorMessage, message } =
      this.props;
    return (
      <>
        <CssBaseline />
        <Paper elevation={1} className={classes.paper}>
          <Typography component="h1" variant="h6" className={classes.title}>
            Your Preferences
          </Typography>
          <Grid container spacing={4} direction="row" justifyContent="center">
            <Grid item xs={12} sm={9}>
              <form className={classes.form}>
                {/* Ad Prices Settings */}
                <Typography
                  component="h6"
                  variant="h6"
                  className={classes.subtitle}
                >
                  Ad Prices Settings
                </Typography>
                <Grid container spacing={3} className={classes.section}>
                  <Grid item sm={5} xs={12}>
                    <Field
                      id="adPricesOrderBy"
                      label="Default Sort By"
                      name="adPricesOrderBy"
                      component={this.renderTextField}
                      select
                      onChange={(e, newValue) => {
                        this.updatePreferences('adPrices', 'orderBy', newValue);
                      }}
                    >
                      {this.renderOptions(AD_PRICES_ORDER_BY_OPTIONS)}
                    </Field>
                  </Grid>
                  <Grid item sm={5} xs={12}>
                    <Field
                      id="adPricesOrderDirection"
                      label="Default Sort Direction"
                      name="adPricesOrderDirection"
                      component={this.renderTextField}
                      select
                      onChange={(e, newValue) => {
                        this.updatePreferences(
                          'adPrices',
                          'orderDirection',
                          newValue
                        );
                      }}
                    >
                      {this.renderOptions(ORDER_DIRECTION_OPTIONS)}
                    </Field>
                  </Grid>
                </Grid>

                {/* Ads Settings */}
                <Typography
                  component="h6"
                  variant="h6"
                  className={classes.subtitle}
                >
                  Ads Settings
                </Typography>
                <Grid container spacing={3} className={classes.section}>
                  <Grid item sm={5} xs={12}>
                    <Field
                      id="adsOrderBy"
                      label="Default Sort By"
                      name="adsOrderBy"
                      component={this.renderTextField}
                      select
                      onChange={(e, newValue) => {
                        this.updatePreferences('ads', 'orderBy', newValue);
                      }}
                    >
                      {this.renderOptions(ADS_ORDER_BY_OPTIONS)}
                    </Field>
                  </Grid>
                  <Grid item sm={5} xs={12}>
                    <Field
                      id="adsOrderDirection"
                      label="Default Sort Direction"
                      name="adsOrderDirection"
                      component={this.renderTextField}
                      select
                      onChange={(e, newValue) => {
                        this.updatePreferences(
                          'ads',
                          'orderDirection',
                          newValue
                        );
                      }}
                    >
                      {this.renderOptions(ORDER_DIRECTION_OPTIONS)}
                    </Field>
                  </Grid>
                  <Grid item sm={5} xs={12}>
                    <Field
                      id="adsCutOffTimeDs"
                      label="Default Cut-off Time (days)"
                      name="adsCutOffTimeDs"
                      component={this.renderTextField}
                      select
                      onChange={(e, newValue) => {
                        this.updatePreferences('ads', 'cutOffTimeDs', newValue);
                      }}
                    >
                      {this.renderOptions(NUMBER_OPTIONS)}
                    </Field>
                  </Grid>
                </Grid>

                {/* Categories Settings */}
                <Typography
                  component="h6"
                  variant="h6"
                  className={classes.subtitle}
                >
                  Categories Settings
                </Typography>
                <Grid container spacing={3} className={classes.section}>
                  <Grid item sm={5} xs={12}>
                    <Field
                      id="categoriesOrderBy"
                      label="Default Sort By"
                      name="categoriesOrderBy"
                      component={this.renderTextField}
                      select
                      onChange={(e, newValue) => {
                        this.updatePreferences(
                          'categories',
                          'orderBy',
                          newValue
                        );
                      }}
                    >
                      {this.renderOptions(CATEGORIES_ORDER_BY_OPTIONS)}
                    </Field>
                  </Grid>
                  <Grid item sm={5} xs={12}>
                    <Field
                      id="categoriesOrderDirection"
                      label="Default Sort Direction"
                      name="categoriesOrderDirection"
                      component={this.renderTextField}
                      select
                      onChange={(e, newValue) => {
                        this.updatePreferences(
                          'categories',
                          'orderDirection',
                          newValue
                        );
                      }}
                    >
                      {this.renderOptions(ORDER_DIRECTION_OPTIONS)}
                    </Field>
                  </Grid>
                </Grid>

                {/* Clients Settings */}
                <Typography
                  component="h6"
                  variant="h6"
                  className={classes.subtitle}
                >
                  Clients Settings
                </Typography>
                <Grid container spacing={3} className={classes.section}>
                  <Grid item sm={5} xs={12}>
                    <Field
                      id="clientsOrderBy"
                      label="Default Sort By"
                      name="clientsOrderBy"
                      component={this.renderTextField}
                      select
                      onChange={(e, newValue) => {
                        this.updatePreferences('clients', 'orderBy', newValue);
                      }}
                    >
                      {this.renderOptions(CLIENTS_ORDER_BY_OPTIONS)}
                    </Field>
                  </Grid>
                  <Grid item sm={5} xs={12}>
                    <Field
                      id="clientsOrderDirection"
                      label="Default Sort Direction"
                      name="clientsOrderDirection"
                      component={this.renderTextField}
                      select
                      onChange={(e, newValue) => {
                        this.updatePreferences(
                          'clients',
                          'orderDirection',
                          newValue
                        );
                      }}
                    >
                      {this.renderOptions(ORDER_DIRECTION_OPTIONS)}
                    </Field>
                  </Grid>
                </Grid>

                {/* Assets Settings */}
                <Typography
                  component="h6"
                  variant="h6"
                  className={classes.subtitle}
                >
                  Assets Settings
                </Typography>
                <Grid container spacing={3} className={classes.section}>
                  <Grid item sm={5} xs={12}>
                    <Field
                      id="assetsOrderBy"
                      label="Default Sort By"
                      name="assetsOrderBy"
                      component={this.renderTextField}
                      select
                      onChange={(e, newValue) => {
                        this.updatePreferences('assets', 'orderBy', newValue);
                      }}
                    >
                      {this.renderOptions(IMAGES_ORDER_BY_OPTIONS)}
                    </Field>
                  </Grid>
                  <Grid item sm={5} xs={12}>
                    <Field
                      id="assetsOrderDirection"
                      label="Default Sort Direction"
                      name="assetsOrderDirection"
                      component={this.renderTextField}
                      select
                      onChange={(e, newValue) => {
                        this.updatePreferences(
                          'assets',
                          'orderDirection',
                          newValue
                        );
                      }}
                    >
                      {this.renderOptions(ORDER_DIRECTION_OPTIONS)}
                    </Field>
                  </Grid>
                  <Grid item sm={5} xs={12}>
                    <Field
                      id="assetsCutOffTimeDs"
                      label="Default Cut-off Time (days)"
                      name="assetsCutOffTimeDs"
                      component={this.renderTextField}
                      select
                      onChange={(e, newValue) => {
                        this.updatePreferences(
                          'assets',
                          'cutOffTimeDs',
                          newValue
                        );
                      }}
                    >
                      {this.renderOptions(NUMBER_OPTIONS)}
                    </Field>
                  </Grid>
                  <Grid item sm={5} xs={12}>
                    <Field
                      id="assetsTopStoriesTimeDs"
                      label="Default Top Stories Time (days)"
                      name="assetsTopStoriesTimeDs"
                      component={this.renderTextField}
                      select
                      onChange={(e, newValue) => {
                        this.updatePreferences(
                          'assets',
                          'topStoriesTimeDs',
                          newValue
                        );
                      }}
                    >
                      {this.renderOptions(NUMBER_OPTIONS)}
                    </Field>
                  </Grid>
                  <Grid item sm={5} xs={12}>
                    <Field
                      id="assetsAutoSaveIntervalS"
                      label="Auto Save Interval (seconds)"
                      name="assetsAutoSaveIntervalS"
                      component={this.renderTextField}
                      select
                      onChange={(e, newValue) => {
                        this.updatePreferences(
                          'assets',
                          'autoSaveIntervalS',
                          newValue
                        );
                      }}
                    >
                      {this.renderOptions(NUMBER_OPTIONS)}
                    </Field>
                  </Grid>
                </Grid>

                {/* Invoices Settings */}
                <Typography
                  component="h6"
                  variant="h6"
                  className={classes.subtitle}
                >
                  Invoices Settings
                </Typography>
                <Grid container spacing={3} className={classes.section}>
                  <Grid item sm={5} xs={12}>
                    <Field
                      id="invoicesOrderBy"
                      label="Default Sort By"
                      name="invoicesOrderBy"
                      component={this.renderTextField}
                      select
                      onChange={(e, newValue) => {
                        this.updatePreferences('invoices', 'orderBy', newValue);
                      }}
                    >
                      {this.renderOptions(INVOICES_ORDER_BY_OPTIONS)}
                    </Field>
                  </Grid>
                  <Grid item sm={5} xs={12}>
                    <Field
                      id="invoicesOrderDirection"
                      label="Default Sort Direction"
                      name="invoicesOrderDirection"
                      component={this.renderTextField}
                      select
                      onChange={(e, newValue) => {
                        this.updatePreferences(
                          'invoices',
                          'orderDirection',
                          newValue
                        );
                      }}
                    >
                      {this.renderOptions(ORDER_DIRECTION_OPTIONS)}
                    </Field>
                  </Grid>
                </Grid>

                {/* Notification Messages Settings */}
                <Typography
                  component="h6"
                  variant="h6"
                  className={classes.subtitle}
                >
                  Notification Messages Settings
                </Typography>
                <Grid container spacing={3} className={classes.section}>
                  <Grid item sm={5} xs={12}>
                    <Field
                      id="notificationMessagesOrderBy"
                      label="Default Sort By"
                      name="notificationMessagesOrderBy"
                      component={this.renderTextField}
                      select
                      onChange={(e, newValue) => {
                        this.updatePreferences(
                          'notificationMessages',
                          'orderBy',
                          newValue
                        );
                      }}
                    >
                      {this.renderOptions(
                        NOTIFICATION_MESSAGES_ORDER_BY_OPTIONS
                      )}
                    </Field>
                  </Grid>
                  <Grid item sm={5} xs={12}>
                    <Field
                      id="notificationMessagesOrderDirection"
                      label="Default Sort Direction"
                      name="notificationMessagesOrderDirection"
                      component={this.renderTextField}
                      select
                      onChange={(e, newValue) => {
                        this.updatePreferences(
                          'notificationMessages',
                          'orderDirection',
                          newValue
                        );
                      }}
                    >
                      {this.renderOptions(ORDER_DIRECTION_OPTIONS)}
                    </Field>
                  </Grid>
                  <Grid item sm={5} xs={12}>
                    <Field
                      id="notificationMessagesAutoSaveIntervalS"
                      label="Auto Save Interval (seconds)"
                      name="notificationMessagesAutoSaveIntervalS"
                      component={this.renderTextField}
                      select
                      onChange={(e, newValue) => {
                        this.updatePreferences(
                          'notificationMessages',
                          'autoSaveIntervalS',
                          newValue
                        );
                      }}
                    >
                      {this.renderOptions(NUMBER_OPTIONS)}
                    </Field>
                  </Grid>
                </Grid>

                {/* Users Settings */}
                <Typography
                  component="h6"
                  variant="h6"
                  className={classes.subtitle}
                >
                  Users Settings
                </Typography>
                <Grid container spacing={3} className={classes.section}>
                  <Grid item sm={5} xs={12}>
                    <Field
                      id="usersOrderBy"
                      label="Default Sort By"
                      name="usersOrderBy"
                      component={this.renderTextField}
                      select
                      onChange={(e, newValue) => {
                        this.updatePreferences('users', 'orderBy', newValue);
                      }}
                    >
                      {this.renderOptions(USERS_ORDER_BY_OPTIONS)}
                    </Field>
                  </Grid>
                  <Grid item sm={5} xs={12}>
                    <Field
                      id="usersOrderDirection"
                      label="Default Sort Direction"
                      name="usersOrderDirection"
                      component={this.renderTextField}
                      select
                      onChange={(e, newValue) => {
                        this.updatePreferences(
                          'users',
                          'orderDirection',
                          newValue
                        );
                      }}
                    >
                      {this.renderOptions(ORDER_DIRECTION_OPTIONS)}
                    </Field>
                  </Grid>
                </Grid>

                <Grid>
                  <Button
                    color="secondary"
                    variant="contained"
                    type="button"
                    onClick={this.onResetPreferences}
                  >
                    Reset All Preferences To Default
                  </Button>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Paper>
        <Snackbar
          autoHideDuration={5000}
          open={!!message}
          onClose={() => clearAdminMessages()}
        >
          <Alert severity="success">{message}</Alert>
        </Snackbar>
        <Snackbar
          autoHideDuration={5000}
          open={!!errorMessage}
          onClose={() => clearAdminMessages()}
        >
          <Alert severity="error">{errorMessage}</Alert>
        </Snackbar>
        <Backdrop className={classes.backdrop} open={processing}>
          <CircularProgress />
        </Backdrop>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const preferences = utils.getPreferences();
  return {
    errorMessage: state.admin.errorMessage,
    message: state.admin.message,
    processing: state.admin.processing,
    initialValues: {
      adPricesOrderBy: preferences.adPrices.orderBy,
      adPricesOrderDirection: preferences.adPrices.orderDirection,
      adsOrderBy: preferences.ads.orderBy,
      adsOrderDirection: preferences.ads.orderDirection,
      adsCutOffTimeDs: preferences.ads.cutOffTimeDs,
      categoriesOrderBy: preferences.categories.orderBy,
      categoriesOrderDirection: preferences.categories.orderDirection,
      clientsOrderBy: preferences.clients.orderBy,
      clientsOrderDirection: preferences.clients.orderDirection,
      assetsOrderBy: preferences.assets.orderBy,
      assetsOrderDirection: preferences.assets.orderDirection,
      assetsCutOffTimeDs: preferences.assets.cutOffTimeDs,
      assetsTopStoriesTimeDs: preferences.assets.topStoriesTimeDs,
      assetsAutoSaveIntervalS: preferences.assets.autoSaveIntervalS,
      invoicesOrderBy: preferences.invoices.orderBy,
      invoicesOrderDirection: preferences.invoices.orderDirection,
      notificationMessagesOrderBy: preferences.notificationMessages.orderBy,
      notificationMessagesOrderDirection:
        preferences.notificationMessages.orderDirection,
      notificationMessagesAutoSaveIntervalS:
        preferences.notificationMessages.autoSaveIntervalS,
      usersOrderBy: preferences.users.orderBy,
      usersOrderDirection: preferences.users.orderDirection,
    },
  };
};

const validate = (values) => {
  const errors = {};
  return errors;
};

export default compose(
  connect(mapStateToProps, {
    resetPreferences,
    updatePreferences,
  }),
  reduxForm({ form: 'editPreferences', validate, enableReinitialize: true }),
  withStyles(styles)
)(Preferences);
