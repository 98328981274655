import React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { NumericFormat } from 'react-number-format';
import {
  getAdPrices,
  getAdPricesCount,
  getAdPricesErrorMessage,
  getAdPricesMessage,
  selectCategories,
  selectCategoryLookup,
} from '../../store/selectors';
import {
  loadAdPrices,
  addAdPrice,
  loadCategories,
  updateAdPrice,
  removeAdPrice,
  clearAdPriceErrorMessage,
  clearAdPriceMessage,
} from '../../store/actions';
import MaterialTable from '../../components/MaterialTable';
import SelectFilterField from '../../components/SelectFilterField';
import {
  formatDateTime,
  formatCurrency,
  dollarsToCents,
  MAX_PAGE_SIZE,
} from '../../utils';

class AdPriceList extends React.Component {
  initialPage = -1;

  componentDidMount() {
    this.initialPage = this.props.initial.page;
    this.props.loadCategories({ pageSize: MAX_PAGE_SIZE });
  }
  createHandlerWrapper = (data, handler) =>
    new Promise((resolve, reject) => {
      handler(data).then(() => {
        if (!this.props.errorMessage) {
          resolve();
        }
        reject();
      });
    });

  onRowAdd = (data) => {
    const payload = {
      position: data.position,
      categoryId: data.category?.id,
      priceRate: dollarsToCents(data.priceRate),
    };
    return this.createHandlerWrapper(payload, this.props.addAdPrice);
  };

  onRowUpdate = (data) => {
    const payload = { ...data, priceRate: dollarsToCents(data.priceRate) };
    return this.createHandlerWrapper(payload, this.props.updateAdPrice);
  };

  onRowDelete = (data) =>
    this.createHandlerWrapper(data, this.props.removeAdPrice);

  isDeletable = (data) => !data.isTopStories;

  renderCreatedAt = (row) => {
    return formatDateTime(row.createdAt);
  };

  renderUpdatedAt = (row) => {
    return formatDateTime(row.updatedAt);
  };

  renderEditNumberField = (props, decimalScale) => {
    return (
      <NumericFormat
        allowNegative={false}
        customInput={TextField}
        label={props.columnDef.title}
        margin="none"
        fullWidth
        decimalScale={decimalScale}
        onValueChange={(value) => props.onChange(value.floatValue)}
      />
    );
  };

  renderCategoryFilter = (props) => {
    const { categories, initial } = this.props;
    return (
      <SelectFilterField
        items={categories}
        initialValue={initial.categoryId}
        onFilterChanged={(value) => {
          props.onFilterChanged(props.columnDef.tableData.id, value);
        }}
        labelKey="name"
      />
    );
  };

  render() {
    const {
      errorMessage,
      message,
      initial,
      loading,
      clearAdPriceErrorMessage,
      clearAdPriceMessage,
      loadAdPrices,
    } = this.props;

    const columns = [
      {
        title: 'Position',
        field: 'position',
        type: 'numeric',
        align: 'left',
        editable: 'onAdd',
        filtering: false,
        render: (row) => (row.position === 0 ? '0 (Default)' : row.position),
        editComponent: (props) => this.renderEditNumberField(props, 0),
        defaultSort:
          initial.orderBy === 'position' ? initial.orderDirection : undefined,
      },
      {
        title: 'Category',
        field: 'category.id',
        filterComponent: this.renderCategoryFilter,
        sorting: false,
        editable: 'onAdd',
        defaultFilter: initial.categoryId,
        lookup: this.props.categoriesLookup,
      },
      {
        title: 'Price Rate ($/hr)',
        field: 'priceRate',
        align: 'right',
        filtering: false,
        render: (row) => formatCurrency(row.priceRate),
        editComponent: (props) => this.renderEditNumberField(props, 2),
        defaultSort:
          initial.orderBy === 'priceRate' ? initial.orderDirection : undefined,
      },
      {
        title: 'Created At',
        field: 'createdAt',
        type: 'datetime',
        editable: 'never',
        render: this.renderCreatedAt,
        filtering: false,
        defaultSort:
          initial.orderBy === 'createdAt' ? initial.orderDirection : undefined,
      },
      {
        title: 'Updated At',
        field: 'updatedAt',
        type: 'datetime',
        editable: 'never',
        render: this.renderUpdatedAt,
        filtering: false,
        defaultSort:
          initial.orderBy === 'updatedAt' ? initial.orderDirection : undefined,
      },
    ];

    return (
      <>
        <MaterialTable
          title="Ad Prices"
          columns={columns}
          data={(query) =>
            new Promise((resolve) => {
              if (this.initialPage > 0) {
                query.page = this.initialPage;
                this.initialPage = -1;
              }
              // orderby and orderDirection are missing from the initial query
              const orderBy = columns[query.orderByCollection[0].orderBy];
              const orderDirection = query.orderByCollection[0].orderDirection;
              loadAdPrices({ ...query, orderBy, orderDirection })
                .then(() => {
                  if (this.props.adPrices.length === 0 && query.page > 0) {
                    query.page = query.page - 1;
                    return loadAdPrices(query);
                  }
                  return null;
                })
                .then(() => {
                  resolve({
                    data: this.props.adPrices,
                    page: query.page,
                    totalCount: this.props.totalCount,
                  });
                });
            })
          }
          editable={{
            isDeletable: this.isDeletable,
            onRowAdd: this.onRowAdd,
            onRowUpdate: this.onRowUpdate,
            onRowDelete: this.onRowDelete,
          }}
          options={{
            filtering: true,
            initialPage: this.initialPage > 0 ? this.initialPage : 0,
            pageSize: this.props.initial.pageSize,
            searchText: this.props.initial.searchText,
            emptyRowsWhenPaging: false,
          }}
          isLoading={loading}
        />
        <Snackbar
          open={!!errorMessage}
          autoHideDuration={7000}
          onClose={clearAdPriceErrorMessage}
        >
          <Alert
            severity="error"
            action={
              <Button
                size="small"
                color="secondary"
                onClick={clearAdPriceErrorMessage}
              >
                Dismiss
              </Button>
            }
          >
            {errorMessage}
          </Alert>
        </Snackbar>
        <Snackbar
          open={!!message}
          autoHideDuration={7000}
          onClose={clearAdPriceMessage}
        >
          <Alert
            severity="success"
            action={
              <Button
                size="small"
                color="secondary"
                onClick={clearAdPriceMessage}
              >
                Dismiss
              </Button>
            }
          >
            {message}
          </Alert>
        </Snackbar>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    errorMessage: getAdPricesErrorMessage(state),
    message: getAdPricesMessage(state),
    adPrices: getAdPrices(state),
    categories: selectCategories(state),
    categoriesLookup: selectCategoryLookup(state),
    initial: state.adPrices.preferences,
    totalCount: getAdPricesCount(state),
    loading: state.adPrices.loading,
  };
};

export default compose(
  connect(mapStateToProps, {
    loadAdPrices,
    loadCategories,
    addAdPrice,
    updateAdPrice,
    removeAdPrice,
    clearAdPriceErrorMessage,
    clearAdPriceMessage,
  })
)(AdPriceList);
