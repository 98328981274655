import { createSelector } from '@reduxjs/toolkit';
import { denormalize } from 'normalizr';
import * as schemas from '../schemas';

export const getAssetsState = (state) => state.assets;

export const getAssetsAllIds = (state) => state.assets.allIds;

export const getAssetsCounter = (state) => state.assets.counter;

export const getAssetsEntities = (state) => state.assets.entities;

export const getAssetsErrorMessage = (state) => state.assets.error;

export const getAssetsLoading = (state) => state.assets.loading;

export const getAssetsMessage = (state) => state.assets.message;

export const getAssetsSummary = (state) => state.assets.summary;

export const selectAssetsPreferences = (state) => state.assets.preferences;

export const getAssets = createSelector(
  getAssetsEntities,
  getAssetsAllIds,
  (entities, allIds) => denormalize(allIds, schemas.assetListSchema, entities)
);

export const getEditingAsset = (state) => state.assets.editing;
