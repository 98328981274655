import React from 'react';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Snackbar from '@mui/material/Snackbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import { PlusBox } from 'mdi-material-ui';
import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';
import {
  getInvoices,
  getInvoicesCompletedCount,
  getInvoicesCount,
  getInvoicesMessage,
  selectCategories,
  getClients,
} from '../../store/selectors';
import {
  loadInvoices,
  loadClients,
  removeInvoice,
  clearInvoiceMessage,
  updateInvoice,
  unloadClients,
} from '../../store/actions';
import MaterialTable from '../../components/MaterialTable';
import SelectFilterField from '../../components/SelectFilterField';
import { MTableToolbar } from '@material-table/core';
import {
  dateToInvoiceNo,
  formatDateTime,
  formatPercent,
  formatCurrency,
  MAX_PAGE_SIZE,
} from '../../utils';
import { COMPLETION_STATUS } from './constants';

const styles = (theme) => ({
  ad: {
    width: 300,
    height: 100,
  },
  toolbarInfo: {
    padding: `0px ${theme.spacing(2)}`,
  },
  toolbarChip: {
    marginRight: theme.spacing(1),
  },
  isPublished: {
    marginLeft: theme.spacing(1),
  },
  tooltipText: {
    fontSize: 'inherit',
  },
});

class InvoiceList extends React.Component {
  initialPage = -1;
  tableRef = React.createRef();

  componentDidMount() {
    this.initialPage = this.props.initial.page;
    this.props.loadClients({ pageSize: MAX_PAGE_SIZE });
  }

  componentWillUnmount() {
    this.props.unloadClients();
  }

  getData = (columns) => (query) =>
    new Promise((resolve) => {
      if (this.initialPage > 0) {
        query.page = this.initialPage;
        this.initialPage = -1;
      }
      // orderby and orderDirection are missing from the initial query
      const orderBy = columns[query.orderByCollection[0].orderBy];
      const orderDirection = query.orderByCollection[0].orderDirection;
      this.props
        .loadInvoices({ ...query, orderBy, orderDirection })
        .then(() => {
          if ((this.props.invoices.length === 0) & (query.page > 0)) {
            query.page = query.page - 1;
            return this.props.loadInvoices(query);
          }
          return null;
        })
        .then(() => {
          resolve({
            data: this.props.invoices,
            page: query.page,
            totalCount: this.props.totalCount,
          });
        });
    });

  renderToolbar = (props) => {
    const { classes, totalCount = 0, completedCount = 0 } = this.props;
    const incompletedCount = totalCount - completedCount;

    return (
      <div>
        <MTableToolbar {...props} />
        <div className={classes.toolbarInfo}>
          <Tooltip
            title={
              <>
                <Typography style={{ fontSize: 'inherit' }}>
                  <b>Completed</b>: {completedCount}
                </Typography>
                <Typography style={{ fontSize: 'inherit' }}>
                  <b>Incompleted</b>: {incompletedCount}
                </Typography>
              </>
            }
          >
            <Chip
              label={`Total: ${totalCount}`}
              color="secondary"
              className={classes.toolbarChip}
            />
          </Tooltip>
          <Chip
            label={`Incompleted: ${incompletedCount}`}
            color="secondary"
            className={classes.toolbarChip}
          />
        </div>
      </div>
    );
  };

  renderDateTime = (date) => {
    return formatDateTime(date);
  };

  renderInvoiceNo = (date) => {
    return dateToInvoiceNo(date);
  };

  renderDiscount = (discount) => {
    return discount ? formatPercent(discount) : '';
  };

  renderCompletionStatusFilter = (props) => {
    return (
      <SelectFilterField
        items={COMPLETION_STATUS}
        initialValue={this.props.initial.completionStatus}
        onFilterChanged={(value) => {
          props.onFilterChanged(props.columnDef.tableData.id, value);
        }}
        labelKey="name"
      />
    );
  };

  renderClientFilter = (props) => {
    const { clients, initial } = this.props;
    return (
      <SelectFilterField
        items={clients}
        initialValue={initial.clientId}
        onFilterChanged={(value) => {
          props.onFilterChanged(props.columnDef.tableData.id, value);
        }}
        labelKey="companyName"
      />
    );
  };

  render() {
    const { removeInvoice, push, message, clearInvoiceMessage, initial } =
      this.props;
    const columns = [
      {
        title: 'No.',
        field: 'no',
        filtering: false,
        editable: 'never',
        render: (row) => this.renderInvoiceNo(row.createdAt),
        defaultSort:
          initial.orderBy === 'no' ? initial.orderDirection : undefined,
      },
      {
        title: 'Completed',
        field: 'isCompleted',
        type: 'boolean',
        filterComponent: this.renderCompletionStatusFilter,
        defaultFilter: initial.completionStatus,
        defaultSort:
          initial.orderBy === 'isCompleted'
            ? initial.orderDirection
            : undefined,
      },
      {
        title: 'Date From',
        field: 'from',
        type: 'datetime',
        editable: 'never',
        filtering: false,
        render: (row) => this.renderDateTime(row.from),
        defaultSort:
          initial.orderBy === 'from' ? initial.orderDirection : undefined,
      },
      {
        title: 'Date To',
        field: 'to',
        type: 'datetime',
        editable: 'never',
        filtering: false,
        render: (row) => this.renderDateTime(row.to),
        defaultSort:
          initial.orderBy === 'to' ? initial.orderDirection : undefined,
      },
      {
        title: 'Client',
        field: 'client.companyName',
        filterComponent: this.renderClientFilter,
        sorting: false,
        editable: 'never',
        defaultFilter: initial.clientId,
      },
      {
        title: 'Discount',
        field: 'discount',
        type: 'numeric',
        editable: 'never',
        filtering: false,
        render: (row) => this.renderDiscount(row.discount),
        defaultSort:
          initial.orderBy === 'discount' ? initial.orderDirection : undefined,
      },
      {
        title: 'Total Price ($)',
        field: 'totalPrice',
        type: 'numeric',
        editable: 'never',
        filtering: false,
        render: (row) => formatCurrency(row.totalPrice),
        defaultSort:
          initial.orderBy === 'totalPrice' ? initial.orderDirection : undefined,
      },
      {
        title: 'Created At',
        field: 'createdAt',
        type: 'datetime',
        editable: 'never',
        filtering: false,
        render: (row) => this.renderDateTime(row.createdAt),
        defaultSort:
          initial.orderBy === 'createdAt' ? initial.orderDirection : undefined,
      },
      {
        title: 'Updated At',
        field: 'updatedAt',
        type: 'datetime',
        editable: 'never',
        filtering: false,
        render: (row) => this.renderDateTime(row.updatedAt),
        defaultSort:
          initial.orderBy === 'updatedAt' ? initial.orderDirection : undefined,
      },
    ];
    return (
      <>
        <MaterialTable
          title="Invoices"
          tableRef={this.tableRef}
          components={{
            Toolbar: this.renderToolbar,
          }}
          columns={columns}
          data={this.getData(columns)}
          onRowClick={(event, row) => {
            if (event.target.type !== 'checkbox') {
              this.props.push(`invoices/${row.id}`);
            }
          }}
          editable={{
            onRowDelete: ({ id }) => removeInvoice(id),
          }}
          cellEditable={{
            onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
              if (newValue === oldValue) {
                return Promise.resolve();
              }
              return this.props
                .updateInvoice({
                  id: rowData.id,
                  isCompleted: newValue,
                })
                .then(() => {
                  this.tableRef.current &&
                    this.tableRef.current.onQueryChange();
                });
            },
          }}
          actions={[
            {
              icon: () => <PlusBox color="secondary" />,
              tooltip: 'Add New Invoice',
              isFreeAction: true,
              onClick: () => push('invoices/create'),
            },
          ]}
          options={{
            filtering: true,
            initialPage: this.initialPage > 0 ? this.initialPage : 0,
            pageSize: this.props.initial.pageSize,
            searchText: this.props.initial.searchText,
            padding: 'dense',
            emptyRowsWhenPaging: false,
          }}
        />

        <Snackbar
          open={!!message}
          autoHideDuration={7000}
          onClose={clearInvoiceMessage}
        >
          <Alert
            severity="success"
            action={
              <Button
                size="small"
                color="secondary"
                onClick={clearInvoiceMessage}
              >
                Dismiss
              </Button>
            }
          >
            {message}
          </Alert>
        </Snackbar>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    invoices: getInvoices(state),
    categories: selectCategories(state),
    clients: getClients(state),
    initial: state.invoices.preferences,
    totalCount: getInvoicesCount(state),
    completedCount: getInvoicesCompletedCount(state),
    message: getInvoicesMessage(state),
  };
};

export default compose(
  connect(mapStateToProps, {
    loadInvoices,
    loadClients,
    removeInvoice,
    push,
    clearInvoiceMessage,
    unloadClients,
    updateInvoice,
  }),
  withStyles(styles)
)(InvoiceList);
